import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  IMAGE_DODO_20,
  IMAGE_DODO_PICK,
  IMAGE_PETER_20,
  IMAGE_PETER_PICK,
  IMAGE_PONYA_20,
  IMAGE_PONYA_PICK,
} from "../../../../constants/appImagePath";
import { Box, Divider, Typography } from "@mui/material";
import Toolbar from "../../../../component/Toolbar";
import { LocalizedText } from "../../../../di/LanguageRepositoryProvider";
import VerticalSpacer, { HorizontalSpacer } from "../../../../component/Spacer";
import { fontStyle } from "../../../../theme/Style";
import { color } from "../../../../theme/Color";

import CommonButton from "../../../../component/CommonButton";
import IconTextDialog, {
  IconTextDialogType,
} from "../../../../component/dialog/IconTextDialog";
import { HasScooterUiState } from "../HasScooterUiState";
import { HasScooterUiEvent } from "../HasScooterUiEvent";
import { ApiService } from "../../../../restAPI/ApiService";
import { SERVER_CONFIG } from "../../../../constants/ServerConfig";
import ProgressBar from "../../../../component/ProgressBar";

interface PickNFTCharacterProps {
  uiState: HasScooterUiState;
  uiEvent: HasScooterUiEvent;
  lastPath: string;
}

const PickNFTCharacter = ({
  uiState,
  uiEvent,
  lastPath,
}: PickNFTCharacterProps) => {
  const navigate = useNavigate();
  const [char, setChar] = useState("Ponya");
  const [charData, setCharData] = useState<any>([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imgLoading, setImgLoading] = useState(false);

  const handleDimEvent = (event: boolean) => {
    //TODO: 로그인탭(회원가입 시) 오류 발생
    // if (window.Android) {
    //   window.Android.isShownDialog(event);
    // }
    // if (window.webkit) {
    //   window.webkit.messageHandlers.isShownDialog.postMessage(event);
    // }
  };

  const handleCharChange = (char: string) => {
    setChar(char);
  };

  // 이미지 프리로드 함수
  const preloadImages = (images: string[]) => {
    images?.forEach((src) => {
      const img = new Image();
      img.src = src;
    });
  };

  useEffect(() => {
    setImgLoading(true);
    ApiService.MobilePost("/pw/backend/api/findAll")
      .then((res) => {
        const data = res?.data.body;
        setCharData(data);
        // 이미지 프리로드 실행
        const imageUrls = data?.map((item: any) => item.character);
        preloadImages(imageUrls);
      })
      .then(() => {
        setImgLoading(false);
      });
  }, []);

  function onSuccessLogin() {
    if (window.Android) {
      console.log("[Success Login][Android]");
      window.Android.onLoginSuccess();
    } else if (window.webkit) {
      console.log("[Success Login][iPhone]");
      window.webkit.messageHandlers.onLoginSuccess.postMessage(
        "onLoginSuccess"
      );
    } else {
      console.log("[Success Login][Web]");
      window.location.href = SERVER_CONFIG.FRONT_URL + "/PWUserAppMap";
    }
  }

  const CreateNft = () => {
    const reqBody = {
      grade: "Red",
      characterName: char,
      uuid: localStorage.getItem("uuid"),
    };
    ApiService.BlockchainPost("/nft/reqNftChar", reqBody).then(() => {
      setLoading(false);
      onSuccessLogin();
    });
  };

  return (
    <>
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          overflowY: "scroll",
          scrollbarWidth: "none", // Firefox에서 스크롤바 숨기기
          "&::-webkit-scrollbar": {
            display: "none", // Webkit 브라우저에서    스크롤바 숨기기
          },
        }}
      >
        <VerticalSpacer height={16} />
        {loading && <ProgressBar />}
        {imgLoading && <ProgressBar />}
        <Box
          sx={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            overflowY: "scroll",
            scrollbarWidth: "none", // Firefox에서 스크롤바 숨기기
            "&::-webkit-scrollbar": {
              display: "none", // Webkit 브라우저에서    스크롤바 숨기기
            },
          }}
        >
          {/* 중간 요소 */}
          <Box
            sx={{
              width: "calc(100% - 40px)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              flexGrow: 1,
            }}
          >
            <Typography fontStyle={fontStyle.headingS}>
              {LocalizedText("ua_g310_selectcharacter")}
            </Typography>

            {/* 선택된 캐릭터 */}
            <Box sx={{ width: "100%" }}>
              {charData?.map((data: any) => {
                return (
                  <>
                    {char === data.id && (
                      <Box
                        component="img"
                        src={data.character}
                        width="100%"
                        minHeight="calc(100vw - 40px)"
                        loading="lazy"
                      />
                    )}
                  </>
                );
              })}
            </Box>

            {/* 캐릭터 선택 버튼 */}
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {charData?.map((data: any) => {
                return (
                  <Box
                    component="img"
                    src={data.thumbnail}
                    width="50px"
                    height="50px"
                    mx="5px"
                    sx={
                      char === data.id
                        ? {
                            border: 2,
                            borderColor: color.primary500,
                            boxSizing: "border-box",
                            borderRadius: "10px",
                          }
                        : { borderRadius: "10px" }
                    }
                    onClick={() => {
                      handleCharChange(data.id);
                    }}
                    loading="lazy"
                  />
                );
              })}
            </Box>
            <VerticalSpacer height={16} />
            <Divider sx={{ width: "100%" }} />
            <VerticalSpacer height={16} />
            {/* 설명 문구 */}
            <Box>
              {char === "Ponya" && (
                <Typography fontStyle={fontStyle.bodyMr} color={color.gray800}>
                  {LocalizedText("ua_g310_ponyainfo")}
                </Typography>
              )}
              {char === "Peter" && (
                <Typography fontStyle={fontStyle.bodyMr} color={color.gray800}>
                  {LocalizedText("ua_g310_peterinfo")}
                </Typography>
              )}
              {char === "Dodo" && (
                <Typography fontStyle={fontStyle.bodyMr} color={color.gray800}>
                  {LocalizedText("ua_g310_dodoinfo")}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>

        <VerticalSpacer height={8} />
        <VerticalSpacer height={66} />
        {/* 하단 버튼 */}
        <Box
          sx={{
            position: "absolute",
            bottom: "0px",
            width: "100%",
            display: "flex",
          }}
        >
          <CommonButton
            title="Select"
            textColor="white"
            onClick={() => {
              // navigate("/PickNFTChar");
              handleDimEvent(true);
              setDialogOpen(true);
            }}
            sx={{
              mx: "20px",
              mb: "14px",
            }}
          />
        </Box>

        <IconTextDialog
          title={LocalizedText("ua_g310_alert_selectsure_title")}
          content={LocalizedText("ua_g310_alert_selectsure_message")}
          type={IconTextDialogType.WARNING}
          positiveText="OK"
          negativeText="Cancel"
          isOpen={dialogOpen}
          onPositive={() => {
            handleDimEvent(false);
            if (lastPath == "LoginFinishPage") {
              setLoading(true);
              setDialogOpen(false);
              CreateNft();
            } else {
              uiEvent.SetwWhatScreen("HasNFTChar");
            }
          }}
          onNegative={() => {
            handleDimEvent(false);
            setDialogOpen(false);
          }}
          onClose={() => {
            handleDimEvent(false);
            setDialogOpen(false);
          }}
        />
      </Box>
    </>
  );
};

export default PickNFTCharacter;
