import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  IconButton,
} from "@mui/material";
import CommonList from "../../component/CommonList";
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import AnnounceCreateGrid from "./section/AnnounceCreateGrid";
import { useState } from "react";
import { AnnounceCreateUiState } from "./model/AnnounceCreateUiState";
import { AnnounceCreateUiEvent } from "./model/AnnounceCreateUiEvent";
import { AnnounceDialog } from "./section/AnnounceDiallog";
import { AnnouncementResponse } from "./model/AnnouncementResponse";
import axios from "axios";

const AnnouncementCreatePage = ({}) => {
  const navigate = useNavigate();

  const [uiState, setUiState] = useState(new AnnounceCreateUiState());

  const [createData, setCreateData] = useState({
    title: "",
    context: "",
    openDate: 0,
    showHide: true,
    country: "",
  });

  const [inValidDataCheck, setInValidDataCheck] = useState({
    title: false,
    context: false,
    openDate: false,
    showHide: false,
    country: false,
  });

  const [fileUrls, setFileUrls] = useState<string[]>([]);

  const [createDialogOpen, setCreateDialogOpen] = useState(false);

  const countryCodeMap: { [key: string]: string } = {
    "South Korea": "KR",
    Cambodia: "KH",
    Vietnam: "VN",
  };

  const handleCreateDataChange = (
    key: keyof typeof createData,
    value: string | number | boolean
  ) => {
    const countryNameToCode: { [key: string]: string } = {
      "South Korea": "KR",
      Cambodia: "KH",
      Vietnam: "VN",
    };

    const transformedValue =
      key === "country" && typeof value === "string"
        ? countryNameToCode[value] || value // 매핑된 코드가 없으면 원래 값을 사용
        : value;

    setCreateData(
      (prevData) =>
        ({
          ...prevData,
          [key]: transformedValue,
        }) as {
          title: string;
          context: string;
          openDate: number;
          showHide: boolean;
          country: string;
        }
    );
    console.log(
      "Title : ",
      createData.title,
      "showHide : ",
      createData.showHide,
      "country : ",
      createData.country
    );
  };

  const handleInValidDataCheckChange = (
    key: keyof typeof createData,
    value: boolean
  ) => {
    setInValidDataCheck((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const validateCreateData = () => {
    let isValid = true;
    Object.keys(inValidDataCheck).forEach((key) => {
      if (key === "title" || key === "country") {
        const isInvalid = createData[key].length === 0;
        handleInValidDataCheckChange(key, isInvalid);
        if (isInvalid) isValid = false;
      } else if (key === "context") {
        const isInvalid = createData[key].length === 0;
        handleInValidDataCheckChange(key, isInvalid);
        if (isInvalid) isValid = false;
      } else if (key === "openDate") {
        const isInvalid = createData[key] === 0;
        handleInValidDataCheckChange(key, isInvalid);
        if (isInvalid) isValid = false;
      }
    });
    return isValid;
  };

  const uiEvent: AnnounceCreateUiEvent = {
    HandleUiStateChange: (key: keyof AnnounceCreateUiState, value: any) => {
      setUiState((prevState) => ({
        ...prevState,
        [key]: value, // key에 해당하는 상태를 업데이트
      }));
    },

    copyClipboard: (id: string) => {
      navigator.clipboard.writeText(id);
    },
  };

  const handleCreateDialogClose = () => {
    setCreateDialogOpen(false);
  };
  async function createAnnouncement(): Promise<AnnouncementResponse> {
    const url = "/web/pw/aw/contents/announce/create";
    const headers = {
      "Content-Type": "multipart/form-data;charset=UTF-8",
    };

    const body = {
      title: createData.title,
      showHide: createData.showHide,
      openDate: createData.openDate,
      content: createData.context,
      uuid: localStorage.getItem("user_uuid") || "",
      country: createData.country,
    };

    try {
      const formData = new FormData();

      // JSON 데이터 추가
      formData.append(
        "body",
        new Blob([JSON.stringify(body)], { type: "application/json" })
      );

      // 이미지 파일 추가
      if (fileUrls.length === 0) {
        formData.append("imageUrls", "");
      } else {
        for (let i = 0; i < fileUrls.length; i++) {
          const blobUrl = fileUrls[i];
          const response = await fetch(blobUrl);
          const blob = await response.blob();
          const imageFileName = `Announcement_image_${i}_${localStorage.getItem(
            "user_uuid"
          )}.jpg`;
          formData.append("imageUrls", blob, imageFileName);
        }
      }

      const request = await axios.post(url, formData, { headers });
      console.log("request : ", request.data);
      const createdId = request.data.createdId;
      console.log("createId : ", request.data.createdId);
      navigate(`/announcement/announceDetails/${createdId}`);
      window.location.reload();
      return request.data as AnnouncementResponse;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  const handleCreateDialogOk = () => {
    setCreateDialogOpen(false);
    const isValid = validateCreateData();
    if (isValid) {
      console.log(
        "data : ",
        createData,
        "uuid : ",
        localStorage.getItem("user_uuid"),
        "image : ",
        fileUrls
      );
      createAnnouncement();
    } else {
      console.log("create 안됨");
    }
  };

  const HeaderButton = () => {
    return (
      <Button
        sx={{
          height: 40,
          backgroundColor: "#5D38E5",
          color: "white",
          borderRadius: 5,
          width: 85,
          ":hover": {
            backgroundColor: "#A38BFC",
          },
          textTransform: "none",
        }}
        onClick={() => {
          console.log("Create 버튼 클릭");
          setCreateDialogOpen(true);
        }}
      >
        Post
      </Button>
    );
  };
  // const handleNavigate = (route: string) => {
  //   navigate(route);
  //   // 페이지를 새로고침
  //   window.location.reload();
  // };
  const HeaderBackButton = () => {
    return (
      <IconButton
        sx={{
          ":hover": { backgroundColor: "#F2F2F2" },
          mr: 1,
        }}
        onClick={() => {
          handleClickOpen();
        }}
      >
        <ArrowBackIosNewIcon fontSize="medium" />
      </IconButton>
    );
  };

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOk = () => {
    navigate("/announcement");
    window.location.reload();
  };
  return (
    <CommonList
      title="Notice Registration"
      headerButton={HeaderButton()}
      headerBackButton={HeaderBackButton()}
    >
      <Dialog open={open} onClose={handleClose}>
        <DialogContent
          sx={{
            width: 450,
            height: 170,
            // mt: -1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <DialogContentText
            id="alert-dialog-description"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              fontSize: 15,
              color: "black",
            }}
          >
            Do you want to stop editing and move?
            <br />
            The current content will not be saved.
          </DialogContentText>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              mt: 1.5,
            }}
          >
            <Button
              onClick={handleClose}
              sx={{
                textTransform: "none",
                height: 40,
                //   backgroundColor: "#5D38E5",
                color: "#5D38E5",
                borderRadius: 5,
                border: 1,
                borderColor: "#5D38E5",
                width: 95,
                ":hover": {
                  backgroundColor: "#E9E9E9",
                },
              }}
            >
              Cancle
            </Button>
            <Button
              onClick={handleOk}
              sx={{
                textTransform: "none",
                height: 40,
                backgroundColor: "#5D38E5",
                color: "white",
                borderRadius: 5,
                width: 75,
                ml: 2,
                ":hover": {
                  backgroundColor: "#A38BFC",
                },
              }}
              autoFocus
            >
              OK
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <AnnounceCreateGrid
        uiEvent={uiEvent}
        uiState={uiState}
        handleCreateDataChange={handleCreateDataChange}
        createData={createData}
        inValidDataCheck={inValidDataCheck}
        fileUrls={fileUrls}
        setFileUrls={setFileUrls}
        countryCodeMap={countryCodeMap}
      />

      <AnnounceDialog
        createDialogOpen={createDialogOpen}
        handleClose={handleCreateDialogClose}
        handleOk={handleCreateDialogOk}
      />
    </CommonList>
  );
};

export default AnnouncementCreatePage;
