import { Box, Typography, Button, Radio, RadioGroup } from "@mui/material";
import { color } from "../../../theme/Color";
import { fontStyle } from "../../../theme/Style";
import {
  ICON_RADIO_OFF,
  ICON_RADIO_ON,
  ICON_X_DEFAULT,
} from "../../../constants/appImagePath";
import VerticalSpacer from "../../../component/Spacer";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";
import ProductItem from "../model/ProductItem";
import CommonButton from "../../../component/CommonButton";
import { useEffect, useState } from "react";
import { ApiService } from "../../../restAPI/ApiService";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "../../../constants/RouteConstants";
import SimpleDialog from "../../../component/dialog/SimpleDialog";

interface PurchaseDrawerProps {
  productOptions: ProductItem[];
  selectedProduct: ProductItem | null;
  onSelectProduct: (product: ProductItem) => void;
  onClose: () => void;
  onPay: (product: ProductItem) => void;
  titleKey: string;
}

const PurchaseDrawer = ({
  productOptions,
  selectedProduct,
  onSelectProduct,
  onClose,
  onPay,
  titleKey,
}: PurchaseDrawerProps) => {
  const navigate = useNavigate();
  const [isPayable, setIsPayable] = useState(false);
  const [hasScooter, setHasScooter] = useState(false);
  const [openRestrictAlert, setOpenRestrictAlert] = useState(false);

  useEffect(() => {
    isPayableUser().then(() => {
      console.log("isPayable", isPayable);
    });
    checkHasScooter().then(() => {
      console.log("hasScooter", hasScooter);
    });
  }, []);

  async function isPayableUser() {
    const url = "/pw/ua/infoplus/isCambodia";
    const requestBody = {
      uuid: localStorage.getItem("uuid"),
    };
    try {
      const res = await ApiService.MobilePost(url, requestBody);
      console.log("isPayableUser res", res);
      if (res) {
        setIsPayable(res.data.isCambodiaNumber);
      } else {
        console.log(`isPayableUser failed ${res}`);
      }
    } catch (error) {
      console.error("Error isPayableUser:", error);
    }
  }

  async function checkHasScooter() {
    const url = "/pw/backend/api/scooter/userScoo";
    const requestBody = {
      uuid: localStorage.getItem("uuid"),
    };
    try {
      const res = await ApiService.MobilePost(url, requestBody);
      console.log("checkHasScooter res", res);
      console.log("List length", res?.data.body.length);
      if (res && res.data) {
        res.data.body.length > 0 ? setHasScooter(true) : setHasScooter(false);
      } else {
        console.log(`checkHasScooter failed ${res}`);
      }
    } catch (error) {
      console.error("Error checkHasScooter:", error);
    }
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        px: "20px",
        pt: "12px",
        pb: "4px",
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "44px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography fontStyle={fontStyle.titleXL} color={color.default}>
          {titleKey}
        </Typography>
        <Button
          onClick={onClose}
          sx={{
            paddingLeft: "20px",
            minWidth: "auto",
          }}
        >
          <Box
            component="img"
            src={ICON_X_DEFAULT}
            alt="close"
            sx={{
              width: "14px",
              height: "14px",
            }}
          />
        </Button>
      </Box>

      <VerticalSpacer height={8} />

      {productOptions.map((option, index) => (
        <Box
          key={index}
          onClick={() => onSelectProduct(option)}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "16px",
            borderRadius: "10px",
            border: `1px solid ${
              selectedProduct === option ? color.primary500 : color.gray100
            }`,
            mb: "8px",
          }}
        >
          <Box>
            <Typography
              fontStyle={fontStyle.titleL}
              color={
                selectedProduct === option ? color.primary500 : color.default
              }
            >
              {option.name}
            </Typography>

            {/* description 존재하는 경우에만 렌더링 */}
            {option.description && (
              <Typography fontStyle={fontStyle.bodyMr} color={color.gray600}>
                {option.description}
              </Typography>
            )}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography fontStyle={fontStyle.titleL} color={color.default}>
              $ {option.price}
            </Typography>
            <Radio
              checked={selectedProduct === option}
              value={option.name}
              icon={
                <Box
                  component="img"
                  src={ICON_RADIO_OFF}
                  alt="radio-off"
                  sx={{
                    width: "24px",
                    height: "24px",
                  }}
                />
              }
              checkedIcon={
                <Box
                  component="img"
                  src={ICON_RADIO_ON}
                  alt="radio-on"
                  sx={{
                    width: "24px",
                    height: "24px",
                  }}
                />
              }
            />
          </Box>
        </Box>
      ))}

      <VerticalSpacer height={4} />

      <Typography
        fontStyle={fontStyle.bodySr}
        color={color.gray400}
        sx={{
          mx: "2.5px",
        }}
      >
        {LocalizedText("ua_g411_modal_agreeterms")}
      </Typography>
      <Box
        sx={{
          width: "100%",
          height: "1px",
          backgroundColor: color.gray200,
          my: "8px",
        }}
      />
      <Typography
        fontStyle={fontStyle.bodySr}
        color={color.gray400}
        sx={{
          mx: "2.5px",
          mb: "12px",
        }}
      >
        {LocalizedText("ua_g411_modal_paymethod")}
      </Typography>

      <Box
        sx={{
          width: "100%",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <CommonButton
          title={LocalizedText("ua_g411_modal_pay_button")}
          disabled={selectedProduct === null}
          onClick={() => {
            if (!hasScooter) {
              setOpenRestrictAlert(true);
              return;
            }
            if (isPayable) {
              if (selectedProduct) {
                onPay(selectedProduct);
              }
            } else {
              navigate(ROUTE_PATHS.PAY_RESTRICT_PAGE);
            }
          }}
        />
      </Box>

      <VerticalSpacer height={38} />
      <SimpleDialog
        children={LocalizedText("ua_g411_alert_noscooter")}
        positiveText={LocalizedText("common_alert_confirm_button")}
        isOpen={openRestrictAlert}
        onPositive={() => setOpenRestrictAlert(false)}
        onClose={() => setOpenRestrictAlert(false)}
      />
    </Box>
  );
};

export default PurchaseDrawer;
