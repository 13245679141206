import CommonTable, { HeadCell, useVisibleRows } from "../../../component/CommonTable";
import { InventoryTableRow } from "./InventoryTableRow";
import { InventoryData } from "./model/InventoryData";

interface InventoryTableProps {
  headCells?: HeadCell[];
  rowsPerPage?: number;
  mngRows: InventoryData[];
  setRowsPerPage?: React.Dispatch<React.SetStateAction<number>>;
  page?: number;
  pageCount?: number;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
  docCount?: number; // docCount의 타입을 number로 변경
}

export function InventoryTable({ headCells, rowsPerPage, mngRows, setRowsPerPage, docCount, pageCount, page, setPage }: InventoryTableProps) {
  const { order, orderBy, handleRequestSort, handleChangePage, visibleRows } = useVisibleRows(mngRows, rowsPerPage || 10, setPage, page || 0);

  const pagedata: InventoryData[] = visibleRows.slice((page || 0) * (rowsPerPage || 10), ((page || 0) + 1) * (rowsPerPage || 10)).map((row) => {
    return new InventoryData(
      Number(row.no), // no를 number로 변환
      row.category as string,
      row.partsCode as string,
      row.productName as string,
      row.spec as string,
      row.company as string,
      row.quantities as string,
      Number(row.amount), // amount를 number로 변환
      Number(row.registrationDate) // registerDate를 number로 변환
    );
  });

  return (
    <CommonTable
      headCells={headCells}
      order={order}
      orderBy={orderBy}
      page={page}
      docCount={docCount}
      pageCount={pageCount}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      handleRequestSort={handleRequestSort}
      handleChangePage={handleChangePage}
      setPage={setPage}
    >
      {pagedata.map((data: InventoryData) => (
        <InventoryTableRow row={data} key={data.no} />
      ))}
    </CommonTable>
  );
}
