import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  MenuItem,
  Radio,
  Select,
  SelectChangeEvent,
  SnackbarOrigin,
  TextField,
  Typography
} from "@mui/material";
import { ReactNode } from "react";
import { ChangeEvent, useEffect, useState, useRef } from "react";
import GoogleMapWrapper from "./GoogleMapWrapper";
import TextEditor from "../../../component/TextEditor";
import {
  Methodology,
  getAreaType,
  getSubAreaType
} from "../../../store/methodologyType";
import { ProjectFormData } from "../../../store/projectType";
import { Button } from "antd";
import { styled } from "styled-components";
import { SERVER_CONFIG } from "../../../constants/ServerConfig";

interface CreateProjectGridProps {
  formData: ProjectFormData;
  setFormData: React.Dispatch<React.SetStateAction<ProjectFormData>>;
  error: string;
}

interface CustomTypographyProps {
  children: ReactNode;
  sx?: object; // Optional prop to accept the sx prop
}

const CustomTextField = styled(TextField)(({ theme }) => ({
  width: "100%",
  minHeight: "48px",
  padding: "12px 16px",
  gap: "2px",
  borderRadius: "10px 0px 0px 0px",
  border: "1px solid transparent",
  opacity: 1,
  marginLeft: "0",
  "& .MuiInputBase-root": {
    height: "48px"
  }
}));

const CreateProjectGridPage: React.FC<CreateProjectGridProps> = ({
  formData,
  setFormData,
  error
}) => {
  const [methodologyData, setMethodologyData] = useState<number | string>("");
  const [menuItems, setMenuItems] = useState<Methodology[]>([]);
  const [selectedArea, setSelectedArea] = useState<string>("");
  const [selectedSubArea, setSelectedSubArea] = useState<string>("");

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const { name, value } = event.target;

    console.log("name ---------------->", name);
    console.log("value ---------------->", value);
    const keys = name.split(".");

    setFormData((prevFormData) => {
      if (keys.length === 1) {
        return { ...prevFormData, [name]: value };
      } else {
        const [parentKey, childKey] = keys;
        return {
          ...prevFormData,
          [parentKey]: {
            ...(prevFormData as any)[parentKey],
            [childKey]: value
          }
        };
      }
    });
  };
  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const handleTeamChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData((prev) => ({
      ...prev,
      team: event.target.value
    }));
  };

  const apiKey =
    process.env.REACT_APP_GOOGLE_MAPS_API_KEY ||
    "AIzaSyC5UuKjoIZ8W24wn-ASHVvkeIcy1Gt4VrI";
  const [latitude, setLatitude] = useState<number | undefined>(
    formData.latitude
  );
  const [longitude, setLongitude] = useState<number | undefined>(
    formData.longitude
  );
  const [address, setAddress] = useState<string>(
    formData.company?.address || ""
  );

  // Sync formData with local state when formData changes
  useEffect(() => {
    if (formData.latitude !== latitude) {
      setLatitude(formData.latitude);
    }
    if (formData.longitude !== longitude) {
      setLongitude(formData.longitude);
    }
    // Safely check if formData.company is defined before accessing address
    if (formData.company && formData.company.address !== address) {
      setAddress(formData.company.address || "");
    }
  }, [formData.latitude, formData.longitude, formData.company?.address]);

  //방법론 list 조회
  useEffect(() => {
    const fetchMethodologyData = async () => {
      try {
        const response = await fetch(
          SERVER_CONFIG.CARBON_SERVER + `/methodology/list`
        );
        const data: Methodology[] = await response.json();
        setMenuItems(data);

        console.log("====> formData.methodology?.id: ", formData);
        const selectedMethodology = data.find(
          (item) => item.id === formData.methodology?.id
        );
        if (selectedMethodology) {
          setMethodologyData(selectedMethodology.id);
          setSelectedArea(selectedMethodology.area || "");
          setSelectedSubArea(selectedMethodology.subArea || "");
        }
      } catch (error) {
        console.error("Error Methodology fetching data: ", error);
      }
    };
    fetchMethodologyData();
  }, []);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileClick = () => {
    fileInputRef.current?.click();
  };
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      console.log("Selected file:", file);
    }
  };

  const handleAddressSelection = (
    address: string,
    lat: number,
    lng: number
  ) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      company: {
        ...prevFormData.company,
        address: address,
        detailAddress: prevFormData.company?.detailAddress ?? "",
        name: prevFormData.company?.name ?? "",
        ownerName: prevFormData.company?.ownerName ?? "",
        businessNumber: prevFormData.company?.businessNumber ?? "",
        certificate: prevFormData.company?.certificate ?? "",
        url: prevFormData.company?.url ?? "",
        faxNumber: prevFormData.company?.faxNumber ?? ""
      },
      latitude: lat || prevFormData.latitude,
      longitude: lng || prevFormData.longitude
    }));

    setLatitude(lat);
    setLongitude(lng);
  };
  const CustomTypography: React.FC<CustomTypographyProps> = ({ children }) => {
    return (
      <Typography
        sx={{
          color: "#99989D",
          marginLeft: "17px",
          lineHeight: "18px",
          fontWeight: 700
        }}
      >
        {children}
      </Typography>
    );
  };

  return (
    <Grid container rowSpacing={4.5} sx={{ mt: 0 }}>
      {/* 기업 정보 */}
      <Grid item lg={12}>
        <Card
          sx={{
            borderRadius: 3,
            minHeight: 240,
            width: "100%",
            display: "flex",
            flexDirection: "column"
          }}
        >
          <CardContent sx={{ width: "100%", height: "100%" }}>
            <CardHeader sx={{ mb: "-18px" }} />
            <Box
              sx={{
                m: 1,
                display: "flex",
                flexDirection: "column"
              }}
            >
              {/* 첫번째 줄 */}
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ width: "48.5%" }}>
                  <CustomTypography sx={{ fontWeight: "bold" }}>
                    기업명
                  </CustomTypography>
                  <CustomTextField
                    sx={{ width: "100%", mt: 0 }}
                    size="small"
                    value={formData.company?.name}
                    onChange={handleChange}
                    name="company.name"
                    required
                    InputProps={{
                      style: {
                        borderRadius: "10px"
                      }
                    }}
                  />
                </Box>
                <Box sx={{ width: "48.5%" }}>
                  <CustomTypography sx={{ fontWeight: "bold" }}>
                    대표자명
                  </CustomTypography>
                  <CustomTextField
                    sx={{ width: "100%", mt: 0 }}
                    size="small"
                    value={formData.company?.ownerName}
                    onChange={handleChange}
                    name="company.ownerName"
                    InputProps={{
                      style: {
                        borderRadius: "10px"
                      }
                    }}
                  />
                </Box>
              </Box>

              {/* 두번째 줄 */}
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
              >
                <Box sx={{ width: "48.5%", mt: 0 }}>
                  <CustomTypography sx={{ fontWeight: "bold" }}>
                    사업자 등록번호
                  </CustomTypography>
                  <CustomTextField
                    sx={{ width: "100%" }}
                    size="small"
                    value={formData.company?.businessNumber}
                    name="company.businessNumber"
                    onChange={handleChange}
                    InputProps={{
                      style: {
                        borderRadius: "10px"
                      }
                    }}
                  />
                </Box>
                <Box sx={{ width: "48.5%", mt: 0 }}>
                  <CustomTypography sx={{ fontWeight: "bold" }}>
                    사업인증서
                  </CustomTypography>
                  <CustomTextField
                    sx={{ width: "100%", mt: 0 }}
                    size="small"
                    value={formData.company?.certificate}
                    name="company.certificate"
                    onChange={handleChange}
                    InputProps={{
                      style: {
                        borderRadius: "10px"
                      },

                      startAdornment: (
                        <Button
                          onClick={handleFileClick}
                          type="text"
                          color="#E9E9EA"
                        >
                          파일 등록
                        </Button>
                      )
                    }}
                  />
                  <input
                    ref={fileInputRef}
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </Box>
              </Box>

              {/* 세번째 줄 */}

              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
              >
                <Box sx={{ width: "48.5%", mt: 0 }}>
                  <CustomTypography sx={{ fontWeight: "bold" }}>
                    홈페이지
                  </CustomTypography>
                  <CustomTextField
                    sx={{ width: "100%", mt: 0 }}
                    size="small"
                    value={formData.company?.url}
                    name="company.url"
                    onChange={handleChange}
                    InputProps={{
                      style: {
                        borderRadius: "10px"
                      }
                    }}
                  />
                </Box>
                <Box sx={{ width: "48.5%" }}>
                  <CustomTypography sx={{ fontWeight: "bold" }}>
                    팩스번호
                  </CustomTypography>
                  <CustomTextField
                    sx={{ width: "100%", mt: 0 }}
                    size="small"
                    value={formData.company?.faxNumber}
                    name="company.faxNumber"
                    onChange={handleChange}
                    InputProps={{
                      style: {
                        borderRadius: "10px"
                      }
                    }}
                  />
                </Box>
              </Box>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
              >
                <Box sx={{ width: "48.5%" }}>
                  <CustomTypography sx={{ fontWeight: "bold" }}>
                    주소
                  </CustomTypography>
                  <CustomTextField
                    sx={{ width: "100%", mt: 0 }}
                    size="small"
                    value={formData.company?.address || ""}
                    name="company.address"
                    onChange={handleChange}
                    InputProps={{
                      style: {
                        borderRadius: "10px"
                      },
                      readOnly: true
                    }}
                  />
                </Box>
                <Box sx={{ width: "48.5%" }}>
                  <CustomTypography sx={{ fontWeight: "bold" }}>
                    상세 주소 입력
                  </CustomTypography>
                  <CustomTextField
                    sx={{ width: "100%", mt: 0 }}
                    size="small"
                    value={formData.company?.detailAddress}
                    name="company.detailAddress"
                    onChange={handleChange}
                    InputProps={{
                      style: {
                        borderRadius: "10px"
                      }
                    }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 2
                }}
              >
                <Box sx={{ width: "48.5%" }}>
                  <CustomTypography sx={{ fontWeight: "bold" }}>
                    위도
                  </CustomTypography>
                  <CustomTextField
                    sx={{ width: "100%", mt: 0, borderRadius: "10px" }}
                    size="small"
                    value={latitude !== undefined ? latitude : ""}
                    name="latitude"
                    onChange={(e) => setLatitude(Number(e.target.value))}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                      style: {
                        backgroundColor: "#f0f0f0",
                        color: "#a0a0a0",
                        borderRadius: "10px"
                      }
                    }}
                  />
                </Box>
                <Box sx={{ width: "48.5%" }}>
                  <CustomTypography sx={{ fontWeight: "bold" }}>
                    경도
                  </CustomTypography>
                  <CustomTextField
                    sx={{ width: "100%", mt: 0 }}
                    size="small"
                    value={longitude !== undefined ? longitude : ""}
                    name="longitude"
                    onChange={(e) => setLongitude(Number(e.target.value))}
                    fullWidth
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                        backgroundColor: "#f0f0f0",
                        color: "#a0a0a0"
                      },
                      readOnly: true
                    }}
                  />
                </Box>
              </Box>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
              >
                <Box
                  sx={{
                    width: "100%",
                    minHeight: "48px",
                    padding: "12px 16px",
                    gap: "2px",
                    borderRadius: "10px 0px 0px 0px",
                    border: "1px solid transparent",
                    opacity: 1,
                    marginLeft: "0",
                    "& .MuiInputBase-root": {
                      height: "48px" // Ensures input area matches the outer height
                    }
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: 1,
                      borderColor: "#B0AFB3",
                      mt: 1,
                      borderRadius: 3,
                      height: "400px",
                      width: "100%",
                      overflow: "hidden"
                    }}
                  >
                    <GoogleMapWrapper
                      latitude={latitude || 37.7749}
                      longitude={longitude || -122.4194}
                      apiKey={apiKey}
                      onAddressSelected={handleAddressSelection}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={12}>
        <Card
          sx={{
            borderRadius: 3,
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column"
          }}
        >
          <CardContent sx={{ width: "100%", height: "100%" }}>
            <CardHeader
              title={"담당자 정보"}
              titleTypographyProps={{ fontWeight: "bold", fontSize: 19 }}
            />
            <Box
              sx={{
                m: 2,
                display: "flex",
                flexDirection: "column"
              }}
            >
              {/* 첫번째 줄 */}
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ width: "48.5%" }}>
                  <CustomTypography sx={{ fontWeight: "bold" }}>
                    담당자명
                  </CustomTypography>
                  <CustomTextField
                    sx={{ width: "100%", mt: 0 }}
                    size="small"
                    value={formData.contactName}
                    name="contactName"
                    onChange={handleChange}
                    InputProps={{
                      style: {
                        borderRadius: "10px"
                      }
                    }}
                  />
                </Box>
                <Box sx={{ width: "48.5%" }}>
                  <CustomTypography sx={{ fontWeight: "bold" }}>
                    부서명
                  </CustomTypography>
                  <CustomTextField
                    sx={{ width: "100%", mt: 0 }}
                    size="small"
                    value={formData.team}
                    name="team"
                    onChange={handleTeamChange}
                    InputProps={{
                      style: {
                        borderRadius: "10px"
                      }
                    }}
                  />
                </Box>
              </Box>

              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 0 }}
              >
                <Box sx={{ width: "48.5%" }}>
                  <CustomTypography sx={{ fontWeight: "bold" }}>
                    Number
                  </CustomTypography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between"
                    }}
                  >
                    <Select
                      value={formData.phoneCode}
                      size="small"
                      sx={{
                        width: "inherit",
                        height: "48px",
                        mt: 1.5,
                        marginLeft: "16px"
                      }}
                      style={{ borderRadius: "10px" }}
                    >
                      <MenuItem value="82">+82</MenuItem>
                      <MenuItem value="44">+44</MenuItem>
                      <MenuItem value="1">+1</MenuItem>
                    </Select>

                    <CustomTextField
                      sx={{ width: "78.5%" }}
                      size="small"
                      value={formData.phone}
                      name="phone"
                      onChange={handleChange}
                      InputProps={{
                        style: {
                          borderRadius: "10px"
                        }
                      }}
                    />
                  </Box>
                </Box>
                <Box sx={{ width: "48.5%" }}>
                  <CustomTypography sx={{ fontWeight: "bold" }}>
                    Email
                  </CustomTypography>
                  <CustomTextField
                    sx={{ width: "100%", mt: 0 }}
                    size="small"
                    value={formData.email}
                    name="email"
                    onChange={handleChange}
                    InputProps={{
                      style: {
                        borderRadius: "10px"
                      }
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default CreateProjectGridPage;
