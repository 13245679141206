export class ProductionManagementCellData {
  value: string;
  align: "center" | "left" | "right";
  width?: string;
  paddingLeft?: string;
  paddingRight?: string;
  onClick?: () => void;

  constructor(
    value: string,
    align: "center" | "left" | "right" = "center",
    width?: string,
    paddingLeft?: string,
    paddingRight?: string,
    onClick?: () => void
  ) {
    this.value = value;
    this.align = align;
    this.width = width;
    this.paddingLeft = paddingLeft;
    this.paddingRight = paddingRight;
    this.onClick = onClick;
  }
}
