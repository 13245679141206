import { useEffect, useState } from "react";
import { useAuthRepository } from "../../di/AuthRepositoryProvider";
import SimpleDialog from "../../component/dialog/SimpleDialog";
import LoginRender from "./LoginRender";
import { ApiService } from "../../restAPI/ApiService";
import { LoginSideEffect } from "./model/LoginSideEffect";
import { LoginUiEvent } from "./model/LoginUiEvent";
import { LoginUiState } from "./model/LoginUiState";
import { SERVER_CONFIG } from "../../constants/ServerConfig";

const LoginPage = () => {
  const msgCkAccount = "Please check your account information.";
  const msgCantLogin = "Can not login your account.";
  const email_regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i;
  const pwMinLength = 6;
  const [msg, setMsg] = useState("");
  const [open, setOpen] = useState(false);
  const authRepository = useAuthRepository();
  const [uiState, setUiState] = useState<LoginUiState>({
    email: "",
    password: "",
    emailErrOpen: false,
    pwErrOpen: false,
  });

  const uiEvent: LoginUiEvent = {
    setEmail: (value): void => {
      setUiState((prev) => {
        return {
          ...prev,
          email: value,
        };
      });
    },
    setPw: (value): void => {
      setUiState((prev) => {
        return {
          ...prev,
          password: value,
        };
      });
    },
    setEmailErrOpen: (value): void => {
      setUiState((prev) => {
        return {
          ...prev,
          emailErrOpen: value,
        };
      });
    },
    setPwErrOpen: (value): void => {
      setUiState((prev) => {
        return {
          ...prev,
          pwErrOpen: value,
        };
      });
    },
  };

  const sideEffect: LoginSideEffect = {
    handelBtnLogin: () => {
      let ck = false;
      uiEvent.setEmailErrOpen(false);
      uiEvent.setPwErrOpen(false);

      if (!email_regex.test(uiState.email)) {
        uiEvent.setEmailErrOpen(true);
        ck = true;
      }
      if (pwMinLength > uiState.password.length) {
        uiEvent.setPwErrOpen(true);
        ck = true;
      }
      if (ck) {
        return;
      }

      // for greenery
      if (
        SERVER_CONFIG.ON_FOR_GREENERY &&
        "greenery@test.com" === uiState.email
      ) {
        console.log("email : " + uiState.email);
        console.log("password : " + uiState.password);

        if ("greenery" === uiState.password) {
          localStorage.setItem("user_uuid", "GREENERY_TEST_ACCOUNT_UUID_0");
          localStorage.setItem("user_email", "greenery@test.com");
          localStorage.setItem("user_name", "Greenery");
          localStorage.setItem("user_type", "Administrator");
          localStorage.setItem("isLoggedIn", "true");
          window.location.href = "/dashboard";
        } else {
          uiEvent.setPwErrOpen(true);
        }
        return;
      }

      authRepository.login(uiState.email, uiState.password).then((res) => {
        if (res) {
          console.log(" - user_uuid : ", localStorage.getItem("user_uuid"));
          ApiService.WebPost("/pw/users/admin/read", {
            uuid: localStorage.getItem("user_uuid"),
          }).then((res1) => {
            if ("Active" !== res1?.data?.body?.status) {
              setMsg(msgCantLogin);
              setOpen(true);
            } else {
              localStorage.setItem("user_role", res1?.data?.body.role);
              localStorage.setItem("user_type", res1?.data?.body.type);
              localStorage.setItem("isLoggedIn", "true");
              window.location.href = "/dashboard";
            }
          });
        } else {
          setMsg(msgCkAccount);
          setOpen(true);
        }
      });
    },
    handelActiveEnter: (e, target) => {
      if ("Enter" !== e.key) return;

      const id = e.target.id;
      if ("email" === id) {
        target.current.focus();
      } else if ("password" === id) {
        sideEffect.handelBtnLogin();
      }
    },
  };

  return (
    <>
      <LoginRender
        sideEffect={sideEffect}
        uiEvent={uiEvent}
        uiState={uiState}
      />
      <SimpleDialog
        children={msg}
        isOpen={open}
        positiveText="OK"
        onPositive={() => {
          setOpen(false);
        }}
        onClose={() => {
          setOpen(false);
        }}
      />
    </>
  );
};

export default LoginPage;
