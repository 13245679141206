import * as React from "react";
import { useState } from "react";
import { Box, MenuItem, Select, styled } from "@mui/material";
import { color } from "../../../theme/Color";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";
import { fontStyle } from "../../../theme/Style";
import { IMAGE_KHMER, IMAGE_US } from "../../../constants/appImagePath";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Text } from "../../../component/Text";

interface LanguageSelectProps {
  currentSelectedItem: string | undefined;
  itemList: string[];
  onChange: (item: string) => void;
}

const languageData = {
  en: { label: "EN", imageSrc: IMAGE_US },
  km: { label: "KM", imageSrc: IMAGE_KHMER },
};

const CustomStyledMenuItem = styled(MenuItem)(({ theme }) => ({
  margin: "4px 8px",
  borderRadius: "10px",
  maxHeight: "38px",
  display: "flex",
  alignItems: "center",
  "&.Mui-selected": {
    backgroundColor: `${color.primary50} !important`,
  },
  "& img": {
    marginRight: "8px",
  },
}));

export default function LanguageSelect({
  currentSelectedItem,
  itemList,
  onChange,
}: LanguageSelectProps) {
  const inputLabel = LocalizedText("ua_f211_select");
  const [isFocused, setIsFocused] = useState(false);

  return (
    <Box sx={{ width: "100%" }}>
      <Select
        IconComponent={(props) => (
          <ArrowDropDownIcon {...props} sx={{ color: "white !important" }} /> // 아이콘 색상 흰색으로 지정
        )}
        sx={{
          width: "100%",
          height: "25px",
          "& .MuiInputBase-input": {
            fontStyle: fontStyle.bodyLr,
            color: color.default,
            paddingLeft: "0px",
            paddingRight: "0px",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        }}
        value={currentSelectedItem}
        variant="outlined"
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        displayEmpty={true}
        renderValue={(selected) => {
          const selectedItem =
            languageData[selected as keyof typeof languageData];
          if (!selectedItem) return inputLabel;
          return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img
                src={selectedItem.imageSrc}
                alt={selectedItem.label}
                width="20px"
                height="20px"
                style={{
                  borderRadius: "50%",
                  marginRight: "8px",
                  objectFit: "cover",
                  border: `1px solid ${color.gray300}`,
                }}
              />
              <Text
                sx={{
                  fontStyle: fontStyle.bodyLr,
                  color: color.white,
                }}
                text={selectedItem.label}
              />
            </Box>
          );
        }}
      >
        {itemList.map((code, index) => {
          const item = languageData[code as keyof typeof languageData];
          return (
            <CustomStyledMenuItem key={index} value={code}>
              <img
                src={item.imageSrc}
                alt={item.label}
                width="20px"
                height="20px"
                style={{
                  borderRadius: "50%",
                  objectFit: "cover",
                  border: `1px solid ${color.gray300}`,
                }}
              />
              {item.label}
            </CustomStyledMenuItem>
          );
        })}
      </Select>
    </Box>
  );
}
