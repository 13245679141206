import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CommonList from "../../component/CommonList";
import CommonSearch from "../../component/CommonSearch";
import { fontStyle } from "../../theme/Style";
import { color } from "../../theme/Color";
import VerticalSpacer from "../../component/Spacer";
import VehicleMngTable from "./section/VehicleMngTable";

const VehicleMngPage = () => {
  const navigate = useNavigate();
  // 페이지 관련
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState<number>(0);
  const [docCount, setDocCount] = useState<number>(0);

  // 검색어 관련
  const [searchContents, setSearchContents] = useState("");

  // Product Name
  const [productName, setProductName] = useState("Product Name");
  const handleProductNameChange = (event: SelectChangeEvent) => {
    setProductName(event.target.value as string);
  };
  return (
    <CommonList title="스쿠터 관리">
      <CommonSearch
        placeHolder={"Scooter S/N, Plate Number, Owner, Renter, Location"}
        setSearchContents={setSearchContents}
      >
        <Select
          value={productName}
          onChange={handleProductNameChange}
          renderValue={(v) => {
            return v?.length ? v : `Country`;
          }}
          sx={{
            width: "auto",
            height: "42px",
            ".MuiOutlinedInput-notchedOutline": { border: 0 },
            "&.MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "red",
              },
              "&:hover fieldset": {
                borderColor: "yellow",
              },
              "&.Mui-focused fieldset": {
                borderColor: "white",
              },
            },
            bgcolor: "white",
            borderRadius: "10px",
            fontStyle: fontStyle.semiboldXS,
            color: color.gray600,
          }}
        >
          <MenuItem
            value={"Product Name"}
            sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}
          >
            Product Name
          </MenuItem>
        </Select>
      </CommonSearch>

      <VerticalSpacer height={16} />
      <VehicleMngTable
        mngRows={rows}
        docCount={docCount}
        rowsPerPage={rowsPerPage}
        pageCount={pageCount}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
    </CommonList>
  );
};

export default VehicleMngPage;
