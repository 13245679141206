import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { ICON_BELL_FILL, ICON_PHONE } from "../../../constants/appImagePath";
import { fontStyle } from "../../../theme/Style";
import { Box, Typography } from "@mui/material";
import { color } from "../../../theme/Color";
import VerticalSpacer, { HorizontalSpacer } from "../../../component/Spacer";

interface EmergencyDialogProps {
  isOpen: boolean;
  title: string;
  subTitle: string;
  content: string;
  iconText: string;
  buttonText: string;
  cancelButtonText: string;
  onClick?: () => void;
  onClose: () => void;
}

export default function EmergencyDialog({
  isOpen,
  title,
  subTitle,
  content,
  iconText,
  buttonText,
  cancelButtonText,
  onClick,
  onClose,
}: EmergencyDialogProps) {
  return (
    <Dialog
      open={isOpen}
      onClose={(event, reason) => {
        if (reason === "backdropClick" && onClose) {
          onClose();
        }
      }}
      PaperProps={{
        sx: {
          borderRadius: "10px",
          px: "30px",
          py: "24px",
        },
      }}
    >
      <DialogContent
        sx={{
          padding: "0px",
        }}
      >
        <Box>
          <img
            src={ICON_BELL_FILL}
            alt=""
            style={{
              width: "24",
              height: "24",
              display: "block",
              margin: "0 auto",
            }}
          />
          <VerticalSpacer height={4} />
          <Typography
            sx={{
              fontStyle: fontStyle.titleS,
              textAlign: "center",
              color: color.error,
            }}
          >
            {title}
          </Typography>
        </Box>
        <VerticalSpacer height={16} />
        <Typography
          sx={{
            fontStyle: fontStyle.titleL,
            textAlign: "center",
            color: color.default,
          }}
        >
          {subTitle}
        </Typography>
        <VerticalSpacer height={16} />
        <Typography
          sx={{
            fontStyle: fontStyle.bodyMr,
            textAlign: "center",
            color: color.default,
          }}
        >
          {content}
        </Typography>
        <VerticalSpacer height={40} />
        <Box
          sx={{
            width: "calc(100%-1px)",
            height: "64px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "10px",
            border: `1px solid ${color.primary500}`,
            backgroundColor: color.white,
          }}
          onClick={() => {
            console.log("onClick");
            if (onClick != null) onClick();
          }}
        >
          <img
            src={ICON_PHONE}
            alt=""
            style={{
              width: "20px",
              height: "20px",
            }}
          />
          <HorizontalSpacer width={4} />
          <Typography
            sx={{
              fontStyle: fontStyle.titleL,
              textAlign: "center",
              color: color.primary500,
            }}
          >
            {iconText}
          </Typography>
          <HorizontalSpacer width={4} />
          <Typography
            sx={{
              fontStyle: fontStyle.bodySr,
              textAlign: "center",
              color: color.primary500,
            }}
          >
            {buttonText}
          </Typography>
        </Box>
        <VerticalSpacer height={20} />
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box onClick={() => onClose()} sx={{ width: "56px", height: "25px" }}>
            <Typography
              sx={{
                fontStyle: fontStyle.titleL,
                textAlign: "center",
                color: color.default,
              }}
            >
              {cancelButtonText}
            </Typography>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
