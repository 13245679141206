import CertificateItem from "../model/CertificateItem";
import { Box, Typography } from "@mui/material";
import { color } from "../../../theme/Color";
import { fontStyle } from "../../../theme/Style";
import CertificateCard from "./CertificateCard";
import VerticalSpacer from "../../../component/Spacer";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";

interface VCSectionProps {
  certificates: CertificateItem[];
  onNavigate: (route: string, state?: any) => void;
  onShowVCDialog: (vc: string) => void;
}

const VCSection = ({
  certificates,
  onNavigate,
  onShowVCDialog,
}: VCSectionProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        backgroundColor: color.gray50,
        flex: 1,
        overflowY: "auto",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          paddingTop: "16px",
          width: "calc(100% - 40px)",
          margin: "0 auto",
          flexWrap: "wrap",
          gap: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexWrap: "wrap",
            gap: "10px",
          }}
        >
          {certificates.map((certificate) => (
            <CertificateCard
              key={certificate.credentialSubject.vcID}
              cert={certificate}
              onClick={() => {
                if (certificate.vc) {
                  onShowVCDialog(certificate.vc);
                }
              }}
            />
          ))}
        </Box>

        <VerticalSpacer height={36} />
      </Box>

      {/* TODO: 추후 추가 예정 */}
      {/* 
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{
            fontStyle: fontStyle.subTitleM,
            color: color.gray600,
            textAlign: "center",
            position: "relative",
            display: "inline-block", // 밑줄을 텍스트 너비에 맞추기 위해 사용
            "&::after": {
              content: '""',
              position: "absolute",
              left: 0,
              right: 0,
              bottom: "1px", // 텍스트와 밑줄 사이의 간격 조정
              height: "1px", // 밑줄 두께
              backgroundColor: color.gray600, // 밑줄 색상
            },
          }}
          onClick={() => onNavigate("IssuedHistory")}
        >
          {LocalizedText("ua_g500_issuedhistory")}
        </Typography>
      </Box> */}

      <VerticalSpacer height={40} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: color.gray100,
          width: "100vw",
          marginLeft: "calc(-50vw + 50%)",
          padding: "24px 20px 12px 20px",
          boxSizing: "border-box",
        }}
      >
        <Typography
          fontStyle={fontStyle.subTitleM}
          color={color.gray600}
          sx={{
            marginBottom: "8px",
          }}
        >
          {LocalizedText("ua_g500_guide_title")}
        </Typography>
        <Typography component="ul" sx={{ paddingLeft: "20px" }}>
          <Typography
            component="li"
            fontStyle={fontStyle.bodyMr}
            color={color.gray600}
            sx={{
              marginBottom: "8px",
              whiteSpace: "pre-line",
            }}
          >
            {LocalizedText("ua_g500_guide_message")}
          </Typography>
          <Typography
            component="li"
            fontStyle={fontStyle.bodyMr}
            color={color.gray600}
            sx={{
              marginBottom: "8px",
              whiteSpace: "pre-line",
            }}
          >
            {LocalizedText("ua_g500_guide_message2")}
          </Typography>
          <Box sx={{ paddingLeft: "10px" }}>
            <Typography
              component="li"
              fontStyle={fontStyle.bodyMr}
              color={color.gray600}
              whiteSpace={"pre-line"}
            >
              {LocalizedText("ua_g500_guide_message3")}
            </Typography>
            <Typography
              component="li"
              fontStyle={fontStyle.bodyMr}
              color={color.gray600}
              whiteSpace={"pre-line"}
            >
              {LocalizedText("ua_g500_guide_message4")}
            </Typography>
          </Box>
        </Typography>
      </Box>
    </Box>
  );
};

export default VCSection;
