import { TableCell, TableRow, Typography } from "@mui/material";
import CommonTable, {
  HeadCell,
  useVisibleRows,
} from "../../../component/CommonTable";
import {
  CouponHistoryTableRow,
  CouponHistoryRow,
} from "./CouponHistoryTableRow";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import { useEffect } from "react";

interface CouponHistoryTableProps {
  headCells?: HeadCell[];
  rowsPerPage?: number;
  setRowsPerPage?: React.Dispatch<React.SetStateAction<number>>;
  page?: number;
  pageCount?: number;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
  docCount?: number | undefined;
  rows?: [];
}
// TODO 타입 가드 부분 추후 구현
// function isCouponHistoryData(data: any): data is any {
//   return (
//     typeof data.name === "string" &&
//     typeof data.type === "string" &&
//     typeof data.valid === "boolean"
//   );
// }

const CouponHistoryTable = ({
  headCells,
  rowsPerPage,
  setRowsPerPage,
  docCount,
  pageCount,
  page,
  setPage,
  rows,
}: CouponHistoryTableProps) => {
  const { order, orderBy, handleRequestSort, handleChangePage, visibleRows } =
    useVisibleRows(rows || [], rowsPerPage ?? 10, setPage, page ?? 10);

    useEffect(() => {
      console.log("Visible rows length:", visibleRows.length); // Visible rows length 확인
      if (visibleRows.length === 0 && setPage) {
        console.log("Setting page to 0");
        setPage(0); // 페이지를 0으로 설정
      }
    }, [visibleRows.length, setPage]);
  // TODO 타입 가드 부분 구현할때 같이
  // const filterVisibleRows = visibleRows
  //   .filter(isCouponHistoryData)
  //   .slice(
  //     (page || 0) * (rowsPerPage || 10),
  //     ((page || 0) + 1) * (rowsPerPage || 10)
  //   );

  return (
    <CommonTable
      headCells={headCells}
      order={order}
      orderBy={orderBy}
      page={page}
      docCount={docCount}
      pageCount={pageCount}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      handleRequestSort={handleRequestSort}
      handleChangePage={handleChangePage}
      setPage={setPage}

    >
      {visibleRows?.length == 0 ? (
        <TableRow sx={{ px: " 4px", height: "1px" }}>
          <TableCell
            colSpan={headCells?.length}
            align="center"
            sx={{
              minWidth: "1032px",
              px: "13px",
              py: "12px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontStyle: fontStyle.regularS,
                color: color.gray600,
              }}
            >
              No Data
            </Typography>
          </TableCell>
        </TableRow>
      ) : (
        visibleRows.map(
          (row: { [x: string]: string | number }, index: number) => (
            <CouponHistoryTableRow
              row={row as unknown as CouponHistoryRow}
              key={index}
              docCount={docCount}
            />
          )
        )
      )}
    </CommonTable>
  );
};

export default CouponHistoryTable;
