import { Box, Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import { fontStyle } from "../../../../../theme/Style";
import { CommonSelect } from "../../../../../component/CommonSelect";
import VerticalSpacer from "../../../../../component/Spacer";
import CommonTextField from "../../../../../component/CommonTextField";
import { color } from "../../../../../theme/Color";
import { ServiceMngDetailUiState } from "../model/ServiceMngDetailUiState";
import { ServiceMngDetailUiEvent } from "../model/ServiceMngDetailUiEvent";
import { useEffect } from "react";
import { ApiService } from "../../../../../restAPI/ApiService";

interface ManagerCardProps {
  uiState: ServiceMngDetailUiState;
  uiEvent: ServiceMngDetailUiEvent;
}

const ManagerCard = ({ uiState, uiEvent }: ManagerCardProps) => {
  useEffect(() => {
    if (uiState.ID) {
      ApiService.WebPost("/pw/users/admin/selectManager", {
        uuid: uiState.ID,
      }).then((res) => {
        uiEvent.HandleUiStateChange("Name", res?.data.body.admin.name);
        uiEvent.HandleUiStateChange("LocalNumber", res?.data.body.admin.countryNumber);
        uiEvent.HandleUiStateChange("PhoneNumber", res?.data.body.admin.phoneNumber);
      });
    }
  }, [uiState.ID]);
  return (
    <Grid item lg={6}>
      <Card
        sx={{
          borderRadius: "15px",
          minHeight: 240,
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <CardContent sx={{ width: "100%", height: "100%" }}>
          <CardHeader title={"Manager"} titleTypographyProps={{ fontStyle: fontStyle.semiboldL }} />
          <Box
            sx={{
              m: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* 첫번째 줄 */}
            <Box>
              <Box sx={{ width: "100%" }}>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  ID
                </Typography>
                <CommonSelect
                  selectValue={uiState.ManagerEmail}
                  menuitems={uiState.ManagerList.map((manager: any) => manager.email)} // email 값만 추출
                  onChange={(event) => {
                    // 선택된 이메일에 해당하는 uuid를 찾고 처리
                    const selectedEmail = event.target.value;
                    uiEvent.HandleUiStateChange("ManagerEmail", selectedEmail);
                    const selectedManager: any = uiState.ManagerList.find((manager: any) => manager.email === selectedEmail);
                    if (selectedManager) {
                      uiEvent.HandleUiStateChange("ID", selectedManager.uuid); // uuid 업데이트
                    }
                  }}
                  sx={{
                    height: "48px",
                    width: "100%",
                    mt: "8px",
                    borderRadius: "10px",
                    fontStyle: fontStyle.regularS,
                    color: "black",
                  }}
                  error={uiState.IDError}
                />
              </Box>
            </Box>
            <VerticalSpacer height={24} />
            {/* 두번째 줄 */}
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ width: "100%" }}>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Name
                </Typography>
                <CommonTextField
                  value={uiState.Name}
                  onChange={(event) => {
                    //   uiEvent.HandleUiStateChange("Name", event.target.value);
                  }}
                  sx={{
                    mt: "8px",
                    width: "100%",
                    height: "48px",
                  }}
                  InputProps={{
                    style: {
                      height: "48px",
                      borderRadius: "10px",
                      // backgroundColor: color.gray200,
                      // color: color.gray600, // 비활성화 상태일 때 색상을 지정
                    },
                  }}
                  disabled
                />
              </Box>
            </Box>
            <VerticalSpacer height={24} />
            {/* 세번째 줄 */}
            <Box>
              <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                Phone Number
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mt: 1,
                }}
              >
                <CommonSelect
                  selectValue={uiState.LocalNumber}
                  menuitems={["1", "82", "855"]}
                  onChange={(event) => {
                    //   uiEvent.HandleUiStateChange(
                    //     "LocalNumber",
                    //     event.target.value
                    //   );
                  }}
                  sx={{
                    height: "48px",
                    width: "18.5%",
                    fontStyle: fontStyle.regularS,
                    color: "black",
                  }}
                  disabled
                />

                <CommonTextField
                  value={uiState.PhoneNumber}
                  onChange={(event) => {
                    //   uiEvent.HandleUiStateChange(
                    //     "PhoneNumber",
                    //     event.target.value
                    //   );
                  }}
                  sx={{ width: "78.5%", height: "48px" }}
                  InputProps={{
                    style: {
                      height: "48px",
                    },
                  }}
                  disabled
                />
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default ManagerCard;
