export class NoticeDetailUiState {
  title: string;
  content: string;
  imageUrls: string[];
  time: string;
  isPhotoDetailOpen: boolean;

  constructor(
    title: string,
    content: string,
    imageUrls: string[],
    time: string,
    isPhotoDetailOpen: boolean
  ) {
    this.title = title;
    this.content = content;
    this.imageUrls = imageUrls;
    this.time = time;
    this.isPhotoDetailOpen = isPhotoDetailOpen;
  }
}
