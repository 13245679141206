import {
  Box,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { fontStyle } from "../../../../../theme/Style";
import { color } from "../../../../../theme/Color";
import VerticalSpacer, {
  HorizontalSpacer,
} from "../../../../../component/Spacer";
import CommonTextField from "../../../../../component/CommonTextField";
import { CommonSelect } from "../../../../../component/CommonSelect";
import { UserDetailsUiState } from "../../../UserDetailsUiState";
import { UserDetailsUiEvent } from "../../../UserDetailsUiEvent";
import { useEffect, useState } from "react";
import { ApiService } from "../../../../../restAPI/ApiService";
interface UserScooterSelectCardProps {
  uiState: UserDetailsUiState;
  uiEvent: UserDetailsUiEvent;
}
const UserScooterSelectCard = ({
  uiState,
  uiEvent,
}: UserScooterSelectCardProps) => {
  const [tempData, setTempData] = useState<any>({});
  const [tempScootetSN, setTempScootetSN] = useState("");
  const [tempScootetPN, setTempScootetPN] = useState("");
  const [loading, setLoading] = useState(false); // 로딩 상태 추가

  useEffect(() => {
    if (uiState.OwnershipType == "Possession") {
      ApiService.WebPost("/pw/backend/api/scooter/availableContract", {
        type: uiState.OwnershipType,
        productName: uiState.ScooterProductName,
      }).then((res) => {
        console.log("availableContract is : ", res?.data.body);
        // 받은 데이터를 각각의 배열로 변환
        const serialNumbers = res?.data.body.map(
          (item: any) => item.serialNumber
        );

        console.log("Serial Numbers: ", serialNumbers);

        // 필요에 따라 상태 업데이트
        uiEvent.HandleUiStateChange("scooterSNList", serialNumbers);
      });
    } else if (uiState.OwnershipType == "Rental") {
      ApiService.WebPost("/pw/backend/api/scooter/availableContract", {
        type: uiState.OwnershipType,
        productName: uiState.ScooterProductName,
        ownerUuid: uiState.OwnerUUid,
      }).then((res) => {
        console.log("availableContract is : ", res?.data.body);

        const plateNumbers = res?.data.body.map(
          (item: any) => item.plateNumber
        );

        // 필요에 따라 상태 업데이트
        uiEvent.HandleUiStateChange("scooterSNPNJson", res?.data.body);
        uiEvent.HandleUiStateChange("plateNumberList", plateNumbers);
      });
    }
  }, [uiState.ScooterProductName]);

  useEffect(() => {
    if (uiState.selectScooterSN || tempScootetSN) {
      ApiService.WebPost("/pw/backend/api/scooter/detailAdmin", {
        serialNumber: uiState.selectScooterSN
          ? uiState.selectScooterSN
          : tempScootetSN,
      }).then((res) => {
        console.log(res?.data);
        setTempData(res?.data.body);
      });
    }
  }, [tempScootetSN, uiState.selectScooterSN]);

  useEffect(() => {
    // uiEvent.HandleUiStateChange("selectScootetSN", "");
    setTempData(null);
    setTempScootetSN("");
  }, [uiState.SelectedScooterContractSheet]);

  useEffect(() => {
    console.log("uiState.isHandOver is :", uiState.isHandOver);
  }, [uiState.isHandOver]);

  const [timeLeft, setTimeLeft] = useState<any>(0);

  useEffect(() => {
    // 현재 시간
    const currentTime = Date.now();
    // 5분 후의 시간
    // const targetTime = uiState.handOverTime + 5 * 60 * 1000; // 5분을 밀리초로 변환
    const targetTime = uiState.handOverTime + 30 * 1000; // 5분을 밀리초로 변환
    const initialTimeLeft = targetTime - currentTime;

    if (initialTimeLeft > 0) {
      setTimeLeft(initialTimeLeft);
    } else {
      // 타겟 시간이 이미 지난 경우
      setTimeLeft(0);
      return;
    }

    // 1초마다 타이머 업데이트
    const interval = setInterval(() => {
      setTimeLeft((prevTimeLeft: any) => {
        if (prevTimeLeft <= 1000) {
          clearInterval(interval);
          return -1;
        }
        return prevTimeLeft - 1000; // 1초 감소
      });
    }, 1000);

    // 컴포넌트 언마운트 시 클리어
    return () => clearInterval(interval);
  }, [uiState.handOverTime]);

  // 남은 시간을 시, 분, 초로 변환
  const formatTime = (milliseconds: any) => {
    const minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
    const seconds = Math.floor((milliseconds / 1000) % 60);
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  useEffect(() => {
    if (timeLeft == -1) {
      uiEvent.HandleUiStateChange("addLoadingState", true);
    }
  }, [timeLeft]);

  useEffect(() => {
    console.log(tempData);
  }, [tempData]);

  return (
    <>
      {loading && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: "140px",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0)", // 배경 흐리게
            zIndex: 1000, // 다른 요소들 위에 배치
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Grid item lg={12} sx={{ mt: "16px" }}>
        <Card
          sx={{
            borderRadius: "15px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            p: "24px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <CardHeader
              title="Scooter"
              titleTypographyProps={{ fontStyle: fontStyle.semiboldL }}
              sx={{ p: "0px" }}
            />
            <Box sx={{ display: "flex" }}>
              <Button
                sx={{
                  textTransform: "none",
                  width: "90px",
                  height: "36px",
                  color: color.primary500,
                  border: 1,
                  borderRadius: "40px",
                  borderColor: color.primary500,
                  fontStyle: fontStyle.semiboldS,
                }}
                onClick={() => {
                  // console.log(uiState.selectScootetSN);
                  if (tempScootetSN) {
                    setLoading(true);
                    uiEvent.HandleUiStateChange("addLoadingState", true);
                    ApiService.WebPost(
                      "/pw/backend/api/scooterAgreement/updateScooterInfo",
                      {
                        serialNumber: tempScootetSN,
                        agreementNumber: uiState.SelectedScooterContractSheet,
                      }
                    )
                      .then((res) => {
                        console.log(res?.data);
                      })
                      .finally(() => {
                        setLoading(false);
                        uiEvent.HandleUiStateChange("addLoadingState", false);
                      });
                  }
                }}
              >
                Update
              </Button>
              <HorizontalSpacer width={8} />

              {timeLeft <= 0 ? (
                <Button
                  sx={{
                    textTransform: "none",
                    width: "113px",
                    height: "36px",
                    color: "white",
                    bgcolor: uiState.isHandOver
                      ? color.gray300
                      : uiState.selectScooterSN
                      ? color.primary500
                      : color.gray300,
                    ":hover": {
                      bgcolor: uiState.isHandOver
                        ? color.gray300
                        : uiState.selectScooterSN
                        ? color.primary300
                        : color.gray300,
                    },
                    border: 1,
                    borderRadius: "40px",
                    fontStyle: fontStyle.semiboldS,
                    "&.Mui-disabled": {
                      color: "white", // 비활성화 시 텍스트 색상
                      bgcolor: color.gray300, // 비활성화 시 배경색
                      borderColor: color.gray300, // 비활성화 시 보더 색상
                    },
                  }}
                  onClick={() => {
                    if (uiState.selectScooterSN) {
                      setLoading(true);
                      uiEvent.HandleUiStateChange("addLoadingState", true);

                      ApiService.WebPost(
                        "/pw/backend/api/scooterAgreement/handOver",
                        {
                          agreementNumber: uiState.SelectedScooterContractSheet,
                        }
                      )
                        .then((res) => {
                          console.log(res?.data.body);
                          if (res?.data.body.handOver) {
                            uiEvent.HandleUiStateChange("isHandOver", true);
                          }
                        })
                        .finally(() => {
                          setLoading(false);
                          uiEvent.HandleUiStateChange("addLoadingState", false);
                        });
                    }
                  }}
                  disabled={
                    // uiState.OwnershipType == "Rental" ? uiState.isHandOver ? uiState.isHandOver
                    // : uiState.selectScooterPN
                    // ? false
                    // : true :

                    uiState.isHandOver
                      ? uiState.isHandOver
                      : uiState.selectScooterSN
                      ? false
                      : true
                  }
                >
                  Hand Over
                </Button>
              ) : (
                <Box
                  sx={{
                    width: "113px",
                    height: "36px",
                    bgcolor: color.gray300,
                    color: "white",
                    borderRadius: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {formatTime(timeLeft)}
                </Box>
              )}
            </Box>
          </Box>
          <VerticalSpacer height={24} />
          <Box>
            {/* 첫번째 줄 요소 */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ width: "48.5%" }}>
                {/* Resident ID Card */}

                <Box>
                  <Typography
                    fontStyle={fontStyle.boldXS}
                    color={color.gray600}
                  >
                    Plate Number
                  </Typography>
                  {uiState.OwnershipType == "Rental" ? (
                    <CommonSelect
                      selectValue={
                        tempData?.plateNumber
                          ? tempData?.plateNumber
                          : tempScootetPN
                      }
                      menuitems={uiState.plateNumberList}
                      onChange={(event) => {
                        const selectedPlateNumber = event.target.value;
                        const matchedItem = uiState.scooterSNPNJson.find(
                          (item: any) =>
                            item.plateNumber === selectedPlateNumber
                        );
                        const serialNumber: any = matchedItem?.serialNumber;
                        console.log("Selected serialNumber:", serialNumber);
                        setTempScootetPN(event.target.value);
                        setTempScootetSN(serialNumber);
                        // uiEvent.HandleUiStateChange(
                        //   "selectScooterSN",
                        //   serialNumber
                        // );
                      }}
                      sx={{
                        height: "48px",
                        width: "100%",
                        mt: "8px",
                        fontStyle: fontStyle.regularS,
                        color: "black",
                      }}
                    />
                  ) : (
                    <CommonTextField
                      value={tempData?.plateNumber} // 파일 이름 표시
                      sx={{
                        width: "100%",
                        height: "48px",
                        mt: "8px",
                      }}
                      InputProps={{
                        style: {
                          height: "48px",
                        },
                      }}
                      inputProps={{
                        style: { zIndex: 100 },
                      }}
                    />
                  )}
                </Box>
              </Box>
              <Box sx={{ width: "48.5%" }}>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Product Name
                </Typography>
                <CommonSelect
                  selectValue={uiState.ScooterProductName}
                  menuitems={["ST20220720003"]}
                  onChange={() => {}}
                  sx={{
                    height: "48px",
                    width: "100%",
                    mt: "8px",
                    fontStyle: fontStyle.regularS,
                    color: "black",
                  }}
                  disabled
                />
              </Box>
            </Box>
            <VerticalSpacer height={24} />
            {/* 나머지 요소 */}
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              {/* 왼쪽요소  */}
              <Box sx={{ width: "48.5%" }}>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Scooter S/N
                </Typography>
                <CommonSelect
                  selectValue={
                    uiState.selectScooterSN
                      ? uiState.selectScooterSN
                      : tempScootetSN
                  }
                  menuitems={uiState.scooterSNList}
                  onChange={(event) => {
                    // uiEvent.HandleUiStateChange(
                    //   "selectScootetSN",
                    //   event.target.value
                    // );
                    setTempScootetSN(event.target.value);
                  }}
                  sx={{
                    height: "48px",
                    width: "100%",
                    mt: "8px",
                    fontStyle: fontStyle.regularS,
                    color: "black",
                  }}
                  disabled={
                    uiState.OwnershipType == "Rental"
                      ? true
                      : uiState.selectScooterSN
                      ? true
                      : false
                  }
                />
                <VerticalSpacer height={24} />
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Frame S/N
                </Typography>
                <CommonTextField
                  value={tempData?.frameSerialNumber} // 파일 이름 표시
                  sx={{
                    width: "100%",
                    height: "48px",
                    mt: "8px",
                  }}
                  InputProps={{
                    style: {
                      height: "48px",
                    },
                  }}
                  inputProps={{
                    style: { zIndex: 100 },
                  }}
                  disabled
                />
                <VerticalSpacer height={24} />
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  IMEI
                </Typography>
                <CommonTextField
                  value={tempData?.imei}
                  sx={{
                    width: "100%",
                    height: "48px",
                    mt: "8px",
                  }}
                  InputProps={{
                    style: {
                      height: "48px",
                    },
                  }}
                  inputProps={{
                    style: { zIndex: 100 },
                  }}
                  disabled
                />
                <VerticalSpacer height={24} />
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Date of Manufacture
                </Typography>
                <CommonTextField
                  value={tempData?.dateOfManufacture}
                  sx={{
                    width: "100%",
                    height: "48px",
                    mt: "8px",
                  }}
                  InputProps={{
                    style: {
                      height: "48px",
                    },
                  }}
                  inputProps={{
                    style: { zIndex: 100 },
                  }}
                  disabled
                />
              </Box>
              {/* 오른쪽요소  */}
              <Box sx={{ width: "48.5%" }}>
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    borderRadius: "10px",
                    border: 1,
                    borderColor: color.gray300,
                    overflow: "hidden", // 부모 요소에서 넘치는 부분 숨기기
                  }}
                >
                  <Box
                    component={"img"}
                    src={tempData?.modelPicUrl}
                    sx={{
                      width: "100%",
                      maxHeight: "100%", // 부모 요소 높이를 넘지 않도록 설정
                      border: 0,
                      display: tempData?.modelPicUrl ? "block" : "none",
                      objectFit: "cover", // 이미지를 비율에 맞게 잘라서 채우기
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Card>
      </Grid>
    </>
  );
};

export default UserScooterSelectCard;
