import useAndroidBackHandler from "../../customHook/useAndroidBackHandler";
import { useState } from "react";
import { LoginDetailUiState } from "./LoginDetailUiState";
import { LoginDetailUiEvent } from "./LoginDetailUiEvent";
import { ApiService } from "../../restAPI/ApiService";
import ProgressBar from "../../component/ProgressBar";
import LoginDetailRender from "./LoginDetailRender";

const LoginDetailInfo = () => {
  const [uiState, setUiState] = useState(new LoginDetailUiState());
  const [loading, setLoading] = useState(false);

  useAndroidBackHandler(() => {
    if (uiState.dialogOpen == true) {
      uiEventHandler.CloseDialog();
    } else {
      uiEventHandler.OpenDialog();
    }
  }, [uiState.dialogOpen]);

  const uiEventHandler: LoginDetailUiEvent = {
    HandleDayChange: (event: string) => {
      setUiState((prev) => {
        return {
          ...prev,
          day: event,
        };
      });
    },
    HandleMonthChange: (event: string) => {
      setUiState((prev) => {
        return {
          ...prev,
          month: event,
        };
      });
    },
    HandleYearChange: (event: string) => {
      setUiState((prev) => {
        return {
          ...prev,
          year: event,
        };
      });
    },

    HandleGenderChange: (event: string) => {
      setUiState((prev) => {
        return {
          ...prev,
          gender: event,
        };
      });
    },

    HandleCountryChange: (event: string) => {
      setUiState((prev) => {
        return {
          ...prev,
          country: event,
        };
      });
    },
    HandleCityCountyDistrictChange: (event: string) => {
      setUiState((prev) => {
        return {
          ...prev,
          cityCountyDistrict: event,
        };
      });
    },
    HandleCityDetailChange: (event: string) => {
      setUiState((prev) => {
        return {
          ...prev,
          cityDetail: event,
        };
      });
    },

    //수정 완료 버튼을 눌렀을 시 실행되는 데이터 verifying 함수
    VerifyingData: () => {
      let isValid = true;
      // 생년월일 검증
      // Day 검증
      if (uiState.day == "") {
        isValid = false;
        setUiState((prev) => ({
          ...prev,
          dayError: "error",
        }));
      } else {
        setUiState((prev) => ({
          ...prev,
          dayError: "",
        }));
      }
      // Month 검증
      if (uiState.month == "") {
        isValid = false;
        setUiState((prev) => ({
          ...prev,
          monthError: "error",
        }));
      } else {
        setUiState((prev) => ({
          ...prev,
          monthError: "",
        }));
      }
      // Year 검증
      if (uiState.year == "") {
        isValid = false;
        setUiState((prev) => ({
          ...prev,
          yearError: "noValue",
        }));
      } else {
        if (/^\d*$/.test(uiState.year)) {
          const num = parseInt(uiState.year, 10);
          if (num >= 1900 && num < 2101) {
            setUiState((prev) => ({
              ...prev,
              yearError: "",
            }));
          } else {
            setUiState((prev) => ({
              ...prev,
              yearError: "overValue",
            }));
          }
        }
      }

      //성별 검증
      // if (uiState.gender == "") {
      //   isValid = false;
      //   setUiState((prev) => ({
      //     ...prev,
      //     genderError: "error",
      //   }));
      // } else {
      //   setUiState((prev) => ({
      //     ...prev,
      //     genderError: "",
      //   }));
      // }

      // 주소 검증
      // Contry 검증
      if (uiState.country == "none") {
        isValid = false;
        setUiState((prev) => ({
          ...prev,
          countryError: "error",
        }));
      } else {
        setUiState((prev) => ({
          ...prev,
          countryError: "",
        }));
      }
      // CityCountyDistrict 검증
      if (uiState.cityCountyDistrict == "") {
        isValid = false;
        setUiState((prev) => ({
          ...prev,
          cityCountyDistrictError: "error",
        }));
      } else {
        setUiState((prev) => ({
          ...prev,
          cityCountyDistrictError: "",
        }));
      }
      // CityDetail
      if (uiState.cityDetail == "") {
        isValid = false;
        setUiState((prev) => ({
          ...prev,
          cityDetailError: "error",
        }));
      } else {
        setUiState((prev) => ({
          ...prev,
          cityDetailError: "",
        }));
      }

      if (isValid) {
        // 데이터가 유효하면 다음 단계 진행
        // navigate("/LoginFinishPage");
        submitSignup();
      }

      return "Invalid";
    },

    OpenDialog: () => {
      setUiState((prev) => {
        return {
          ...prev,
          dialogOpen: true,
        };
      });
    },
    CloseDialog: () => {
      setUiState((prev) => {
        return {
          ...prev,
          dialogOpen: false,
        };
      });
    },
    OpenFinishScreen: () => {
      setUiState((prev) => {
        return {
          ...prev,
          finishOpen: true,
        };
      });
    },
    CloseFinishScreen: () => {
      setUiState((prev) => {
        return {
          ...prev,
          finishOpen: false,
        };
      });
    },
    OpenNetworkErrorToast: () => {
      setUiState((prev) => {
        return {
          ...prev,
          networkError: true,
        };
      });
    },
    CloseNetworkErrorToast: () => {
      setUiState((prev) => {
        return {
          ...prev,
          networkError: false,
        };
      });
    },
  };

  async function submitSignup() {
    setLoading(true);

    const requestBody = {
      birthday: getBirthday(),
      addressCountry: uiState.country,
      addressProvince: uiState.cityCountyDistrict,
      addressDetail: uiState.cityDetail,
      gender: getGender(),
      name: getName(),
      type: "General User",
      snsType: getSnsType(), // Google, Facebook, Apple
      uuid: localStorage.getItem("uuid"),
      email: localStorage.getItem("email"),
      isMarketingConsent: getBoolFromString("isMarketingConsent"),
      isMinor: getBoolFromString("isMinor"),
      country: localStorage.getItem("COUNTRY_CODE_STORAGE_KEY"),
      countryNumber: localStorage.getItem("user_country_number") ?? "855",
      phoneNumber: localStorage.getItem("user_phone_number") ?? "01000000000",
    };

    console.log("SIGNUP RequestBody", requestBody);
    console.log('localStorage.getItem("uuid")', localStorage.getItem("uuid"));
    console.log('localStorage.getItem("email")', localStorage.getItem("email"));
    try {
      const response = await ApiService.MobilePost(
        "/pw/users/join",
        requestBody,
      );

      const data = response?.data?.body;
      console.log("data : ", data);

      if (data.wallet && data.userVc) {
        console.log("SUCCESS SIGNUP", localStorage.getItem("name"));
        uiEventHandler.OpenFinishScreen();
      } else {
        console.log("FAIL SIGNUP", localStorage.getItem("name"));
        uiEventHandler.OpenNetworkErrorToast();
      }
      //TODO: response 에 "coupon" bool값 추가 -> 이후 false일 경우 처리 필요
    } catch (e) {
      console.error(e);
      uiEventHandler.OpenNetworkErrorToast();
    } finally {
      // 네트워크 요청 완료 후 로딩 해제
      setLoading(false);
    }
  }

  function getBirthday(): number {
    const dayNumber = parseInt(uiState.day, 10);
    const monthNumber = parseInt(uiState.month, 10);
    const yearNumber = parseInt(uiState.year, 10);

    if (
      isNaN(dayNumber) ||
      isNaN(monthNumber) ||
      isNaN(yearNumber) ||
      dayNumber < 1 ||
      dayNumber > 31 ||
      monthNumber < 1 ||
      monthNumber > 12 ||
      yearNumber < 1900 ||
      yearNumber > 2100
    ) {
      console.error("Invalid date provided");
      return 0;
    }

    const dateObject = new Date(yearNumber, monthNumber - 1, dayNumber);
    return dateObject.getTime();
  }

  function getName(): string {
    return `${localStorage.getItem("name")}`;
  }

  function getGender(): string {
    if (uiState.gender == "male") {
      return "Male";
    } else if (uiState.gender == "female") {
      return "Female";
    } else {
      return "";
    }
  }

  function getSnsType(): string {
    const snsType = localStorage.getItem("snsType");
    if (snsType == "google.com") {
      return "Google";
    } else if (snsType == "facebook.com") {
      return "Facebook";
    } else {
      return "Apple";
    }
  }

  function getBoolFromString(value: string): boolean {
    return localStorage.getItem(value) == "true";
  }

  if (loading) {
    return <ProgressBar />;
  }

  return <LoginDetailRender uiState={uiState} uiEvent={uiEventHandler} />;
};

export default LoginDetailInfo;
