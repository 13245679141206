import { CommonSelect } from "../../../../../component/CommonSelect";
import { Box } from "@mui/material";
import React from "react";
import { HorizontalSpacer } from "../../../../../component/Spacer";
import { Text } from "../../../../../component/Text";
import { fontStyle } from "../../../../../theme/Style";
import { color } from "../../../../../theme/Color";

interface TimeSelectorProps {
  currentHour: string;
  currentMinute: string;
  onChangeHour: (hour: string) => void;
  onChangeMinute: (min: string) => void;
}

export function TimeSelector({
  currentHour,
  currentMinute,
  onChangeHour,
  onChangeMinute,
}: TimeSelectorProps) {
  console.log(`currentHour:${currentHour}`);
  const selectableHours = Array.from({ length: 24 }, (_, i) =>
    i.toString().padStart(2, "0"),
  );
  const selectableMinutes = Array.from({ length: 60 }, (_, i) =>
    i.toString().padStart(2, "0"),
  );

  // 기본값 보정
  const validHour = selectableHours.includes(currentHour)
    ? currentHour
    : selectableHours[0];
  const validMinute = selectableMinutes.includes(currentMinute)
    ? currentMinute
    : selectableMinutes[0];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        alignContent: "center",
      }}
    >
      <CommonSelect
        sx={{
          height: "48px",
          flexGrow: 1,
        }}
        selectValue={validHour} // 수정된 기본값 사용
        menuitems={selectableHours}
        onChange={(event) => {
          onChangeHour(event.target.value);
        }}
      />
      <HorizontalSpacer width={12} />
      <Text
        sx={{
          display: "flex",
          alignItems: "center",
          fontStyle: fontStyle.regularM,
          color: color.default,
        }}
        text={":"}
      />
      <HorizontalSpacer width={12} />
      <CommonSelect
        sx={{
          flexGrow: 1,
        }}
        selectValue={validMinute} // 수정된 기본값 사용
        menuitems={selectableMinutes}
        onChange={(event) => {
          onChangeMinute(event.target.value);
        }}
      />
    </Box>
  );
}
