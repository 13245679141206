import { Box, Card, CardContent } from "@mui/material";
import { color } from "../../../../theme/Color";
import VerticalSpacer, { HorizontalSpacer } from "../../../../component/Spacer";
import React from "react";
import { StationMngDetailUiState } from "../model/StationMngDetailUiState";
import { StationMngDetailUiEvent } from "../model/StationMngDetailUiEvent";
import { Text } from "../../../../component/Text";
import { fontStyle } from "../../../../theme/Style";
import { LabeledPaper } from "../../../vehicleMng/detail/basic/LabeledPaper";
import { TimeSelector } from "./component/TimeSelector";
import LocalizedDate, {
  DateFormatType,
} from "../../../../component/LocalizedDate";
import { DateCheckbox } from "./component/DateCheckbox";

interface StationMngBasicPageProps {
  uiState: StationMngDetailUiState;
  uiEvent: StationMngDetailUiEvent;
}

export function StationMngBasicPage({
  uiState,
  uiEvent,
}: StationMngBasicPageProps) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
        }}
      >
        {/*  Manager 카드 */}
        <Card
          sx={{
            flexGrow: 1,
            minWidth: "506px",
            borderRadius: "15px",
            border: "1px solid",
            borderColor: color.gray300,
            backgroundColor: color.white,
            boxShadow: 0,
          }}
        >
          <CardContent
            sx={{
              padding: "28px",
            }}
          >
            <Text
              sx={{
                fontStyle: fontStyle.semiboldL,
                color: color.default,
              }}
              text={"Manager"}
            />
            <VerticalSpacer height={16} />
            <LabeledPaper label={"ID"} value={uiState.managerEmail} />
            <VerticalSpacer height={40} />
            <LabeledPaper label={"Name"} value={uiState.managerName} />
            <VerticalSpacer height={40} />
            <LabeledPaper // TODO 변경
              label={"Phone Number"}
              value={
                uiState.managerLocalNumber + " " + uiState.managerPhoneNumber
              }
            />
          </CardContent>
        </Card>
        <HorizontalSpacer width={20} />
        {/*  Service 카드 */}
        <Card
          sx={{
            flexGrow: 1,
            minWidth: "506px",
            borderRadius: "15px",
            border: "1px solid",
            borderColor: color.gray300,
            backgroundColor: color.white,
            boxShadow: 0,
          }}
        >
          <CardContent
            sx={{
              padding: "28px",
            }}
          >
            <Text
              sx={{
                fontStyle: fontStyle.semiboldL,
                color: color.default,
              }}
              text={"Service"}
            />
            <VerticalSpacer height={20} />

            <Text
              sx={{
                fontStyle: fontStyle.boldXS,
                color: color.gray600,
              }}
              text={"Open Time"}
            />
            <VerticalSpacer height={8} />
            <TimeSelector
              currentHour={uiState.serviceOpenHour}
              currentMinute={uiState.serviceOpenMin}
              onChangeHour={(hour) => {
                uiEvent.onChangeServiceOpenHour(hour);
              }}
              onChangeMinute={(min) => {
                uiEvent.onChangeServiceOpenMin(min);
              }}
            />
            <VerticalSpacer height={40} />
            <Text
              sx={{
                fontStyle: fontStyle.boldXS,
                color: color.gray600,
              }}
              text={"Open Time"}
            />
            <VerticalSpacer height={8} />
            <TimeSelector
              currentHour={uiState.serviceCloseHour}
              currentMinute={uiState.serviceCloseMin}
              onChangeHour={(hour) => {
                uiEvent.onChangeServiceCloseHour(hour);
              }}
              onChangeMinute={(min) => {
                uiEvent.onChangeServiceCloseMin(min);
              }}
            />
            <VerticalSpacer height={40} />
            <Text
              sx={{
                fontStyle: fontStyle.boldXS,
                color: color.gray600,
              }}
              text={"Type"}
            />
            <VerticalSpacer height={8} />
            <DateCheckbox
              checkedStates={uiState.serviceDate}
              onChange={uiEvent.onChangeServiceDate}
            />
          </CardContent>
        </Card>
      </Box>
      <VerticalSpacer height={20} />
      {/*  Details 카드 */}
      <Card
        sx={{
          width: "100%",
          minWidth: "1034px",
          borderRadius: "15px",
          border: "1px solid",
          borderColor: color.gray300,
          backgroundColor: color.white,
          boxShadow: 0,
        }}
      >
        <CardContent
          sx={{
            padding: "28px",
          }}
        >
          <Text
            sx={{
              fontStyle: fontStyle.semiboldL,
              color: color.default,
            }}
            text={"Details"}
          />
          <VerticalSpacer height={20} />

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
              }}
            >
              <LabeledPaper
                label={"Service Name"}
                value={uiState.serviceName}
              />
              <VerticalSpacer height={40} />
              <LabeledPaper label={"Service"} value={uiState.serviceType} />
              <VerticalSpacer height={40} />
              <LabeledPaper
                label={"Registration Date"}
                value={LocalizedDate({
                  date: uiState.registrationDate,
                  type: DateFormatType.AdminType,
                })}
              />
            </Box>
            <HorizontalSpacer width={20} />
            <Box
              sx={{
                flexGrow: 1,
              }}
            >
              <LabeledPaper
                label={"Area Name (Address)"}
                value={uiState.serviceAreaName}
              />
              {/*  미사용 필드 UI는 있어서 만들어놨음. */}
              {/*<VerticalSpacer height={40} />*/}
              {/*<LabeledPaper label={"Link"} value={"값없음"} />*/}
              <VerticalSpacer height={40} />
              <LabeledPaper label={"Status"} value={uiState.status} />
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}
