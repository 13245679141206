import { Grid, IconButton } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CommonList from "../../../component/CommonList";
import { CouponCodeCard } from "./CouponCodeCard";
import { CouponQRScanner } from "./CouponQRScanner";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const CouponValidation = () => {
  const [coupon, setCoupon] = useState(false);
  const HeaderBackButton = () => {
    return (
      <IconButton
        sx={{
          ":hover": { backgroundColor: "#F2F2F2" },
          mr: 1,
        }}
        onClick={() => {
          handleClickBackButton();
        }}
      >
        <ArrowBackIosNewIcon fontSize="medium" />
      </IconButton>
    );
  };

  // const [open, setopen] = useState(false); 백버튼 클릭 시 알림창 기능 구현해야함
  const navigate = useNavigate();
  const handleClickBackButton = () => {
    navigate("/paymentManagement");
  };

  return (
    <CommonList title="Payment" headerBackButton={HeaderBackButton()}>
      <Grid container spacing={"20px"}>
        <CouponCodeCard coupon={coupon} setCoupon={setCoupon} />

        {/* <CouponQRScanner /> //TODO 추후 QR스캔하는 기능 만들기 */}
      </Grid>
    </CommonList>
  );
};

export default CouponValidation;
