import { ReactNode, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography
} from "@mui/material";
import TextEditor from "../../../component/TextEditor";
import { styled } from "styled-components";
import { KeyboardArrowDown } from "@mui/icons-material";
import { MonitoringFormData, MonitoringParamName } from "../../../store/monitoringType";
import { ProjectList } from "../../../store/projectType";
import { useMethodologies } from "../../../context/MethodologyContext";
import { areaOptions, subAreaOptions } from "../../../store/methodologyType";
import { ValueListItem } from "../paramData/ParamDetailsPage";
import { SERVER_CONFIG } from "../../../constants/ServerConfig";

interface CreateMonitoringGridPageProps {
  formData: MonitoringFormData;
  setFormData: React.Dispatch<React.SetStateAction<MonitoringFormData>>;
  isNew: boolean;
  error?: string;
  handleTextChange: (fieldName: string, value: string) => void;
  handlePageChange: (newPage: number) => void;
}
interface CustomTypographyProps {
  children: ReactNode;
  sx?: object;
}

const CreateMonitoringGridPage: React.FC<CreateMonitoringGridPageProps> = ({
  formData,
  setFormData,
  handleTextChange,
  isNew,
}) => {
  const methodologies = useMethodologies();
  const [menuItems, setMenuItems] = useState<ProjectList[]>([]);
  const [selectedArea, setSelectedArea] = useState<string>("");
  const [baseLineParamMenuItems, setBaseLineParamMenuItems] = useState<
    MonitoringParamName[]
  >([]);
  const [selectedSubArea, setSelectedSubArea] = useState<string>("");

  const handleReductionAreaChange = (event: SelectChangeEvent<string>) => {
    setSelectedArea(event.target.value || "");
  };

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSelectParamTypeChange = async (
    methodologyId: string | number
  ) => {
    const endpoint = SERVER_CONFIG.CARBON_SERVER + "/methodology";
    try {
      const response = await fetch(`${endpoint}/${methodologyId}`);
      const data = await response.json();
      console.log("API Data:", data);

      if (response.ok) {
        setBaseLineParamMenuItems(data.params || []);
        setSelectedArea(data.area);
        setSelectedSubArea(data.subArea);
      } else {
        setBaseLineParamMenuItems([]);
      }
    } catch (error) {
      setBaseLineParamMenuItems([]);
    }
  };

  useEffect(() => {
    if (formData?.methodologyId && formData?.methodologyId !== 0)
      handleSelectParamTypeChange(formData?.methodologyId);
  }, [formData?.methodologyId]);

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        const response = await fetch(
          SERVER_CONFIG.CARBON_SERVER + '/project/list'
        );
        const data: ProjectList[] = await response.json();
        if (Array.isArray(data) && data.length > 0) {
          setMenuItems(data);
        } else {
          setMenuItems([]);
        }
      } catch (error) {
        setMenuItems([]);
      }
    };
    fetchProjectData();
  }, []);

  const handleProjectChange = async (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Find the selected project to get the methodologyId
    const selectedProject = menuItems.find(
      (project) => project.id.toString() === value
    );
    if (selectedProject?.methodologyId) {
      const methodologyId = selectedProject.methodologyId;
      setFormData((prev: any) => ({
        ...prev,
        methodologyId: selectedProject.methodologyId,
      }));
      handleSelectParamTypeChange(selectedProject.methodologyId);

      if (methodologyId === undefined) {
        console.error("Methodology ID is undefined");
        return; // Stop if undefined
      }

      const endpoint = SERVER_CONFIG.CARBON_SERVER + `/data-argument/monitoring?standard=FIXED&purpose=BASELINE&methodologyId=${methodologyId}`;
      console.log("API Endpoint:", endpoint);

      // Fetch data
      try {
        const response = await fetch(endpoint);
        const data = await response.json();
        // Handle your data...
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  const { dataArgumentValueIdList, ...otherData } = formData || {};

  console.log("formData --------->", formData);
  console.log("menuItems --------->", menuItems);

  const CustomTypography: React.FC<CustomTypographyProps> = ({
    children,
    ...props
  }) => {
    return (
      <Typography
        {...props}
        sx={{
          color: "#99989D",
          ...props.sx,

          lineHeight: "18px",
          fontWeight: 700,
        }}
      >
        {children}
      </Typography>
    );
  };

  return (
    <Grid container rowSpacing={4.5} columnSpacing={2.75} sx={{ mt: 0 }}>
      <Grid item lg={12}>
        <Card
          sx={{
            borderRadius: 3,
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CardContent sx={{ width: "100%", height: "100%" }}>
            <Box
              sx={{
                m: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "63%" }}>
                  <CustomTypography
                    sx={{ fontWeight: "bold", marginLeft: "17px" }}
                  >
                    프로젝트 선택
                  </CustomTypography>
                  {menuItems.length && (
                    <Select
                      value={
                        formData.projectId
                          ? formData.projectId?.toString()
                          : "none"
                      }
                      onChange={handleProjectChange}
                      disabled={!isNew}
                      name="projectId"
                      size="small"
                      IconComponent={(props) => (
                        <KeyboardArrowDown
                          {...props}
                          sx={{ fontSize: "16px" }}
                        />
                      )}
                      sx={{
                        marginLeft: "15px",
                        width: "95%",
                        minHeight: "48px",
                        opacity: 1,
                        borderRadius: "10px",
                        mt: 2,
                        gap: "2px",
                        backgroundColor: "#ffff",
                        "&:hover": {
                          borderColor: "#F9F6FD",
                        },
                      }}
                    >
                      <MenuItem value="none">프로젝트명 선택</MenuItem>
                      {menuItems.map((item) => (
                        <MenuItem key={item.id} value={item.id.toString()}>
                          {item.title}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </Box>
                <Box sx={{ width: "45%" }}>
                  <CustomTypography
                    sx={{ fontWeight: "bold", marginLeft: "17px" }}
                  >
                    기간 조회
                  </CustomTypography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      minHeight: "42px",
                    }}
                  >
                    <TextField
                      type="date"
                      name="startDate"
                      value={formData.startDate}
                      onChange={handleDateChange}
                      sx={{
                        width: "47%",
                        marginLeft: "15px",
                        minHeight: "48px",
                        opacity: 1,
                        borderRadius: "10px",
                        mt: 2,
                        gap: "2px",
                        backgroundColor: "#ffff",
                        "&:hover": {
                          borderColor: "#F9F6FD",
                        },
                      }}
                      size="small"
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                          minHeight: "48px",
                        },
                      }}
                    />
                    <CustomTypography
                      sx={{ alignSelf: "center", marginLeft: "17px" }}
                    >
                      {" "}
                      ~{" "}
                    </CustomTypography>
                    <TextField
                      type="date"
                      name="endDate"
                      value={formData.endDate}
                      onChange={handleDateChange}
                      size="small"
                      sx={{
                        width: "47%",
                        marginLeft: "15px",
                        minHeight: "48px",
                        opacity: 1,
                        borderRadius: "10px",
                        mt: 2,

                        marginRight: "15px",
                        gap: "2px",
                        backgroundColor: "#ffff",
                        "&:hover": {
                          borderColor: "#F9F6FD",
                        },
                      }}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                          minHeight: "48px",
                        },
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}
              >
                <Box sx={{ width: "63%" }}>
                  <CustomTypography
                    sx={{ fontWeight: "bold", mt: 2, marginLeft: "17px" }}
                  >
                    방법론
                  </CustomTypography>
                  <Select
                    name="methodology.title"
                    value={formData.methodologyId}
                    size="small"
                    sx={{
                      marginLeft: "15px",
                      width: "95%",
                      minHeight: "48px",
                      opacity: 1,
                      borderRadius: "10px",
                      mt: 2,
                      gap: "2px",
                      backgroundColor: "#ffff",
                      "&:hover": {
                        borderColor: "#F9F6FD",
                      },
                    }}
                    IconComponent={(props) => (
                      <KeyboardArrowDown {...props} sx={{ fontSize: "16px" }} />
                    )}
                    inputProps={{
                      style: {
                        borderRadius: "7px",
                      },
                      // readOnly: true,
                    }}
                  >
                    <MenuItem value="none"></MenuItem>
                    {[...methodologies].map((methodology) => (
                      <MenuItem key={methodology.id} value={methodology.id}>
                        {methodology.title}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box sx={{ width: "45%" }}>
                  <Box sx={{ width: "100%" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "47%" }}>
                        <CustomTypography
                          sx={{ fontWeight: "bold", mt: 2, marginLeft: "17px" }}
                        >
                          감축 분야
                        </CustomTypography>
                        <Select
                          name="methodology.area"
                          value={selectedArea}
                          onChange={handleReductionAreaChange}
                          size="small"
                          sx={{
                            width: "88.79%",
                            minHeight: "48px",
                            opacity: 1,
                            borderRadius: "10px",
                            mt: 2,
                            marginLeft: "15px",
                            gap: "2px",
                            backgroundColor: "#ffff",
                            "&:hover": {
                              borderColor: "#F9F6FD",
                            },
                          }}
                          IconComponent={(props) => (
                            <KeyboardArrowDown
                              {...props}
                              sx={{ fontSize: "16px" }}
                            />
                          )}
                          inputProps={{
                            style: {
                              borderRadius: "10px",
                            },
                            readOnly: true,
                          }}
                        >
                          {areaOptions.map((el) => (
                            <MenuItem key={el.value} value={el.value}>
                              {el.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                      <Box sx={{ width: "47%" }}>
                        <CustomTypography
                          sx={{ fontWeight: "bold", mt: 2, marginLeft: "17px" }}
                        >
                          세부 분야
                        </CustomTypography>
                        <Select
                          name="methodology.subArea"
                          value={selectedSubArea}
                          size="small"
                          defaultValue=""
                          sx={{
                            width: "88.79%",
                            minHeight: "48px",
                            opacity: 1,
                            borderRadius: "10px",
                            mt: 2,
                            marginLeft: "10px",
                            gap: "2px",
                            backgroundColor: "#ffff",
                            "&:hover": {
                              borderColor: "#F9F6FD",
                            },
                          }}
                          IconComponent={(props) => (
                            <KeyboardArrowDown
                              {...props}
                              sx={{ fontSize: "16px" }}
                            />
                          )}
                          inputProps={{
                            style: {
                              borderRadius: "10px",
                            },
                            readOnly: true,
                          }}
                        >
                          {subAreaOptions.map((el) => (
                            <MenuItem key={el.value} value={el.value}>
                              {el.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
        <Card
          sx={{
            marginTop: "20px",
            borderRadius: "20px",
          }}
        >
          <CardContent>
            <Box>
              <Box>
                <Box
                  sx={{
                    mt: 2,
                    maxWidth: "100%",
                    width: "1018px",
                    marginLeft: "25px",
                  }}
                >
                  <CustomTypography sx={{ fontWeight: "bold", mb: 1 }}>
                    프로젝트 내용 및 목적
                  </CustomTypography>

                  <TextEditor
                    setData={(val: string) => {
                      handleTextChange("projectContent", val);
                    }}
                    minHeight={120}
                    data={formData.projectContent}
                  />
                </Box>
                <Box
                  sx={{
                    mt: 4,
                    maxWidth: "100%",
                    width: "1018px",
                    marginLeft: "25px",
                  }}
                >
                  <CustomTypography sx={{ fontWeight: "bold", mb: 1 }}>
                    사업자 및 온실가스 감축량 소유권
                  </CustomTypography>
                  <TextEditor
                    setData={(val: string) => {
                      handleTextChange("businessOwnership", val);
                    }}
                    minHeight={120}
                    data={formData.businessOwnership}
                  />
                </Box>

                <Box
                  sx={{
                    mt: 4,
                    maxWidth: "100%",
                    width: "1018px",
                    marginLeft: "25px",
                  }}
                >
                  <CustomTypography sx={{ fontWeight: "bold", mb: 1 }}>
                    프로젝트 인증실적의 중복성 평가
                  </CustomTypography>
                  <TextEditor
                    setData={(val: string) => {
                      handleTextChange("businessAssignment", val);
                    }}
                    minHeight={120}
                    data={formData.businessAssignment}
                  />
                </Box>

                <Box
                  sx={{
                    mt: 4,
                    maxWidth: "100%",
                    width: "1018px",
                    marginLeft: "25px",
                  }}
                >
                  <CustomTypography sx={{ fontWeight: "bold", mb: 1 }}>
                    사업 전,후 공정
                  </CustomTypography>
                  <TextEditor
                    setData={(val: string) => {
                      handleTextChange("prePostProcess", val);
                    }}
                    minHeight={120}
                    data={formData.prePostProcess}
                  />
                </Box>

                <Box
                  sx={{
                    mt: 4,
                    maxWidth: "100%",
                    width: "1018px",
                    marginLeft: "25px",
                  }}
                >
                  <CustomTypography sx={{ fontWeight: "bold", mb: 1 }}>
                    사업 이행 상태
                  </CustomTypography>
                  <TextEditor
                    setData={(val: string) => {
                      handleTextChange("businessPerformanceStatus", val);
                    }}
                    minHeight={120}
                    data={formData.businessPerformanceStatus}
                  />
                </Box>
                <Box
                  sx={{
                    mt: 4,
                    maxWidth: "100%",
                    width: "1018px",
                    marginLeft: "25px",
                  }}
                >
                  <CustomTypography sx={{ fontWeight: "bold", mb: 1 }}>
                    사업 승인 후 변경사항
                  </CustomTypography>
                  <TextEditor
                    setData={(val: string) => {
                      handleTextChange("changeAfterBusinessApproval", val);
                    }}
                    minHeight={120}
                    data={formData.changeAfterBusinessApproval}
                  />
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default CreateMonitoringGridPage;
