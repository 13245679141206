import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import ProgressBar from "../component/ProgressBar";
import { AuthRepository } from "../repository/AuthRepository";
import { AuthRepositoryImpl } from "../repository/impl/AuthRepositoryImpl";
import { Auth, onAuthStateChanged, User } from "firebase/auth";
import { auth } from "../firebase";

const AuthRepositoryContext = createContext<AuthRepository | null>(null);

export const AuthRepositoryProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [authRepository, setAuthRepository] = useState<AuthRepository | null>(
    null
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const authRepositoryInstance = AuthRepositoryImpl.getInstance();
    setAuthRepository(authRepositoryInstance);
    // 회원 정보가 변경될 때, 호출되는 콜백함수 입니다.
    // Firebase Auth의 대시보드에서 사용자 정보를 변경했을 때는 호출되지 않습니다.
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      // console.log("origin boolean : ", originalUser == null);
      if (authRepositoryInstance.originalUser == null && currentUser != null) {
        authRepositoryInstance.originalUser = currentUser;
        // console.log("asdasdasdasdasdas is : ", originalUser, currentUser);
      }
      console.log("[DEV] onAuthStateChanged auth", auth.currentUser);
      if (currentUser && currentUser.email) {
        if (window.location.href.includes("loginmobile")) {
          console.log("[DEV] success login! Hello, ", currentUser.displayName);
          await validateAndStoreUserData(currentUser);
        }
        if (window.location.href.includes("login")) {
          console.log("[DEV] success login! Hello, ", currentUser.displayName);
          await validateAndStoreUserData(currentUser);
        }
      } else {
        // TODO 실패시 처리 로직 추가필요합니다. (ex. 로그인 페이지로 리다이렉트)
        // window.location.href = "http://localhost:3000/login";
        console.log("[DEV] No user is signed in.");
      }
      setLoading(false);
    });

    // 컴포넌트 언마운트 시 구독 해제
    return () => unsubscribe();
  }, []);

  // 사용자의 정보를 주기적으로 체크하여, 계정이 삭제되었을 경우 로그아웃 처리할 수 있습니다.
  // 필요시 사용하시면 됩니다.
  useEffect(() => {
    const intervalId = setInterval(() => {
      console.log(`[DEV] Checking user account... ${auth.currentUser?.email}`);
      auth.currentUser?.reload().catch((error) => {
        if (error.code === "auth/user-not-found") {
          console.log("[DEV] User account deleted from Firebase.");
          // 여기서 로그아웃 처리나 에러 메시지 출력을 할 수 있습니다.
          // 예: 로그아웃 처리 및 로그인 페이지로 리다이렉트
          // auth.signOut();
          // window.location.href = "/login";
        }
      });
    }, 3000); // 3초마다 체크 (필요에 따라 조절 가능)

    return () => clearInterval(intervalId); // 컴포넌트 언마운트 시 interval 해제
  }, []);

  function validateAndStoreUserData(currentUser: User): Promise<void> {
    return new Promise((resolve) => {
      const userData = {
        user_name: currentUser.displayName,
        user_email: currentUser.email,
        user_phone_number: currentUser.phoneNumber,
        user_uuid: currentUser.uid,
        refresh_token: currentUser.refreshToken,
      };

      // 유효한 데이터만 저장
      Object.entries(userData).forEach(([key, value]) => {
        if (value !== undefined) {
          console.log(`[DEV] save ${key}: ${value}`);
          localStorage.setItem(key, value ?? "");
        }
      });

      resolve();
    });
  }

  if (loading) {
    return <ProgressBar />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <AuthRepositoryContext.Provider value={authRepository}>
      {children}
    </AuthRepositoryContext.Provider>
  );
};

export const useAuthRepository = () => {
  const context = useContext(AuthRepositoryContext);
  if (!context) {
    throw new Error(
      "useAuthRepository must be used within a AuthRepositoryProvider"
    );
  }
  return context;
};
