import { useEffect, useRef, useState } from "react";
import GoogleMap from "./section/GoogleMap";
import PWUserAppMapSearchBox from "./section/PWUserAppMapSearchBox";
import PWUserBottomDrawer from "./section/PWUserBottomDrawer";
import { PWUserAppMapUiState } from "./PWUserAppMapUiState";
import { PWUserAppMapUiEvent } from "./PWUserAppMapUiEvent";
import { useLocation, useNavigate } from "react-router-dom";
import SearchStationPage from "./SearchStationPage";
import useAndroidBackHandler from "../../customHook/useAndroidBackHandler";
import RightBottomButton from "./section/RightBottomButton";
import { PWUserAppMapSideEffect } from "./PWUserAppMapSideEffect";
import PopupAdvertisementDialog, {
  POPUP_ADVERTISEMENT_DIALOG_SKIP_TIME,
} from "../../component/PopupAdvertisementDialog";
import { ApiService } from "../../restAPI/ApiService";
import { BannerModel } from "../MobilePartners/model/BannerModel";
import { getCountryCode } from "../../customHook/useLocale";
import SimpleDialog from "../../component/dialog/SimpleDialog";
import QrCardDialog from "./section/QrCardDialog";
import {
  IMAGE_BATTERY_BAD,
  IMAGE_BATTERY_ERROR,
  IMAGE_BATTERY_GOOD,
} from "../../constants/appImagePath";
import { LocalizedText } from "../../di/LanguageRepositoryProvider";
import { ROUTE_PATHS } from "../../constants/RouteConstants";

function registerQRCodeEvent(url: string): void {
  const event = new CustomEvent("dispatchQRData", { detail: url });
  window.dispatchEvent(event);
}

// 전역 스코프에 함수 노출
(window as any).dispatchQRData = registerQRCodeEvent;

function didUpdateLocation(lat: number, lng: number) {
  const event = new CustomEvent("didUpdateLocation", {
    detail: { lat, lng },
  });
  window.dispatchEvent(event);
}

(window as any).didUpdateLocation = didUpdateLocation;

function sendLocationPermission(isGranted: boolean) {
  const event = new CustomEvent("sendLocationPermission", {
    detail: isGranted,
  });
  window.dispatchEvent(event);
}

(window as any).sendLocationPermission = sendLocationPermission;

const PWUserAppMapRender = () => {
  const navigate = useNavigate();
  const [qrdata, setQRData] = useState("");
  const [uiState, setUiState] = useState(new PWUserAppMapUiState());
  const location = useLocation();
  const svcAreaId: string = uiState.svcAreaId; // 전달된 상태를 받음
  const allYearText = <>{LocalizedText("ua_c300_allyear")}</>;

  // 광고 팝업 다이얼로그 표시 여부 상태
  const [
    isVisiblePopupAdvertisementDialog,
    setIsVisiblePopupAdvertisementDialog,
  ] = useState(false);

  const [popupAdvertisementData, setPopupAdvertisementData] = useState<
    BannerModel[]
  >([]);

  useEffect(() => {
    const handleAppToWeb = (event: Event) => {
      const customEvent = event as CustomEvent<string>;
      setQRData(customEvent.detail);
    };

    window.addEventListener("dispatchQRData", handleAppToWeb);
    return () => {
      window.removeEventListener("dispatchQRData", handleAppToWeb);
    };
  }, []);

  useEffect(() => {
    // popupAdvertisementData에 데이터가 있는 경우에만 실행
    if (popupAdvertisementData && popupAdvertisementData.length > 0) {
      const storedTime = localStorage.getItem(
        POPUP_ADVERTISEMENT_DIALOG_SKIP_TIME
      );

      if (storedTime) {
        const lastDismissTime = new Date(parseInt(storedTime, 10)); // 로컬스토리지 값 -> Date 변환
        const currentTime = new Date();

        // 같은 날인지 확인 (연도, 월, 일 비교)
        const isSameDay =
          lastDismissTime.getFullYear() === currentTime.getFullYear() &&
          lastDismissTime.getMonth() === currentTime.getMonth() &&
          lastDismissTime.getDate() === currentTime.getDate();

        if (!isSameDay) {
          togglePopupAdvertisementDialog(true);
        }
      } else {
        togglePopupAdvertisementDialog(true);
      }
    }
  }, [popupAdvertisementData]);

  async function getPopupAdvertisementData() {
    const url = "/pw/ua/contents/popup/random";
    const requestBody = {
      country: getCountryCode(),
    };
    await ApiService.MobilePost(url, requestBody)
      .then((response) => {
        if (response != null) {
          const data = response.data.body as BannerModel[];
          setPopupAdvertisementData(data);
          console.log("getPopupAdvertisementData API response : ", data);
        }
      })
      .catch((e) => {
        console.log("getPopupAdvertiseData API error : ", e);
      });
  }

  function togglePopupAdvertisementDialog(state: boolean) {
    if (state) {
      if (window.Android) {
        //- API 요청이 오기전에 다른 탭을 눌러서 이동한 이후에 isShowDialog가 호출되는 경우, 바텀네비를 누르지 못하는 이슈가 있어서 해당 코드를 임시 주석 처리함.
        // window.Android.isShownDialog(true);
      }
      // TODO ios Native Bridge code 추가시 주석 해제
      // if (window.webkit) {
      //   window.webkit.messageHandlers.isShownDialog.postMessage(true);
      // }
      setIsVisiblePopupAdvertisementDialog(true);
    } else {
      if (window.Android) {
        // window.Android.isShownDialog(false);
      }
      // if (window.webkit) {
      //   window.webkit.messageHandlers.isShownDialog.postMessage(false);
      // }
      setIsVisiblePopupAdvertisementDialog(false);
    }
  }

  useEffect(() => {
    getPopupAdvertisementData();
  }, []);

  const isUserCenteredForiOS = useRef(true);

  useEffect(() => {
    const event = (event: Event) => {
      const { lat, lng } = (event as CustomEvent<{ lat: number; lng: number }>)
        .detail;

      if (isUserCenteredForiOS.current) {
        console.log("UserCenteredTrue");
        uiEventHandler.CoordinatesClickTrue();
        isUserCenteredForiOS.current = false;
      } else {
        console.log("UserCenteredFalse");
        uiEventHandler.CoordinatesClickFalse();
      }

      uiEventHandler.SetUserPosition(lat, lng);
    };
    window.addEventListener("didUpdateLocation", event);
    return () => {
      window.removeEventListener("didUpdateLocation", event);
    };
  }, []);

  useEffect(() => {
    const event = (event: Event) => {
      const customEvent = event as CustomEvent<boolean>;
      const isGranted = customEvent.detail;
      if (isGranted) {
        uiEventHandler.SetPermissionTrue();
      } else {
        uiEventHandler.SetPermissionFalse();
      }
    };

    window.addEventListener("sendLocationPermission", event);
    return () => {
      window.removeEventListener("sendLocationPermission", event);
    };
  }, []);

  useAndroidBackHandler(() => {
    if (uiState.isShowEmergencyDialog) {
      uiEventHandler.HideEmergencyDialog();
    }
    if (
      uiState.drawerSwipeOpen == true &&
      uiState.drawerOpen == true &&
      uiState.detailsOpen == true &&
      uiState.isShowEmergencyDialog == false
    ) {
      uiEventHandler.SetDetailsOpen(false);
    }
    if (
      uiState.drawerSwipeOpen == true &&
      uiState.drawerOpen == true &&
      uiState.detailsOpen == false &&
      uiState.isShowEmergencyDialog == false
    ) {
      uiEventHandler.HandleDrawerSwipeOpenChange(false);
    }
    if (
      uiState.drawerSwipeOpen == false &&
      uiState.drawerOpen == true &&
      uiState.detailsOpen == false &&
      uiState.isShowEmergencyDialog == false
    ) {
      uiEventHandler.HandleDrawerOpenChange(false);
      uiEventHandler.SetClickedMarkerId(null);
    }
    if (
      uiState.drawerSwipeOpen == false &&
      uiState.drawerOpen == false &&
      uiState.detailsOpen == false &&
      uiState.isShowEmergencyDialog == false
    ) {
      if (uiState.isClickSearchStation == true) {
        uiEventHandler.SetIsClickSearchStation(false);
      } else {
        window.Android.onBackStackCleared();
      }
    }
  }, [
    uiState.drawerSwipeOpen,
    uiState.drawerOpen,
    uiState.isClickSearchStation,
    uiState.detailsOpen,
    uiState.isShowEmergencyDialog,
  ]);

  const uiEventHandler: PWUserAppMapUiEvent = {
    HandleDrawerOpenChange: (event: boolean) => {
      setUiState((prev) => {
        return {
          ...prev,
          drawerOpen: event,
        };
      });
    },
    HandleDrawerSwipeOpenChange: (event: boolean) => {
      setUiState((prev) => {
        return {
          ...prev,
          drawerSwipeOpen: event,
        };
      });
    },
    HandleDrawerDataChange: (event: any) => {
      setUiState((prev) => {
        return {
          ...prev,
          drawerData: event,
        };
      });
    },
    SetGoogleMap: (event: any) => {
      setUiState((prev) => {
        return {
          ...prev,
          googleMap: event,
        };
      });
    },
    SetPositionChange(event: any) {
      setUiState((prev) => {
        return {
          ...prev,
          position: event,
        };
      });
    },
    SetClickedMarkerId(event: any) {
      setUiState((prev) => {
        return {
          ...prev,
          clickedMarkerId: event,
        };
      });
    },
    SetIsClickSearchStation(event: any) {
      setUiState((prev) => {
        return {
          ...prev,
          isClickSearchStation: event,
        };
      });
    },
    SetSvcAreaId(event: any) {
      setUiState((prev) => {
        return {
          ...prev,
          svcAreaId: event,
        };
      });
    },
    SetIsSearchListClick(event: any) {
      setUiState((prev) => {
        return {
          ...prev,
          isSearchListClick: event,
        };
      });
    },
    ShowEmergencyDialog: () => {
      if (window.Android) {
        window.Android.isShownDialog(true);
      }

      if (window.webkit) {
        window.webkit.messageHandlers.isShownDialog.postMessage(true);
      }

      setUiState((prev) => {
        return {
          ...prev,
          isShowEmergencyDialog: true,
        };
      });
    },

    HideEmergencyDialog: function (): void {
      if (window.Android) {
        window.Android.isShownDialog(false);
      }

      if (window.webkit) {
        window.webkit.messageHandlers.isShownDialog.postMessage(false);
      }

      setUiState((prev) => {
        return {
          ...prev,
          isShowEmergencyDialog: false,
        };
      });
    },
    CoordinatesClickTrue: () => {
      setUiState((prev) => {
        return {
          ...prev,
          myCoordinatesClick: true,
        };
      });
    },
    CoordinatesClickFalse: () => {
      setUiState((prev) => {
        return {
          ...prev,
          myCoordinatesClick: false,
        };
      });
    },

    SetIsMarkerClick: (event) => {
      setUiState((prev) => {
        return {
          ...prev,
          isMarkerClick: event,
        };
      });
    },

    SetUserPosition: (lat, lng) => {
      setUiState((prev) => {
        return {
          ...prev,
          userLat: lat,
          userLng: lng,
        };
      });
    },

    SetFinalPosition: (position) => {
      setUiState((prev) => {
        return {
          ...prev,
          finalPosition: {
            lat: position.lat,
            lng: position.lng,
          },
        };
      });
    },
    SetIsMapOnloaded: () => {
      setUiState((prev) => {
        return {
          ...prev,
          isMapOnloaded: true,
        };
      });
    },
    SetSvcFilterType: (svcType) => {
      setUiState((prev) => {
        return {
          ...prev,
          svcFilterType: svcType,
        };
      });
    },
    SetSelectedChip: (selected) => {
      setUiState((prev) => {
        return {
          ...prev,
          selectedChip: selected,
        };
      });
    },
    SetDetailsOpen: (event: boolean) => {
      setUiState((prev) => {
        return {
          ...prev,
          detailsOpen: event,
        };
      });
    },
    SetDetailsSvcImageData: (event: any) => {
      setUiState((prev) => {
        return {
          ...prev,
          detailsSvcImageData: event,
        };
      });
    },
    SetPermissionTrue: () => {
      setUiState((prev) => {
        return {
          ...prev,
          isLocationPermissionGranted: true,
        };
      });
    },
    SetPermissionFalse: () => {
      setUiState((prev) => {
        return {
          ...prev,
          isLocationPermissionGranted: false,
        };
      });
    },

    arrayDifference: (arr: string): React.ReactNode => {
      const week = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      if (!arr) {
        return "-";
      }
      if (arr.split("").every((res) => res === "O")) {
        return allYearText;
      }
      let res: string[] = [];
      for (let i = 0; i < arr.length; ++i) {
        if ("X" === arr[i]) {
          res.push(week[i]);
        }
      }
      return res.join(", ");
    },
  };

  const sideEffectHandlers: PWUserAppMapSideEffect = {
    callEmergency: (number: string) => {
      window.location.href = `tel:${number}`;
    },
  };

  // navigator.permissions
  //   .query({ name: "geolocation" })
  //   .then(function (permissionStatus) {
  //     console.log("geolocation permission state is ", permissionStatus.state);

  //     permissionStatus.onchange = function () {
  //       console.log("geolocation permission state has changed to ", this.state);
  //     };
  //   });

  let test1 = useRef(false);
  let test2 = useRef(true);

  function handleSuccess(position: any) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;

    console.log(latitude, longitude);
    if (test1.current == true) {
      console.log("1");
      if (test2.current == true) {
        console.log("2");
        uiEventHandler.CoordinatesClickTrue();
        test2.current = false;
      }
    }
    test1.current = true;
    // 좌표를 상태로 저장
    uiEventHandler.SetUserPosition(latitude, longitude);
  }

  function handleError() {}

  const askForCoords = () => {
    return navigator.geolocation.watchPosition(handleSuccess, handleError, {
      enableHighAccuracy: true,
      maximumAge: 10000,
      timeout: 5000,
    });
  };

  useEffect(() => {
    if (window.Android) {
      const watchId = askForCoords();
      // 컴포넌트 언마운트 시 watchPosition을 정리
      return () => navigator.geolocation.clearWatch(watchId);
    }
  }, []);

  // const askForCoords = () => {
  //   return navigator.geolocation.getCurrentPosition(handleSuccess, handleError);
  // };

  const checkGeolocationPermission = () => {
    console.log("aaaaa");
    if (window.Android) {
      navigator.geolocation.getCurrentPosition(
        uiEventHandler.SetPermissionTrue, // 성공시 실행할 콜백
        (error) => {
          // 권한이 거부된 경우 또는 오류가 발생한 경우
          if (error.code === error.PERMISSION_DENIED) {
            uiEventHandler.SetPermissionFalse();
          }
        }
      );
    } else if (window.webkit) {
      window.webkit.messageHandlers.checkLocationPermission.postMessage(
        "checkLocationPermission"
      );
    }
  };

  useEffect(() => {
    checkGeolocationPermission();
  }, []); // 빈 배열을 전달하여 컴포넌트가 처음 렌더링될 때만 실행

  useEffect(() => {
    console.log(
      "uiState.isLocationPermissionGranted is : ",
      uiState.isLocationPermissionGranted
    );
  }, [uiState.isLocationPermissionGranted]);

  function handleLinkAction(link: string | undefined) {
    if (!link) {
      console.log("Link does not exist");
      return;
    }

    if (link.startsWith("notice://")) {
      handleNoticeLink(link);
    } else if (link.startsWith("http")) {
      handleExternalLink(link);
    } else {
      console.error("Invalid link format : ", link);
    }
  }

  function handleNoticeLink(link: string) {
    const noticeId = link.split("notice://")[1];
    if (noticeId) {
      navigate(ROUTE_PATHS.NOTICE_DETAIL, { state: { id: noticeId } });
    } else {
      console.error("Invalid notice link id");
    }
  }

  function handleExternalLink(link: string) {
    if (window.Android) {
      window.Android.openExternalBrowser(link);
    } else if (window.webkit) {
      window.webkit.messageHandlers.openExternalBrowser.postMessage(link);
    } else {
      console.log("Web browser open: ", link);
    }
  }

  return (
    <>
      {uiState.isClickSearchStation == false ? (
        <>
          {uiState.drawerOpen == true ? (
            <PWUserBottomDrawer
              open={uiState.drawerOpen}
              uiState={uiState}
              uiEvent={uiEventHandler}
            />
          ) : null}

          {uiState.drawerSwipeOpen == false ? (
            <PWUserAppMapSearchBox uiState={uiState} uiEvent={uiEventHandler} />
          ) : null}
          <GoogleMap
            uiEvent={uiEventHandler}
            uiState={uiState}
            svcAreaID={svcAreaId}
          />

          <RightBottomButton
            uiState={uiState}
            sideEffect={sideEffectHandlers}
            uiEvent={uiEventHandler}
          />
        </>
      ) : (
        <SearchStationPage uiEvent={uiEventHandler} />
      )}
      {isVisiblePopupAdvertisementDialog ? (
        <PopupAdvertisementDialog
          data={popupAdvertisementData}
          onNegative={() => {
            togglePopupAdvertisementDialog(false);
          }}
          onPositive={(index: number) => {
            try {
              handleLinkAction(popupAdvertisementData[index].link);
            } catch (e) {
              console.log("popupAdvertisementData[index].link is not exist");
            } finally {
              togglePopupAdvertisementDialog(false);
            }
          }}
          onClickSkipToday={() => {
            togglePopupAdvertisementDialog(false);
          }}
        />
      ) : null}
      {/* qr scan 테스트 코드 */}
      {qrdata != "" && (
        <SimpleDialog
          isOpen={qrdata != ""}
          onPositive={() => {
            setQRData("");
          }}
          onClose={() => {
            setQRData("");
          }}
          children={qrdata}
        />
      )}
      <QrCardDialog
        isOpen={false}
        image={IMAGE_BATTERY_GOOD}
        title={LocalizedText("ua_c100_alert_qrsuccess_title")}
        description={LocalizedText("ua_c100_alert_qrsuccess_message")}
        onClick={() => {}}
      />
      <QrCardDialog
        isOpen={false}
        image={IMAGE_BATTERY_BAD}
        title={LocalizedText("ua_c100_alert_qrinsufficient_title")}
        description={LocalizedText("ua_c100_alert_qrinsufficient_message")}
        onClick={() => {}}
      />
      <QrCardDialog
        isOpen={false}
        image={IMAGE_BATTERY_ERROR}
        title={LocalizedText("ua_c100_alert_qrfailed_title")}
        description={LocalizedText("ua_c100_alert_qrfailed_message")}
        onClick={() => {}}
      />
    </>
  );
};

export default PWUserAppMapRender;
