import { Box, Typography } from "@mui/material";
import BenefitButton from "./BenefitButton";
import { color } from "../../../theme/Color";
import { fontStyle } from "../../../theme/Style";
import { partners } from "../model/PartnersModel";
import {
  ICON_PRIMARY_PIN,
  ICON_RIGHT_ARROW_GRAY600,
  IMAGE_EMPTY_IMAGE,
} from "../../../constants/appImagePath";

interface PartnersItemProps {
  partners: partners;
  onClick: (partners: partners) => void;
}

const PartnersItem = ({ partners, onClick }: PartnersItemProps) => {
  return (
    <Box
      onClick={() => onClick(partners)}
      sx={{
        width: "100%",
        backgroundColor: color.white,
        borderBottom: `1px solid ${color.gray200}`,
        py: "12px",
        boxSizing: "border-box",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          height: "85px",
          gap: "10px",
          pr: "8px",
          boxSizing: "border-box",
          minWidth: "0",
        }}
      >
        <img
          src={partners?.serviceImage[0] ?? IMAGE_EMPTY_IMAGE}
          alt="partner image"
          style={{
            width: "85px",
            height: "85px",
            borderRadius: "10px",
            objectFit: "cover",
            flexShrink: 0,
          }}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: "4px",
            minWidth: "0",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
            <Typography
              fontStyle={fontStyle.titleS}
              color={color.default}
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {partners.serviceName}
            </Typography>
            {/* <BenefitButton sx={{ flexShrink: 0 }} /> */}
          </Box>
          <Typography
            fontStyle={fontStyle.bodySr}
            color={color.gray600}
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              lineHeight: "19.5px",
              height: "39px",
              display: "block",
            }}
          >
            {partners.serviceDescription}
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={ICON_PRIMARY_PIN}
              style={{ width: "16px", height: "16px" }}
            />
            <Typography
              fontStyle={fontStyle.subTitleS}
              color={color.gray600}
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {partners.location}
            </Typography>
          </Box>
        </Box>
      </Box>
      <img
        src={ICON_RIGHT_ARROW_GRAY600}
        alt="right arrow"
        style={{ width: "24px", height: "24px" }}
      />
    </Box>
  );
};

export default PartnersItem;
