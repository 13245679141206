export class PWUserAppMapUiState {
  drawerOpen: boolean = false;
  drawerSwipeOpen: boolean = false;
  isMarkerClick: boolean = false;
  drawerData: any[] = [];
  googleMap: any = google.maps.Map;
  position: any = google.maps.LatLng;
  clickedMarkerId: string | null = null;
  isClickSearchStation: boolean = false;
  svcAreaId: string = "";
  isSearchListClick: boolean = false;
  isShowEmergencyDialog: boolean = false;
  isMapOnloaded: boolean = false;
  detailsOpen: boolean = false;
  detailsImgData: Array<any> = [];

  userLat: number = 11.5563738;
  userLng: number = 104.9282099;

  finalPosition: any = { lat: 11.5563738, lng: 104.9282099 };

  myCoordinatesClick: boolean = false;

  svcFilterType: Array<string> = [];

  selectedChip: string = "";

  whatScreen = "Map";
  isLocationPermissionGranted: boolean = false;
}
