import { Box, Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import { fontStyle } from "../../../../../theme/Style";
import { ServiceMngCreateUiState } from "../model/ServiceMngCreateUiState";
import { ServiceMngCreateUiEvent } from "../model/ServiceMngCreateUiEvent";
import FileUploadPreview from "../component/FileUploadPreview";
import { color } from "../../../../../theme/Color";
import VerticalSpacer from "../../../../../component/Spacer";
import CommonTextField from "../../../../../component/CommonTextField";
import { CommonSelect } from "../../../../../component/CommonSelect";

import { ADMIN_CONFIG } from "../../../../../constants/AdminConfig";

interface DetailsCardProps {
  uiState: ServiceMngCreateUiState;
  uiEvent: ServiceMngCreateUiEvent;
}

const DetailsCard = ({ uiState, uiEvent }: DetailsCardProps) => {
  return (
    <Grid item lg={12}>
      <Card
        sx={{
          borderRadius: "15px",
          minHeight: 240,
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <CardContent sx={{ width: "100%", height: "100%" }}>
          <CardHeader
            title={"Details"}
            titleTypographyProps={{ fontStyle: fontStyle.semiboldL }}
            // sx={{ ml: -2, mb: 0 }}
          />
          <Box
            sx={{
              m: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* 첫번째 줄 */}
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ width: "48.5%" }}>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Attach File
                </Typography>
                <VerticalSpacer height={8} />
                <FileUploadPreview uiState={uiState} uiEvent={uiEvent} />
              </Box>
              <Box sx={{ width: "48.5%" }}>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Service Name
                </Typography>
                {/* <FileUploadPreview /> */}
                <CommonTextField
                  value={uiState.ServiceName}
                  onChange={(event) => {
                    uiEvent.HandleUiStateChange("ServiceName", event.target.value);
                  }}
                  sx={{
                    mt: "8px",
                    width: "100%",
                    height: "48px",
                  }}
                  InputProps={{
                    style: {
                      height: "48px",
                      borderRadius: "10px",
                      // backgroundColor: color.gray200,
                      // color: color.gray600, // 비활성화 상태일 때 색상을 지정
                    },
                  }}
                  // disabled
                  error={uiState.ServiceNameError}
                />
              </Box>
            </Box>
            {/* 두번째 줄 */}
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                mt: -4.5,
              }}
            >
              <Box sx={{ width: "48.5%" }}></Box>
              <Box sx={{ width: "48.5%" }}>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Service
                </Typography>
                <CommonSelect
                  selectValue={uiState.Service || ""}
                  menuitems={[ADMIN_CONFIG.CAFE, ADMIN_CONFIG.STATION, ADMIN_CONFIG.CSCENTER, ADMIN_CONFIG.PARTNER]}
                  onChange={(event) => {
                    uiEvent.HandleUiStateChange("Service", event.target.value);
                  }}
                  sx={{
                    height: "48px",
                    width: "100%",
                    mt: "8px",
                    borderRadius: "10px",
                    fontStyle: fontStyle.regularS,
                    color: "black",
                  }}
                  placeholder="Select"
                  error={uiState.ServiceError}
                />
              </Box>
            </Box>
            <VerticalSpacer height={24} />
            {/* 세번째 줄 */}
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ width: "48.5%" }}></Box>
              <Box sx={{ width: "48.5%" }}>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Status
                </Typography>
                <CommonSelect
                  selectValue={uiState.Status || ""}
                  menuitems={[ADMIN_CONFIG.NEW, ADMIN_CONFIG.PREPARING, ADMIN_CONFIG.OPEN, ADMIN_CONFIG.CLOSE, ADMIN_CONFIG.CLOSURE]}
                  onChange={(event) => {
                    uiEvent.HandleUiStateChange("Status", event.target.value);
                  }}
                  sx={{
                    height: "48px",
                    width: "100%",
                    mt: "8px",
                    borderRadius: "10px",
                    fontStyle: fontStyle.regularS,
                    color: "black",
                  }}
                  placeholder="Select"
                  error={uiState.StatusError}
                />
              </Box>
            </Box>
            <VerticalSpacer height={24} />
            {/* 네번째 줄 */}
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ width: "48.5%" }}>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Area Name (Address)
                </Typography>
                <CommonSelect
                  selectValue={uiState.AreaName || ""}
                  menuitems={uiState.AreaList.map((data: any) => data.svcAreaNm)}
                  onChange={(event) => {
                    uiEvent.HandleUiStateChange("AreaName", event.target.value);
                    const selectArea: any = uiState.AreaList.filter((data: any) => data.svcAreaNm === event.target.value)[0];
                    uiEvent.HandleUiStateChange("AreaId", selectArea.svcAreaId);
                  }}
                  sx={{
                    height: "48px",
                    width: "100%",
                    mt: "8px",
                    borderRadius: "10px",
                    fontStyle: fontStyle.regularS,
                    color: "black",
                  }}
                  placeholder="Select"
                  error={uiState.AreaNameError}
                />
              </Box>
              <Box sx={{ width: "48.5%" }}>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Linked
                </Typography>
                <CommonTextField
                  value={""}
                  onChange={(event) => {}}
                  sx={{
                    mt: "8px",
                    width: "100%",
                    height: "48px",
                  }}
                  InputProps={{
                    style: {
                      height: "48px",
                      borderRadius: "10px",
                    },
                  }}
                  disabled
                />
              </Box>
            </Box>
            <VerticalSpacer height={24} />
            {/* 다섯번째 줄 */}
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ width: "48.5%" }}>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Registration Date
                </Typography>
                <CommonTextField
                  value={""}
                  onChange={(event) => {
                    //   uiEvent.HandleUiStateChange("Name", event.target.value);
                  }}
                  sx={{
                    mt: "8px",
                    width: "100%",
                    height: "48px",
                  }}
                  InputProps={{
                    style: {
                      height: "48px",
                      borderRadius: "10px",
                      // backgroundColor: color.gray200,
                      // color: color.gray600, // 비활성화 상태일 때 색상을 지정
                    },
                  }}
                  disabled
                />
              </Box>
              <Box sx={{ width: "48.5%" }}>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Last Modified Date
                </Typography>
                {/* <FileUploadPreview /> */}
                <CommonTextField
                  value={""}
                  onChange={(event) => {
                    //   uiEvent.HandleUiStateChange("Name", event.target.value);
                  }}
                  sx={{
                    mt: "8px",
                    width: "100%",
                    height: "48px",
                  }}
                  InputProps={{
                    style: {
                      height: "48px",
                      borderRadius: "10px",
                      // backgroundColor: color.gray200,
                      // color: color.gray600, // 비활성화 상태일 때 색상을 지정
                    },
                  }}
                  disabled
                />
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default DetailsCard;
