import { Box, Card, CardContent, CardHeader, Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import { fontStyle } from "../../../../../theme/Style";
import { ServiceMngDetailUiState } from "../model/ServiceMngDetailUiState";
import { ServiceMngDetailUiEvent } from "../model/ServiceMngDetailUiEvent";
import { color } from "../../../../../theme/Color";
import { CommonSelect } from "../../../../../component/CommonSelect";
import VerticalSpacer from "../../../../../component/Spacer";
import { useEffect, useState } from "react";
import { ICON_CHECK2, ICON_UNCHECK } from "../../../../../constants/appImagePath";

interface ServiceCardProps {
  uiState: ServiceMngDetailUiState;
  uiEvent: ServiceMngDetailUiEvent;
}
const ServiceCard = ({ uiState, uiEvent }: ServiceCardProps) => {
  // 상태 초기값 수정
  const [checkedDays, setCheckedDays] = useState<any>({
    all: false,
    Sun: false,
    Mon: false,
    Tue: false,
    Wed: false,
    Thu: false,
    Fri: false,
    Sat: false,
  });

  // OpenDay 값에 따라 초기 상태 설정
  useEffect(() => {
    const openDayString = uiState.OpenDay || "XXXXXXX"; // 기본값 설정
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    // newCheckedDays의 타입을 명시적으로 정의
    const newCheckedDays: Record<string, boolean> = days.reduce(
      (acc, day, index) => ({
        ...acc,
        [day]: openDayString[index] === "O",
      }),
      {} as Record<string, boolean> // 초기 타입 지정
    );

    // "all" 키를 제외하고 확인
    const allSelected = days.every((day) => newCheckedDays[day]);

    // all 포함해서 상태 업데이트
    setCheckedDays({ ...newCheckedDays, all: allSelected });
  }, [uiState.OpenDay]);

  // "All Day" 체크박스 핸들러
  const handleAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setCheckedDays({
      all: isChecked,
      Sun: isChecked,
      Mon: isChecked,
      Tue: isChecked,
      Wed: isChecked,
      Thu: isChecked,
      Fri: isChecked,
      Sat: isChecked,
    });
  };

  // 개별 요일 체크박스 핸들러
  const handleDayChange = (day: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    const updatedDays = { ...checkedDays, [day]: isChecked };

    // "All Day" 상태 업데이트
    const allSelected = Object.values(updatedDays)
      .slice(1) // 첫 번째 "all" 제외
      .every((value) => value); // 모두 체크된 상태인지 확인
    setCheckedDays({ ...updatedDays, all: allSelected });
  };

  // 상태에 따라 문자열 생성
  const getCheckedDaysString = () => {
    return Object.entries(checkedDays)
      .filter(([key]) => key !== "all") // "all" 키 제외
      .map(([_, value]) => (value ? "O" : "X")) // true는 "O", false는 "X"
      .join(""); // 문자열로 합치기
  };
  useEffect(() => {
    uiEvent.HandleUiStateChange("OpenDay", getCheckedDaysString());
  }, [checkedDays]);

  return (
    <Grid item lg={6}>
      <Card
        sx={{
          borderRadius: "15px",
          minHeight: 240,
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <CardContent sx={{ width: "100%", height: "100%" }}>
          <CardHeader
            title={"Service"}
            titleTypographyProps={{ fontStyle: fontStyle.semiboldL }}
            // sx={{ ml: -2, mb: 0 }}
          />
          <Box
            sx={{
              m: 2,
              mb: -1.5,
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* Open Time */}
            <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
              <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                Open Time
              </Typography>

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box sx={{ width: "47.5%" }}>
                  <CommonSelect
                    selectValue={uiState.OpenTimeHour}
                    menuitems={Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, "0"))} // 00시 ~ 23시
                    onChange={(event) => {
                      uiEvent.HandleUiStateChange("OpenTimeHour", event.target.value);
                    }}
                    sx={{
                      height: "48px",
                      width: "100%",
                      mt: "8px",
                      borderRadius: "10px",
                      fontStyle: fontStyle.regularS,
                      color: "black",
                    }}
                  />
                </Box>
                :
                <Box sx={{ width: "47.5%" }}>
                  <CommonSelect
                    selectValue={uiState.OpenTimeMin}
                    menuitems={Array.from({ length: 12 }, (_, i) => (i * 5).toString().padStart(2, "0"))} // 00분, 05분, ..., 55분
                    onChange={(event) => {
                      uiEvent.HandleUiStateChange("OpenTimeMin", event.target.value);
                    }}
                    sx={{
                      height: "48px",
                      width: "100%",
                      mt: "8px",
                      borderRadius: "10px",
                      fontStyle: fontStyle.regularS,
                      color: "black",
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <VerticalSpacer height={24} />
            {/* Close Time */}
            <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
              <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                Close Time
              </Typography>

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box sx={{ width: "47.5%" }}>
                  <CommonSelect
                    selectValue={uiState.CloseTimeHour}
                    menuitems={Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, "0"))} // 00시 ~ 23시
                    onChange={(event) => {
                      uiEvent.HandleUiStateChange("CloseTimeHour", event.target.value);
                    }}
                    sx={{
                      height: "48px",
                      width: "100%",
                      mt: "8px",
                      borderRadius: "10px",
                      fontStyle: fontStyle.regularS,
                      color: "black",
                    }}
                  />
                </Box>
                :
                <Box sx={{ width: "47.5%" }}>
                  <CommonSelect
                    selectValue={uiState.CloseTimeMin}
                    menuitems={Array.from({ length: 12 }, (_, i) => (i * 5).toString().padStart(2, "0"))} // 00분, 05분, ..., 55분
                    onChange={(event) => {
                      uiEvent.HandleUiStateChange("CloseTimeMin", event.target.value);
                    }}
                    sx={{
                      height: "48px",
                      width: "100%",
                      mt: "8px",
                      borderRadius: "10px",
                      fontStyle: fontStyle.regularS,
                      color: "black",
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <VerticalSpacer height={24} />
            {/* Open Day */}
            <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
              <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                Open Day
              </Typography>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(4, 1fr)", // 4개의 칸으로 나누기
                  rowGap: 0,
                  columnGap: 4,
                  // mt: 2, // 위쪽 마진
                }}
              >
                {/* All Day 체크박스 */}
                <FormControlLabel
                  label={<Typography fontStyle={fontStyle.regularS}>All Day</Typography>}
                  control={
                    <Checkbox
                      checked={checkedDays.all}
                      onChange={handleAllChange}
                      indeterminate={
                        Object.values(checkedDays)
                          .slice(1)
                          .some((value) => value) && !checkedDays.all
                      } // 일부 선택된 경우 indeterminate 표시
                      icon={
                        <img
                          src={ICON_UNCHECK}
                          alt="unchecked"
                          // style={{
                          //   filter: disabled ? "grayscale(100%)" : "none", // 비활성화 시 회색
                          // }}
                        />
                      }
                      indeterminateIcon={
                        <img
                          src={ICON_UNCHECK}
                          alt="indeterminate"
                          // style={{
                          //   filter: disabled ? "grayscale(100%)" : "none",
                          // }}
                        />
                      }
                      checkedIcon={
                        <img
                          src={ICON_CHECK2}
                          alt="checked"
                          // style={{
                          //   filter: disabled ? "grayscale(100%)" : "none",
                          // }}
                        />
                      }
                    />
                  }
                />

                {/* 요일별 체크박스 */}
                {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
                  <FormControlLabel
                    key={day}
                    label={<Typography fontStyle={fontStyle.regularS}>{day.charAt(0).toUpperCase() + day.slice(1)}</Typography>} // 첫 글자 대문자로 표시
                    control={
                      <Checkbox
                        checked={checkedDays[day as keyof typeof checkedDays]}
                        onChange={handleDayChange(day)}
                        icon={
                          <img
                            src={ICON_UNCHECK}
                            alt="unchecked"
                            // style={{
                            //   filter: disabled ? "grayscale(100%)" : "none", // 비활성화 시 회색
                            // }}
                          />
                        }
                        indeterminateIcon={
                          <img
                            src={ICON_UNCHECK}
                            alt="indeterminate"
                            // style={{
                            //   filter: disabled ? "grayscale(100%)" : "none",
                            // }}
                          />
                        }
                        checkedIcon={
                          <img
                            src={ICON_CHECK2}
                            alt="checked"
                            // style={{
                            //   filter: disabled ? "grayscale(100%)" : "none",
                            // }}
                          />
                        }
                      />
                    }
                  />
                ))}
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default ServiceCard;
