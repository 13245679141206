import { Box, SxProps, Typography } from "@mui/material";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import { ICON_SCOOTER, ICON_WARNING, IMAGE_PONYA_HELLO_1 } from "../../../constants/appImagePath";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";
import VerticalSpacer from "../../../component/Spacer";

interface EmptyMyScooterProps {
  sx?: SxProps;
  title: string;
  subTitle: string;
}

export default function EmptyMyScooter({ title, subTitle, sx }: EmptyMyScooterProps) {
  return (
    <Box
      sx={{
        position: "relative",
        width: "calc(100vw - 40px)",
        height: "auto",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        ...sx,
      }}
    >
      <Box
        sx={{
          position: "relative",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          src={ICON_SCOOTER}
          alt="Scooter"
          sx={{
            position: "absolute",
            width: "250px", // 스쿠터 이미지 크기 조절
            height: "218.97px",
          }}
        />
        <Box
          component="img"
          src={IMAGE_PONYA_HELLO_1}
          alt="Penguin"
          sx={{
            marginTop: "30.26px",
            width: "292px",
            height: "202px",
            zIndex: 1, // 펭귄 이미지를 스쿠터 이미지 위에 배치
          }}
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          marginTop: "20px",
        }}
      >
        <Typography
          sx={{
            fontStyle: fontStyle.titleL,
            color: color.default,
          }}
        >
          {title ? title : ""}
        </Typography>
        <Typography
          sx={{
            marginTop: "4px",
            fontStyle: fontStyle.bodyMr,
            color: color.gray600,
          }}
        >
          {subTitle ? subTitle : ""}
        </Typography>
      </Box>
      <VerticalSpacer height={30} />

      <Box
        sx={{
          maxWidth: "100%",
          bgcolor: color.primary50,
          px: "20px",
          py: "16px",
          borderRadius: "10px",
        }}
      >
        <Typography sx={{ textAlign: "start" }} fontStyle={fontStyle.titleM} color={color.primary500}>
          {LocalizedText("ua_e100_forminors_title")}
        </Typography>
        <VerticalSpacer height={4} />
        <Typography sx={{ textAlign: "start" }} fontStyle={fontStyle.bodySr} color={color.gray700}>
          {LocalizedText("ua_e100_forminors_message")}
        </Typography>
      </Box>
    </Box>
  );
}
