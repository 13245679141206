import { useEffect, useState } from "react";
import { Box, IconButton, Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import ChargingStationOutlinedIcon from "@mui/icons-material/ChargingStationOutlined";
import ElectricMopedOutlinedIcon from "@mui/icons-material/ElectricMopedOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import EvStationOutlinedIcon from "@mui/icons-material/EvStationOutlined";
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';
import LocalPoliceOutlinedIcon from '@mui/icons-material/LocalPoliceOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import {
  BarChart,
  LineChart,
  BarChartProps,
  AxisConfig,
  axisClasses,
  chartsGridClasses,
  ChartsXAxisProps,
} from "@mui/x-charts";
import CommonList from "../../component/CommonList";
import VerticalSpacer from "../../component/Spacer";
import { Text } from "../../component/Text";
import { useAuthRepository } from "../../di/AuthRepositoryProvider";
import { ADMIN_CONFIG } from "../../constants/AdminConfig";
import { ApiService } from "../../restAPI/ApiService";

const getIconByType = (type: string) => {
  switch (type) {
    case ADMIN_CONFIG.PAGE_DASHBOARD.STATION:
      return <EvStationOutlinedIcon fontSize="large" sx={{ color: "gray" }} />;
    case ADMIN_CONFIG.PAGE_DASHBOARD.BATTERY:
      return <ChargingStationOutlinedIcon fontSize="large" sx={{ color: "gray" }} />;
    case ADMIN_CONFIG.PAGE_DASHBOARD.SCOOTER:
      return <ElectricMopedOutlinedIcon fontSize="large" sx={{ color: "gray" }} />;
    case ADMIN_CONFIG.PAGE_DASHBOARD.USER:
      return <PersonOutlinedIcon fontSize="large" sx={{ color: "gray" }} />;
    case ADMIN_CONFIG.PAGE_DASHBOARD.ADMIN:
      return <LocalPoliceOutlinedIcon fontSize="large" sx={{ color: "gray" }} />;
    case ADMIN_CONFIG.PAGE_DASHBOARD.CS:
      return <HandymanOutlinedIcon fontSize="large" sx={{ color: "gray" }} />;
    case ADMIN_CONFIG.PAGE_DASHBOARD.INQUIRY:
      return <QuestionAnswerOutlinedIcon fontSize="large" sx={{ color: "gray" }} />;
    case ADMIN_CONFIG.PAGE_DASHBOARD.BLOCKS:
      return <GridViewOutlinedIcon fontSize="large" sx={{ color: "gray" }} />;
    case ADMIN_CONFIG.PAGE_DASHBOARD.TRANSACTIONS:
      return <PaidOutlinedIcon fontSize="large" sx={{ color: "gray" }} />;
    case "Obstacle":
      return <ErrorOutlineOutlinedIcon fontSize="large" sx={{ color: "gray" }} />;
    default:
      return null;
  }
};

const AnalysisCard = ({
  title,
  count,
  icon,
  usedPower,
}: {
  title: string;
  count: number;
  icon: string;
  usedPower?: number;
}) => {
  return (
    <Card
      sx={{
        borderRadius: 3,
        display: "flex",
        width: "100%",
        minHeight: 100,
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          p: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            height: "100%",
          }}
        >
          <Box sx={{ width: "48.5%", }}>
            {getIconByType(icon)}
            <Typography
              sx={{
                color: "gray",
                fontSize: 15,
                fontWeight: "bold",
              }}
            >
              {title}
            </Typography>
          </Box>
          <Box sx={{ width: "48.5%" }}>
            <Typography
              sx={{
                fontSize: 40,
                fontWeight: "bold",
                textAlign: "right",
                height: "100%",
              }}
            >
              {count}
            </Typography>
            {/*
            <Typography sx={{ fontSize: 13, color: "gray" }}>
              {title == "Station" && (
                <>총 사용전력: {usedPower?.toLocaleString()} Kwh</>
              )}
              {title == "Battety" && (
                <>총 사용전력: {usedPower?.toLocaleString()} Kwh</>
              )}
              {title == "Vechicle" && (
                <>총 사용전력: {usedPower?.toLocaleString()} Kwh</>
              )}
            </Typography>
            */}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

interface CustomBandAxisConfig
  extends Omit<AxisConfig<"band", any, ChartsXAxisProps>, "id"> {
  position: "top" | "bottom" | undefined;
}

const xAxisConfig: CustomBandAxisConfig = {
  categoryGapRatio: 0.8,
  position: "bottom", // Adjust position as per your requirement
  data: ["bar A", "bar B", "bar C"],
  scaleType: "band",
};

const BarChartCard = () => {
  return (
    <Card
      sx={{
        borderRadius: 3,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <BarChart
        grid={{ horizontal: true }}
        sx={(theme) => ({
          [`.${axisClasses.root}`]: {
            [`.${axisClasses.tick}, .${axisClasses.line}`]: {
              strokeWidth: 0, // y축 눈금과 선 숨기기
            },
          },
          [`& .${chartsGridClasses.line}`]: {
            strokeDasharray: "5 5",
            strokeWidth: 1,
          },
        })}
        xAxis={[xAxisConfig]}
        series={[
          {
            data: [550, 110, 300],
          },
        ]}
        slotProps={{
          bar: {
            clipPath: `inset(0px round 15px 15px 0px 0px)`,
            style: {
              // width: 20,
              // transform: "translateX(18px)", // 막대를 가운데 정렬
            },
            strokeWidth: 10,
          },
        }}
        // width={500}
        height={400}
      />
    </Card>
  );
};

const LineChartCard = () => {
  const chartsParams = {
    margin: { bottom: 40, left: 40, right: 40 },
    height: 300,
  };
  return (
    <Card sx={{ borderRadius: 3, width: "100%", display: "flex" }}>
      <LineChart
        {...chartsParams}
        series={[
          {
            data: [15, 23, 18, 19, 13],
            color: "#4e79a7",
          },
          {
            data: [17, 22, 13, 25, 19],
            color: "red",
          },
        ]}
        height={400}
      />
    </Card>
  );
};

const OperateDashBoardPage = () => {
  const authRepository = useAuthRepository();
  const [cntBattery, setCntBattery] = useState<number>(0);
  const [cntScooter, setCntScooter] = useState<number>(0);
  const [cntStation, setCntStation] = useState<number>(0);
  const [cntUser, setCntUser] = useState<number>(0);
  const [cntAdmin, setCntAdmin] = useState<number>(0);
  const [cntCS, setCntCS] = useState<number>(0);
  const [cntInquiry, setCntInquiry] = useState<number>(0);
  const [cntBlocks, setCntBlocks] = useState<number>(0);
  const [cntTransactions, setCntTransactions] = useState<number>(0);

  const updateDashboard = () => {
    setCntBattery(0);
    setCntScooter(0);
    setCntStation(0);
    setCntUser(0);
    setCntAdmin(0);
    setCntCS(0);
    setCntInquiry(0);
    setCntBlocks(0);
    setCntTransactions(0);

    // line 1
    ApiService.WebPost("/pw/backend/api/station/searchCondition", {
      ctry: "",
      searchCondition: "",
      svcStt: "",
      size: 0,
      page: 0,
    }).then((res) => {
      if(res?.data?.body?.docCount){
        setCntStation(res?.data?.body?.docCount);
      }
    });

    ApiService.WebPost("/pw/backend/api/scooter/searchCondition", {
      productName: "",
      searchCondition: "",
      page: 0,
      size: 1,
    }).then((res) => {
      if(res?.data?.body?.docCount){
        setCntScooter(res?.data?.body?.docCount);
      }
    });

    // line 2
    ApiService.WebPost("/pw/users/userList", {
      searchCondition: "",
      sns: "",
      status: "",
      page: 0,
      size: 0,
      membership: "",
    }).then((res) => {
      if(res?.data?.body?.docCount){
        setCntUser(res?.data?.body?.docCount);
      }
    });

    ApiService.WebPost("/pw/users/admin/searchCondition", {
      searchCondition: "",
      status: "",
      type: "",
      page: 0,
      size: 0,
    }).then((res) => {
      if(res?.data?.body?.docCount){
        setCntAdmin(res?.data?.body?.docCount);
      }
    });

    ApiService.WebPost("/pw/aw/contents/cs/filter", {
      searchContents: "",
      station: 0,
      status: "",
      page: 1,
      size: 1,
    }).then((res) => {
      if(res?.data?.docCount){
        setCntCS(res?.data?.docCount);
      }
    });

    ApiService.WebPost("/pw/aw/contents/personal/filter", {
      searchContents: "",
      country: "",
      category: "",
      status: "",
      page: 1,
      size: 1,
    }).then((res) => {
      if(res?.data?.docCount){
        setCntInquiry(res?.data?.docCount);
      }
    });

    // line 3
    ApiService.BlockchainPost("/nft/totalBlock", {
      page: 1,
      size: 1,
    }).then((res) => {
      if(res?.data?.body?.total){
        setCntBlocks(res?.data.body.total);
      }
    });

    ApiService.BlockchainPost("/nft/totalTransactions", {
      page: 1,
      size: 1,
    }).then((res) => {
      if(res?.data?.body?.total){
        setCntTransactions(res?.data?.body?.total);
      }
    });
  }

  useEffect(() => {
    updateDashboard();
  },[]);

  const HeaderButton = () => {
    return (
      <IconButton
        sx={{
          ":hover": { backgroundColor: "#F2F2F2" },
          mt: 0.5,
          mr: 1,
        }}
        onClick={updateDashboard}
      >
        <CachedOutlinedIcon fontSize="medium" />
      </IconButton>
    );
  };

  return (
    <CommonList
      title={ADMIN_CONFIG.PAGE_DASHBOARD.TITLE}
      setModal={null}
      headerButton={HeaderButton()}
    >
      <Grid container rowSpacing={4.5} columnSpacing={2.75}>
        <Grid item lg={4}>
          <AnalysisCard
            title="Station"
            count={cntStation}
            icon="Station"
          />
        </Grid>
        <Grid item lg={4}>
          <AnalysisCard
            title="Battety"
            count={cntBattery}
            icon="Battery"
          />
        </Grid>
        <Grid item lg={4}>
          <AnalysisCard
            title="Scooter"
            count={cntScooter}
            icon="Scooter"
          />
        </Grid>

        <Grid item lg={3}>
          <AnalysisCard
            title="User"
            count={cntUser}
            icon="User"
          />
        </Grid>
        <Grid item lg={3}>
          <AnalysisCard
            title="Admin"
            count={cntAdmin}
            icon="Admin"
          />
        </Grid>
        <Grid item lg={3}>
          <AnalysisCard
            title="CS"
            count={cntCS}
            icon="CS"
          />
        </Grid>
        <Grid item lg={3}>
          <AnalysisCard
            title="Inquiry"
            count={cntInquiry}
            icon="Inquiry"
          />
        </Grid>

        <Grid item lg={6}>
          <AnalysisCard
            title="Blocks"
            count={cntBlocks}
            icon="Blocks"
          />
        </Grid>
        <Grid item lg={6}>
          <AnalysisCard
            title="Transactions"
            count={cntTransactions}
            icon="Transactions"
          />
        </Grid>

        {/*
        <Grid item lg={7}>
          <LineChartCard />
        </Grid>
        <Grid item lg={5}>
          <BarChartCard />
        </Grid>
        */}
      </Grid>
    </CommonList>
  );
};

export default OperateDashBoardPage;
