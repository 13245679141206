export class ScooterBasicInfoUiState {
  owner: string;
  renter: string;
  registrant: string;
  registrationDate: number;
  lastUpdater: string;
  lastUpdatedDate: number;
  plateNumber: string;
  productName: string;
  scooterSerialNumber: string;
  frameSerialNumber: string;
  imei: string;
  dateOfManufacture: number;
  scooterImage: string;

  constructor(
    owner: string = "",
    renter: string = "",
    registrant: string = "",
    registrationDate: number = 0,
    lastUpdater: string = "",
    lastUpdatedDate: number = 0,
    plateNumber: string = "",
    productName: string = "",
    scooterSerialNumber: string = "",
    frameSerialNumber: string = "",
    imei: string = "",
    dateOfManufacture: number = 0,
    scooterImage: string = "",
  ) {
    this.owner = owner;
    this.renter = renter;
    this.registrant = registrant;
    this.registrationDate = registrationDate;
    this.lastUpdater = lastUpdater;
    this.lastUpdatedDate = lastUpdatedDate;
    this.plateNumber = plateNumber;
    this.productName = productName;
    this.scooterSerialNumber = scooterSerialNumber;
    this.frameSerialNumber = frameSerialNumber;
    this.imei = imei;
    this.dateOfManufacture = dateOfManufacture;
    this.scooterImage = scooterImage;
  }
}
