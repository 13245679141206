import DocumentPageRender from "./DocumentPageRender";
import useAndroidBackHandler from "../../../customHook/useAndroidBackHandler";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";
import { getLanguageCode, getLocale } from "../../../customHook/useLocale";
import { useEffect, useState } from "react";
import { ApiService } from "../../../restAPI/ApiService";
import ProgressBar from "../../../component/ProgressBar";

export default function TermsOfUsePage() {
  const [termsOfUseText, setTermsOfUseText] = useState("");
  const language = getLocale();

  const toolbarTitle = LocalizedText("ua_g731_termsofuse");
  const [progress, setProgress] = useState(true);

  useEffect(() => {
    if ("en-US" === language) {
      setTermsOfUseText("There are currently no terms of use prepared.");
    } else if ("km-KH" === language) {
      setTermsOfUseText(
        "បច្ចុប្បន្នមិនមានលក្ខខណ្ឌនៃការប្រើប្រាស់ដែលបានរៀបចំទេ។",
      );
    }
  }, []);

  useAndroidBackHandler(() => {
    window.history.back();
  });

  useEffect(() => {
    setProgress(true);
    fetchPrivacyPolicy().then((r) => {});
  }, []);

  const fetchPrivacyPolicy = async () => {
    getLanguageCode();
    const url = "/pw/system/termsAndConditions/findOne";
    const requestBody = {
      language: getLocale(),
      type: "이용약관",
    };
    try {
      const res = await ApiService.MobilePost(url, requestBody);
      console.log("PrivacyPolicyPage fetchPrivacyPolicy res:", res);
      if (res?.data?.body) {
        setTermsOfUseText(res.data.body as string);
      }
    } catch (e) {
      console.log("PrivacyPolicyPage error:", e);
    } finally {
      setProgress(false);
    }
  };

  if (progress) {
    return <ProgressBar />;
  } else {
    return (
      <DocumentPageRender
        toolbarTitle={toolbarTitle}
        documentText={termsOfUseText}
      />
    );
  }
}
