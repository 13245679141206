export class ProductionData {
  id: string;
  no: number;
  category: string;
  partsCode: string;
  productName: string;
  spec: string;
  made: string;
  quantities: string;
  amount: number;
  state: ProductionStatusType;
  registrationDate: number;
  lastUpdateDate: number;

  constructor(
    id: string,
    no: number,
    category: string,
    partsCode: string,
    productName: string,
    spec: string,
    made: string,
    quantities: string,
    amount: number,
    state: ProductionStatusType,
    registrationDate: number,
    lastUpdateDate: number
  ) {
    this.id = id;
    this.no = no;
    this.category = category;
    this.partsCode = partsCode;
    this.productName = productName;
    this.spec = spec;
    this.made = made;
    this.quantities = quantities;
    this.amount = amount;
    this.state = state;
    this.registrationDate = registrationDate;
    this.lastUpdateDate = lastUpdateDate;
  }
}

export enum ProductionStatusType {
  CONFIRM = "Confirm",
  REJECT = "Reject",
  UPDATE = "Update",
}

export const ProductionList: ProductionData[] = [
  new ProductionData(
    "asdasdas1123sss",
    1,
    "ASSY",
    "VBP-220RGP",
    "BATTERY PACK",
    "ASSY BATTERY SET (3600000",
    "Gwang-Ju",
    "SET",
    20,
    ProductionStatusType.CONFIRM,
    1716499200,
    1716499200
  ),
  new ProductionData(
    "aXedffta42Xcd3",
    2,
    "ASSY",
    "VBP-220RGP",
    "BATTERY PACK",
    "ASSY BATTERY SET (3600000",
    "Gwang-Ju",
    "SET",
    20,
    ProductionStatusType.REJECT,
    1716499200,
    1716499200
  ),
  new ProductionData(
    "aXedffta42Xcd4",
    3,
    "ASSY",
    "VBP-220RGP",
    "BATTERY PACK",
    "ASSY BATTERY SET (3600000",
    "Manufacture",
    "SET",
    20,
    ProductionStatusType.UPDATE,
    1716499200,
    1716499200
  ),
];
