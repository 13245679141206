import { Box, Button, IconButton } from "@mui/material";
import CommonList from "../../../component/CommonList";
import VerticalSpacer from "../../../component/Spacer";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import { StationMngDetailUiEvent } from "./model/StationMngDetailUiEvent";
import { TabType } from "./model/TabType";
import { StationInfoDetailTab } from "./component/StationInfoDetailTab";
import { StationMngDetailUiState } from "./model/StationMngDetailUiState";
import { StationMngBasicPage } from "./basic/StationMngBasicPage";

interface StationMngDetailPageProps {
  currentTab: TabType;
  uiState: StationMngDetailUiState;
  uiEvent: StationMngDetailUiEvent;
}

export function StationMngDetailPage({
  currentTab,
  uiState,
  uiEvent,
}: StationMngDetailPageProps) {
  return (
    <Box>
      <CommonList
        title={"Station Details"}
        headerBackButton={HeaderBackButton(() => {
          uiEvent.back();
        })}
        headerButton={HeaderButton(() => {
          uiEvent.onClickUpdate();
        })}
        children={
          <Box>
            <StationInfoDetailTab
              currentTab={currentTab}
              onClick={(tab) => {
                uiEvent.onClickTab(tab);
              }} // 탭 변경 핸들러
            />
            <VerticalSpacer height={16} />
            <div>
              {currentTab === TabType.BASICS && (
                <StationMngBasicPage uiState={uiState} uiEvent={uiEvent} />
              )}
            </div>
          </Box>
        }
      />
    </Box>
  );
}

const HeaderBackButton = (onClick: () => void) => {
  return (
    <IconButton
      sx={{
        ":hover": { backgroundColor: "#F2F2F2" },
        mb: 0.5,
        mr: 1,
      }}
      onClick={onClick}
    >
      <ArrowBackIosNewIcon fontSize="medium" />
    </IconButton>
  );
};

const HeaderButton = (onClick: () => void) => {
  return (
    <Button
      sx={{
        fontStyle: fontStyle.semiboldS,
        height: "45px",
        backgroundColor: color.primary500,
        color: color.white,
        borderRadius: "40px",
        textTransform: "none",
        padding: "12.5px 20px",
        ":hover": {
          backgroundColor: color.primary600,
        },
      }}
      onClick={onClick}
    >
      Update
    </Button>
  );
};
