import { useLocation, useNavigate } from "react-router-dom";
import CommonList from "../../../../component/CommonList";
import { CommonWebHeaderButton } from "../../../../component/CommonWebHeaderButton";
import { Box, Card, Grid, Typography } from "@mui/material";
import { color } from "../../../../theme/Color";
import VerticalSpacer, { HorizontalSpacer } from "../../../../component/Spacer";
import { useEffect, useState } from "react";
import { ApiService } from "../../../../restAPI/ApiService";
import { ReceiveDeliverUiState } from "../model/ReceiveDeliverUiState";
import { ReceiveDeliverUiEvent } from "../model/ReceiveDeliverUiEvent";
import { InventoryDetailFindParts } from "./InventoryDetailPageFindParts";
import { InventoryDetailInspector } from "./MaterialRegistrationInspector";
import { fontStyle } from "../../../../theme/Style";
import CommonTextField from "../../../../component/CommonTextField";
import LocalizedDate, { DateFormatType } from "../../../../component/LocalizedDate";
import { ReceiveDeliverDataType } from "../model/RecieveDeliverData";

const InventoryDetailPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const productId = pathname.split("/").pop(); // URL에서 마지막 부분을 추출

  const [uiState, setUiState] = useState(new ReceiveDeliverUiState());
  const uiEvent: ReceiveDeliverUiEvent = {
    HandleUiStateChange: (key: keyof ReceiveDeliverUiState, value: any) => {
      setUiState((prevState) => ({
        ...prevState,
        [key]: value, // key에 해당하는 상태를 업데이트
      }));
    },
  };
  useEffect(() => {
    ApiService.WebPost("/pw/aw/material/searchBranch").then((res) => {
      if (res?.data.body) {
        uiEvent.HandleUiStateChange("BranchList", res?.data.body);
      }
    });
  }, []);
  useEffect(() => {
    ApiService.WebPost("/pw/aw/material/io/detail", {
      id: productId,
    }).then((res) => {
      console.log(res?.data);
      if (res?.data) {
        const detailsData = res?.data.body;
        uiEvent.HandleUiStateChange("Type", detailsData.isIncoming ? ReceiveDeliverDataType.RECIEVE : ReceiveDeliverDataType.DELIVER);
        uiEvent.HandleUiStateChange("BranchName", detailsData.branchName);
        uiEvent.HandleUiStateChange("BranchId", detailsData.branchId);
        uiEvent.HandleUiStateChange("FinalRegisterDate", detailsData.ioDate);

        uiEvent.HandleUiStateChange("Inspector", detailsData.inspectorUuid);
        uiEvent.HandleUiStateChange("InspectorName", detailsData.inspector);
        uiEvent.HandleUiStateChange("Commnet", detailsData.comment);
        uiEvent.HandleUiStateChange("MaterialName", detailsData.productName);
        uiEvent.HandleUiStateChange("Count", detailsData.amount);
        uiEvent.HandleUiStateChange("LastUpdateDate", detailsData.lastUpdateDate);
        uiEvent.HandleUiStateChange("RegistrationDate", detailsData.registrationDate);
        if (detailsData.inspectorUuid == localStorage.getItem("user_uuid")) {
          uiEvent.HandleUiStateChange("IsWriter", true);
        } else {
          uiEvent.HandleUiStateChange("IsWriter", false);
        }
      }
    });
  }, []);

  const handleUpdateClick = () => {
    // 에러 상태를 업데이트
    const updatedBranchError = uiState.BranchName === "";
    const updatedCommnetError = uiState.Commnet === "";
    const updatedMaterialNameError = uiState.MaterialName === "";
    const updatedCountError = uiState.Count === 0;

    // 상태 변경
    uiEvent.HandleUiStateChange("BranchError", updatedBranchError);
    uiEvent.HandleUiStateChange("CommnetError", updatedCommnetError);
    uiEvent.HandleUiStateChange("MaterialNameError", updatedMaterialNameError);
    uiEvent.HandleUiStateChange("CountError", updatedCountError);

    // 에러 상태가 업데이트된 후 검증
    if (!updatedBranchError && !updatedCommnetError && !updatedMaterialNameError && !updatedCountError) {
      const MaterialUpdateData = {
        branchId: uiState.BranchId,
        registerMaterialName: uiState.MaterialName,
        registerMaterialAmount: uiState.Count,
        branchName: uiState.BranchName,
        comment: uiState.Commnet,
        id: productId,
        inspector: uiState.Inspector,
        ioDate: uiState.FinalRegisterDate,
        isIncoming: uiState.Type == ReceiveDeliverDataType.RECIEVE ? true : false,
      };
      console.log(MaterialUpdateData);
      ApiService.WebPost("/pw/aw/material/io/update", MaterialUpdateData).then((res) => {
        console.log(res?.data);
        if (res?.data.message == "update success") {
          window.location.reload();
        }
      });
    }
  };

  return (
    <CommonList
      title="Stock In and Out Details"
      headerBackButton={true}
      navigateURL={`/inventoryManagement?tab=receivedeliver`}
      headerButton={
        <CommonWebHeaderButton
          buttonContent="Update"
          height="45px"
          sx={{ display: "flex", flexDirection: "column" }}
          onClick={handleUpdateClick} //TODO : 버튼 눌렀을 때 동작 추가
        />
      }
    >
      <Grid item lg={12}>
        <Card
          sx={{
            borderRadius: "15px",
            border: "1px solid",
            borderColor: color.gray300,
            padding: "24px",
            paddingBottom: "40px",
            boxShadow: 0,
          }}
        >
          <InventoryDetailInspector uiState={uiState} uiEvent={uiEvent} />
        </Card>
        <VerticalSpacer height={20} />
        <Card
          sx={{
            borderRadius: "15px",
            border: "1px solid",
            borderColor: color.gray300,
            padding: "24px",
            paddingBottom: "40px",
            boxShadow: 0,
          }}
        >
          <InventoryDetailFindParts uiState={uiState} uiEvent={uiEvent} />
        </Card>
        <VerticalSpacer height={20} />
        <Card
          sx={{
            borderRadius: "15px",
            border: "1px solid",
            borderColor: color.gray300,
            padding: "24px",
            paddingBottom: "40px",
            boxShadow: 0,
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "50%",
              }}
            >
              <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                Regist Date
              </Typography>
              <VerticalSpacer height={8} />
              <CommonTextField
                sx={{ minHeight: "48px", flexGrow: 1 }}
                value={LocalizedDate({ date: uiState.RegistrationDate, type: DateFormatType.AdminType })}
                onChange={(event) => {}}
                disabled
              />
            </Box>
            <HorizontalSpacer width={28} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "50%",
              }}
            >
              <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                Last Update Date
              </Typography>
              <VerticalSpacer height={8} />
              <CommonTextField
                sx={{ minHeight: "48px", flexGrow: 1 }}
                value={LocalizedDate({ date: uiState.LastUpdateDate, type: DateFormatType.AdminType })}
                onChange={(event) => {}}
                disabled
              />
            </Box>
          </Box>
        </Card>
      </Grid>
    </CommonList>
  );
};

export default InventoryDetailPage;
