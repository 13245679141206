import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import ProgressBar from "../../../component/ProgressBar";
import { SERVER_CONFIG } from "../../../constants/ServerConfig";
import { ROUTE_PATHS } from "../../../constants/RouteConstants";
import { getLanguageCode } from "../../../customHook/useLocale";
import PhoneAuthPageRender from "./PhoneAuthPageRender";
import { PhoneAuthPageUiState } from "./model/PhoneAuthPageUiState";
import { PhoneAuthPageUiEvent } from "./model/PhoneAuthPageUiEvent";
import { PhoneAuthPageSideEffect } from "./model/PhoneAuthPageSideEffect";
import { ApiService } from "../../../restAPI/ApiService";

export default function PhoneAuthPage() {
  const [uiState, setUiState] = useState(new PhoneAuthPageUiState());
  const navigate = useNavigate();
  const [progress, setProgress] = useState(false);
  const location = useLocation();
  const previousScreenState = location.state as
    | {
        path?: string;
        localNumber?: string;
        phoneNumber?: string;
      }
    | undefined;

  useEffect(() => {
    setUiState((prev) => {
      return {
        ...prev,
        localNumber: previousScreenState?.localNumber || "",
        inputPhoneNumber: previousScreenState?.phoneNumber || "",
      };
    });
  }, []);

  useEffect(() => {
    if (uiState.timer === 0) {
      setUiState((prev) => {
        return {
          ...prev,
          isTimerInProgress: false,
        };
      });
    }
  }, [uiState.timer]);

  const uiEvent: PhoneAuthPageUiEvent = {
    changeExitPageDialog: function (state: boolean): void {
      setUiState((prev) => {
        return {
          ...prev,
          isShownExitPageDialog: state,
        };
      });
    },
    setTimer: function (timer: number): void {
      setUiState((prev) => {
        return {
          ...prev,
          timer: timer,
        };
      });
    },
    changeAuthText: function (text: string): void {
      const onlyNumbers = text.replace(/[^0-9]/g, "");
      setUiState((prev) => {
        return {
          ...prev,
          inputAuthNumber: onlyNumbers,
        };
      });
    },
    changeLocalNumber: function (text: string): void {
      setUiState((prev) => {
        return {
          ...prev,
          localNumber: text,
        };
      });
    },
    changePhoneNumber: function (text: string): void {
      const onlyNumbers = text.replace(/[^0-9]/g, "");
      setUiState((prev) => {
        return {
          ...prev,
          inputPhoneNumber: onlyNumbers,
        };
      });
    },
    changeLocalNumberError: function (state: boolean): void {
      setUiState((prev) => {
        return {
          ...prev,
          localNumberError: state,
        };
      });
    },
    changePhoneNumberError: function (state: boolean): void {
      setUiState((prev) => {
        return {
          ...prev,
          phoneNumberError: state,
        };
      });
    },
    changeAuthCodeNumberError: function (state: boolean): void {
      setUiState((prev) => {
        return {
          ...prev,
          authCodeError: state,
        };
      });
    },
    changeTimerState: function (state: boolean): void {
      setUiState((prev) => {
        return {
          ...prev,
          isTimerInProgress: state,
          timer: 3 * 60 * 1000,
        };
      });
    },
    onClickSendButton: function (): void {
      if (uiState.inputPhoneNumber) {
      }
      sideEffect.requestAuthCode(uiState.localNumber, uiState.inputPhoneNumber);
    },
    showAndHideToast: function (state: boolean): void {
      setUiState((prev) => {
        return {
          ...prev,
          isShownAuthCodeSuccessRequestToast: state,
        };
      });
    },
    changeAuthCodeRequestFailDialogState: function (state: boolean): void {
      setUiState((prev) => {
        return {
          ...prev,
          isShownAuthFailDialog: state,
        };
      });
    },
    changeAuthCodeValidateFailDialogState: function (state: boolean): void {
      setUiState((prev) => {
        return {
          ...prev,
          isShownAuthCodeValidateFailDialog: state,
        };
      });
    },
  };
  const sideEffect: PhoneAuthPageSideEffect = {
    navigate: function (path: string): void {
      navigate(path);
    },
    setTimer: function (timer: number): void {
      setUiState((prev) => {
        return {
          ...prev,
          timer: timer,
        };
      });
    },
    requestAuthCode: function (
      countryNumber: string,
      phoneNumber: string,
    ): void {
      const convertCountryNumber = countryNumber.replace("+", "");

      // 한국 번호인 경우, 그냥 넘어가게 처리
      if (SERVER_CONFIG.ON_FOR_PASS_KOREAN_NUMBER
            && convertCountryNumber === "82"
            && phoneNumber.startsWith("010")) {
        localStorage.setItem("user_phone_number", phoneNumber);
        localStorage.setItem("user_country_number", convertCountryNumber);
        localStorage.setItem("temp_user_phone_number", phoneNumber);
        localStorage.setItem("temp_user_country_number", convertCountryNumber);
        if (previousScreenState?.path === ROUTE_PATHS.EDIT_PROFILE) {
          window.history.back();
        } else {
          sideEffect.navigate(ROUTE_PATHS.LOGIN_DETAIL_INFO);
        }
      } else {
        if (convertCountryNumber === "82"
            && phoneNumber.startsWith("10")) {
          phoneNumber = "0" + phoneNumber;
          uiEvent.changePhoneNumber(phoneNumber);
        }
        getAuthCode(getLanguageCode(), countryNumber, phoneNumber);
      }
    },
    validateAuthCode: function (
      countryNumber: string,
      phoneNumber: string,
      authCode: string,
    ): void {
      validateAuthCode(countryNumber, phoneNumber, authCode);
    },
  };

  // http://dev.verywords.com/documents/207
  async function getAuthCode(
    language: string,
    countryNumber: string,
    phoneNumber: string,
  ) {
    const url = "/pw/sms/sendCode";
    const requestBody = {
      language: language,
      countryNumber: countryNumber.replace("+", ""),
      phoneNumber: phoneNumber,
    };
    try {
      setProgress(true);
      await ApiService.SmsAuthPost(url, requestBody);
      uiEvent.showAndHideToast(true);
      uiEvent.changeTimerState(true);
    } catch (error) {
      // 마스터 번호
      if (
        SERVER_CONFIG.ON_FOR_TEST &&
        uiState.inputPhoneNumber === SERVER_CONFIG.MASTER_NUMBER
      ) {
//         uiEvent.showAndHideToast(true);
//         uiEvent.changeTimerState(true);
        sideEffect.navigate(ROUTE_PATHS.LOGIN_DETAIL_INFO);
      } else {
        uiEvent.changeAuthCodeRequestFailDialogState(true);
      }
    } finally {
      setProgress(false);
    }
  }

  async function validateAuthCode(
    countryNumber: string,
    phoneNumber: string,
    authCode: string,
  ) {
    const url = "/pw/sms/checkCode";
    const convertCountryNumber = countryNumber.replace("+", "");
    const requestBody = {
      countryNumber: convertCountryNumber,
      phoneNumber: phoneNumber,
      authCode: authCode,
    };
    try {
      setProgress(true);
      await ApiService.SmsAuthPost(url, requestBody);
      localStorage.setItem("user_phone_number", phoneNumber);
      localStorage.setItem("user_country_number", convertCountryNumber);
      localStorage.setItem("temp_user_phone_number", phoneNumber);
      localStorage.setItem("temp_user_country_number", convertCountryNumber);

      if (previousScreenState?.path === ROUTE_PATHS.EDIT_PROFILE) {
        window.history.back();
        // navigate(ROUTE_PATHS.PROFILE, { replace: true });
      } else {
        sideEffect.navigate(ROUTE_PATHS.LOGIN_DETAIL_INFO);
      }
    } catch (error) {
      // 마스터키
      if (
        SERVER_CONFIG.ON_FOR_TEST &&
        uiState.inputAuthNumber === SERVER_CONFIG.MASTER_CODE
      ) {
        localStorage.setItem("user_phone_number", phoneNumber);
        localStorage.setItem("user_country_number", convertCountryNumber);
        localStorage.setItem("temp_user_phone_number", phoneNumber);
        localStorage.setItem("temp_user_country_number", convertCountryNumber);
        if (previousScreenState?.path === ROUTE_PATHS.EDIT_PROFILE) {
          window.history.back();
        } else {
          sideEffect.navigate(ROUTE_PATHS.LOGIN_DETAIL_INFO);
        }
      } else {
        uiEvent.changeAuthCodeValidateFailDialogState(true);
      }
    } finally {
      setProgress(false);
    }
  }

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      {progress ? <ProgressBar /> : null}
      <PhoneAuthPageRender
        uiState={uiState}
        uiEvent={uiEvent}
        sideEffect={sideEffect}
      />
    </Box>
  );
}
