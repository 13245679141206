import { Box, Typography } from "@mui/material";
import Toolbar, { ToolbarActionButton } from "../../../component/Toolbar";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import { InquiryDetailHeader } from "./section/InquiryDetailHeader";
import VerticalSpacer from "../../../component/Spacer";
import { HorizontalGrayDivider } from "../../../component/Divider";
import { InquiryDetailPageUiState } from "./model/InquiryDetailPageUiState";
import React from "react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import AdminAnswerCard from "./section/AdminAnswerCard";
import { InquiryDetailPageSideEffect } from "./model/InquiryDetailPageSideEffect";
import { ICON_TRASH_GRAY } from "../../../constants/appImagePath";
import { InquiryDetailPageUiEvent } from "./model/InquiryDetailPageUiEvent";
import IconTextDialog, {
  IconTextDialogType,
} from "../../../component/dialog/IconTextDialog";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";
import { InquiryHistoryStatus } from "../model/InquiryHistoryStatus";
import PhotoDetails from "../../MobilePartners/component/PhotoDetails";

interface InquiryDetailPageProps {
  uiState: InquiryDetailPageUiState;
  sideEffect: InquiryDetailPageSideEffect;
  uiEvent: InquiryDetailPageUiEvent;
}

export default function InquiryDetailPageRender({
  uiState,
  sideEffect,
  uiEvent,
}: InquiryDetailPageProps) {
  type CustomCSSProperties = React.CSSProperties & {
    "--swiper-pagination-fraction-color"?: string;
    "--swiper-pagination-top"?: string;
    "--swiper-pagination-bullet-horizontal-gap"?: string;
    height?: string;
  };

  // TODO indicator bullet color와 위치 변경
  const swiperStyles: CustomCSSProperties = {
    "--swiper-pagination-fraction-color": "#fff",
    "--swiper-pagination-top": "90%",
    width: "100%",
    height: "100%", // Swiper가 Box의 크기를 채우도록 설정
  };

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Toolbar
        onBack={() => {
          sideEffect.back();
        }}
        title={"1:1 Inquiry"}
        actionButtonList={[
          new ToolbarActionButton(ICON_TRASH_GRAY, () => {
            uiEvent.showDeleteDialog();
          }),
        ]}
      />
      <Box
        sx={{
          px: "20px",
          height: "100%",
          overflowY: "scroll",
        }}
      >
        <VerticalSpacer height={24} />
        <InquiryDetailHeader
          status={uiState.inquiry?.status ?? InquiryHistoryStatus.WAITING}
          category={uiState.inquiry?.category ?? ""}
          createdDate={uiState.inquiry?.registrationDate ?? 0}
        />
        <VerticalSpacer height={8} />
        <Typography
          sx={{
            fontStyle: fontStyle.headingS,
            color: color.default,
          }}
        >
          {uiState.inquiry?.title ?? ""}
        </Typography>
        <VerticalSpacer height={16} />
        <HorizontalGrayDivider />
        <VerticalSpacer height={16} />
        <Typography
          sx={{
            fontStyle: fontStyle.bodyMr,
            color: color.default,
            whiteSpace: "pre-line",
          }}
        >
          {uiState.inquiry?.content}
        </Typography>
        <VerticalSpacer height={12} />

        {uiState.inquiry?.imageUrls && uiState.inquiry.imageUrls.length > 0 && (
          <Box
            sx={{
              borderRadius: "10px",
              width: "100%",
              aspectRatio: "1/1",
              alignItems: "center",
              justifyContent: "center",
              overflow: "hidden",
            }}
          >
            <Swiper
              pagination={{
                type: "bullets",
                clickable: true,
              }}
              style={swiperStyles}
              modules={[Pagination]}
            >
              {uiState.inquiry.imageUrls.map((imageUrl, index) => {
                return (
                  <SwiperSlide key={index} onClick={uiEvent.showDetailPhoto}>
                    <img
                      src={imageUrl}
                      alt="Example Icon"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover", // 이미지가 박스 크기에 맞게 조정
                        objectPosition: "center", // 이미지가 박스 내 중앙에 위치
                      }}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Box>
        )}

        {uiState.inquiryAnswer && uiState.inquiryAnswer.length > 0 && (
          <Box>
            {uiState.inquiryAnswer.map((answer, index) => {
              return (
                <Box>
                  <VerticalSpacer height={16} />
                  <HorizontalGrayDivider />
                  <VerticalSpacer height={16} />
                  <AdminAnswerCard
                    key={index}
                    answerer={answer.answerer}
                    answerDate={answer.answerDate}
                    answerContent={answer.answerContent}
                    answerImageUrls={answer.answerImageUrls}
                  />
                </Box>
              );
            })}
          </Box>
        )}
        <VerticalSpacer height={16} />
      </Box>

      <IconTextDialog
        title={LocalizedText("ua_f212_alert_delete_title")}
        content={LocalizedText("ua_f212_alert_delete_message")}
        type={IconTextDialogType.SAD}
        isOpen={uiState.isVisibleDeleteDialog}
        onPositive={() => {
          sideEffect.delete();
        }}
        onNegative={() => {
          uiEvent.hideDeleteDialog();
        }}
        onClose={() => {
          uiEvent.hideDeleteDialog();
        }}
        positiveText={LocalizedText("common_alert_confirm_button")}
        negativeText={LocalizedText("common_alert_cancel_button")}
      />

      {uiState.detailPhotoOpen && (
        <Box sx={{ position: "absolute" }}>
          <PhotoDetails
            data={uiState.inquiry?.imageUrls}
            setDetailsOpen={uiEvent.hideDetailPhoto}
          />
        </Box>
      )}
    </Box>
  );
}
