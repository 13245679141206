import { Box, Radio, SxProps, Theme, Typography } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { color } from "../theme/Color";
import { fontStyle } from "../theme/Style";

interface CommonRadioProps<T> {
  selectValue?: T;
  value?: T;
  label?: string;
  sx?: SxProps<Theme>;
  disabled?: boolean;
  onchange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

export function CommonRadio<T>({ selectValue, value, onchange, disabled, sx }: CommonRadioProps<T>) {
  return (
    <Box sx={{ display: "flex" }}>
      <Radio
        sx={{
          padding: 0,
          width: "16px",
          height: "16px",

          "& .MuiSvgIcon-root": {
            fontSize: 16,
          },
          "&.Mui-checked": {
            color: color.primary500,
          },
          "&.Mui-checked + label": {
            color: color.gray600,
          },
          ...sx,
        }}
        checked={selectValue === value}
        onChange={onchange}
        value={value}
        disabled={disabled}
      />
    </Box>
  );
}
