export class ReceiveDeliverUiState {
  BranchList: Array<any> = [];
  IsWriter: boolean = false;

  Type: string = "Receive";
  RegisterDate: number | null | undefined = null;
  RegisterTime: number | null | undefined = null;
  FinalRegisterDate: number | null | undefined = null;

  BranchName: string = "";
  BranchId: string = "";

  Inspector: string = "";
  InspectorName: string = "";
  Commnet: string = "";
  MaterialName: string = "";
  Count: number = 0;
  LastUpdateDate: number = 0;
  RegistrationDate: number = 0;

  TypeError: boolean = false;
  BranchError: boolean = false;
  InspectorError: boolean = false;
  CommnetError: boolean = false;
  MaterialNameError: boolean = false;
  CountError: boolean = false;
}
