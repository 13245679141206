import { Inquiry } from "./Inquiry";
import { InquiryAnswer } from "./InquiryAnswer";

export class InquiryDetailPageUiState {
  inquiry?: Inquiry;
  inquiryAnswer?: InquiryAnswer[];
  isVisibleDeleteDialog: boolean;
  detailPhotoOpen: boolean;

  constructor(
    inquiry?: Inquiry,
    inquiryAnswer?: InquiryAnswer[],
    isVisibleDeleteDialog: boolean = false,
    detailPhotoOpen: boolean = false
  ) {
    this.inquiry = inquiry;
    this.inquiryAnswer = inquiryAnswer;
    this.isVisibleDeleteDialog = isVisibleDeleteDialog;
    this.detailPhotoOpen = detailPhotoOpen;
  }
}
