import {
  Box,
  Divider,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useEffect, useState } from "react";
import CommonList from "../../component/CommonList";
import CommonSearch from "../../component/CommonSearch";
import UserAddModal from "./section/UserAddModal";
import VerticalSpacer, { HorizontalSpacer } from "../../component/Spacer";
import { fontStyle } from "../../theme/Style";
import { color } from "../../theme/Color";
import UserTable from "./component/UserTable";
import { ApiService } from "../../restAPI/ApiService";

const UsrMngListPage = () => {
  // 페이지네이션 기능을 위한 변수
  const [rowsPerPage, setRowsPerPage] = useState(10); // 한 테이블당 보여줄 row 의 갯수
  const [rows, setRows] = useState([]); // 받아온 데이터
  const [page, setPage] = useState<number>(0); // 현재 페이지의 번호
  const [docCount, setDocCount] = useState<number>(0); // 총 row의 수
  const [pageCount, setPageCount] = useState(0); // 테이블 왼쪽 하단에 보여질 페이지의 갯수

  // 검색어 관련 변수
  const [searchContents, setSearchContents] = useState("");

  const [sns, setSns] = useState("");
  const handleSnsChange = (event: SelectChangeEvent) => {
    setSns(event.target.value as string);
  };

  const [membership, setMembership] = useState("");
  const handleMembershipChange = (event: SelectChangeEvent) => {
    setMembership(event.target.value as string);
  };

  const [status, setStatus] = useState("");
  const handleStatusChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value as string);
  };

  const [tabVelue, setTabVelue] = useState("userMng");
  const TabButton = () => {
    return (
      <Box sx={{ display: "flex", height: "24px" }}>
        <HorizontalSpacer width={16} />
        <Box
          sx={{
            fontStyle: fontStyle.semiboldM,
            cursor: "pointer",
            color: tabVelue == "userMng" ? color.primary500 : color.gray600,
          }}
          onClick={() => {
            setTabVelue("userMng");
          }}
        >
          Management
        </Box>
        <HorizontalSpacer width={16} />
        {/* <Divider orientation="vertical" />
        <HorizontalSpacer width={16} />
        <Box
          sx={{
            fontStyle: fontStyle.semiboldM,
            cursor: "pointer",
            color: tabVelue == "userHis" ? color.primary500 : color.gray600,
          }}
          onClick={() => {
            setTabVelue("userHis");
          }}
        >
          이력
        </Box>
        <HorizontalSpacer width={16} /> */}
      </Box>
    );
  };

  useEffect(() => {
    console.log("rowsPerPage : " + rowsPerPage);
    console.log("page : " + page);
    console.log("searchContents : " + searchContents);
    console.log("sns : " + sns);
    console.log("status : " + status);
    console.log("membership : " + membership);
    ApiService.WebPost("/pw/users/userList", {
      //500에러 테스트
      size: rowsPerPage,
      page: page + 1,
      searchCondition: searchContents,
      sns: sns,
      status: status,
      membership: membership,
    }).then((res) => {
      if (res?.data.body) {
        setRows(res?.data.body.list);
        setPageCount(Math.ceil(res?.data.body.docCount / rowsPerPage));
        setDocCount(res?.data.body.docCount);
      }
    });
  }, [page, rowsPerPage, sns, status, membership, searchContents]);

  return (
    <CommonList title="Member" tabButton={TabButton()}>
      <CommonSearch
        // setRowsPerPage={setRowsPerPage}
        placeHolder={"Name, E-mail, Phone Number, Last login, Registration"}
        setSearchContents={setSearchContents}
      >
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Select
            value={sns}
            onChange={handleSnsChange}
            displayEmpty
            renderValue={(v) => (v?.length ? v : `SNS`)}
            sx={{
              width: "auto",
              height: "42px",
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
              "&.MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "red",
                },
                "&:hover fieldset": {
                  borderColor: "yellow",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "white",
                },
              },
              bgcolor: "white",
              borderRadius: "10px",
              fontStyle: fontStyle.semiboldXS,
              color: color.gray600,
            }}
          >
            <MenuItem
              value={""}
              sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}
            >
              All
            </MenuItem>
            <MenuItem
              value={"Show"}
              sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}
            >
              Apple
            </MenuItem>
            <MenuItem
              value={"Period"}
              sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}
            >
              Facebook
            </MenuItem>
            <MenuItem
              value={"Hide"}
              sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}
            >
              Google
            </MenuItem>
          </Select>
          <HorizontalSpacer width={12} />
          <Select
            value={membership}
            onChange={handleMembershipChange}
            displayEmpty
            renderValue={(v) => (v?.length ? v : `Membership`)}
            sx={{
              width: "auto",
              height: "42px",
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
              "&.MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "red",
                },
                "&:hover fieldset": {
                  borderColor: "yellow",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "white",
                },
              },
              bgcolor: "white",
              borderRadius: "10px",
              fontStyle: fontStyle.semiboldXS,
              color: color.gray600,
            }}
          >
            <MenuItem
              value={""}
              sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}
            >
              All
            </MenuItem>
            <MenuItem
              value={"Basic"}
              sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}
            >
              Basic
            </MenuItem>
            <MenuItem
              value={"Standard"}
              sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}
            >
              Standard
            </MenuItem>
            <MenuItem
              value={"Plus"}
              sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}
            >
              Plus
            </MenuItem>
            <MenuItem
              value={"Premium"}
              sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}
            >
              Premium
            </MenuItem>
            <MenuItem
              value={"Lite"}
              sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}
            >
              Lite
            </MenuItem>
            <MenuItem
              value={"Lite Plus"}
              sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}
            >
              Lite Plus
            </MenuItem>
          </Select>
          <HorizontalSpacer width={12} />
          <Select
            value={status}
            onChange={handleStatusChange}
            displayEmpty
            renderValue={(v) => (v?.length ? v : `Status`)}
            sx={{
              width: "auto",
              height: "42px",
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
              "&.MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "red",
                },
                "&:hover fieldset": {
                  borderColor: "yellow",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "white",
                },
              },
              bgcolor: "white",
              borderRadius: "10px",
              fontStyle: fontStyle.semiboldXS,
              color: color.gray600,
            }}
          >
            <MenuItem
              value={""}
              sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}
            >
              All
            </MenuItem>
            <MenuItem
              value={"Active"}
              sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}
            >
              Active
            </MenuItem>
            <MenuItem
              value={"Pending approval"}
              sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}
            >
              Pending approval
            </MenuItem>
            <MenuItem
              value={"Blocked"}
              sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}
            >
              Blocked
            </MenuItem>
            <MenuItem
              value={"Deleted"}
              sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}
            >
              Deleted
            </MenuItem>
          </Select>
        </Box>
      </CommonSearch>
      {tabVelue == "userMng" && <></>}
      <VerticalSpacer height={16} />
      <UserTable
        mngRows={rows}
        docCount={docCount}
        rowsPerPage={rowsPerPage}
        pageCount={pageCount}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
    </CommonList>
  );
};

export default UsrMngListPage;
