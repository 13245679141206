import LanguagePageRender from "./LanguagePageRender";
import useAndroidBackHandler from "../../../customHook/useAndroidBackHandler";
import { useEffect, useState } from "react";
import { LanguageButtonModel } from "./model/LanguageButtonModel";
import {
  getLanguageCode,
  setLanguageCode,
} from "../../../customHook/useLocale";
import { useLanguageRepository } from "../../../di/LanguageRepositoryProvider";
import { IMAGE_KHMER, IMAGE_US } from "../../../constants/appImagePath";

export default function LanguagePage() {
  const [selectableLanguage, setSelectableLanguage] = useState<
    LanguageButtonModel[]
  >([]);

  const languageRepository = useLanguageRepository();
  const [toolbarTitle, setToolbarTitle] = useState(
    languageRepository.getLocalizedText("ua_g720_language", getLanguageCode()),
  );
  const [pageTitle, setPageTitle] = useState(
    languageRepository.getLocalizedText(
      "ua_g720_chooselanguage",
      getLanguageCode(),
    ),
  );

  useAndroidBackHandler(() => {
    window.history.back();
  });

  useEffect(() => {
    const currentSelectedLanguage = getLanguageCode(); // 로컬 스토리지에서 현재 언어코드 가져옴
    const languageList = [
      new LanguageButtonModel(
        "English",
        IMAGE_US,
        "en",
        currentSelectedLanguage === "en",
      ),
      new LanguageButtonModel(
        "Khmer",
        IMAGE_KHMER,
        "km",
        currentSelectedLanguage === "km",
      ),
      /* it's for develop
            new LanguageButtonModel(
              "한국어",
              "",
              "ko",
              currentSelectedLanguage === "ko",
            ),
            //*/
    ];
    setSelectableLanguage(languageList); // 초기 언어 리스트 설정
  }, []);

  // 언어 버튼을 선택할 때 호출되는 함수
  const handleLanguageChange = (locale: string) => {
    // 로컬 스토리지에 새로운 언어 코드 저장
    const [languageCode, countryCode] = locale.split("-");
    setLanguageCode(languageCode);
    // 선택된 버튼 상태 업데이트
    const updatedLanguageList = selectableLanguage.map((it) => {
      return {
        ...it,
        isSelected: it.languageCode === locale,
      };
    });
    setSelectableLanguage(updatedLanguageList);

    setPageTitle(
      languageRepository.getLocalizedText("ua_g720_chooselanguage", locale),
    );
    setToolbarTitle(
      languageRepository.getLocalizedText("ua_g720_language", locale),
    );
  };

  return (
    <LanguagePageRender
      selectableLanguage={selectableLanguage}
      toolbarTitle={toolbarTitle}
      pageTitle={pageTitle}
      onLanguageChange={handleLanguageChange}
    />
  );
}
