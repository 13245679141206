import { Tab, Tabs } from "@mui/material";
import { TabType } from "../model/TabType";
import { Text } from "../../../../component/Text";
import { fontStyle } from "../../../../theme/Style";
import { color } from "../../../../theme/Color";

interface ScooterInfoDetailTabProps {
  currentTab: TabType;
  onClick: (tab: TabType) => void;
}

export function ScooterInfoDetailTab({
  currentTab,
  onClick,
}: ScooterInfoDetailTabProps) {
  return (
    <Tabs
      value={currentTab}
      onChange={(event, value) => {
        onClick(value);
      }}
      TabIndicatorProps={{
        style: {
          backgroundColor: color.primary500,
        },
      }}
      sx={{
        height: "29px",
      }}
    >
      <Tab
        sx={{
          minWidth: 0,
          padding: 0,
        }}
        disableRipple
        value={TabType.BASICS}
        label={
          <Text
            sx={{
              fontStyle: fontStyle.semiboldM,
              textTransform: "none",
              color:
                currentTab === TabType.BASICS
                  ? color.primary500
                  : color.gray500,
            }}
            text={TabType.BASICS}
          />
        }
      />
      {/* TODO 미완성 페이지로 숨김 처리 합니다. */}
      {/*<HorizontalSpacer width={24} />*/}
      {/*<Tab*/}
      {/*  sx={{*/}
      {/*    minWidth: 0,*/}
      {/*    padding: 0,*/}
      {/*  }}*/}
      {/*  disableRipple*/}
      {/*  value={TabType.DRIVING}*/}
      {/*  label={*/}
      {/*    <Text*/}
      {/*      sx={{*/}
      {/*        fontStyle: fontStyle.semiboldM,*/}
      {/*        textTransform: "none",*/}
      {/*        color:*/}
      {/*          currentTab === TabType.DRIVING*/}
      {/*            ? color.primary500*/}
      {/*            : color.gray500,*/}
      {/*      }}*/}
      {/*      text={TabType.DRIVING}*/}
      {/*    />*/}
      {/*  }*/}
      {/*/>*/}
      {/*<HorizontalSpacer width={24} />*/}
      {/*<Tab*/}
      {/*  sx={{*/}
      {/*    minWidth: 0,*/}
      {/*    padding: 0,*/}
      {/*  }}*/}
      {/*  value={TabType.SALES_AND_RENTAL}*/}
      {/*  disableRipple*/}
      {/*  label={*/}
      {/*    <Text*/}
      {/*      sx={{*/}
      {/*        fontStyle: fontStyle.semiboldM,*/}
      {/*        textTransform: "none",*/}
      {/*        color:*/}
      {/*          currentTab === TabType.SALES_AND_RENTAL*/}
      {/*            ? color.primary500*/}
      {/*            : color.gray500,*/}
      {/*      }}*/}
      {/*      text={TabType.SALES_AND_RENTAL}*/}
      {/*    />*/}
      {/*  }*/}
      {/*/>*/}
    </Tabs>
  );
}
