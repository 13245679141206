import { Box, Button, IconButton } from "@mui/material";
import CommonList from "../../../component/CommonList";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { ScooterInfoDetailUiState } from "./model/ScooterInfoDetailUiState";
import { ScooterInfoDetailTab } from "./component/ScooterInfoDetailTab";
import { TabType } from "./model/TabType";
import { ScooterInfoDetailUiEvent } from "./model/ScooterInfoDetailUiEvent";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import VerticalSpacer from "../../../component/Spacer";
import { ScooterBasicInfoUiState } from "./basic/model/ScooterBasicInfoUiState";
import { ScooterBasicInfoPage } from "./basic/ScooterBasicInfoPage";
import { ScooterBasicInfoUiEvent } from "./basic/model/ScooterBasicInfoUiEvent";

interface ScooterInfoDetailPageProps {
  uiState: ScooterInfoDetailUiState;
  scooterBasicInfoUiState: ScooterBasicInfoUiState;
  scooterBasicInfoUiEvent: ScooterBasicInfoUiEvent;
  uiEvent: ScooterInfoDetailUiEvent;
}

export function ScooterInfoDetailPage({
  uiState,
  scooterBasicInfoUiState,
  scooterBasicInfoUiEvent,
  uiEvent,
}: ScooterInfoDetailPageProps) {
  return (
    <Box>
      <CommonList
        title={"Scooter Details"}
        headerBackButton={HeaderBackButton(() => {
          uiEvent.back();
        })}
        headerButton={HeaderButton(() => {
          uiEvent.onClickUpdate();
        })}
        children={
          <Box>
            <ScooterInfoDetailTab
              currentTab={uiState.currentTab}
              onClick={(tab) => {
                uiEvent.onClickTab(tab);
              }} // 탭 변경 핸들러
            />
            <VerticalSpacer height={16} />
            <div>
              {uiState.currentTab === TabType.BASICS && (
                <ScooterBasicInfoPage
                  uiState={scooterBasicInfoUiState}
                  uiEvent={scooterBasicInfoUiEvent}
                />
              )}
              {uiState.currentTab === TabType.DRIVING && <div>Coming Soon</div>}
              {uiState.currentTab === TabType.SALES_AND_RENTAL && (
                <div>Coming Soon</div>
              )}
            </div>
          </Box>
        }
      />
    </Box>
  );
}

const HeaderBackButton = (onClick: () => void) => {
  return (
    <IconButton
      sx={{
        ":hover": { backgroundColor: "#F2F2F2" },
        mb: 0.5,
        mr: 1,
      }}
      onClick={onClick}
    >
      <ArrowBackIosNewIcon fontSize="medium" />
    </IconButton>
  );
};

const HeaderButton = (onClick: () => void) => {
  return (
    <Button
      sx={{
        fontStyle: fontStyle.semiboldS,
        height: "45px",
        backgroundColor: color.primary500,
        color: color.white,
        borderRadius: "40px",
        textTransform: "none",
        padding: "12.5px 20px",
        ":hover": {
          backgroundColor: color.primary600,
        },
      }}
      onClick={onClick}
    >
      Update
    </Button>
  );
};
