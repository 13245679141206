export class PhoneAuthPageUiState {
  timer: number;
  inputPhoneNumber: string;
  inputAuthNumber: string;
  isShownExitPageDialog: boolean;
  localNumber: string;
  localNumberError: boolean;
  phoneNumberError: boolean;
  authCodeError: boolean;
  isTimerInProgress: boolean;
  isShownAuthCodeSuccessRequestToast: boolean;
  isShownAuthFailDialog: boolean;
  isShownAuthCodeValidateFailDialog: boolean;

  constructor(
    timer: number = 0,
    inputPhoneNumber: string = "",
    inputAuthNumber: string = "",
    isShownExitPageDialog: boolean = false,
    localNumber: string = "",
    localNumberError: boolean = false,
    phoneNumberError: boolean = false,
    authCodeError: boolean = false,
    isTimerInProgress: boolean = false,
    isShownAuthSuccessDialog: boolean = false,
    isShownAuthFailDialog: boolean = false,
    isShownAuthCodeValidateFailDialog: boolean = false
  ) {
    this.timer = timer;
    this.inputPhoneNumber = inputPhoneNumber;
    this.inputAuthNumber = inputAuthNumber;
    this.isShownExitPageDialog = isShownExitPageDialog;
    this.localNumber = localNumber;
    this.localNumberError = localNumberError;
    this.phoneNumberError = phoneNumberError;
    this.authCodeError = authCodeError;
    this.isTimerInProgress = isTimerInProgress;
    this.isShownAuthCodeSuccessRequestToast = isShownAuthSuccessDialog;
    this.isShownAuthFailDialog = isShownAuthFailDialog;
    this.isShownAuthCodeValidateFailDialog = isShownAuthCodeValidateFailDialog;
  }
}
