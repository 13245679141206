import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { fontStyle } from "../../../../theme/Style";
import { color } from "../../../../theme/Color";
import CommonTextField from "../../../../component/CommonTextField";
import VerticalSpacer from "../../../../component/Spacer";
import { CommonSelect } from "../../../../component/CommonSelect";
import CommonIndeterminateCheckBox from "../../../../component/CommonIndeterminateCheckBox";
import LocalizedDate, {
  DateFormatType,
} from "../../../../component/LocalizedDate";
import dayjs, { Dayjs } from "dayjs";
import CommonDatePicker from "../../../../component/CommonDatePicker";

interface AdminDetailGridProps {
  adminDetailData: any;
  updateAdminDetailData: any;
}

const AdminDetailGrid = ({
  adminDetailData,
  updateAdminDetailData,
}: AdminDetailGridProps) => {
  console.log(adminDetailData);
  const statisticsChildLabels = ["statistics"];
  const [statisticsChecked, setStatisticsChecked] = useState<boolean[]>(
    Array(statisticsChildLabels.length).fill(false)
  );

  const userManagementChildLabels = ["member", "administrator", "group"];
  const [userManagementChecked, setUserManagementChecked] = useState<boolean[]>(
    Array(userManagementChildLabels.length).fill(false)
  );

  const contentChildLabels = [
    "faq",
    "inquiry",
    "cs",
    "notice",
    "pushNotification",
    "popupBanner",
  ];
  const [contentChecked, setContentChecked] = useState<boolean[]>(
    Array(contentChildLabels.length).fill(false)
  );

  const merchandiseChildLabels = [
    "membershipVoucher",
    "nft",
    "coupon",
    "payment",
  ];
  const [merchandiseChecked, setMerchandiseChecked] = useState<boolean[]>(
    Array(merchandiseChildLabels.length).fill(false)
  );

  const productionMaterialChildLabels = [
    "production",
    "inventory",
    "request",
    "productName",
  ];
  const [productionMaterialChecked, setProductionMaterialChecked] = useState<
    boolean[]
  >(Array(productionMaterialChildLabels.length).fill(false));

  const operationsChildLabels = [
    "regionService",
    "scooter",
    "station",
    "battery",
    "nfcCard",
  ];
  const [operationsChecked, setOperationsChecked] = useState<boolean[]>(
    Array(operationsChildLabels.length).fill(false)
  );

  const carbonReductionChildLabels = [
    "methodology",
    "project",
    "dataParameter",
    "monitoring",
  ];
  const [carbonReductionChecked, setCarbonReductionsChecked] = useState<
    boolean[]
  >(Array(carbonReductionChildLabels.length).fill(false));

  const blockchainChildLabels = ["monitoring", "wallet"];
  const [blockchainChecked, setBlockchainChecked] = useState<boolean[]>(
    Array(blockchainChildLabels.length).fill(false)
  );

  const systemSettingChildLabels = [
    "notification",
    "multiLanguage",
    "batch",
    "history",
  ];
  const [systemSettingChecked, setSystemSettingChecked] = useState<boolean[]>(
    Array(systemSettingChildLabels.length).fill(false)
  );

  useEffect(() => {
    handleCheck(
      "statistics",
      statisticsChildLabels,
      statisticsChecked,
      setStatisticsChecked
    );
    handleCheck(
      "userManagement",
      userManagementChildLabels,
      userManagementChecked,
      setUserManagementChecked
    );
    handleCheck(
      "content",
      contentChildLabels,
      contentChecked,
      setContentChecked
    );
    handleCheck(
      "merchandise",
      merchandiseChildLabels,
      merchandiseChecked,
      setMerchandiseChecked
    );
    handleCheck(
      "productionMaterial",
      productionMaterialChildLabels,
      productionMaterialChecked,
      setProductionMaterialChecked
    );
    handleCheck(
      "operations",
      operationsChildLabels,
      operationsChecked,
      setOperationsChecked
    );
    handleCheck(
      "carbonReduction",
      carbonReductionChildLabels,
      carbonReductionChecked,
      setCarbonReductionsChecked
    );
    handleCheck(
      "blockchain",
      blockchainChildLabels,
      blockchainChecked,
      setBlockchainChecked
    );
    handleCheck(
      "system",
      systemSettingChildLabels,
      systemSettingChecked,
      setSystemSettingChecked
    );
  }, [adminDetailData]);

  // 상태가 실제로 바뀔 때만 setChecked 호출
  const handleCheck = (
    field: string,
    labels: string[],
    currentChecked: boolean[],
    setChecked: React.Dispatch<React.SetStateAction<boolean[]>>
  ) => {
    if (adminDetailData?.[field]) {
      const newChecked = [...currentChecked];
      labels.forEach((label, index) => {
        if (adminDetailData[field].includes(label)) {
          if (!newChecked[index]) {
            newChecked[index] = true; // 상태가 바뀌어야만 업데이트
          }
        } else {
          if (newChecked[index]) {
            newChecked[index] = false; // 상태가 바뀌어야만 업데이트
          }
        }
      });
      // 상태가 바뀐 경우에만 setChecked 호출
      if (
        !newChecked.every((value, index) => value === currentChecked[index])
      ) {
        setChecked(newChecked);
      }
    }
  };

  useEffect(() => {
    const Statistics = statisticsChildLabels.filter(
      (label, index) => statisticsChecked[index]
    );
    const UserManagement = userManagementChildLabels.filter(
      (label, index) => userManagementChecked[index]
    );
    const Content = contentChildLabels.filter(
      (label, index) => contentChecked[index]
    );
    const Merchandise = merchandiseChildLabels.filter(
      (label, index) => merchandiseChecked[index]
    );
    const ProductionMaterial = productionMaterialChildLabels.filter(
      (label, index) => productionMaterialChecked[index]
    );
    const Operations = operationsChildLabels.filter(
      (label, index) => operationsChecked[index]
    );
    const CarbonReduction = carbonReductionChildLabels.filter(
      (label, index) => carbonReductionChecked[index]
    );
    const Blockchain = blockchainChildLabels.filter(
      (label, index) => blockchainChecked[index]
    );
    const SystemSetting = systemSettingChildLabels.filter(
      (label, index) => systemSettingChecked[index]
    );

    updateAdminDetailData("statistics", Statistics);
    updateAdminDetailData("userManagement", UserManagement);
    updateAdminDetailData("content", Content);
    updateAdminDetailData("merchandise", Merchandise);
    updateAdminDetailData("productionMaterial", ProductionMaterial);
    updateAdminDetailData("operations", Operations);
    updateAdminDetailData("carbonReduction", CarbonReduction);
    updateAdminDetailData("blockchain", Blockchain);
    updateAdminDetailData("system", SystemSetting);
  }, [
    statisticsChecked,
    userManagementChecked,
    contentChecked,
    merchandiseChecked,
    productionMaterialChecked,
    operationsChecked,
    carbonReductionChecked,
    blockchainChecked,
    systemSettingChecked,
  ]);

  useEffect(() => {
    console.log(adminDetailData);
  }, [adminDetailData]);

  return (
    <Grid container rowSpacing={4.5} columnSpacing={2.75}>
      <Grid item lg={6}>
        <Card
          sx={{
            borderRadius: 3,
            width: "100%",
            display: "flex",
          }}
        >
          <CardContent sx={{ width: "100%", m: 2 }}>
            <Box
              sx={{
                display: "flex",
                height: "100%",
                // minHeight: 330,
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Name
                </Typography>
                <CommonTextField
                  value={adminDetailData?.name}
                  onChange={(event) => {
                    updateAdminDetailData("name", event.target.value);
                  }}
                  sx={{ width: "100%", height: "48px", mt: "8px" }}
                  InputProps={{
                    sx: {
                      height: "48px",
                    },
                  }}
                  disabled={adminDetailData.status == "Deleted"}
                />
              </Box>
              <VerticalSpacer height={16} />
              <Box>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Email ID
                </Typography>
                <CommonTextField
                  value={adminDetailData?.email}
                  sx={{ width: "100%", height: "48px", mt: "8px" }}
                  onChange={(event) => {
                    updateAdminDetailData("email", event.target.value);
                  }}
                  InputProps={{
                    sx: {
                      height: "48px",
                    },
                  }}
                  disabled={adminDetailData.status == "Deleted"}
                />
              </Box>
              <VerticalSpacer height={16} />
              <Box>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Phone Number
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mt: 1,
                  }}
                >
                  <CommonSelect
                    key={adminDetailData?.countryNumber}
                    selectValue={adminDetailData?.countryNumber}
                    menuitems={["1", "82", "855"]}
                    onChange={(event) => {
                      updateAdminDetailData(
                        "countryNumber",
                        event.target.value
                      );
                    }}
                    sx={{
                      height: "48px",
                      width: "18.5%",
                      fontStyle: fontStyle.regularS,
                      color: "black",
                    }}
                    disabled={adminDetailData.status == "Deleted"}
                  />

                  <CommonTextField
                    // key={adminDetailData?.phoneNumber}
                    value={adminDetailData?.phoneNumber}
                    onChange={(event) => {
                      updateAdminDetailData("phoneNumber", event.target.value);
                    }}
                    sx={{ width: "78.5%", height: "48px" }}
                    InputProps={{
                      style: {
                        height: "48px",
                      },
                    }}
                    disabled={adminDetailData.status == "Deleted"}
                  />
                </Box>
              </Box>
              <VerticalSpacer height={16} />
              <Box>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Password
                </Typography>
                <CommonTextField
                  sx={{ width: "100%", height: "48px", mt: "8px" }}
                  InputProps={{
                    sx: {
                      height: "48px",
                    },
                  }}
                  disabled
                />
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={6}>
        <Card
          sx={{
            borderRadius: 3,
            minHeight: 240,
            width: "100%",
            display: "flex",
          }}
        >
          <CardContent sx={{ width: "100%", m: 2 }}>
            <Box
              sx={{
                display: "flex",
                height: "100%",
                flexDirection: "column",
              }}
            >
              <Box>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Status
                </Typography>
                <CommonSelect
                  key={adminDetailData.status}
                  selectValue={adminDetailData.status}
                  menuitems={["Active", "Blocked", "Deleted"]}
                  onChange={(event) => {
                    updateAdminDetailData("status", event.target.value);
                  }}
                  sx={{
                    height: "48px",
                    width: "100%",
                    fontStyle: fontStyle.regularS,
                    color: "black",
                    mt: "8px",
                  }}
                  // disabled
                />
              </Box>
              <VerticalSpacer height={16} />
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ width: "48.5%" }}>
                  <Typography
                    fontStyle={fontStyle.boldXS}
                    color={color.gray600}
                  >
                    Type
                  </Typography>

                  <CommonSelect
                    key={adminDetailData.type}
                    selectValue={adminDetailData.type}
                    menuitems={[
                      "Site Manager",
                      "Customer Manager",
                      "Engineering Manager",
                      "Dealer",
                    ]}
                    onChange={(event) => {
                      updateAdminDetailData("type", event.target.value);
                    }}
                    sx={{
                      height: "48px",
                      width: "100%",
                      fontStyle: fontStyle.regularS,
                      color: "black",
                      mt: "8px",
                    }}
                    disabled={adminDetailData.status == "Deleted"}
                  />
                </Box>
                <Box sx={{ width: "48.5%" }}>
                  <Typography
                    fontStyle={fontStyle.boldXS}
                    color={color.gray600}
                  >
                    Role
                  </Typography>

                  <CommonSelect
                    key={adminDetailData.role}
                    selectValue={adminDetailData.role}
                    menuitems={["Normal", "Lead"]}
                    onChange={(event) => {
                      updateAdminDetailData("role", event.target.value);
                    }}
                    sx={{
                      height: "48px",
                      width: "100%",
                      fontStyle: fontStyle.regularS,
                      color: "black",
                      mt: "8px",
                    }}
                    disabled={adminDetailData.status == "Deleted"}
                  />
                </Box>
              </Box>
              <VerticalSpacer height={16} />
              <Box sx={{ width: "100%" }}>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Office
                </Typography>

                <CommonSelect
                  key={adminDetailData?.office}
                  selectValue={adminDetailData?.office}
                  menuitems={[]}
                  onChange={(event) => {
                    // uiEvent.HandleUiStateChange(
                    //   "LocalNumber",
                    //   event.target.value
                    // );
                  }}
                  sx={{
                    height: "48px",
                    width: "100%",
                    fontStyle: fontStyle.regularS,
                    color: "black",
                    mt: "8px",
                  }}
                  disabled={adminDetailData.status == "Deleted"}
                />
              </Box>
              <VerticalSpacer height={16} />
              <Box sx={{ width: "100%" }}>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Group
                </Typography>

                <CommonSelect
                  key={adminDetailData?.group}
                  selectValue={adminDetailData?.group}
                  menuitems={[]}
                  onChange={(event) => {
                    // uiEvent.HandleUiStateChange(
                    //   "LocalNumber",
                    //   event.target.value
                    // );
                  }}
                  sx={{
                    height: "48px",
                    width: "100%",
                    fontStyle: fontStyle.regularS,
                    color: "black",
                    mt: "8px",
                  }}
                  disabled={adminDetailData.status == "Deleted"}
                />
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      {/* Role 체크박스 card */}
      <Grid item lg={12}>
        <Card
          sx={{
            borderRadius: 3,
            minHeight: 140,
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CardContent sx={{ width: "100%", height: "100%" }}>
            <CardHeader
              title="Role"
              titleTypographyProps={{ fontWeight: "bold", fontSize: 19 }}
              // sx={{ ml: -2, mb: 0 }}
            />
            {/* 체크박스 작업 */}
            <Box sx={{ display: "flex" }}>
              <Box sx={{ width: "20%" }}>
                <CommonIndeterminateCheckBox
                  parentLabel="Statistics"
                  childLabels={statisticsChildLabels}
                  checked={statisticsChecked}
                  setChecked={setStatisticsChecked}
                  onParentClick={() => console.log("Parent checkbox clicked")}
                  disabled={adminDetailData.status == "Deleted"}
                />
              </Box>
              <Box sx={{ width: "20%" }}>
                <CommonIndeterminateCheckBox
                  parentLabel="User Management"
                  childLabels={userManagementChildLabels}
                  checked={userManagementChecked}
                  setChecked={setUserManagementChecked}
                  onParentClick={() => console.log("Parent checkbox clicked")}
                  disabled={adminDetailData.status == "Deleted"}
                />
              </Box>
              <Box sx={{ width: "20%" }}>
                <CommonIndeterminateCheckBox
                  parentLabel="Content"
                  childLabels={contentChildLabels}
                  checked={contentChecked}
                  setChecked={setContentChecked}
                  onParentClick={() => console.log("Parent checkbox clicked")}
                  disabled={adminDetailData.status == "Deleted"}
                />
              </Box>
              <Box sx={{ width: "20%" }}>
                <CommonIndeterminateCheckBox
                  parentLabel="Merchandise"
                  childLabels={merchandiseChildLabels}
                  checked={merchandiseChecked}
                  setChecked={setMerchandiseChecked}
                  onParentClick={() => console.log("Parent checkbox clicked")}
                  disabled={adminDetailData.status == "Deleted"}
                />
              </Box>
              <Box sx={{ width: "20%" }}>
                <CommonIndeterminateCheckBox
                  parentLabel="Production Material"
                  childLabels={productionMaterialChildLabels}
                  checked={productionMaterialChecked}
                  setChecked={setProductionMaterialChecked}
                  onParentClick={() => console.log("Parent checkbox clicked")}
                  disabled={adminDetailData.status == "Deleted"}
                />
              </Box>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Box sx={{ width: "20%" }}>
                <CommonIndeterminateCheckBox
                  parentLabel="Operations"
                  childLabels={operationsChildLabels}
                  checked={operationsChecked}
                  setChecked={setOperationsChecked}
                  onParentClick={() => console.log("Parent checkbox clicked")}
                  disabled={adminDetailData.status == "Deleted"}
                />
              </Box>

              <Box sx={{ width: "20%" }}>
                <CommonIndeterminateCheckBox
                  parentLabel="Carbon Reduction"
                  childLabels={carbonReductionChildLabels}
                  checked={carbonReductionChecked}
                  setChecked={setCarbonReductionsChecked}
                  onParentClick={() => console.log("Parent checkbox clicked")}
                  disabled={adminDetailData.status == "Deleted"}
                />
              </Box>

              <Box sx={{ width: "20%" }}>
                <CommonIndeterminateCheckBox
                  parentLabel="Blockchain"
                  childLabels={blockchainChildLabels}
                  checked={blockchainChecked}
                  setChecked={setBlockchainChecked}
                  onParentClick={() => console.log("Parent checkbox clicked")}
                  disabled={adminDetailData.status == "Deleted"}
                />
              </Box>

              <Box sx={{ width: "20%" }}>
                <CommonIndeterminateCheckBox
                  parentLabel="System Setting"
                  childLabels={systemSettingChildLabels}
                  checked={systemSettingChecked}
                  setChecked={setSystemSettingChecked}
                  onParentClick={() => console.log("Parent checkbox clicked")}
                  disabled={adminDetailData.status == "Deleted"}
                />
              </Box>
            </Box>
            {/* 체크박스 작업 */}
          </CardContent>
        </Card>
      </Grid>
      {/* 하단 detail 카드 */}
      <Grid item lg={12}>
        <Card
          sx={{
            borderRadius: 3,
            minHeight: 240,
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CardContent sx={{ width: "100%", height: "100%" }}>
            <CardHeader
              title="Details"
              titleTypographyProps={{ fontWeight: "bold", fontSize: 19 }}
              // sx={{ ml: -2, mb: 0 }}
            />
            <Box
              sx={{
                m: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* 첫번째 줄 */}
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ width: "48.5%" }}>
                  <Typography>Birth of Date</Typography>
                  <Box
                    sx={{
                      display: "flex",
                      mt: 1,
                      justifyContent: "space-between",
                    }}
                  >
                    <CommonDatePicker
                      value={
                        adminDetailData?.birthOfDate !== null
                          ? dayjs(adminDetailData.birthOfDate)
                          : null
                      }
                      onChange={(newDate: Dayjs | null) => {
                        updateAdminDetailData(
                          "birthOfDate",
                          newDate?.valueOf()
                        );
                      }}
                      views={["year", "month", "day"]}
                      textFieldSx={{ width: "100%" }}
                      InputPropsSx={{ height: "48px" }}
                      // error={inValidDataCheck.birthOfDate}
                      disabled={adminDetailData.status == "Deleted"}
                    />
                  </Box>
                </Box>
                <Box sx={{ width: "48.5%" }}>
                  <Typography>Gender</Typography>
                  <CommonSelect
                    key={adminDetailData?.gender}
                    selectValue={adminDetailData?.gender}
                    menuitems={["Male", "Female"]}
                    onChange={(event) => {
                      updateAdminDetailData("gender", event.target.value);
                    }}
                    sx={{
                      height: "48px",
                      width: "100%",
                      mt: "8px",
                      borderRadius: "10px",
                      fontStyle: fontStyle.regularS,
                      color: "black",
                    }}
                    // error={inValidDataCheck.gender}
                    disabled={adminDetailData.status == "Deleted"}
                  />
                </Box>
              </Box>
              <VerticalSpacer height={40} />
              {/* 두번째 줄 */}
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ width: "48.5%" }}>
                  <Typography>Address</Typography>
                  <Box
                    sx={{
                      display: "flex",
                      mt: 1,
                      justifyContent: "space-between",
                    }}
                  >
                    <CommonSelect
                      key={adminDetailData?.addressCountry}
                      selectValue={adminDetailData?.addressCountry}
                      menuitems={[
                        "Cambodia",
                        // , "Korea", "Usa"
                      ]}
                      onChange={(event) => {
                        updateAdminDetailData(
                          "addressCountry",
                          event.target.value
                        );
                      }}
                      sx={{
                        height: "48px",
                        width: "48.5%",
                        mt: "8px",
                        borderRadius: "10px",
                        fontStyle: fontStyle.regularS,
                        color: "black",
                      }}
                      // error={inValidDataCheck.addressCountry}
                      disabled={adminDetailData.status == "Deleted"}
                    />

                    <CommonSelect
                      key={adminDetailData?.addressProvince}
                      selectValue={adminDetailData?.addressProvince}
                      menuitems={[
                        "Siem Reap",
                        "Battambang",
                        "Banteay Meanchey",
                        "Phnom Penh",
                        "Kandal",
                        "Sihanoukville",
                        "Oddar Meanchey",
                        "Pursat",
                        "Kampong Thom",
                        "Kampong Speu",
                        "Svay Rieng",
                        "Takéo",
                        "Svay Rieng",
                        "Kampong Chhnang",
                        "Kampong Cham",
                        "Pailin",
                        "Prey Veng",
                        "Tboung Khmum",
                        "Kampot",
                        "Ratanakiri",
                        "Koh Kong",
                        "Kratié",
                        "Preah Vihear",
                        "Mondulkiri",
                        "Kep",
                        "Sihanoukville",
                        "other",
                      ]}
                      onChange={(event) => {
                        updateAdminDetailData(
                          "addressProvince",
                          event.target.value
                        );
                      }}
                      sx={{
                        height: "48px",
                        width: "48.5%",
                        mt: "8px",
                        borderRadius: "10px",
                        fontStyle: fontStyle.regularS,
                        color: "black",
                      }}
                      // error={inValidDataCheck.addressProvince}
                      disabled={adminDetailData.status == "Deleted"}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "48.5%",
                    display: "flex",
                    alignItems: "flex-end",
                  }}
                >
                  <CommonTextField
                    value={adminDetailData?.addressDetail}
                    onChange={(event) => {
                      updateAdminDetailData(
                        "addressDetail",
                        event.target.value
                      );
                    }}
                    sx={{
                      width: "100%",
                      mt: "8px",
                      height: "48px",
                    }}
                    InputProps={{
                      style: {
                        height: "48px",
                        borderRadius: "10px",
                        // backgroundColor: color.gray200,
                        // color: color.gray600, // 비활성화 상태일 때 색상을 지정
                      },
                    }}
                    // error={inValidDataCheck.addressDetail}
                    disabled={adminDetailData.status == "Deleted"}
                  />
                </Box>
              </Box>

              <VerticalSpacer height={40} />

              {/* 세번째 줄 */}
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
              >
                <Box sx={{ width: "48.5%" }}>
                  <Typography>Registration Date</Typography>
                  <CommonTextField
                    sx={{ width: "100%", mt: 1 }}
                    value={LocalizedDate({
                      date: adminDetailData?.registrationDt,
                      type: DateFormatType.numericTime,
                    })}
                    disabled
                  />
                </Box>
                <Box sx={{ width: "48.5%" }}>
                  <Typography>Last Modified Date</Typography>
                  <CommonTextField
                    sx={{ width: "100%", mt: 1 }}
                    value={LocalizedDate({
                      date: adminDetailData?.lastModifiedDt,
                      type: DateFormatType.numericTime,
                    })}
                    disabled
                  />
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default AdminDetailGrid;
