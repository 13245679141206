import { CustomerSupportRegistrationPageUiState } from "./model/CustomerSupportRegistrationPageUiState";
import { CustomerSupportRegistrationPageUiEvent } from "./model/CustomerSupportRegistrationPageUiEvent";
import { CustomerSupportRegistrationPageSideEffect } from "./model/CustomerSupportRegistrationPageSideEffect";
import Toolbar from "../../../component/Toolbar";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";
import { Text } from "../../../component/Text";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import { Box } from "@mui/material";
import ProgressBar from "../../../component/ProgressBar";
import VerticalSpacer from "../../../component/Spacer";
import CategorySelect from "../../MobileInquiry/registration/section/CategorySelect";
import { HorizontalGrayDivider } from "../../../component/Divider";
import CommonSingleLineTextField from "../../../component/CommonSingleLineTextField";
import CommonMultiLineTextField from "../../../component/CommonMultiLineTextField";
import ImageUpload from "../../../component/ImageUpload";
import CommonButton from "../../../component/CommonButton";
import { useEffect } from "react";
import CustomDatePicker from "./section/CustomDatePicker";
import CustomTimePicker from "./section/CustomTimePicker";
import SimpleDialog from "../../../component/dialog/SimpleDialog";

interface CSRenderProps {
  uiState: CustomerSupportRegistrationPageUiState;
  uiEvent: CustomerSupportRegistrationPageUiEvent;
  sideEffect: CustomerSupportRegistrationPageSideEffect;
  progress: boolean;
}

export default function CustomerSupportRegistrationRender({
  uiState,
  uiEvent,
  sideEffect,
  progress,
}: CSRenderProps) {
  useEffect(() => {
    const handleAppToWeb = (event: Event) => {
      const customEvent = event as CustomEvent<string>;
      const imageUrl = customEvent.detail;
      uiEvent.onClickAddFile(imageUrl);
    };

    window.addEventListener("addImageUrl", handleAppToWeb);
    return () => {
      window.removeEventListener("addImageUrl", handleAppToWeb);
    };
  }, []);

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {progress && <ProgressBar />}
      <Toolbar
        title={LocalizedText("ua_f310_cs")}
        onBack={() => {
          uiEvent.onBack();
        }}
      />
      <Box
        sx={{
          overflowY: "scroll",
          px: "20px",
        }}
      >
        <VerticalSpacer height={16} />
        <Text
          sx={{
            fontStyle: fontStyle.titleS,
            color: color.gray800,
          }}
          text={LocalizedText("ua_f311_vin")}
        />
        <VerticalSpacer height={4} />

        <CategorySelect
          currentCategory={uiState.vinList.find((it) => it.isSelected)}
          categoryList={uiState.vinList}
          selectValueError={uiState.vinError}
          onChange={(it) => {
            uiEvent.onSelectVIN(it);
          }}
        />

        <VerticalSpacer height={16} />
        <HorizontalGrayDivider />
        <VerticalSpacer height={16} />

        <Text
          sx={{
            fontStyle: fontStyle.titleS,
            color: color.gray800,
          }}
          text={LocalizedText("ua_f311_preferredstation")}
        />
        <VerticalSpacer height={4} />
        <CategorySelect
          currentCategory={uiState.preferredStationCategoryList.find(
            (it) => it.isSelected,
          )}
          categoryList={uiState.preferredStationCategoryList}
          selectValueError={uiState.preferredStationError}
          onChange={(it) => {
            uiEvent.onSelectPreferredStation(it);
          }}
        />
        <VerticalSpacer height={16} />
        <Text
          sx={{
            fontStyle: fontStyle.titleS,
            color: color.gray800,
          }}
          text={LocalizedText("ua_f311_preferreddate")}
        />
        <VerticalSpacer height={4} />
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            width: "100%",
          }}
        >
          <CustomDatePicker
            onSelectDate={uiEvent.onSelectPreferredDate}
            selectValueError={uiState.preferredDateError}
          />
          <CustomTimePicker
            onSelectTime={uiEvent.onSelectPreferredTime}
            selectValueError={uiState.preferredTimeError}
            selectedTime={uiState.preferredTime}
            openTime={uiState.openTime}
            closeTime={uiState.closeTime}
          />
        </Box>

        <VerticalSpacer height={16} />
        <HorizontalGrayDivider />
        <VerticalSpacer height={16} />

        <Text
          sx={{
            fontStyle: fontStyle.titleS,
            color: color.gray800,
          }}
          text={LocalizedText("ua_f311_csdetail")}
        />
        <VerticalSpacer height={4} />
        <CommonSingleLineTextField
          inputValue={uiState.titleInputValue}
          heightPx={38}
          inputValueError={uiState.titleInputError}
          onChange={(value) => {
            uiEvent.onChangeTitleValue(value);
          }}
          onClear={uiEvent.onClearTitleValue}
        />
        <VerticalSpacer height={8} />
        <CommonMultiLineTextField
          inputValue={uiState.contentInputValue}
          heightPx={178}
          maxLength={uiState.contentInputMaxLength}
          onChange={(value) => {
            uiEvent.onChangeContentValue(value);
          }}
          onClear={uiEvent.onClearContentValue}
        />
        <VerticalSpacer height={28} />
        <Text
          sx={{
            fontStyle: fontStyle.titleS,
            color: color.gray800,
          }}
          text={LocalizedText("ua_f311_attachfile")}
        />
        <ImageUpload
          imageUrls={uiState.fileUrls}
          maxImages={5}
          maxFileSizeMB={10}
          onClickAddImage={function (): void {
            sideEffect.openCamera();
          }}
          removeImage={uiEvent.onClickDeleteFile}
        />
        <VerticalSpacer height={8} />
        <Text
          sx={{
            fontStyle: fontStyle.bodySr,
            color: color.gray500,
            whiteSpace: "pre-line",
          }}
          text={LocalizedText("ua_f211_attachmb")}
        />
        <VerticalSpacer height={40} />
        <CommonButton
          onClick={() => {
            uiEvent.onClickSubmit();
          }}
          title={LocalizedText("ua_f311_submit_button")}
        />
        <VerticalSpacer height={20} />
      </Box>

      <SimpleDialog
        children={LocalizedText("common_alert_goprevious")}
        isOpen={uiState.isBackDialog}
        positiveText={LocalizedText("common_alert_confirm_button")}
        nagativeText={LocalizedText("common_alert_cancel_button")}
        onPositive={() => {
          uiEvent.onClickConfirmExitPageDialog();
        }}
        onNagative={() => {
          uiEvent.onClickCancelExitPageDialog();
        }}
        onClose={() => {
          uiEvent.onClickCancelExitPageDialog();
        }}
      />

      <SimpleDialog
        children={LocalizedText("ua_f311_alert_complete_message")}
        isOpen={uiState.isSubmitDialog}
        positiveText={LocalizedText("common_alert_confirm_button")}
        disableBackdropClose={true}
        onPositive={() => {
          uiEvent.onClickConfirmSubmitDialog();
        }}
        onClose={() => {
          uiEvent.onClickCancelSubmitDialog();
        }}
      />
    </Box>
  );
}
