import { Box, Card, Grid } from "@mui/material";
import CommonList from "../../../../../component/CommonList";
import { CommonWebHeaderButton } from "../../../../../component/CommonWebHeaderButton";
import { ProductConfirmationList } from "./ProductConfirmationList";
import VerticalSpacer from "../../../../../component/Spacer";
import { ProductBOMTree } from "./ProductBOMTree";
import { useEffect, useState } from "react";
import { SelectedProduct } from "./SelectedProduct";
import { ProductUiState } from "../model/ProductUiState";
import { ProductUiEvent } from "../model/ProductUiEvent";
import { ApiService } from "../../../../../restAPI/ApiService";
import { useNavigate } from "react-router-dom";

const ProductionRegistration = () => {
  const navigate = useNavigate();
  const [uiState, setUiState] = useState(new ProductUiState());
  const uiEvent: ProductUiEvent = {
    HandleUiStateChange: (key: keyof ProductUiState, value: any) => {
      setUiState((prevState) => ({
        ...prevState,
        [key]: value, // key에 해당하는 상태를 업데이트
      }));
    },
  };

  const handleRegisterClick = () => {
    // 에러 상태를 업데이트
    const updatedProductNameError = uiState.ProductName === "";
    const updatedMadeError = uiState.Made === "";
    const updatedSerialNumberError = uiState.SerialNumber === "";
    const updatedSerialNumberCELLPACKError = uiState.SerialNumberCELLPACK === "";
    const updatedSerialNumberMBMSV240Error = uiState.SerialNumberMBMSV240 === "";
    const updatedSerialNumberFETV240Error = uiState.SerialNumberFETV240 === "";
    const updatedMemoCtSensorHarnessError = uiState.MemoCtSensorHarness === "";

    // 상태 변경
    uiEvent.HandleUiStateChange("ProductNameError", updatedProductNameError);
    uiEvent.HandleUiStateChange("MadeError", updatedMadeError);
    uiEvent.HandleUiStateChange("SerialNumberError", updatedSerialNumberError);
    uiEvent.HandleUiStateChange("SerialNumberCELLPACKError", updatedSerialNumberCELLPACKError);
    uiEvent.HandleUiStateChange("SerialNumberMBMSV240Error", updatedSerialNumberMBMSV240Error);
    uiEvent.HandleUiStateChange("SerialNumberFETV240Error", updatedSerialNumberFETV240Error);
    uiEvent.HandleUiStateChange("MemoCtSensorHarnessError", updatedMemoCtSensorHarnessError);

    // 에러 상태가 업데이트된 후 검증
    if (
      !updatedProductNameError &&
      !updatedMadeError &&
      !updatedSerialNumberError &&
      !updatedSerialNumberCELLPACKError &&
      !updatedSerialNumberMBMSV240Error &&
      !updatedSerialNumberFETV240Error &&
      !updatedMemoCtSensorHarnessError
    ) {
      const ProductionRegistData = {
        serialNumberMBmsV: uiState.SerialNumberMBMSV240,
        serialNumberFetV: uiState.SerialNumberFETV240,
        serialNumber: uiState.SerialNumber,
        memoCtSensorHarness: uiState.MemoCtSensorHarness,
        made: uiState.MadeId,
        writer: localStorage.getItem("user_uuid"),
        materialId: uiState.MaterialId,
        serialNumberCellPack: uiState.SerialNumberCELLPACK,
      };
      console.log("ProductionRegistData is : ", ProductionRegistData);
      ApiService.WebPost("/pw/aw/material/product/create", ProductionRegistData).then((res) => {
        console.log("res?.data is : ", res?.data);
        if (res?.data.message == "Success") {
          navigate(`/productionDetails/${res.data.createdId}`);
        }
      });
    }
  };
  return (
    <CommonList
      title="Production Registration"
      headerBackButton={true}
      navigateURL="/productionManagement"
      headerButton={
        <CommonWebHeaderButton
          width="96px"
          height="45px"
          buttonContent="Register"
          sx={{ display: "flex", flexDirection: "column" }}
          onClick={handleRegisterClick}
        />
      }
    >
      {/* <VerticalSpacer height={16} /> */}
      <Grid item lg={12}>
        {/* <Card
          sx={{
            borderRadius: "15px",
            padding: "24px",
            boxShadow: 0,
          }}
        >
          <ProductConfirmationList
            selectValue={selectValue}
            onchange={handleChange}
          />
          <ProductBOMTree selectValue={selectValue} />
        </Card> */}
        {/* <VerticalSpacer height={20} /> */}
        <Card
          sx={{
            borderRadius: "15px",
            padding: "24px",
            boxShadow: 0,
          }}
        >
          <SelectedProduct uiState={uiState} uiEvent={uiEvent} />
        </Card>
      </Grid>
    </CommonList>
  );
};

export default ProductionRegistration;
