import { StationDetailInfoResponse } from "../model/StationDetailInfoResponse";
import { StationMngDetailUiState } from "../model/StationMngDetailUiState";

export function convertStationDetailUiModel(
  response: StationDetailInfoResponse,
): StationMngDetailUiState {
  const [openHour, openMinute] = response.openTime.split(":");
  const [closeHour, closeMinute] = response.closeTime.split(":");

  return new StationMngDetailUiState(
    response.managerId,
    response.managerName,
    response.managerEmail,
    response.countryNumber,
    response.phoneNumber,
    openHour,
    openMinute,
    closeHour,
    closeMinute,
    parseOpenDays(response.openDay),
    response.svcStt,
    response.svcNm,
    response.svcType,
    response.svcAreaNm + `(${response.svcAreaLocation})`,
    response.registerDt,
  );
}

function parseOpenDays(openDaysString: string): boolean[] {
  const daysArray = openDaysString.split("").map((day) => day === "O");

  // 전체 선택 상태를 확인
  const allSelected = daysArray.every((isOpen) => isOpen);

  // 전체 선택 상태를 배열 맨 앞에 추가
  return [allSelected, ...daysArray];
}
