import { useState } from "react";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { GridRowModes, GridRowModesModel, GridRowsProp, } from "@mui/x-data-grid";
import { randomId } from "@mui/x-data-grid-generator";
import CommonList from "../../component/CommonList";
import CommonSearch from "../../component/CommonSearch";
import MultilingualGrid from "./section/MultilingualGrid";
import axios from "axios";

const Multilingual = () => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const handleClick = () => {
    const id = randomId();
    const newRow = {
      id,
      code: "코드를 입력하세요.",
      lang: "",
      // registrationDate: new Date(),
      isNew: false,
    };
    axios.post("/pw/pw/language/api/createOne", newRow).then((res) => {
      console.log("new Row is", res.data);
      setRows((oldRows) => [...oldRows, res.data]);
    });
    //
    // setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.View, fieldToFocus: "code" },
    }));
  };

  const HeaderButton = () => {
    return (
      <Button
        sx={{
          height: 40,
          backgroundColor: "#5D38E5",
          color: "white",
          borderRadius: 5,
          width: 85,
          ":hover": {
            backgroundColor: "#A38BFC",
          },
        }}
        onClick={handleClick}
      >
        <AddIcon fontSize="small" />
        등록
      </Button>
    );
  };

  return (
    <CommonList
      title="다국어 관리"
      setModal={null}
      headerButton={HeaderButton()}
    >
      <CommonSearch setRowsPerPage={setRowsPerPage}>{null}</CommonSearch>
      <MultilingualGrid
        setRows={setRows}
        setRowModesModel={setRowModesModel}
        rowModesModel={rowModesModel}
        rows={rows}
        rowsPerPage={rowsPerPage}
      />
    </CommonList>
  );
};

export default Multilingual;
