import { StationMngDetailPage } from "./StationMngDetailPage";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ApiService } from "../../../restAPI/ApiService";
import { StationDetailInfoResponse } from "./model/StationDetailInfoResponse";
import { TabType } from "./model/TabType";
import { StationMngDetailUiEvent } from "./model/StationMngDetailUiEvent";
import { StationMngDetailUiState } from "./model/StationMngDetailUiState";
import { convertStationDetailUiModel } from "./mapper/StationModelMapper";

export function StationMngDetail() {
  const location = useLocation();
  const stationState = location.state as { id?: string };
  const savedStationId = sessionStorage.getItem("stationId") || undefined;
  const stationServiceId = stationState?.id || savedStationId;

  const [stationDetailUiState, setStationDetailUiState] = useState(
    new StationMngDetailUiState(),
  );

  useEffect(() => {
    if (stationServiceId) sessionStorage.setItem("stationId", stationServiceId);
  }, [stationServiceId]);

  async function getStationInfo(
    stationId: string,
  ): Promise<StationDetailInfoResponse | undefined> {
    const url = "/pw/backend/api/station/detail";
    const requestBody = {
      svcId: stationId,
    };

    try {
      const res = await ApiService.webPost(url, requestBody);
      const data: StationDetailInfoResponse = res.data;
      if (typeof data === "object" && Object.keys(data).length === 0)
        return undefined;
      return data;
    } catch (e) {
      throw e;
    }
  }

  useEffect(() => {
    const fetchStationInfo = async () => {
      try {
        if (!stationServiceId) {
          alert("No station information available.");
          return;
        }
        const stationInfo = await getStationInfo(stationServiceId);
        if (stationInfo === undefined) {
          alert("No station information available.");
          return;
        }
        setStationDetailUiState(convertStationDetailUiModel(stationInfo));
      } catch (e) {
        alert("Failed to fetch station information.");
      }
    };

    fetchStationInfo(); // 비동기 함수 호출
  }, [stationServiceId]);

  const stationInfoDetailUiEvent: StationMngDetailUiEvent = {
    back: function (): void {
      window.history.back();
    },
    onClickUpdate: function (): void {
      alert("준비중 API 수정");
    },
    onClickTab: function (tab: TabType): void {
      // TODO : 탭 변경 이벤트 처리
    },
    onChangeServiceDate: function (newStates: boolean[]): void {
      setStationDetailUiState({
        ...stationDetailUiState,
        serviceDate: newStates,
      });
    },
    onChangeServiceOpenHour: function (newHour: string): void {
      setStationDetailUiState({
        ...stationDetailUiState,
        serviceOpenHour: newHour,
      });
    },
    onChangeServiceOpenMin: function (newMin: string): void {
      setStationDetailUiState({
        ...stationDetailUiState,
        serviceOpenMin: newMin,
      });
    },
    onChangeServiceCloseHour: function (newHour: string): void {
      setStationDetailUiState({
        ...stationDetailUiState,
        serviceCloseHour: newHour,
      });
    },
    onChangeServiceCloseMin: function (newMin: string): void {
      setStationDetailUiState({
        ...stationDetailUiState,
        serviceCloseMin: newMin,
      });
    },
  };

  return (
    <StationMngDetailPage
      currentTab={TabType.BASICS}
      uiState={stationDetailUiState}
      uiEvent={stationInfoDetailUiEvent}
    />
  );
}
