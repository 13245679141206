import { useEffect, useState } from "react";
import CommonDialog from "../../../../component/CommonDialog";
import CommonList from "../../../../component/CommonList";
import { ServiceMngDetailUiEvent } from "./model/ServiceMngDetailUiEvent";
import { ServiceMngDetailUiState } from "./model/ServiceMngDetailUiState";
import { Box, Button, CircularProgress, IconButton } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useLocation, useNavigate } from "react-router-dom";
import { ApiService } from "../../../../restAPI/ApiService";
import ServiceMngDetailGrid from "./ServiceMngDetailGrid";
import { ADMIN_CONFIG } from "../../../../constants/AdminConfig";
const ServiceMngDetailPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); // 로딩 상태 추가
  const location = useLocation();
  const pathname = location.pathname;
  const svcId = pathname.split("/").pop(); // URL에서 마지막 부분을 추출

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOk = () => {
    navigate("/regionAndServiceManagement");
    window.location.reload();
  };
  const HeaderButton = () => {
    const handleUpdate = () => {
      setLoading(true);
      // 로컬 변수로 에러 상태 확인
      const idError = uiState.ID.trim() === "";
      const fileError = uiState.DetailsFile.length === 0;
      const serviceNameError = uiState.ServiceName.trim() === "";
      const serviceError = uiState.Service.trim() === "";
      const statusError = uiState.Status.trim() === "";
      const areaNameError = uiState.AreaName.trim() === "";
      const partnersCategoryError = uiState.Service == ADMIN_CONFIG.PARTNER ? uiState.PartnersCategory.trim() === "" : false;
      const partnersSubtitleError = uiState.Service == ADMIN_CONFIG.PARTNER ? uiState.PartnersSubtitle.trim() === "" : false;
      const partnersDescriptionError = uiState.Service == ADMIN_CONFIG.PARTNER ? uiState.PartnersDescription.trim() === "" : false;

      // 에러 상태를 한 번에 업데이트
      uiEvent.HandleUiStateChange("IDError", idError);
      uiEvent.HandleUiStateChange("FileError", fileError);
      uiEvent.HandleUiStateChange("ServiceNameError", serviceNameError);
      uiEvent.HandleUiStateChange("ServiceError", serviceError);
      uiEvent.HandleUiStateChange("StatusError", statusError);
      uiEvent.HandleUiStateChange("AreaNameError", areaNameError);
      uiEvent.HandleUiStateChange("PartnersCategoryError", partnersCategoryError);
      uiEvent.HandleUiStateChange("PartnersSubtitleError", partnersSubtitleError);
      uiEvent.HandleUiStateChange("PartnersDescriptionError", partnersDescriptionError);

      // 에러가 하나라도 있으면 함수 종료
      if (
        idError ||
        fileError ||
        serviceNameError ||
        serviceError ||
        statusError ||
        areaNameError ||
        partnersCategoryError ||
        partnersSubtitleError ||
        partnersDescriptionError
      ) {
        return;
      } else {
        // 모든 값이 유효하면 데이터 생성
        const formData = new FormData();
        const updateSvcData = {
          svcId: svcId,
          managerId: uiState.ID,
          openTime: `${uiState.OpenTimeHour}:${uiState.OpenTimeMin}`,
          closeTime: `${uiState.CloseTimeHour}:${uiState.CloseTimeMin}`,
          openDay: uiState.OpenDay,
          svcNm: uiState.ServiceName,
          svcStt: uiState.Status,
          svcAreaId: uiState.AreaId,
          svcType: uiState.Service == ADMIN_CONFIG.PARTNER ? uiState.PartnersCategory : uiState.Service,
          contact: `${uiState.LocalNumber} ${uiState.PhoneNumber}`,
          description: uiState.Service == ADMIN_CONFIG.PARTNER ? uiState.PartnersDescription : "",
          subtitle: uiState.Service == ADMIN_CONFIG.PARTNER ? uiState.PartnersSubtitle : "",
          svcImage: uiState.SvcImgList,
        };
        const blob = new Blob([JSON.stringify(updateSvcData)], {
          type: "application/json",
        });
        // console.log(updateSvcData);
        formData.append("svcDoc", blob);
        // 파일 데이터 추가
        uiState.DetailsFile.forEach((file, index) => {
          if (file.size) {
            formData.append("svcImage", file); // 고유 키를 사용하여 파일 추가
          }
        });
        ApiService.WebPost("/pw/backend/api/svc/update", formData).then((res) => {
          if (res?.data) {
            window.location.reload();
          }
        });
      }
    };

    return (
      <Button
        sx={{
          height: 40,
          backgroundColor: "#5D38E5",
          color: "white",
          borderRadius: 5,
          width: 110,
          ":hover": {
            backgroundColor: "#A38BFC",
          },
        }}
        onClick={handleUpdate}
      >
        Update
      </Button>
    );
  };

  const HeaderBackButton = () => {
    return (
      <IconButton
        sx={{
          ":hover": { backgroundColor: "#F2F2F2" },
          mb: 0.5,
          mr: 1,
        }}
        onClick={() => {
          handleClickOpen();
        }}
      >
        <ArrowBackIosNewIcon fontSize="medium" />
      </IconButton>
    );
  };

  const [uiState, setUiState] = useState(new ServiceMngDetailUiState());
  const uiEvent: ServiceMngDetailUiEvent = {
    HandleUiStateChange: (key: keyof ServiceMngDetailUiState, value: any) => {
      setUiState((prevState) => ({
        ...prevState,
        [key]: value, // key에 해당하는 상태를 업데이트
      }));
    },
  };

  useEffect(() => {
    console.log(svcId);
    ApiService.WebPost("/pw/backend/api/svc/detail", { svcId: svcId }).then((res) => {
      console.log(res?.data);
      if (res?.data) {
        const detailData = res?.data;
        uiEvent.HandleUiStateChange("ID", detailData.managerId || "");

        const openTime = detailData.openTime;
        const [openHour, openMinute] = openTime.split(":");
        uiEvent.HandleUiStateChange("OpenTimeHour", openHour);
        uiEvent.HandleUiStateChange("OpenTimeMin", openMinute);

        const closeTime = detailData.closeTime;
        const [closeHour, closeMinute] = closeTime.split(":");
        uiEvent.HandleUiStateChange("CloseTimeHour", closeHour);
        uiEvent.HandleUiStateChange("CloseTimeMin", closeMinute);

        uiEvent.HandleUiStateChange("OpenDay", detailData.openDay);
        uiEvent.HandleUiStateChange("ServiceName", detailData.svcNm);
        if (
          detailData.svcType == ADMIN_CONFIG.HOT_SPOT ||
          detailData.svcType == ADMIN_CONFIG.RESTAURANT ||
          detailData.svcType == ADMIN_CONFIG.HOTEL
        ) {
          uiEvent.HandleUiStateChange("Service", ADMIN_CONFIG.PARTNER);
          uiEvent.HandleUiStateChange("PartnersCategory", detailData.svcType);
          uiEvent.HandleUiStateChange("PartnersDescription", detailData.description);
          uiEvent.HandleUiStateChange("PartnersSubtitle", detailData.subtitle);
        } else {
          uiEvent.HandleUiStateChange("Service", detailData.svcType);
        }

        uiEvent.HandleUiStateChange("Status", detailData.svcStt);
        uiEvent.HandleUiStateChange("AreaId", detailData.svcAreaId);
        uiEvent.HandleUiStateChange("SvcImgList", detailData.svcImage);
        uiEvent.HandleUiStateChange("RegistrationDate", detailData.registerDt);
        uiEvent.HandleUiStateChange("LastModifiedDate", detailData.lastUptDt);
      }
    });
  }, []);

  return (
    <>
      {loading && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: "140px",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0)", // 배경 흐리게
            zIndex: 1000, // 다른 요소들 위에 배치
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <CommonList title="Service Details" headerButton={HeaderButton()} headerBackButton={HeaderBackButton()}>
        <CommonDialog open={open} handleClose={handleClose} handleOk={handleOk}>
          Do you want to stop editing and move? The current content will not be saved.
        </CommonDialog>
        <ServiceMngDetailGrid uiState={uiState} uiEvent={uiEvent} />
      </CommonList>
    </>
  );
};

export default ServiceMngDetailPage;
