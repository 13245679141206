import { ScooterBasicInfoResponse } from "../model/ScooterBasicInfoResponse";
import { ScooterBasicInfoUiState } from "../basic/model/ScooterBasicInfoUiState";

/**
 * Maps a ScooterBasicInfoResponse object to a ScooterBasicInfoUiState object.
 * @param response - The response from the API
 * @returns ScooterBasicInfoUiState - The mapped UI state
 */
export function scooterBasicInfoMapper(
  response: ScooterBasicInfoResponse,
): ScooterBasicInfoUiState {
  return new ScooterBasicInfoUiState(
    response.ownerId || "", // Default value if undefined
    response.renterId || "", // Default value if undefined
    response.registrant || "", // Default value if undefined
    response.registerDt || 0, // Default value if undefined
    response.updaterId || "", // Default value if undefined
    response.lastUptDt || 0, // Default value if undefined
    response.plateNumber || "", // Default value if undefined
    response.productName || "", // Default value if undefined
    response.serialNumber || "", // Default value if undefined
    response.frameSerialNumber || "", // Default value if undefined
    response.imei || "", // Default value if undefined
    response.dateOfManufacture || 0, // Default value if undefined
    response.scooterModelImage.replace(/\(/g, "%28").replace(/\)/g, "%29") ||
      "",
  );
}
