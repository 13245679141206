import { Box, Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import { fontStyle } from "../../../../theme/Style";
import { color } from "../../../../theme/Color";
import VerticalSpacer from "../../../../component/Spacer";
import { CommonSelect } from "../../../../component/CommonSelect";
import CommonErrorText from "../../../../component/CommonErrorText";
import { useEffect, useRef, useState } from "react";
import { ApiService } from "../../../../restAPI/ApiService";
import CommonTextField from "../../../../component/CommonTextField";
import ManagerCard from "./section/ManagerCard";
import { ServiceMngCreateUiState } from "./model/ServiceMngCreateUiState";
import { ServiceMngCreateUiEvent } from "./model/ServiceMngCreateUiEvent";
import ServiceCard from "./section/ServiceCard";
import DetailsCard from "./section/DetailsCard";
import PartnersInformationCard from "./section/PartnersInformationCard";

interface ServiceMngCreateGridProps {
  uiState: ServiceMngCreateUiState;
  uiEvent: ServiceMngCreateUiEvent;
}

const ServiceMngCreateGrid = ({ uiState, uiEvent }: ServiceMngCreateGridProps) => {
  const partnersInfoCardRef = useRef<HTMLDivElement | null>(null); // ref 추가

  useEffect(() => {
    ApiService.WebPost("/pw/users/admin/stationAdmin").then((res) => {
      uiEvent.HandleUiStateChange("ManagerList", res?.data.body);
    });
  }, []);

  useEffect(() => {
    ApiService.WebPost("/pw/backend/api/geo/svcAreaNameList").then((res) => {
      uiEvent.HandleUiStateChange("AreaList", res?.data.body);
    });
  }, []);

  useEffect(() => {
    // uiState.Service가 "Hot Spot", "Hotel", 또는 "Restaurant"일 때 스크롤 내리기
    if (uiState.Service === "Partner" && partnersInfoCardRef.current) {
      partnersInfoCardRef.current.scrollIntoView({
        behavior: "smooth", // 부드러운 스크롤
        block: "start", // 스크롤을 카드의 시작 부분으로 맞춤
      });
    }
  }, [uiState.Service]); // uiState.Service가 변경될 때마다 실행
  return (
    <Grid container columnSpacing={2.75} rowSpacing={2.75} sx={{ mt: -2.75 }}>
      {/* Manager 카드 */}
      <ManagerCard uiState={uiState} uiEvent={uiEvent} />
      {/* Service 카드 */}
      <ServiceCard uiState={uiState} uiEvent={uiEvent} />
      {/* Details 카드 */}
      <DetailsCard uiState={uiState} uiEvent={uiEvent} />
      {/* Partners Information 카드 */}
      {uiState.Service == "Partner" && <PartnersInformationCard ref={partnersInfoCardRef} uiState={uiState} uiEvent={uiEvent} />}
    </Grid>
  );
};

export default ServiceMngCreateGrid;
