import { Box, InputBase } from "@mui/material";
import { Text } from "../../../../component/Text";
import Paper from "@mui/material/Paper";
import { fontStyle } from "../../../../theme/Style";
import { color } from "../../../../theme/Color";
import VerticalSpacer from "../../../../component/Spacer";

interface LabeledPaperProps {
  label: string;
  value: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
}

export function LabeledPaper({
  label,
  value,
  disabled = true,
  onChange,
}: LabeledPaperProps) {
  return (
    <Box>
      <Text
        sx={{
          fontStyle: fontStyle.boldXS,
          color: color.gray600,
        }}
        text={label}
      />
      <VerticalSpacer height={8} />
      <Paper
        component="form"
        sx={{
          display: "flex",
          alignItems: "center",
          flexGrow: 1,
          height: "48px",
          border: `1px solid`,
          borderRadius: "10px",
          backgroundColor: disabled ? color.gray200 : color.white,
          borderColor: color.gray300,
        }}
        elevation={0}
      >
        <InputBase
          value={disabled ? (value === "" ? "-" : value) : value}
          sx={{
            paddingX: "16px",
            width: "100%",
            fontStyle: fontStyle.regularS,
            color: disabled ? color.gray600 : color.default,
          }}
          disabled={disabled}
          onChange={(event) => {
            onChange && onChange(event.target.value);
          }}
        />
      </Paper>
    </Box>
  );
}
