import { ChangeEvent, ReactNode, useEffect, useState, useRef } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  MenuItem,
  Radio,
  Select,
  SelectChangeEvent,
  SnackbarOrigin,
  TextField,
  Typography,
} from "@mui/material";
import { Button } from "antd";
import { styled } from "styled-components";
import GoogleMapWrapper from "./GoogleMapWrapper";
import TextEditor from "../../../component/TextEditor";
import { Methodology } from "../../../store/methodologyType";
import { ProjectFormData } from "../../../store/projectType";
import { SERVER_CONFIG } from "../../../constants/ServerConfig";
import { ADMIN_CONFIG } from "../../../constants/AdminConfig";
import { getFileName } from "../../../utils/FileUtil";

interface CreateProjectGridProps {
  formData: ProjectFormData;
  setFormData: React.Dispatch<React.SetStateAction<ProjectFormData>>;
  errors: { [key: string]: string };
}

interface CustomTypographyProps {
  children: ReactNode;
  sx?: object;
}

const CustomTextField = styled(TextField)(({ theme }) => ({
  width: "100%",
  minHeight: "48px",
  padding: "12px 16px",
  gap: "2px",
  borderRadius: "10px 0px 0px 0px",
  border: "1px solid transparent",
  opacity: 1,
  marginLeft: "0",
  "& .MuiInputBase-root": {
    height: "48px",
  },
}));

const CreateProjectGridPage: React.FC<CreateProjectGridProps> = ({
  formData,
  setFormData,
  errors,
}) => {
  const [methodologyData, setMethodologyData] = useState<number | string>("");
  const [menuItems, setMenuItems] = useState<Methodology[]>([]);
  const [selectedArea, setSelectedArea] = useState<string>("");
  const [selectedSubArea, setSelectedSubArea] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);
  const fileINputRef = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState<string>("");
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const { name, value } = event.target;

    const keys = name.split(".");

    setFormData((prevFormData) => {
      if (keys.length === 1) {
        return { ...prevFormData, [name]: value };
      } else {
        const [parentKey, childKey] = keys;
        return {
          ...prevFormData,
          [parentKey]: {
            ...(prevFormData as any)[parentKey],
            [childKey]: value,
          },
        };
      }
    });
  };
  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleTeamChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData((prev) => ({
      ...prev,
      team: event.target.value,
    }));
  };

  const apiKey =
    process.env.REACT_APP_GOOGLE_MAPS_API_KEY ||
    "AIzaSyC5UuKjoIZ8W24wn-ASHVvkeIcy1Gt4VrI";
  const [latitude, setLatitude] = useState<number | undefined>(
    formData.latitude
  );
  const [longitude, setLongitude] = useState<number | undefined>(
    formData.longitude
  );
  const [address, setAddress] = useState<string>(
    formData.company?.address || ""
  );

  useEffect(() => {
    if (formData.latitude !== latitude) {
      setLatitude(formData.latitude);
    }
    if (formData.longitude !== longitude) {
      setLongitude(formData.longitude);
    }

    if (formData.company && formData.company.address !== address) {
      setAddress(formData.company.address || "");
    }
  }, [formData.latitude, formData.longitude, formData.company?.address]);

  useEffect(() => {
    if (
      formData?.company?.certificate &&
      typeof formData?.company?.certificate === "string"
    ) {
      // url.substring(url.lastIndexOf('/') + 1);
      const url = formData?.company?.certificate;
      setFileName(getFileName(url));
    }
  }, [formData.company]);

  useEffect(() => {
    const fetchMethodologyData = async () => {
      try {
        const response = await fetch(
          SERVER_CONFIG.CARBON_SERVER + `/methodology/list`
        );
        const data: Methodology[] = await response.json();
        setMenuItems(data);

        console.log("====> formData.methodology?.id: ", formData);
        const selectedMethodology = data.find(
          (item) => item.id === formData.methodology?.id
        );
        if (selectedMethodology) {
          setMethodologyData(selectedMethodology.id);
          setSelectedArea(selectedMethodology.area || "");
          setSelectedSubArea(selectedMethodology.subArea || "");
        }
      } catch (error) {
        console.error("Error Methodology fetching data: ", error);
      }
    };
    fetchMethodologyData();
  }, []);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];

    if (selectedFile) {
      setFile(selectedFile);
      setFileName(selectedFile.name);

      setFormData((prevData) => ({
        ...prevData,
        company: {
          ...prevData.company,
          certificate: selectedFile,
        },
      }));
    }
  };

  const handleUploadClick = () => {
    const fileInput = document.getElementById("fileInput") as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  };
  const handleAddressSelection = (
    address: string,
    lat: number,
    lng: number
  ) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      company: {
        ...prevFormData.company,
        address: address,
        detailAddress: prevFormData.company?.detailAddress ?? "",
        name: prevFormData.company?.name ?? "",
        ownerName: prevFormData.company?.ownerName ?? "",
        businessNumber: prevFormData.company?.businessNumber ?? "",
        certificate: prevFormData.company?.certificate ?? "",
        url: prevFormData.company?.url ?? "",
        faxNumber: prevFormData.company?.faxNumber ?? "",
      },

      latitude: lat || prevFormData.latitude,
      longitude: lng || prevFormData.longitude,
    }));

    setLatitude(lat);
    setLongitude(lng);
  };

  const CustomTypography: React.FC<CustomTypographyProps> = ({ children }) => {
    return (
      <Typography
        sx={{
          color: "#99989D",
          marginLeft: "17px",
          lineHeight: "18px",
          fontWeight: 700,
        }}
      >
        {children}
      </Typography>
    );
  };

  return (
    <Grid container rowSpacing={4.5} sx={{ mt: 0 }}>
      {/* 기업 정보 */}
      <Grid item lg={12}>
        <Card
          sx={{
            borderRadius: 3,
            minHeight: 240,
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CardContent sx={{ width: "100%", height: "100%" }}>
            <CardHeader sx={{ mb: "-18px" }} />
            <Box
              sx={{
                m: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* 첫번째 줄 */}
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ width: "48.5%" }}>
                  <CustomTypography sx={{ fontWeight: "bold" }}>
                    {ADMIN_CONFIG.PAGE_PROJECT.COMPANY}
                  </CustomTypography>
                  <CustomTextField
                    sx={{ width: "100%", mt: 0 }}
                    size="small"
                    value={formData.company?.name}
                    onChange={(val) => {
                      handleChange(val);
                      errors.companyName = "";
                    }}
                    name="company.name"
                    required
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                    error={!!errors.companyName}
                    helperText={errors.companyName}
                  />
                </Box>
                <Box sx={{ width: "48.5%" }}>
                  <CustomTypography sx={{ fontWeight: "bold" }}>
                    {ADMIN_CONFIG.PAGE_PROJECT.OWNER}
                  </CustomTypography>
                  <CustomTextField
                    sx={{ width: "100%", mt: 0 }}
                    size="small"
                    value={formData.company?.ownerName}
                    onChange={(val) => {
                      handleChange(val);
                      errors.companyOwnerName = "";
                    }}
                    name="company.ownerName"
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                    error={!!errors.companyOwnerName}
                    helperText={errors.companyOwnerName}
                  />
                </Box>
              </Box>

              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
              >
                <Box sx={{ width: "48.5%", mt: 0 }}>
                  <CustomTypography sx={{ fontWeight: "bold" }}>
                    사업자 등록번호
                  </CustomTypography>
                  <CustomTextField
                    sx={{ width: "100%" }}
                    size="small"
                    value={formData.company?.businessNumber}
                    name="company.businessNumber"
                    onChange={(val) => {
                      handleChange(val);
                      errors.companyBusinessNumber = "";
                    }}
                    inputProps={{
                      maxLength: 10,
                      pattern: "[0-9]",
                      inputMode: "numeric",
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                    error={!!errors.companyBusinessNumber}
                    helperText={errors.companyBusinessNumber}
                  />
                </Box>
                <Box sx={{ width: "48.5%", mt: 0 }}>
                  <CustomTypography sx={{ fontWeight: "bold" }}>
                    사업인증서
                  </CustomTypography>
                  <CustomTextField
                    className="certificate"
                    sx={{ width: "100%", mt: 0 }}
                    size="small"
                    value={fileName}
                    onChange={handleTextFieldChange}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },

                      startAdornment: (
                        <Button onClick={handleUploadClick} type="text">
                          파일 등록
                        </Button>
                      ),
                    }}
                  />
                  <input
                    type="file"
                    id="fileInput"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                    // value={formData.businessValidationReportFile | null}
                  />
                </Box>
              </Box>

              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
              >
                <Box sx={{ width: "48.5%", mt: 0 }}>
                  <CustomTypography sx={{ fontWeight: "bold" }}>
                    {ADMIN_CONFIG.PAGE_PROJECT.HOMEPAGE}
                  </CustomTypography>
                  <CustomTextField
                    sx={{ width: "100%", mt: 0 }}
                    size="small"
                    value={formData.company?.url}
                    name="company.url"
                    onChange={(val) => {
                      handleChange(val);
                      errors.companyUrl = "";
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                    error={!!errors.companyUrl}
                    helperText={errors.companyUrl}
                  />
                </Box>
                <Box sx={{ width: "48.5%" }}>
                  <CustomTypography sx={{ fontWeight: "bold" }}>
                    {ADMIN_CONFIG.PAGE_PROJECT.FAX}
                  </CustomTypography>
                  <CustomTextField
                    sx={{ width: "100%", mt: 0 }}
                    size="small"
                    value={formData.company?.faxNumber}
                    name="company.faxNumber"
                    onChange={handleChange}
                    inputProps={{
                      maxLength: 13,
                      pattern: "[0-9]",
                      inputMode: "numeric",
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Box>
              </Box>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
              >
                <Box sx={{ width: "48.5%" }}>
                  <CustomTypography sx={{ fontWeight: "bold" }}>
                    {ADMIN_CONFIG.PAGE_PROJECT.ADDRESS}
                  </CustomTypography>
                  <CustomTextField
                    sx={{ width: "100%", mt: 0 }}
                    size="small"
                    value={formData.company?.address || ""}
                    name="company.address"
                    onChange={handleChange}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                      readOnly: true,
                    }}
                  />
                </Box>
                <Box sx={{ width: "48.5%" }}>
                  <CustomTypography sx={{ fontWeight: "bold" }}>
                    {ADMIN_CONFIG.PAGE_PROJECT.ADDRESS_DETAIL}
                  </CustomTypography>
                  <CustomTextField
                    sx={{ width: "100%", mt: 0 }}
                    size="small"
                    value={formData.company?.detailAddress}
                    name="company.detailAddress"
                    onChange={handleChange}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 2,
                }}
              >
                <Box sx={{ width: "48.5%" }}>
                  <CustomTypography sx={{ fontWeight: "bold" }}>
                    {ADMIN_CONFIG.PAGE_PROJECT.LAT}
                  </CustomTypography>
                  <CustomTextField
                    sx={{ width: "100%", mt: 0, borderRadius: "10px" }}
                    size="small"
                    value={latitude !== undefined ? latitude : ""}
                    name="latitude"
                    onChange={(e) => setLatitude(Number(e.target.value))}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                      style: {
                        backgroundColor: "#f0f0f0",
                        color: "#a0a0a0",
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Box>
                <Box sx={{ width: "48.5%" }}>
                  <CustomTypography sx={{ fontWeight: "bold" }}>
                    {ADMIN_CONFIG.PAGE_PROJECT.LONG}
                  </CustomTypography>
                  <CustomTextField
                    sx={{ width: "100%", mt: 0 }}
                    size="small"
                    value={longitude !== undefined ? longitude : ""}
                    name="longitude"
                    onChange={(e) => setLongitude(Number(e.target.value))}
                    fullWidth
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                        backgroundColor: "#f0f0f0",
                        color: "#a0a0a0",
                      },
                      readOnly: true,
                    }}
                  />
                </Box>
              </Box>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
              >
                <Box
                  sx={{
                    width: "100%",
                    minHeight: "48px",
                    padding: "12px 16px",
                    gap: "2px",
                    borderRadius: "10px 0px 0px 0px",
                    border: "1px solid transparent",
                    opacity: 1,
                    marginLeft: "0",
                    "& .MuiInputBase-root": {
                      height: "48px", // Ensures input area matches the outer height
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: 1,
                      borderColor: "#B0AFB3",
                      mt: 1,
                      borderRadius: 3,
                      height: "400px",
                      width: "100%",
                      overflow: "hidden",
                    }}
                  >
                    <GoogleMapWrapper
                      latitude={latitude || 37.7749}
                      longitude={longitude || -122.4194}
                      apiKey={apiKey}
                      onAddressSelected={handleAddressSelection}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={12}>
        <Card
          sx={{
            borderRadius: 3,
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CardContent sx={{ width: "100%", height: "100%" }}>
            <CardHeader
              title={"담당자 정보"}
              titleTypographyProps={{ fontWeight: "bold", fontSize: 19 }}
            />
            <Box
              sx={{
                m: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* 첫번째 줄 */}
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ width: "48.5%" }}>
                  <CustomTypography sx={{ fontWeight: "bold" }}>
                    담당자명
                  </CustomTypography>
                  <CustomTextField
                    sx={{ width: "100%", mt: 0 }}
                    size="small"
                    value={formData.contactName}
                    name="contactName"
                    onChange={(val) => {
                      handleChange(val);
                      errors.contactName = "";
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                    error={!!errors.contactName}
                    helperText={errors.contactName}
                  />
                </Box>
                <Box sx={{ width: "48.5%" }}>
                  <CustomTypography sx={{ fontWeight: "bold" }}>
                    부서명
                  </CustomTypography>
                  <CustomTextField
                    sx={{ width: "100%", mt: 0 }}
                    size="small"
                    value={formData.team}
                    name="team"
                    onChange={(val) => {
                      handleTeamChange(val);
                      errors.team = "";
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                    error={!!errors.team}
                    helperText={errors.team}
                  />
                </Box>
              </Box>

              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 0 }}
              >
                <Box sx={{ width: "48.5%" }}>
                  <CustomTypography sx={{ fontWeight: "bold" }}>
                    {ADMIN_CONFIG.PAGE_PROJECT.NUMBER}
                  </CustomTypography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Select
                      value={formData.phoneCode}
                      size="small"
                      sx={{
                        width: "inherit",
                        height: "48px",
                        mt: 1.5,
                        marginLeft: "16px",
                      }}
                      style={{ borderRadius: "10px" }}
                    >
                      <MenuItem value="82">+82</MenuItem>
                      <MenuItem value="44">+44</MenuItem>
                      <MenuItem value="1">+1</MenuItem>
                    </Select>

                    <CustomTextField
                      sx={{ width: "78.5%" }}
                      size="small"
                      value={formData.phone}
                      name="phone"
                      onChange={(val) => {
                        handleChange(val);
                        errors.phone = "";
                      }}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                      error={!!errors.phone}
                      helperText={errors.phone}
                    />
                  </Box>
                </Box>
                <Box sx={{ width: "48.5%" }}>
                  <CustomTypography sx={{ fontWeight: "bold" }}>
                    {ADMIN_CONFIG.PAGE_PROJECT.EMAIL}
                  </CustomTypography>
                  <CustomTextField
                    sx={{ width: "100%", mt: 0 }}
                    size="small"
                    value={formData.email}
                    name="email"
                    onChange={(val) => {
                      handleChange(val);
                      errors.email = "";
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                    error={!!errors.email}
                    helperText={errors.email}
                  />
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default CreateProjectGridPage;
