import { Box, SelectChangeEvent, Typography } from "@mui/material";
import Toolbar from "../../component/Toolbar";
import SimpleDialog from "../../component/dialog/SimpleDialog";
import { LocalizedText } from "../../di/LanguageRepositoryProvider";
import { useState } from "react";
import useAndroidBackHandler from "../../customHook/useAndroidBackHandler";
import VerticalSpacer from "../../component/Spacer";
import { fontStyle } from "../../theme/Style";
import EditPhoneNumberField from "./component/EditPhoneNumberField";
import CommonButton from "../../component/CommonButton";
import { color } from "../../theme/Color";
import { useNavigate } from "react-router-dom";

const LoginPhoneAuth = () => {
  const navigate = useNavigate();
  const [authButtonClick, setAuthButtonClick] = useState(false);
  const [localNumberError, setLocalNumberError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [localNumber, setLocalNumber] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const handleLocaleNumberChange = (event: SelectChangeEvent) => {
    setLocalNumber(event.target.value);
  };
  const handlePhoneNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setPhoneNumber(value);
  };
  const [dialogOpen, setDialogOpen] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(3 * 60 * 1000); // 3분을 밀리초로 설정

  useAndroidBackHandler(() => {
    if (dialogOpen == true) {
      setDialogOpen(false);
    } else {
      setDialogOpen(true);
    }
  }, [dialogOpen]);
  const [authCode, setAuthCode] = useState("");
  const [authCodeError, setAuthCodeError] = useState("");

  const [codeErrorDialogOpen, setCodeErrorDialogOpen] = useState(false);
  const [timeOverDialogOpen, setTimeOverDialogOpen] = useState(false);
  return (
    <>
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          overflowY: "scroll",
          scrollbarWidth: "none", // Firefox에서 스크롤바 숨기기
          "&::-webkit-scrollbar": {
            display: "none", // Webkit 브라우저에서    스크롤바 숨기기
          },
        }}
      >
        {/* 상단 toolbar */}
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Toolbar
            title=""
            onBack={() => {
              setDialogOpen(true);
            }}
          />
        </Box>
        <VerticalSpacer height={20} />
        <Box
          sx={{
            width: "calc(100% - 40px)",
            height: "calc(100vh - 40px)",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography fontStyle={fontStyle.headingS}>
            {LocalizedText("ua_b123_phonenumber")}
          </Typography>
          <VerticalSpacer height={16} />
          <EditPhoneNumberField
            type="Phone"
            authCode={authCode}
            setAuthCode={setAuthCode}
            authCodeError={authCodeError}
            timeRemaining={timeRemaining}
            setTimeRemaining={setTimeRemaining}
            localNumberError={localNumberError}
            setLocalNumberError={setLocalNumberError}
            phoneNumberError={phoneNumberError}
            setPhoneNumberError={setPhoneNumberError}
            localNumber={localNumber}
            phoneNumber={phoneNumber}
            handleLocaleNumberChange={handleLocaleNumberChange}
            handlePhoneNumberChange={handlePhoneNumberChange}
            authButtonClick={authButtonClick}
            setAuthButtonClick={setAuthButtonClick}
          />
        </Box>
        {/* 하단 버튼 */}
        <Box
          sx={{
            position: "absolute",
            bottom: "0px",
            width: "100%",
            display: "flex",
          }}
        >
          <CommonButton
            title={LocalizedText("ua_b120_next_button")}
            textColor="white"
            // buttonColor="white"
            onClick={() => {
              if (localNumber == "") {
                setLocalNumberError("error");
              }
              if (phoneNumber == "") {
                setPhoneNumberError("error");
              }

              if (localNumber != "" || phoneNumber != "") {
                if (localNumber != "") {
                  setLocalNumberError("noError");
                }
                if (phoneNumber != "") {
                  setPhoneNumberError("noError");
                }
                if (localNumber != "" && phoneNumber != "") {
                  if (authButtonClick == true) {
                    if (authCode == "") {
                      setAuthCodeError("error");
                    } else {
                      setAuthCodeError("");
                      if (timeRemaining == 0) {
                        setTimeOverDialogOpen(true);
                      } else {
                        if (authCode == "1234asdf") {
                          navigate("/LoginDetailInfo");
                        } else {
                          setCodeErrorDialogOpen(true);
                        }
                      }
                    }
                  }
                }
              }

              //
            }}
            sx={{
              mx: "20px",
              mb: "14px",
              // border: 1,
              // borderColor: `${color.gray700}`,
              bgcolor: color.primary500,
            }}
          />
        </Box>
      </Box>
      <SimpleDialog
        children={LocalizedText("common_alert_goprevious")}
        isOpen={dialogOpen}
        positiveText="OK"
        onPositive={() => {
          window.history.back();
        }}
        onNagative={() => {
          setDialogOpen(false);
        }}
        onClose={() => {
          setDialogOpen(false);
        }}
      />
      <SimpleDialog
        children={LocalizedText("ua_b123_alert_checkcode")}
        isOpen={codeErrorDialogOpen}
        positiveText="OK"
        onPositive={() => {
          setCodeErrorDialogOpen(false);
        }}
        onClose={() => {
          setCodeErrorDialogOpen(false);
        }}
      />
      <SimpleDialog
        children={LocalizedText("ua_b123_newcode")}
        isOpen={timeOverDialogOpen}
        positiveText="OK"
        onPositive={() => {
          setTimeOverDialogOpen(false);
        }}
        onClose={() => {
          setTimeOverDialogOpen(false);
        }}
      />
    </>
  );
};

export default LoginPhoneAuth;
