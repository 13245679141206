import React, { useState, useRef, useEffect } from "react";
import { SelectChangeEvent } from "@mui/material/Select";
import { ThemeProvider } from "@mui/material/styles";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import {
  Box,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  Typography,
  FormHelperText
} from "@mui/material";
import theme from "../themes/theme";
import { MethodologyType } from "./enum";
import { downloadLink } from "../../../utils/FileUtil";
import { ADMIN_CONFIG } from "../../../constants/AdminConfig";

interface FormData {
  version: string;
  standard: MethodologyType;
  title: string;
  methodologyIdName: string;
  startDate: string;
  createdAt: number;
  area: MethodologyType;
  subArea: MethodologyType;
  expiredYear: string;
  id: number;
  methodologyFile?: File | string;
}

interface CreateMethodologyGridProps {
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
  errors: { [key: string]: string };
  handleSelect: (
    field: "standard" | "area" | "subArea",
    value: MethodologyType
  ) => void;
}

const CreateMethodologyGrid: React.FC<CreateMethodologyGridProps> = ({
  formData,
  setFormData,
  errors,
}) => {
  const [fileName, setFileName] = useState<string>("");

  useEffect(() => {
    if (formData.methodologyFile) {
      if (typeof formData.methodologyFile === "string") {
        const fileNameFromUrl =
          formData.methodologyFile.split("/").pop() || "Unknown File";
        setFileName(fileNameFromUrl);
      } else {
        setFileName(formData.methodologyFile.name);
      }
    }
  }, [formData.methodologyFile]);

  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleUploadClick = () => {
    const fileInput = document.getElementById("fileInput") as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      setFileName(file.name);
      console.log("FILE NAME: ", file.name);
      setFormData((prevData) => ({
        ...prevData,
        methodologyFile: file,
      }));
    }
  };

  const handleSelectChange = (
    event: SelectChangeEvent<MethodologyType>,
    name: string
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: event.target.value as MethodologyType,
    }));
  };

  const textFieldStyles = {
    width: "480px",
    height: "48px",
    minHeight: "48px",
    gap: "5px",
    borderRadius: "10px 0px 0px 0px",
    opacity: 1,
    marginLeft: "6px",
  };

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
        gap: 2,
        width: "100%",
        boxSizing: "border-box",
        borderRadius: "10px",
      }}
    >
      <Box
        sx={{
          bgcolor: "background.paper",
          borderRadius: 3,
          p: 3,
          display: "flex",
          flexDirection: "column",
          gap: 1,
          boxShadow: 3,
          paddingBottom: 4,
        }}
      >
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            fontWeight: "bold",
            gap: 2,
          }}
        >
          {ADMIN_CONFIG.PAGE_METHODOLOGY.INFORMATION}
        </Typography>

        <InputLabel
          htmlFor="version"
          sx={{
            fontWeight: "bold",
            fontSize: "12px",
            padding: 1,
            paddingBottom: 0,
          }}
        >
          {ADMIN_CONFIG.PAGE_METHODOLOGY.VERSION}
        </InputLabel>
        <TextField
          sx={{ textFieldStyles, margin: "5px" }}
          name="version"
          value={formData.version}
          onChange={handleTextFieldChange}
          InputProps={{
            sx: {
              borderRadius: 2,
              height: "45px",
              padding: 0,
              m: 0,
            },
          }}
          error={!!errors.version}
          helperText={errors.version}
        />
        <InputLabel
          htmlFor="title"
          sx={{
            marginTop: 1,
            fontWeight: "bold",
            fontSize: "12px",
            p: 1,
            paddingBottom: 0,
          }}
        >
          {ADMIN_CONFIG.PAGE_METHODOLOGY.NAME}
        </InputLabel>
        <TextField
          sx={{ textFieldStyles, margin: "5px" }}
          name="title"
          value={formData.title}
          onChange={handleTextFieldChange}
          InputProps={{
            sx: {
              borderRadius: 2,
              height: "45px",
            },
          }}
          error={!!errors.title}
          helperText={errors.title}
        />
        <InputLabel
          htmlFor="methodologyIdName"
          sx={{
            fontWeight: "bold",
            fontSize: "12px",
            p: 1,
            paddingBottom: 0,
          }}
        >
          {ADMIN_CONFIG.PAGE_METHODOLOGY.ID}
        </InputLabel>
        <TextField
          sx={{ textFieldStyles, margin: "5px" }}
          name="methodologyIdName"
          value={formData.methodologyIdName}
          onChange={handleTextFieldChange}
          InputProps={{
            sx: {
              borderRadius: 2,
              height: "45px",
              width: "480px",
            },
          }}
          error={!!errors.methodologyIdName}
          helperText={errors.methodologyIdName}
        />

        <InputLabel
          htmlFor="standard"
          sx={{
            fontWeight: "bold",
            fontSize: "12px",
            p: 1,
            paddingBottom: 0,
          }}
        >
          {ADMIN_CONFIG.PAGE_METHODOLOGY.CRITERIA}
        </InputLabel>
        <FormControl fullWidth error={!!errors.standard}>
          <Select
            name="standard"
            value={formData.standard}
            onChange={(event) => handleSelectChange(event, "standard")}
            IconComponent={(props) => (
              <KeyboardArrowDown {...props} sx={{ fontSize: "16px" }} />
            )}
            sx={{
              borderRadius: 2,
              marginLeft: 1,
              height: "45px",
              width: "480px",
              fontSize: "12px",
              p: 1,
            }}
          >
            <MenuItem
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "18px",
              }}
              value={MethodologyType.DOMESTIC}
            >
              국내 방법론
            </MenuItem>
            <MenuItem
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "18px",
              }}
              value={MethodologyType.INTERNATIONAL}
            >
              국외 방법론
            </MenuItem>
            <MenuItem
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "18px",
              }}
              value={MethodologyType.CDM}
            >
              CDM
            </MenuItem>
          </Select>
          <FormHelperText>{errors.standard}</FormHelperText>
        </FormControl>

        <InputLabel
          htmlFor="area"
          sx={{
            fontWeight: "bold",
            fontSize: "12px",
            p: 1,
            paddingBottom: 0,
          }}
        >
          {ADMIN_CONFIG.PAGE_METHODOLOGY.FIELD}
        </InputLabel>
        <FormControl fullWidth error={!!errors.area}>
          <Select
            name="area"
            value={formData.area}
            IconComponent={(props) => (
              <KeyboardArrowDown {...props} sx={{ fontSize: "16px" }} />
            )}
            onChange={(event) => handleSelectChange(event, "area")}
            sx={{
              borderRadius: 2,
              height: "45px",
              width: "480px",
              fontSize: "12px",
              fontWeight: "400",
              lineHeight: "18px",
              marginLeft: "7px",
            }}
          >
            <MenuItem
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "18px",
              }}
              value={MethodologyType.REMOVAL}
            >
              감축
            </MenuItem>

            <MenuItem
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "18px",
              }}
              value={MethodologyType.REDUCTION}
            >
              제거
            </MenuItem>
          </Select>
          <FormHelperText>{errors.area}</FormHelperText>
        </FormControl>

        <InputLabel
          htmlFor="subArea"
          sx={{
            fontWeight: "bold",
            fontSize: "12px",
            p: 1,
            paddingBottom: 0,
          }}
        >
          {ADMIN_CONFIG.PAGE_METHODOLOGY.DETAILED_FIELD}
        </InputLabel>
        <FormControl fullWidth error={!!errors.subArea}>
          <Select
            name="subArea"
            value={formData.subArea}
            onChange={(event) => handleSelectChange(event, "subArea")}
            IconComponent={(props) => (
              <KeyboardArrowDown {...props} sx={{ fontSize: "16px" }} />
            )}
            sx={{
              borderRadius: 2,
              height: "45px",
              marginLeft: "7px",
              width: "480px",
            }}
          >
            <MenuItem
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "18px",
              }}
              value={MethodologyType.FORESTRY}
            >
              산림
            </MenuItem>
            <MenuItem
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "18px",
              }}
              value={MethodologyType.SOIL_CARBON}
            >
              토양탄소
            </MenuItem>
            <MenuItem
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "18px",
              }}
              value={MethodologyType.DAC}
            >
              DAC
            </MenuItem>
            <MenuItem
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "18px",
              }}
              value={MethodologyType.RENEWABLE_ENERGY}
            >
              재생에너지
            </MenuItem>
            <MenuItem
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "18px",
              }}
              value={MethodologyType.E_MOBILITY}
            >
              e-mobility
            </MenuItem>
            <MenuItem
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "18px",
              }}
              value={MethodologyType.RECYCLE}
            >
              재활용
            </MenuItem>
            <MenuItem
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "18px",
              }}
              value={MethodologyType.REPLACE_ELEMENT}
            >
              대체물질
            </MenuItem>
            <MenuItem
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "18px",
              }}
              value={MethodologyType.ENERGY_CONVERSION}
            >
              에너지전환
            </MenuItem>

            <MenuItem
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "18px",
              }}
              value={MethodologyType.CHEMICAL}
            >
              화학물질
            </MenuItem>
            <MenuItem
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "18px",
              }}
              value={MethodologyType.AGRICULTURE}
            >
              농업
            </MenuItem>
            <MenuItem
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "18px",
              }}
              value={MethodologyType.CCS}
            >
              CCS
            </MenuItem>
            <MenuItem
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "18px",
              }}
              value={MethodologyType.SECONDHAND}
            >
              중고거리
            </MenuItem>
            <MenuItem
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "18px",
              }}
              value={MethodologyType.REPLACE_VEHICLE}
            >
              이동수단 대체
            </MenuItem>
            <MenuItem
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "18px",
              }}
              value={MethodologyType.SERVICE}
            >
              서비스
            </MenuItem>
            <MenuItem
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "18px",
              }}
              value={MethodologyType.ETC}
            >
              기타
            </MenuItem>
          </Select>
          <FormHelperText>{errors.subArea}</FormHelperText>
        </FormControl>

        <InputLabel
          htmlFor="startDate"
          sx={{
            fontWeight: "bold",
            fontSize: "12px",
            p: 1,
          }}
        >
          {ADMIN_CONFIG.PAGE_METHODOLOGY.EXPIRATION_START_DATE}
        </InputLabel>
        <TextField
          fullWidth
          type="date"
          name="startDate"
          value={formData.startDate}
          onChange={handleTextFieldChange}
          sx={{
            "& .MuiInputBase-root": {
              borderRadius: 2,
              height: "45px",
            },
            marginLeft: "7px",
            "& .MuiInputBase-input": {
              padding: "10px",
            },
            width: "480px",
          }}
          error={!!errors.startDate}
          helperText={errors.startDate}
        />
        <InputLabel
          htmlFor="expiredYear"
          sx={{
            fontWeight: "bold",
            fontSize: "12px",
            p: 1,
          }}
        >
          {ADMIN_CONFIG.PAGE_METHODOLOGY.EXPIRATION_YEAR}
        </InputLabel>
        <TextField
          fullWidth
          name="expiredYear"
          value={formData.expiredYear}
          onChange={handleTextFieldChange}
          sx={{
            "& .MuiInputBase-root": {
              borderRadius: 2,
              height: "45px",
            },
            marginLeft: "7px",
            width: "480px",
            "& .MuiInputBase-input": {
              padding: "10px",
            },
          }}
          error={!!errors.expiredYear}
          helperText={errors.expiredYear}
        />
      </Box>
      <Box
        sx={{
          bgcolor: "background.paper",
          boxShadow: 3,
          borderRadius: 3,
          p: 3,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            fontWeight: "bold",
          }}
        >
          {ADMIN_CONFIG.PAGE_METHODOLOGY.ATTACHMENT}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <InputLabel
            sx={{
              fontWeight: "bold",
              fontSize: "12px",
              margin: 0,
            }}
          >
            {ADMIN_CONFIG.PAGE_METHODOLOGY.METHODOLOGICAL_FILE}
          </InputLabel>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            {formData.methodologyFile && (
              <Typography
                component="span"
                onClick={() => {
                  setFormData((prev) => ({
                    ...prev,
                    methodologyFile: "",
                  }));
                  setFileName("");
                }}
                sx={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  textDecoration: "underline",
                  cursor: "pointer",
                  "&:hover": {
                    color: "primary.dark",
                  },
                }}
              >
                Delete File
              </Typography>
            )}
            <Typography
              component="span"
              onClick={handleUploadClick}
              sx={{
                fontWeight: "bold",
                fontSize: "12px",
                textDecoration: "underline",
                cursor: "pointer",
                "&:hover": {
                  color: "primary.dark",
                },
              }}
            >
              Upload File
            </Typography>
          </Box>
        </Box>
        <input
          type="file"
          id="fileInput"
          name="uploadedFile"
          onChange={handleFileChange}
          style={{ display: "none" }}
        />

        <TextField
          fullWidth
          className="methodologyFile"
          variant="outlined"
          value={fileName}
          InputProps={{
            readOnly: true,
          }}
          onChange={handleTextFieldChange}
          onClick={() => {
            // readOnly
            if (
              formData.methodologyFile &&
              typeof formData.methodologyFile === "string"
            ) {
              downloadLink(formData.methodologyFile);
            }
          }}
          sx={{
            "& .MuiInputBase-root": {
              borderRadius: 2,
              height: "45px",
              cursor: "pointer",
            },
            "& .MuiInputBase-input": {
              cursor: "pointer",
            },
          }}
          aria-readonly
        />
        {errors.uploadedFile && (
          <FormHelperText error>{errors.uploadedFile}</FormHelperText>
        )}
      </Box>
    </Box>
  );
};

export default CreateMethodologyGrid;
