import Toolbar from "../../../component/Toolbar";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";
import { Text } from "../../../component/Text";
import VerticalSpacer from "../../../component/Spacer";
import { Box } from "@mui/material";
import CommonButton from "../../../component/CommonButton";
import { HorizontalGrayDivider } from "../../../component/Divider";
import CategorySelect from "./section/CategorySelect";
import ImageUpload from "../../../component/ImageUpload";
import { useEffect } from "react";
import { InquiryRegistrationSideEffect } from "./model/InquiryRegistrationSideEffect";
import { InquiryRegistrationUiState } from "./model/InquiryRegistrationUiState";
import { InquiryRegistrationUiEvent } from "./model/InquiryRegistrationUiEvent";
import CommonMultiLineTextField from "../../../component/CommonMultiLineTextField";
import CommonSingleLineTextField from "../../../component/CommonSingleLineTextField";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import ProgressBar from "../../../component/ProgressBar";
import SimpleDialog from "../../../component/dialog/SimpleDialog";

function addImageUrl(url: string): void {
  const event = new CustomEvent("addImageUrl", { detail: url });
  window.dispatchEvent(event);
}

(window as any).addImageUrl = addImageUrl;

interface InquiryRegistrationRenderProps {
  uiState: InquiryRegistrationUiState;
  uiEvent: InquiryRegistrationUiEvent;
  sideEffect: InquiryRegistrationSideEffect;
  progress: boolean;
}

export default function InquiryRegistrationRender({
  uiState,
  uiEvent,
  sideEffect,
  progress,
}: InquiryRegistrationRenderProps) {
  useEffect(() => {
    const handleAppToWeb = (event: Event) => {
      const customEvent = event as CustomEvent<string>;
      const imageUrl = customEvent.detail;
      uiEvent.addImageUrl(imageUrl);
    };

    window.addEventListener("addImageUrl", handleAppToWeb);
    return () => {
      window.removeEventListener("addImageUrl", handleAppToWeb);
    };
  }, []);

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {progress && <ProgressBar />}
      <Toolbar
        title={LocalizedText("ua_f210_inquiry")}
        onBack={() => {
          uiEvent.onBack();
        }}
      />
      <Box
        sx={{
          overflowY: "scroll",
          px: "20px",
        }}
      >
        <VerticalSpacer height={16} />
        <Text
          sx={{
            fontStyle: fontStyle.titleS,
            color: color.default,
          }}
          text={LocalizedText("ua_f211_category")}
        />
        <VerticalSpacer height={4} />
        <CategorySelect
          currentCategory={uiState.categoryItems.find((it) => it.isSelected)}
          categoryList={uiState.categoryItems}
          selectValueError={uiState.categoryItemsError}
          onChange={(it) => {
            uiEvent.onSelectCategory(it);
          }}
        />
        <VerticalSpacer height={16} />
        <HorizontalGrayDivider />
        <VerticalSpacer height={16} />
        <Text
          sx={{
            fontStyle: fontStyle.titleS,
            color: color.default,
          }}
          text={LocalizedText("ua_f211_inquiry")}
        />
        <VerticalSpacer height={4} />
        <CommonSingleLineTextField
          inputValue={uiState.inputTitle}
          inputValueError={uiState.inputTitleError}
          placeholder={LocalizedText("ua_f211_briefly")}
          heightPx={38}
          onChange={(value) => {
            uiEvent.onChangeTitleTextValue(value);
          }}
          onClear={uiEvent.onClearTitleText}
        />
        <VerticalSpacer height={8} />
        <CommonMultiLineTextField
          inputValue={uiState.inputContent}
          inputValueError={uiState.inputContentError}
          onChange={(value) => {
            uiEvent.onChangeContentTextValue(value);
          }}
          placeholder={LocalizedText("ua_f211_detail")}
          maxLength={uiState.contentMaxLength}
          onClear={() => {
            uiEvent.onClearContentText();
          }}
          heightPx={180}
        />

        <VerticalSpacer height={42} />
        <Text
          sx={{
            fontStyle: fontStyle.titleS,
            color: color.default,
          }}
          text={LocalizedText("ua_f211_attachfile")}
        />
        <VerticalSpacer height={4} />
        <ImageUpload
          imageUrls={uiState.uploadedImageUrls}
          maxImages={5}
          maxFileSizeMB={10}
          onClickAddImage={function (): void {
            sideEffect.openCamera();
          }}
          removeImage={uiEvent.removeImageUrl}
        />
        <VerticalSpacer height={8} />
        <Text
          sx={{
            fontStyle: fontStyle.bodySr,
            color: color.gray500,
            whiteSpace: "pre-line",
          }}
          text={LocalizedText("ua_f211_attachmb")}
        />
        <VerticalSpacer height={56 + 32} />
      </Box>

      <Box
        sx={{
          position: "fixed",
          bottom: "20px",
          width: "calc(100% - 40px)",
          left: "20px",
          zIndex: 10, // 다른 요소 위로 올라오도록 설정
        }}
      >
        <CommonButton
          onClick={() => {
            uiEvent.onClickSubmitButton();
          }}
          title={LocalizedText("ua_f211_submit_button")}
        />
      </Box>
      <SimpleDialog
        children={LocalizedText("common_alert_goprevious")}
        isOpen={uiState.isBackDialog}
        positiveText={LocalizedText("common_alert_confirm_button")}
        nagativeText={LocalizedText("common_alert_cancel_button")}
        onPositive={() => {
          uiEvent.onClickConfirmExitPageDialog();
        }}
        onNagative={() => {
          uiEvent.onClickCancelExitPageDialog();
        }}
        onClose={() => {
          uiEvent.onClickCancelExitPageDialog();
        }}
      />

      <SimpleDialog
        children={LocalizedText("ua_f211_alert_registered_message")}
        isOpen={uiState.isSubmitDialog}
        positiveText={LocalizedText("common_alert_confirm_button")}
        disableBackdropClose={true}
        onPositive={() => {
          uiEvent.onClickConfirmSubmitDialog();
        }}
        onClose={() => {
          uiEvent.onClickCancelSubmitDialog();
        }}
      />
    </Box>
  );
}
