import { useEffect, useState } from "react";
import CommonList from "../../component/CommonList";
import {
  InventoryManagementTab,
  InventoryManagementTabType,
} from "./section/InventoryManagementTab";
import { Inventory } from "./inventory/Inventory";
import { ReceiveDeliver } from "./receive-deliver/ReceiveDeliver";
import { CommonWebHeaderButton } from "../../component/CommonWebHeaderButton";
import { useLocation, useNavigate } from "react-router-dom";

const InventoryManagement = () => {
  const [currentTab, setCurrentTab] = useState<InventoryManagementTabType>(
    InventoryManagementTabType.INVENTORY
  );
  const navigate = useNavigate();
  const location = useLocation();

  const onClick = () => {
    navigate("/receivedeliverregistration");
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tabParam = params.get("tab");

    switch (tabParam) {
      case InventoryManagementTabType.INVENTORY:
        setCurrentTab(InventoryManagementTabType.INVENTORY);
        break;
      case InventoryManagementTabType.RECEIVE_DELEVER:
        setCurrentTab(InventoryManagementTabType.RECEIVE_DELEVER);
        break;
    }
  }, [location.search]);
  const handleChangeTab = (tab: InventoryManagementTabType) => {
    setCurrentTab(tab);
    navigate(`/inventoryManagement?tab=${tab}`);
  };
  return (
    <CommonList
      title="Inventory"
      headerButton={
        currentTab == InventoryManagementTabType.RECEIVE_DELEVER && (
          <CommonWebHeaderButton
            width="122px"
            height="45px"
            buttonContent="Register"
            addIcon={true}
            onClick={onClick}
          />
        )
      }
      tabButton={
        <InventoryManagementTab
          currentTab={currentTab}
          changeTab={handleChangeTab}
        />
      }
    >
      {currentTab == InventoryManagementTabType.INVENTORY && <Inventory />}
      {currentTab == InventoryManagementTabType.RECEIVE_DELEVER && (
        <ReceiveDeliver />
      )}
    </CommonList>
  );
};

export default InventoryManagement;
