import {
  Box,
  Button,
  Divider,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import VerticalSpacer, { HorizontalSpacer } from "../../component/Spacer";
import { fontStyle } from "../../theme/Style";
import { color } from "../../theme/Color";
import { useEffect, useState } from "react";
import CommonList from "../../component/CommonList";
import { ICON_SHOW_WHITE } from "../../constants/imagePath";
import CommonSearch from "../../component/CommonSearch";
import PopupMngGrid from "./section/PopupMngGrid";
import { ApiService } from "../../restAPI/ApiService";

const PopupMngPage = () => {
  const [rows, setRows] = useState([]); // 받아온 데이터
  const [tabVelue, setTabVelue] = useState("popupMng");
  // 검색어 관련 변수
  const [searchContents, setSearchContents] = useState("");
  // 국가관련 변수
  const [countryList, setCountryList] = useState([]);
  const [country, setCountry] = useState("Country");
  const [countryCode, setCountryCode] = useState("");
  const handleCountryChange = (event: SelectChangeEvent) => {
    setCountry(event.target.value[0] as string);
    setCountryCode(event.target.value[1] as string);
  };
  // 상태 관련 변수
  const [status, setStatus] = useState("");
  const handleStatusChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value as string);
  };
  const HeaderButton = () => {
    return (
      <Button
        sx={{
          height: "45px",
          width: "149px",
          backgroundColor: "#5D38E5",
          color: "white",
          borderRadius: "40px",

          ":hover": {
            backgroundColor: "#A38BFC",
          },
        }}
        onClick={() => {
          // setCreateModal(true);
        }}
      >
        {/* <AddIcon fontSize="small" /> */}
        <Box component="img" src={ICON_SHOW_WHITE} />
        Show Status
      </Button>
    );
  };

  const TabButton = () => {
    return (
      <Box sx={{ display: "flex", height: "24px" }}>
        <HorizontalSpacer width={16} />
        <Box
          sx={{
            fontStyle: fontStyle.semiboldM,
            cursor: "pointer",
            color: tabVelue == "popupMng" ? color.primary500 : color.gray600,
          }}
          onClick={() => {
            setTabVelue("popupMng");
          }}
        >
          팝업 관리
        </Box>
        <HorizontalSpacer width={16} />
        <Divider orientation="vertical" />
        <HorizontalSpacer width={16} />
        <Box
          sx={{
            fontStyle: fontStyle.semiboldM,
            cursor: "pointer",
            color: tabVelue == "bannerMng" ? color.primary500 : color.gray600,
          }}
          onClick={() => {
            setTabVelue("bannerMng");
          }}
        >
          배너 관리
        </Box>
        <HorizontalSpacer width={16} />
        <Divider orientation="vertical" />
        <HorizontalSpacer width={16} />
        <Box
          sx={{
            fontStyle: fontStyle.semiboldM,
            cursor: "pointer",
            color: tabVelue == "popupHis" ? color.primary500 : color.gray600,
          }}
          onClick={() => {
            setTabVelue("popupHis");
          }}
        >
          팝업 이력
        </Box>
        <HorizontalSpacer width={16} />
        <Divider orientation="vertical" />
        <HorizontalSpacer width={16} />
        <Box
          sx={{
            fontStyle: fontStyle.semiboldM,
            cursor: "pointer",
            color: tabVelue == "bannerHis" ? color.primary500 : color.gray600,
          }}
          onClick={() => {
            setTabVelue("bannerHis");
          }}
        >
          배너 이력
        </Box>
        <HorizontalSpacer width={16} />
      </Box>
    );
  };

  // 서버에 select 목록들 가져오기
  useEffect(() => {
    ApiService.WebPost("/pw/aw/contents/getCountry").then((res) => {
      setCountryList(res?.data.body);
    });
  }, []);

  // 서버에 filter된 리스트들 가져오기
  useEffect(() => {
    ApiService.WebPost("/pw/aw/contents/popup/filter", {
      country: countryCode,
      searchContents: searchContents,
      state: status,
    }).then((res) => {
      console.log(res?.data.body);
      setRows(res?.data.body);
    });
  }, [tabVelue, searchContents, status, countryCode]);
  return (
    <CommonList
      title="팝업/배너 관리"
      headerButton={tabVelue == "popupMng" && HeaderButton()}
      tabButton={TabButton()}
    >
      {tabVelue == "popupMng" && (
        <CommonSearch
          placeHolder={"Title, Date, URL"}
          setSearchContents={setSearchContents}
        >
          <Box sx={{ display: "flex" }}>
            <Select
              value={country}
              onChange={handleCountryChange}
              renderValue={(v) => {
                return v?.length ? v : `Country`;
              }}
              sx={{
                width: "auto",
                height: "42px",
                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                "&.MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "red",
                  },
                  "&:hover fieldset": {
                    borderColor: "yellow",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "white",
                  },
                },
                bgcolor: "white",
                borderRadius: "10px",
                fontStyle: fontStyle.semiboldXS,
                color: color.gray600,
              }}
            >
              <MenuItem
                value={["Country", ""]}
                sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}
              >
                all
              </MenuItem>
              {countryList.map((data: any, index: any) => {
                return (
                  <MenuItem
                    value={[data.label, data.code]}
                    key={index}
                    sx={{
                      fontStyle: fontStyle.semiboldXS,
                      color: color.gray600,
                    }}
                  >
                    {data.label}
                  </MenuItem>
                );
              })}
            </Select>
            <HorizontalSpacer width={12} />
            <Select
              value={status}
              onChange={handleStatusChange}
              displayEmpty
              renderValue={(v) => (v?.length ? v : `Status`)}
              sx={{
                width: "auto",
                height: "42px",
                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                "&.MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "red",
                  },
                  "&:hover fieldset": {
                    borderColor: "yellow",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "white",
                  },
                },
                bgcolor: "white",
                borderRadius: "10px",
                fontStyle: fontStyle.semiboldXS,
                color: color.gray600,
              }}
            >
              <MenuItem
                value={""}
                sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}
              >
                All
              </MenuItem>
              <MenuItem
                value={"Show"}
                sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}
              >
                Show
              </MenuItem>
              <MenuItem
                value={"Period"}
                sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}
              >
                Period
              </MenuItem>
              <MenuItem
                value={"Hide"}
                sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}
              >
                Hide
              </MenuItem>
            </Select>
          </Box>
        </CommonSearch>
      )}
      {tabVelue == "bannerMng" && (
        <CommonSearch
          placeHolder={"Search"}
          setSearchContents={setSearchContents}
        >
          {null}
        </CommonSearch>
      )}
      {tabVelue == "popupHis" && (
        <CommonSearch
          placeHolder={"Title, Date, URL"}
          setSearchContents={setSearchContents}
        >
          {null}
        </CommonSearch>
      )}
      {tabVelue == "bannerHis" && (
        <CommonSearch
          placeHolder={"Title, Date, URL"}
          setSearchContents={setSearchContents}
        >
          {null}
        </CommonSearch>
      )}

      <VerticalSpacer height={16} />
      <PopupMngGrid rows={rows} countryLists={countryList} />
    </CommonList>
  );
};

export default PopupMngPage;
