import { Box, TableRow } from "@mui/material";
import { RegionManagementTableCell } from "./RegionManagementTableCell";
import { Text } from "../../../../component/Text";
import { fontStyle } from "../../../../theme/Style";
import { color } from "../../../../theme/Color";
import VerticalSpacer from "../../../../component/Spacer";
import { RegionManagementServiceType } from "../../model/RegionManagementData";
import { ServiceBadge } from "../ServiceBadge";
import LocalizedDate, {
  DateFormatType,
} from "../../../../component/LocalizedDate";

interface RegionManagementTableRowProps {
  row?: any;
}

export function RegionManagementTableRow({
  row,
}: RegionManagementTableRowProps) {
  return (
    <>
      <TableRow>
        <RegionManagementTableCell
          sx={{ paddingLeft: "24px" }}
          childrenContainerSx={{ maxWidth: "36px" }}
          children={
            <Text
              sx={{
                fontStyle: fontStyle.regularXS,
                color: color.gray800,
              }}
              text={row.no + 1} //백에서 넘버를 0부터 던져줌 고로 +1 해줘야함
            />
          }
          align={"center"}
        />
        <RegionManagementTableCell
          sx={{ whiteSpace: "pre-line" }}
          childrenContainerSx={{ width: "112px" }}
          children={
            <Text
              sx={{
                fontStyle: fontStyle.regularXS,
                color: color.gray800,
              }}
              text={row.svcAreaNm}
            />
          }
          align={"left"}
        />
        <RegionManagementTableCell
          sx={{ whiteSpace: "pre-line" }}
          childrenContainerSx={{ width: "167px" }}
          children={
            <Text
              sx={{
                fontStyle: fontStyle.regularXS,
                color: color.gray800,
                whiteSpace: "pre-line",
              }}
              text={row.location}
            />
          }
          align={"left"}
        />
        <RegionManagementTableCell
          sx={{ whiteSpace: "pre-line" }}
          childrenContainerSx={{ width: "115px" }}
          children={
            <Text
              sx={{
                fontStyle: fontStyle.regularXS,
                color: color.gray800,
                whiteSpace: "pre-line",
              }}
              text={row.province}
            />
          }
          align={"left"}
        />
        <RegionManagementTableCell
          sx={{ whiteSpace: "pre-line" }}
          childrenContainerSx={{ width: "75px" }}
          children={
            <Text
              sx={{
                fontStyle: fontStyle.regularXS,
                color: color.gray800,
              }}
              text={row.ctryCd}
            />
          }
          align={"left"}
        />
        <RegionManagementTableCell
          childrenContainerSx={{
            width: "71px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          children={
            <>
              <VerticalSpacer height={2} />
              <Box>
                {row.service.map(
                  (serviceType: RegionManagementServiceType, index: number) => (
                    <Box key={index}>
                      <VerticalSpacer height={2} />
                      <ServiceBadge key={index} serviceType={serviceType} />
                      <VerticalSpacer height={2} />
                    </Box>
                  )
                )}
                <VerticalSpacer height={2} />
              </Box>
            </>
          }
        />
        <RegionManagementTableCell
          childrenContainerSx={{ width: "120px" }}
          children={
            <Text
              sx={{
                fontStyle: fontStyle.regularXS,
                color: color.gray800,
              }}
              text={LocalizedDate({
                date: row.lastUpdateDate,
                type: DateFormatType.AdminType,
              })}
            />
          }
          align={"left"}
        />
        <RegionManagementTableCell
          childrenContainerSx={{ width: "120px" }}
          children={
            <Text
              sx={{
                fontStyle: fontStyle.regularXS,
                color: color.gray800,
              }}
              text={LocalizedDate({
                date: row.registerDate,
                type: DateFormatType.AdminType,
              })}
            />
          }
          align={"left"}
        />
      </TableRow>
    </>
  );
}
