import CommonTable, { HeadCell, useVisibleRows } from "../../../component/CommonTable";
import { ReceiveDeliverData, ReceiveDeliverDataType } from "./model/RecieveDeliverData";
import { ReceiveDeliverTableRow } from "./RecieveDeliverTableRow";

interface ReceiveDeliverTableProps {
  mngRows: ReceiveDeliverData[];
  headCells?: HeadCell[];
  rowsPerPage?: number;
  setRowsPerPage?: React.Dispatch<React.SetStateAction<number>>;
  page?: number;
  pageCount?: number;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
  docCount?: number;
}

export function ReceiveDeliverTable({
  mngRows,
  headCells,
  rowsPerPage,
  setRowsPerPage,
  page,
  pageCount,
  setPage,
  docCount,
}: ReceiveDeliverTableProps) {
  const { order, orderBy, handleRequestSort, handleChangePage, visibleRows } = useVisibleRows(mngRows, rowsPerPage || 10, setPage, page || 0);
  const pagedata: ReceiveDeliverData[] = visibleRows.slice((page || 0) * (rowsPerPage || 10), ((page || 0) + 1) * (rowsPerPage || 10)).map((row) => {
    return new ReceiveDeliverData(
      row.id as string,
      Number(row.no),
      row.category as string,
      row.partsCode as string,
      row.productName as string,
      row.spec as string,
      row.company as string,
      row.quantities as string,
      row.branch as string,
      Number(row.amount),
      row.status as ReceiveDeliverDataType,
      Number(row.inOutDate)
    );
  });
  return (
    <CommonTable
      headCells={headCells}
      order={order}
      orderBy={orderBy}
      page={page}
      docCount={docCount}
      pageCount={pageCount}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      handleRequestSort={handleRequestSort}
      handleChangePage={handleChangePage}
      setPage={setPage}
    >
      {pagedata.map((data: ReceiveDeliverData) => (
        <ReceiveDeliverTableRow row={data} key={data.no} />
      ))}
    </CommonTable>
  );
}
