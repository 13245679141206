import NotificationPageRender from "./NotificationPageRender";
import { useEffect, useState } from "react";
import { NotificationUiState } from "./model/NotificationUiState";
import useAndroidBackHandler from "../../../customHook/useAndroidBackHandler";

export default function NotificationPage() {
  // 로컬스토리지에서 알림 정보 가져오기, 없으면 기본값 false 설정
  const getNotificationInfo = (key: string): boolean => {
    const result = localStorage.getItem(key);
    if (result === null) {
      localStorage.setItem(key, "false");
      return false;
    }
    return result === "true"; // 문자열 "true"를 boolean true로 변환
  };

  // UI 상태 초기화
  const [uiState, setUiState] = useState(
    new NotificationUiState(
      getNotificationInfo("service_notification"),
      getNotificationInfo("event_notification"),
    ),
  );

  // 안드로이드 백 버튼 처리
  useAndroidBackHandler(() => {
    window.history.back();
  });

  // 클릭 이벤트 발생 시 로컬 스토리지 업데이트 및 UI 상태 변경
  const handleServiceSwitch = () => {
    setUiState((prev) => {
      const newState = {
        ...prev,
        isActivatedServiceNotification: !prev.isActivatedServiceNotification,
      };
      // 로컬 스토리지 값 업데이트
      localStorage.setItem(
        "service_notification",
        String(newState.isActivatedServiceNotification),
      );
      return newState;
    });
  };

  const handleEventSwitch = () => {
    setUiState((prev) => {
      const newState = {
        ...prev,
        isActivatedEventingNotification: !prev.isActivatedEventingNotification,
      };
      // 로컬 스토리지 값 업데이트
      localStorage.setItem(
        "event_notification",
        String(newState.isActivatedEventingNotification),
      );
      return newState;
    });
  };

  // uiState가 변경될 때마다 동작하는 useEffect (uiState 로컬 스토리지 동기화)
  useEffect(() => {
    localStorage.setItem(
      "service_notification",
      String(uiState.isActivatedServiceNotification),
    );
    localStorage.setItem(
      "event_notification",
      String(uiState.isActivatedEventingNotification),
    );
  }, [uiState]);

  return (
    <NotificationPageRender
      uiState={uiState}
      onClickServiceSwitch={handleServiceSwitch} // 서비스 스위치 이벤트 핸들러
      onClickEventSwitch={handleEventSwitch} // 이벤트 스위치 이벤트 핸들러
    />
  );
}
