import { Box, InputBase, SxProps, Theme } from "@mui/material";
import { Text } from "../../../component/Text";
import Paper from "@mui/material/Paper";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import VerticalSpacer from "../../../component/Spacer";

interface LabeledPaperProps {
  label: string;
  values: string[];
  disabled?: boolean;
  onChange?: (values: string[]) => void;
  paperSx?: SxProps<Theme>;
}

export function LabeledPaper({
  label,
  values,
  disabled = true,
  onChange,
  paperSx,
}: LabeledPaperProps) {
  const handleInputChange = (index: number, newValue: string) => {
    if (!onChange) return;
    const updatedValues = [...values];
    updatedValues[index] = newValue;
    onChange(updatedValues);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Text
        sx={{ fontStyle: fontStyle.boldXS, color: color.gray600 }}
        text={label}
      />
      <VerticalSpacer height={8} />
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          alignItems: "center",
          gap: "16px",
        }}
      >
        {values.map((value, index) => (
          <Paper
            key={index}
            sx={{
              display: "flex",
              alignItems: "center",
              flexGrow: 1,
              height: "48px",
              paddingX: "16px",
              border: `1px solid`,
              borderRadius: "10px",
              backgroundColor: color.gray200,
              borderColor: color.gray300,
              ...paperSx,
            }}
            elevation={0}
          >
            <InputBase
              value={value}
              sx={{
                width: "100%",
                fontStyle: fontStyle.regularS,
                color: color.gray600,
              }}
              disabled={disabled}
              onChange={(event) => {
                handleInputChange(index, event.target.value);
              }}
            />
          </Paper>
        ))}
      </Box>
    </Box>
  );
}
