export class BatteryUiState {
  model: string;
  station: string;
  status: string;
  data: BatteryDataType[];
  constructor(
    data: BatteryDataType[] = [],
    model: string = "",
    station: string = "",
    status: string = ""
  ) {
    this.data = data;
    this.model = model;
    this.station = station;
    this.status = status;
  }
}

export type BatteryDataType = {
  packCount: number;
  lastUptDt: number;
  soc: number;
  station: string;
  registerDt: number;
  type: string;
  battSerialNumber: string;
  userD: string;
  status: string;
};
