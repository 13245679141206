import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import {
  Button,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography
} from "@mui/material";
import axios from "axios";
import CommonList from "../../../component/CommonList";
import CommonSearch from "../../../component/CommonSearch";
import { Monitoring } from "../../../store/monitoringType";
import { Project } from "../../../store/projectType";
import MonitoringTable from "../section/MonitoringTable";
import { SERVER_CONFIG } from "../../../constants/ServerConfig";
import { ADMIN_CONFIG } from "../../../constants/AdminConfig";

const MonitoringMngListPage = () => {
  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [menuItems, setMenuItems] = useState<Project[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState<string>("");
  const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [selectedProjectId, setSelectedProjectId] = useState<number | string>("none");
  const [page, setPage] = useState<number>(0);
  const [data, setData] = useState<Monitoring[]>([]);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const API_URL = SERVER_CONFIG.CARBON_SERVER + "/monitoring";
  const API_URL_Project = SERVER_CONFIG.CARBON_SERVER + "/project";

  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    debounceTimeoutRef.current = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 900);

    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, [searchQuery]);

  const fetchData = async (projectId?: number | string) => {
    setLoading(true);
    try {
      const params: Record<string, any> = {
        ...(debouncedSearchQuery && {
          searchValue: debouncedSearchQuery.trim(),
        }),
      };
      const url = projectId
        ? `${API_URL}?projectId=${projectId}&page=${page}&size=${rowsPerPage}`
        : `${API_URL}?page=${page}&size=${rowsPerPage}`;
      const response = await axios.get(url, { params });
      setData(response.data.content);
      setTotalRows(response.data.totalElements);
    } catch (error) {
      console.error("Error fetching monitoring data:", error);
      setError("Error fetching monitoring data.");
    } finally {
      setLoading(false);
    }
  };

  const fetchMenuItems = async () => {
    try {
      const response = await axios.get(API_URL_Project);

      const data: Project[] = Array.isArray(response.data.content)
        ? response.data.content
        : [];

      setMenuItems(data);
      console.log("Processed Data: ", data);
    } catch (error) {
      console.error("Error fetching project data:", error);
      setError("Error fetching project data.");
    }
  };

  useEffect(() => {
    fetchData(selectedProjectId !== "none" ? selectedProjectId : undefined);
    fetchMenuItems();
  }, [page, rowsPerPage, selectedProjectId, debouncedSearchQuery]);

  const handleProjectChange = (event: SelectChangeEvent<number | string>) => {
    const selectedId = event.target.value as number | string;
    setSelectedProjectId(selectedId);
    setPage(0);
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage - 1);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const headerButton = () => (
    <Button
      sx={{
        width: "113px",
        height: "45px",
        borderRadius: "40px",
        padding: "20px, 0px, 20px, 0",
        background: "#5D38E5",
        color: "#FFFFFF",
        textTransform: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textWeight: "600",
        textSize: "14px",
        lineHeight: "20px",
        "&:hover": {
          background: " #5a3acb",
        },
      }}
      onClick={() => navigate("/monitoringMng/monitoringDetails")}
    >
      <AddIcon fontSize="small" />
      Create
    </Button>
  );

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <CommonList
      title={ADMIN_CONFIG.MENU_CARBON_REDUCTION_MONITORING.TITLE}
      headerButton={headerButton()}
    >
      {/* <CommonSearch
        setRowsPerPage={setRowsPerPage}
        setSearchContents={(val: any) => setSearchQuery(val)}
        //         rowsPerPage={rowsPerPage}
        //         isSearch={false}
      > */}
        <Select
          value={selectedProjectId}
          onChange={handleProjectChange}
          size="small"
          IconComponent={(props) => (
            <KeyboardArrowDown {...props} sx={{ fontSize: "16px" }} />
          )}
          sx={{
            width: "82.5%",
            minHeight: "42px",
            borderRadius: "10px",
            backgroundColor: "#fff",
            "& > fieldset": {
              border: 0,
              outline: 0,
            },
          }}
          disabled={menuItems.length === 0}
        >
          <MenuItem value="none">프로젝트 선택</MenuItem>
          {menuItems.length > 0
            ? menuItems.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.title}
                </MenuItem>
              ))
            : null}
        </Select>
      {/* </CommonSearch> */}
      <MonitoringTable
        rowsPerPage={rowsPerPage}
        rows={data}
        page={page}
        totalRows={totalRows}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleRowsPerPageChange}
      />
    </CommonList>
  );
};

export default MonitoringMngListPage;
