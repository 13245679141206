import { Box, LinearProgress, linearProgressClasses, styled, Typography } from "@mui/material";
import VerticalSpacer, { HorizontalSpacer } from "../../../../../../component/Spacer";
import { fontStyle } from "../../../../../../theme/Style";
import { color } from "../../../../../../theme/Color";
import LocalizedDate, { DateFormatType } from "../../../../../../component/LocalizedDate";

interface NFTCardImageProps {
  image?: string;
  fontColor?: string;
  issuedAtDate: number;
}

const NFTCardImage = ({ image, fontColor, issuedAtDate }: NFTCardImageProps) => {
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 15,
    borderRadius: 10,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 10,
      backgroundColor: `${fontColor}`,
    },
  }));
  return (
    <>
      <Box
        sx={{
          mt: "10px",
          display: "flex",
          flexDirection: "column",
          boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)",
          bgcolor: "white",
          borderRadius: "20px",
          // height: "auto",
          px: "20px",
        }}
      >
        <VerticalSpacer height={16} />
        <Box>
          <Box
            component="img"
            src={image}
            sx={{ display: "flex", borderRadius: "10px", width: "100%", height: "auto", objectFit: "contain", bgcolor: "red" }}
          />
        </Box>
        <VerticalSpacer height={18} />
        <Typography fontStyle={fontStyle.headingS} color={fontColor} sx={{ display: "flex", alignItems: "center" }}>
          0
          <HorizontalSpacer width={4} />
          <Typography fontStyle={fontStyle.titleL} color={color.gray700}>
            kgCO₂-eq
          </Typography>
        </Typography>
        <VerticalSpacer height={8} />
        <BorderLinearProgress variant="determinate" value={0} sx={{ height: "8px" }} />
        <VerticalSpacer height={8} />
        <Typography fontStyle={fontStyle.titleS} color={color.gray600} sx={{ display: "flex", alignItems: "center" }}>
          Issue Date
          <HorizontalSpacer width={8} />
          <Typography fontStyle={fontStyle.titleS} color={color.gray700}>
            {issuedAtDate && (
              <>
                {LocalizedDate({
                  date: issuedAtDate,
                  type: DateFormatType.YearMonthDayTime,
                })}
              </>
            )}
          </Typography>
        </Typography>
        <VerticalSpacer height={14} />
      </Box>
    </>
  );
};

export default NFTCardImage;
