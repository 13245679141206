import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  IconButton,
  Typography,
} from "@mui/material";
import CommonList from "../../component/CommonList";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useEffect, useState } from "react";
import AnnounceDetailsGrid, {
  AnnounceRow,
} from "./section/AnnounceDetailsGrid";
import CommonDialog from "../../component/CommonDialog";
import { CommonWebHeaderButton } from "../../component/CommonWebHeaderButton";
import { HorizontalSpacer } from "../../component/Spacer";
import { ICON_TRASH } from "../../constants/imagePath";
import { color } from "../../theme/Color";
import { AnnounceCreateUiEvent } from "./model/AnnounceCreateUiEvent";
import { AnnounceCreateUiState } from "./model/AnnounceCreateUiState";
import { ApiService } from "../../restAPI/ApiService";
import { AnnouncementResponse } from "./model/AnnouncementResponse";
import axios from "axios";
import { fontStyle } from "../../theme/Style";

const AnnouncementDetailsPage = () => {
  const navigate = useNavigate();

  const [uiState, setUiState] = useState(new AnnounceCreateUiState());
  const [rows, setRows] = useState<AnnounceRow | null>(null);

  // URL에서 마지막 부분을 추출
  const location = useLocation();
  const pathname = location.pathname;
  const id = pathname.split("/").pop();

  useEffect(() => {
    console.log("id : ", id, "uuid : ", localStorage.getItem("user_uuid"));
    ApiService.WebPost("/pw/aw/contents/announce/detail", {
      id: id,
      uuid: localStorage.getItem("user_uuid"),
    }).then((res) => {
      setRows(res?.data.body);
      setFileUrls(res?.data.body.imageUrls || []);
      console.log("불러온 데이터 : ", res?.data.body);
    });
  }, [id]);

  useEffect(() => {
    if (rows) {
      setCreateData({
        title: rows.title,
        context: rows.content,
        date: rows.openDate,
        time: rows.updateDate,
        showHide: rows.showHide,
        country: rows.country,
      });
      console.log("createData : ", createData);
    }
  }, [rows]);

  const [createData, setCreateData] = useState({
    title: rows?.title || "",
    context: rows?.content || "",
    date: rows?.openDate || 0,
    time: rows?.updateDate || 0,
    showHide: rows?.showHide || false,
    country: rows?.country || "",
  });

  const [inValidDataCheck, setInValidDataCheck] = useState({
    title: false,
    context: false,
    date: false,
    time: false,
    showHide: false,
    country: false,
  });

  const [fileUrls, setFileUrls] = useState<string[]>([]);

  const countryCodeMap: { [key: string]: string } = {
    "South Korea": "KR",
    Cambodia: "KH",
    Vietnam: "VN",
  };

  const handleDetailDataChange = (
    key: keyof typeof createData,
    value: string | number | boolean
  ) => {
    const countryNameToCode: { [key: string]: string } = {
      "South Korea": "KR",
      Cambodia: "KH",
      Vietnam: "VN",
    };

    const transformedValue =
      key === "country" && typeof value === "string"
        ? countryNameToCode[value] || value // 매핑된 코드가 없으면 원래 값을 사용
        : value;
    setCreateData(
      (prevData) =>
        ({
          ...prevData,
          [key]: transformedValue,
        } as {
          title: string;
          context: string;
          date: number;
          time: number;
          showHide: boolean;
          country: string;
        })
    );
  };

  const handleInValidDataCheckChange = (
    key: keyof typeof createData,
    value: boolean
  ) => {
    setInValidDataCheck((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const validateDetailData = () => {
    Object.keys(inValidDataCheck).forEach((key) => {
      if (key === "title") {
        handleInValidDataCheckChange(
          key,
          typeof createData[key] === "string" &&
            (createData[key] as string).length === 0
        );
      } else if (key === "context") {
        handleInValidDataCheckChange(
          key,
          typeof createData[key] === "string" &&
            (createData[key] as string).length === 0
        );
      } else if (key === "date") {
        handleInValidDataCheckChange(key, createData[key] === 0);
        // } else if (key === "time") {
        //   handleInValidDataCheckChange(key, createData[key] === 0);
      }
    });
  };

  const uiEvent: AnnounceCreateUiEvent = {
    HandleUiStateChange: (key: keyof AnnounceCreateUiState, value: any) => {
      setUiState((prevState) => ({
        ...prevState,
        [key]: value, // key에 해당하는 상태를 업데이트
      }));
    },
  };
  const HeaderBackButton = () => {
    return (
      <IconButton
        sx={{
          ":hover": { backgroundColor: "#F2F2F2" },
          mb: 0.5,
          mr: 1,
        }}
        onClick={() => {
          handleBackOpen();
        }}
      >
        <ArrowBackIosNewIcon fontSize="medium" />
      </IconButton>
    );
  };

  const [open, setOpen] = useState(false);

  const handleBackOpen = () => {
    setOpen(true);
  };
  const handleBackClose = () => {
    setOpen(false);
  };
  const handleBackOk = () => {
    navigate("/announcement");
    window.location.reload();
  };

  const [updateOpen, setUpdateOpen] = useState(false);
  const handleUpdateOpen = () => {
    setUpdateOpen(true);
  };

  const handleUpdateClose = () => {
    setUpdateOpen(false);
  };

  const handleUpdateOk = () => {
    console.log("업데이트 버튼눌림");
    validateDetailData();
    setUpdateOpen(false);
    if (Object.values(inValidDataCheck).every((value) => value === false)) {
      console.log(
        "data : ",
        createData,
        "uuid : ",
        localStorage.getItem("user_uuid"),
        "image : ",
        fileUrls
      );
      updateAnnouncement();
    }
  };

  const [deleteOpen, setDeleteOpen] = useState(false);
  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  };
  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleDeleteOk = () => {
    console.log("Delete 버튼 클릭");
    setDeleteOpen(false);
    ApiService.WebPost("/pw/aw/contents/announce/delete", {
      id: id,
    }).then((res) => {
      console.log("삭제 response : ", res?.data);
      window.location.href = `/announcement`;
    });
  };

  async function updateAnnouncement(): Promise<AnnouncementResponse> {
    const url = "/web/pw/aw/contents/announce/update";
    const headers = {
      "Content-Type": "multipart/form-data;charset=UTF-8",
    };
    const body = {
      id: id,
      title: createData.title,
      showHide: createData.showHide,
      openDate: createData.date,
      content: createData.context,
      uuid: localStorage.getItem("user_uuid") || "",
      country: createData.country,
    };
    console.log("업데이트할 데이터 : ", body, fileUrls);
    try {
      const formData = new FormData();

      // JSON 데이터 추가
      formData.append(
        "body",
        new Blob([JSON.stringify(body)], { type: "application/json" })
      );
      console.log("fileUrls ", fileUrls);
      // 이미지 파일 추가
      if (fileUrls.length === 0) {
        const a = new Blob([], { type: "image/jpeg" });
        formData.append("imageUrls", a, "");
        // formData.append("imageUrls", "");
      } else {
        for (let i = 0; i < fileUrls.length; i++) {
          const blobUrl = fileUrls[i];
          const response = await fetch(blobUrl);
          const blob = await response.blob();
          const imageFileName = `Announcement_image_${i}_${localStorage.getItem(
            "user_uuid"
          )}.jpeg`;
          formData.append("imageUrls", blob, imageFileName);
        }
      }

      const request = await axios.post(url, formData, { headers });
      console.log("request : ", request.data);
      console.log("createId : ", request.data.createdId);
      navigate(`/announcement/announceDetails/${id}`);
      window.location.reload();
      return request.data as AnnouncementResponse;
    } catch (e) {
      console.error("실패", e);
      throw e;
    }
  }

  return (
    <CommonList
      title="Notice Details"
      headerButton={
        <Box sx={{ display: "flex" }}>
          <CommonWebHeaderButton
            buttonContent="Delete"
            sx={{
              backgroundColor: color.white,
              fontStyle: color.error,
              ":hover": {
                backgroundColor: color.red01,
              },
            }}
            icon={<img src={ICON_TRASH}></img>}
            height="45px"
            textColor={color.error}
            onClick={handleDeleteOpen}
          />
          <HorizontalSpacer width={12} />
          <CommonWebHeaderButton
            width="90px"
            height="45px"
            buttonContent="Update"
            sx={{ display: "flex", flexDirection: "column" }}
            onClick={handleUpdateOpen}
          />
        </Box>
      }
      headerBackButton={HeaderBackButton()}
    >
      <CommonDialog
        open={open}
        handleClose={handleBackClose}
        handleOk={handleBackOk}
        textSx={{ fontStyle: fontStyle.regularS, color: color.gray800 }}
      >
        Do you want to stop editing and move? <br />
        The current content will not be saved.
      </CommonDialog>
      <CommonDialog
        open={deleteOpen}
        handleClose={handleDeleteClose}
        handleOk={handleDeleteOk}
        textSx={{ fontStyle: fontStyle.regularS, color: color.gray800 }}
      >
        Do you want to delete the notice? <br />
        It cannot be restored afterwards.
      </CommonDialog>
      <CommonDialog
        open={updateOpen}
        handleClose={handleUpdateClose}
        handleOk={handleUpdateOk}
        textSx={{ fontStyle: fontStyle.regularS, color: color.gray800 }}
      >
        Would you like to update the notice?
      </CommonDialog>
      {rows && (
        <AnnounceDetailsGrid
          uiEvent={uiEvent}
          uiState={uiState}
          handleDetailDataChange={handleDetailDataChange}
          createData={createData}
          inValidDataCheck={inValidDataCheck}
          fileUrls={fileUrls}
          setFileUrls={setFileUrls}
          rows={rows}
          countryCodeMap={countryCodeMap}
        />
      )}
    </CommonList>
  );
};

export default AnnouncementDetailsPage;
