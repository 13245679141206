import { Box } from "@mui/material";
import Toolbar from "../../../../component/Toolbar";
import VerticalSpacer from "../../../../component/Spacer";
import { color } from "../../../../theme/Color";
import { Text } from "../../../../component/Text";
import { NoticeDetailUiState } from "./model/NoticeDetailUiState";
import { HorizontalGrayDivider } from "../../../../component/Divider";
import { fontStyle } from "../../../../theme/Style";
import React from "react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { LocalizedText } from "../../../../di/LanguageRepositoryProvider";
import { NoticeDetailUiEvent } from "./model/NoticeDetailUiEvent";
import PhotoDetails from "../../../MobilePartners/component/PhotoDetails";

interface NoticeDetailRenderProps {
  uiState: NoticeDetailUiState;
  uiEvent: NoticeDetailUiEvent;
}

export default function NoticeDetailRender({
  uiState,
  uiEvent,
}: NoticeDetailRenderProps) {
  type CustomCSSProperties = React.CSSProperties & {
    "--swiper-pagination-fraction-color"?: string;
    "--swiper-pagination-top"?: string;
    "--swiper-pagination-bullet-horizontal-gap"?: string;
    height?: string;
  };

  // TODO indicator bullet color와 위치 변경
  const swiperStyles: CustomCSSProperties = {
    "--swiper-pagination-fraction-color": "#fff",
    "--swiper-pagination-top": "90%",
    width: "100%",
    height: "100%", // Swiper가 Box의 크기를 채우도록 설정
  };

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Toolbar
        onBack={() => {
          window.history.back();
        }}
        title={LocalizedText("ua_f510_notice")}
      />

      {/* 스크롤이 가능한 내용 부분 */}
      <Box
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          px: "20px",
        }}
      >
        <VerticalSpacer height={16} />
        <Text
          sx={{
            fontStyle: fontStyle.headingS,
            color: color.default,
          }}
          text={uiState.title}
        />
        <Text
          sx={{
            fontStyle: fontStyle.bodySr,
            color: color.gray600,
          }}
          text={uiState.time}
        />
        <VerticalSpacer height={24} />
        <HorizontalGrayDivider />
        <VerticalSpacer height={16} />

        <Text
          sx={{
            fontStyle: fontStyle.bodyLr,
            color: color.default,
            whiteSpace: "pre-line",
          }}
          text={uiState.content}
        />
        <VerticalSpacer height={12} />

        {uiState.imageUrls && uiState.imageUrls.length > 0 && (
          <Box
            sx={{
              borderRadius: "10px",
              width: "100%",
              aspectRatio: "1/1",
              alignItems: "center",
              justifyContent: "center",
              overflow: "hidden",
            }}
          >
            <Swiper
              pagination={{
                type: "bullets",
                clickable: true,
              }}
              style={swiperStyles}
              modules={[Pagination]}
            >
              {uiState.imageUrls.map((imageUrl, index) => {
                return (
                  <SwiperSlide key={index} onClick={uiEvent.openPhotoDetail}>
                    <img
                      src={imageUrl}
                      alt="Example Icon"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover", // 이미지가 박스 크기에 맞게 조정
                        objectPosition: "center", // 이미지가 박스 내 중앙에 위치
                      }}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Box>
        )}
        <VerticalSpacer height={16} />
      </Box>

      {uiState.isPhotoDetailOpen && (
        <Box sx={{ position: "absolute" }}>
          <PhotoDetails
            data={uiState.imageUrls}
            setDetailsOpen={uiEvent.closePhotoDetail}
          />
        </Box>
      )}
    </Box>
  );
}
