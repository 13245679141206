import { Box, Card, CardContent } from "@mui/material";
import { ScooterBasicInfoUiState } from "./model/ScooterBasicInfoUiState";
import { Text } from "../../../../component/Text";
import VerticalSpacer, { HorizontalSpacer } from "../../../../component/Spacer";
import { LabeledPaper } from "./LabeledPaper";
import { color } from "../../../../theme/Color";
import { fontStyle } from "../../../../theme/Style";
import React from "react";
import { IMAGE_EMPTY_IMAGE } from "../../../../constants/appImagePath";
import LocalizedDate, {
  DateFormatType,
} from "../../../../component/LocalizedDate";
import { ScooterBasicInfoUiEvent } from "./model/ScooterBasicInfoUiEvent";

interface ScooterBasicInfoPageProps {
  uiState: ScooterBasicInfoUiState;
  uiEvent: ScooterBasicInfoUiEvent;
}

export function ScooterBasicInfoPage({
  uiState,
  uiEvent,
}: ScooterBasicInfoPageProps) {
  return (
    <Box>
      <Card
        sx={{
          minWidth: "1034px",
          borderRadius: "15px",
          border: "1px solid",
          borderColor: color.gray300,
          backgroundColor: color.white,
          boxShadow: 0,
        }}
      >
        <CardContent
          sx={{
            padding: "28px",
          }}
        >
          <Text
            sx={{
              fontStyle: fontStyle.semiboldL,
              color: color.default,
            }}
            text={"User"}
          />
          <VerticalSpacer height={20} />
          <Box
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
              }}
            >
              <LabeledPaper label={"Owner"} value={uiState.owner} />
              <VerticalSpacer height={40} />
              <LabeledPaper label={"Registrant"} value={uiState.registrant} />
              <VerticalSpacer height={40} />
              <LabeledPaper
                label={"Last Updater"}
                value={uiState.lastUpdater}
              />
            </Box>
            <HorizontalSpacer width={20} />
            <Box
              sx={{
                flexGrow: 1,
              }}
            >
              <LabeledPaper label={"Renter"} value={uiState.renter} />
              <VerticalSpacer height={40} />
              <LabeledPaper
                label={"Registration Date"}
                value={
                  uiState.registrationDate === 0
                    ? "-"
                    : LocalizedDate({
                        date: uiState.registrationDate,
                        type: DateFormatType.AdminType,
                      })
                }
              />
              <VerticalSpacer height={40} />
              <LabeledPaper
                label={"Last Updated Date"}
                value={
                  uiState.lastUpdatedDate === 0
                    ? "-"
                    : LocalizedDate({
                        date: uiState.lastUpdatedDate,
                        type: DateFormatType.AdminType,
                      })
                }
              />
            </Box>
          </Box>
        </CardContent>
      </Card>
      <VerticalSpacer height={20} />
      <Card
        sx={{
          minWidth: "1034px",
          borderRadius: "15px",
          border: "1px solid",
          borderColor: color.gray300,
          backgroundColor: color.white,
          boxShadow: 0,
        }}
      >
        <CardContent
          sx={{
            padding: "28px",
          }}
        >
          <Text
            sx={{
              fontStyle: fontStyle.semiboldL,
              color: color.default,
            }}
            text={"Scooter"}
          />
          <VerticalSpacer height={16} />
          <Box
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
              }}
            >
              <LabeledPaper
                label={"Plate Number"}
                value={uiState.plateNumber}
                disabled={false}
                onChange={(value) => {
                  uiEvent.onChangePlateNumber(value);
                }}
              />
              <VerticalSpacer height={24} />
              <LabeledPaper
                label={"Scooter S/N"}
                value={uiState.scooterSerialNumber}
              />
              <VerticalSpacer height={40} />
              <LabeledPaper
                label={"Frame S/N"}
                value={uiState.frameSerialNumber}
              />
              <VerticalSpacer height={40} />
              <LabeledPaper label={"IMEI"} value={uiState.imei} />
              <VerticalSpacer height={40} />
              <LabeledPaper
                label={"Date of Manufacture"}
                value={
                  uiState.dateOfManufacture === 0
                    ? "-"
                    : uiState.dateOfManufacture.toString()
                }
              />
            </Box>
            <HorizontalSpacer width={20} />
            <Box
              sx={{
                flexGrow: 1,
              }}
            >
              <LabeledPaper
                label={"Product Name"}
                value={uiState.productName}
              />
              <VerticalSpacer height={24} />

              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  minHeight: "416px",
                  boxSizing: "border-box",
                  backgroundImage: `url(${
                    uiState.scooterImage === ""
                      ? IMAGE_EMPTY_IMAGE
                      : uiState.scooterImage
                  })`,
                  backgroundSize:
                    uiState.scooterImage === "" ? "cover" : "contain",
                  backgroundRepeat: "no-repeat", // 이미지 반복 없음
                  backgroundPosition: "center", // 배경 이미지 중앙 정렬
                  alignItems: "center", // 이미지 중앙 정렬
                  border: "1px solid", // 테두리
                  borderColor: color.gray300, // 테두리 색상
                  borderRadius: "10px", // 모서리를 둥글게
                  overflow: "hidden", // 이미지가 Box를 벗어나지 않도록
                }}
              />
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}
