import {
  Box,
  Card,
  CardContent,
  InputAdornment,
  InputBase,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  SxProps,
  TextField,
  Theme,
} from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import React, { ChangeEvent, FC, ReactNode, useEffect, useState } from "react";
import { ICON_SEARCH } from "../constants/appImagePath";
import { HorizontalSpacer } from "./Spacer";
import { fontStyle } from "../theme/Style";

interface CommonSearchProps {
  children?: ReactNode;
  placeHolder?: any;
  setRowsPerPage?: any;
  searchContents?: any;
  setSearchContents?: any;
  sx?: SxProps<Theme>,
  placeholderSx?: SxProps<Theme>,
}

const CommonSearch = ({
  children,
  setRowsPerPage,
  placeHolder,
  searchContents,
  setSearchContents,
  sx,
  placeholderSx,
}: CommonSearchProps) => {
  const handleSearchContentsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchContents(event.target.value);
  };
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Paper
        component="form"
        sx={{
          // p: "2px 4px",
          display: "flex",
          alignItems: "center",
          width: "360px",
          height: "42px",
          borderRadius: "10px",
          ...sx
        }}
        elevation={0}
      >
        <HorizontalSpacer width={12} />
        <Box component="img" src={ICON_SEARCH} />
        <HorizontalSpacer width={8} />
        <InputBase
          value={searchContents}
          sx={{
            fontStyle: fontStyle.semiboldXS,
            width: "90%",
            ...placeholderSx
          }}
          onChange={handleSearchContentsChange}
          placeholder={placeHolder}
        />
        <HorizontalSpacer width={12} />
      </Paper>
      {children}
    </Box>
/*
=======
interface CommonSearchProps {
  children: React.ReactNode;
  rowsPerPage?: number;
  setRowsPerPage: (value: number) => void;
  isSearch?: boolean;
  searchTerm?: string;
  onSearch?: (query: string) => void;
  setSearchTerm?: (term: string) => void;
  placeholder?: string;
  sx?: React.CSSProperties;
}

const CommonSearch: FC<CommonSearchProps> = ({
  children,
  setRowsPerPage,
  placeholder = ""
  rowsPerPage = 10,
  isSearch = true,
  searchTerm = "",
  setSearchTerm,
  onSearch,
}: CommonSearchProps) => {
  const [paging, setPaging] = useState("10");
  const childCount = React.Children.count(children);
  const [selectedValue, setSelectedValue] = React.useState("");

  const handlePagingChange = (event: SelectChangeEvent) => {
    const newPageSize = Number(event.target.value);
    if (setRowsPerPage) {
      setPaging("0");
      setRowsPerPage(newPageSize);
    }
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    setSelectedValue(event.target.value);
  };
  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newTerm = event.target.value;
    if (onSearch) {
      onSearch(newTerm);
    }
  };
  return (
    <CardContent
      sx={{
        // height: 60,
        alignItems: "center",
        justifyContent: "space-between",
        display: "flex",
        width: "100%",
        border: "none",
        height: "100%",
        padding: 0
      }}
    >
      {isSearch ? (
        <>
          {childCount === 0 && (
            <TextField
              id="margin-none"
              variant="outlined"
              placeholder={placeholder}
              onChange={handleSearchChange}
              value={searchTerm}
              size="small"
              sx={{
                width: "83.5%",
                minHeight: "42px",
                // padding: "12px 16px 12px 16px",
                border: "0.5px solid transparent",
                borderRadius: "10px",
                opacity: 0,
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none"
                }
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  border: "none"
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
          )}
          {childCount === 1 && (
            <TextField
              id="margin-none"
              variant="outlined"
              placeholder={placeholder}
              onChange={handleSearchChange}
              size="small"
              sx={{
                width: "68.5%",
                height: "42px",
                backgroundColor: "white",
                borderRadius: "10px",
                "& .MuiOutlinedInput-root": {
                  height: "42px" // Ensures the input field respects this height
                }
              }}
              InputProps={{
                style: {
                  borderRadius: "inherit",
                  height: "42px"
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
          )}

          {childCount === 2 && (
            <Select
              id="dropdown-select"
              value={selectedValue}
              onChange={handleSelectChange}
            ></Select>
          )}

          {children}
        </>
      ) : (
        children
      )}
      <Select
        id="paging"
        value={rowsPerPage.toString()}
        onChange={handlePagingChange}
        size="small"
        IconComponent={(props) => (
          <KeyboardArrowDown {...props} sx={{ fontSize: "16px" }} />
        )}
        sx={{
          width: "13%",
          maxWidth: "600px",
          minHeight: "42px",
          fontSize: "12px",
          opacity: 1,
          backgroundColor: "#ffff",
          color: "#99989D", // Text color
          fontWeight: 600,
          borderRadius: "10px",
          ":hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)", // Optional: change background color on hover
            cursor: "pointer", // Change cursor to pointer
            border: "1px solid #5D38E5", // Change border color on hover
            transition: "border 0.3s ease" // Optional: smooth transition for border color
          }
        }}
      >
        <MenuItem value={10} sx={{ fontSize: "12px", fontWeight: "400" }}>
          10
        </MenuItem>
        <MenuItem value={25} sx={{ fontSize: "12px", fontWeight: "400" }}>
          25
        </MenuItem>
        <MenuItem value={50} sx={{ fontSize: "12px", fontWeight: "400" }}>
          50
        </MenuItem>
        <MenuItem value={100} sx={{ fontSize: "12px", fontWeight: "400" }}>
          100
        </MenuItem>
      </Select>
    </CardContent>
    // </Card>
>>>>>>> feature/greenery
*/
  );
};

export default CommonSearch;
