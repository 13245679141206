import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  SnackbarOrigin,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import CommonTextField from "../../../../component/CommonTextField";
import VerticalSpacer from "../../../../component/Spacer";
import { fontStyle } from "../../../../theme/Style";
import { color } from "../../../../theme/Color";
import { CommonSelect } from "../../../../component/CommonSelect";

import CommonIndeterminateCheckBox from "../../../../component/CommonIndeterminateCheckBox";
import LocalizedDate, {
  DateFormatType,
} from "../../../../component/LocalizedDate";
import CommonDatePicker from "../../../../component/CommonDatePicker";
import dayjs, { Dayjs } from "dayjs";

interface CreateAdminGridProps {
  createData: any;
  handleCreateDataChange: any;
  inValidDataCheck: any;
}

const CreateAdminGrid = ({
  createData,
  handleCreateDataChange,
  inValidDataCheck,
}: CreateAdminGridProps) => {
  const statisticsChildLabels = ["statistics"];
  const [statisticsChecked, setStatisticsChecked] = useState<boolean[]>(
    Array(statisticsChildLabels.length).fill(false)
  );

  const userManagementChildLabels = ["member", "administrator", "group"];
  const [userManagementChecked, setUserManagementChecked] = useState<boolean[]>(
    Array(userManagementChildLabels.length).fill(false)
  );

  const contentChildLabels = [
    "faq",
    "inquiry",
    "cs",
    "notice",
    "pushNotification",
    "popupBanner",
  ];
  const [contentChecked, setContentChecked] = useState<boolean[]>(
    Array(contentChildLabels.length).fill(false)
  );

  const merchandiseChildLabels = [
    "membershipVoucher",
    "nft",
    "coupon",
    "payment",
  ];
  const [merchandiseChecked, setMerchandiseChecked] = useState<boolean[]>(
    Array(merchandiseChildLabels.length).fill(false)
  );

  const productionMaterialChildLabels = [
    "production",
    "inventory",
    "request",
    "productName",
  ];
  const [productionMaterialChecked, setProductionMaterialChecked] = useState<
    boolean[]
  >(Array(productionMaterialChildLabels.length).fill(false));

  const operationsChildLabels = [
    "regionService",
    "scooter",
    "station",
    "battery",
    "nfcCard",
  ];
  const [operationsChecked, setOperationsChecked] = useState<boolean[]>(
    Array(operationsChildLabels.length).fill(false)
  );

  const carbonReductionChildLabels = [
    "methodology",
    "project",
    "dataParameter",
    "monitoring",
  ];
  const [carbonReductionChecked, setCarbonReductionsChecked] = useState<
    boolean[]
  >(Array(carbonReductionChildLabels.length).fill(false));

  const blockchainChildLabels = ["monitoring", "wallet"];
  const [blockchainChecked, setBlockchainChecked] = useState<boolean[]>(
    Array(blockchainChildLabels.length).fill(false)
  );

  const systemSettingChildLabels = [
    "notification",
    "multiLanguage",
    "batch",
    "history",
  ];
  const [systemSettingChecked, setSystemSettingChecked] = useState<boolean[]>(
    Array(systemSettingChildLabels.length).fill(false)
  );

  useEffect(() => {
    const selectedLabels = {
      Statistics: statisticsChildLabels.filter(
        (label, index) => statisticsChecked[index]
      ),
      UserManagement: userManagementChildLabels.filter(
        (label, index) => userManagementChecked[index]
      ),
      Content: contentChildLabels.filter(
        (label, index) => contentChecked[index]
      ),
      Merchandise: merchandiseChildLabels.filter(
        (label, index) => merchandiseChecked[index]
      ),
      ProductionMaterial: productionMaterialChildLabels.filter(
        (label, index) => productionMaterialChecked[index]
      ),
      Operations: operationsChildLabels.filter(
        (label, index) => operationsChecked[index]
      ),
      CarbonReduction: carbonReductionChildLabels.filter(
        (label, index) => carbonReductionChecked[index]
      ),
      Blockchain: blockchainChildLabels.filter(
        (label, index) => blockchainChecked[index]
      ),
      SystemSetting: systemSettingChildLabels.filter(
        (label, index) => systemSettingChecked[index]
      ),
    };

    handleCreateDataChange(
      "statistics",
      statisticsChildLabels.filter((label, index) => statisticsChecked[index])
    );
    handleCreateDataChange(
      "userManagement",
      userManagementChildLabels.filter(
        (label, index) => userManagementChecked[index]
      )
    );
    handleCreateDataChange(
      "content",
      contentChildLabels.filter((label, index) => contentChecked[index])
    );
    handleCreateDataChange(
      "merchandise",
      merchandiseChildLabels.filter((label, index) => merchandiseChecked[index])
    );
    handleCreateDataChange(
      "productionMaterial",
      productionMaterialChildLabels.filter(
        (label, index) => productionMaterialChecked[index]
      )
    );
    handleCreateDataChange(
      "operations",
      operationsChildLabels.filter((label, index) => operationsChecked[index])
    );
    handleCreateDataChange(
      "carbonReduction",
      carbonReductionChildLabels.filter(
        (label, index) => carbonReductionChecked[index]
      )
    );
    handleCreateDataChange(
      "blockchain",
      blockchainChildLabels.filter((label, index) => blockchainChecked[index])
    );
    handleCreateDataChange(
      "system",
      systemSettingChildLabels.filter(
        (label, index) => systemSettingChecked[index]
      )
    );

    // console.log("Selected Labels:", selectedLabels);
  }, [
    statisticsChecked,
    userManagementChecked,
    contentChecked,
    merchandiseChecked,
    productionMaterialChecked,
    operationsChecked,
    carbonReductionChecked,
    blockchainChecked,
    systemSettingChecked,
  ]);

  return (
    <Grid container rowSpacing={4.5} columnSpacing={2.75}>
      {/* 상단 카드 */}
      <Grid item lg={6}>
        <Card
          sx={{
            borderRadius: 3,
            width: "100%",
            display: "flex",
          }}
        >
          <CardContent sx={{ width: "100%", m: 2 }}>
            <Box
              sx={{
                display: "flex",
                height: "100%",
                // minHeight: 330,
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Name
                </Typography>
                <CommonTextField
                  value={createData.name}
                  onChange={(event) => {
                    handleCreateDataChange("name", event.target.value);
                  }}
                  sx={{ width: "100%", height: "48px", mt: "8px" }}
                  InputProps={{
                    sx: {
                      height: "48px",
                    },
                  }}
                  error={inValidDataCheck.name}
                />
                {inValidDataCheck.name && (
                  <Typography
                    fontStyle={fontStyle.regularXS}
                    color={color.error}
                  >
                    Please enter a name with at least 2 characters.
                  </Typography>
                )}
              </Box>
              <VerticalSpacer height={16} />
              <Box>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Email ID
                </Typography>
                <CommonTextField
                  value={createData.email}
                  onChange={(event) => {
                    handleCreateDataChange("email", event.target.value);
                  }}
                  sx={{ width: "100%", height: "48px", mt: "8px" }}
                  InputProps={{
                    sx: {
                      height: "48px",
                    },
                  }}
                  error={inValidDataCheck.email}
                />
                {inValidDataCheck.email && (
                  <Typography
                    fontStyle={fontStyle.regularXS}
                    color={color.error}
                  >
                    Please enter an email in the correct format.
                  </Typography>
                )}
              </Box>
              <VerticalSpacer height={16} />
              <Box>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Phone Number
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mt: 1,
                  }}
                >
                  <CommonSelect
                    selectValue={createData.countryNumber}
                    menuitems={["1", "82", "855"]}
                    onChange={(event) => {
                      handleCreateDataChange(
                        "countryNumber",
                        event.target.value
                      );
                    }}
                    sx={{
                      height: "48px",
                      width: "18.5%",
                      fontStyle: fontStyle.regularS,
                      color: "black",
                    }}
                    error={inValidDataCheck.countryNumber}
                  />

                  <CommonTextField
                    value={createData.phoneNumber}
                    onChange={(event) => {
                      const value = event.target.value;
                      // 숫자만 허용 (정규식 사용)
                      if (/^\d*$/.test(value)) {
                        handleCreateDataChange("phoneNumber", value);
                      }
                    }}
                    sx={{ width: "78.5%", height: "48px" }}
                    InputProps={{
                      style: {
                        height: "48px",
                      },
                    }}
                    // inputProps={{
                    //   type: "number", // 숫자만 입력 가능
                    // }}
                    error={inValidDataCheck.phoneNumber}
                  />
                </Box>
                {(inValidDataCheck.countryNumber ||
                  inValidDataCheck.phoneNumber) && (
                  <Typography
                    fontStyle={fontStyle.regularXS}
                    color={color.error}
                  >
                    Please enter your phone number.
                  </Typography>
                )}
              </Box>
              <VerticalSpacer height={16} />
              <Box>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Password
                </Typography>
                <CommonTextField
                  sx={{ width: "100%", height: "48px", mt: "8px" }}
                  InputProps={{
                    sx: {
                      height: "48px",
                    },
                  }}
                  disabled
                />
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={6}>
        <Card
          sx={{
            borderRadius: 3,
            minHeight: 240,
            width: "100%",
            display: "flex",
          }}
        >
          <CardContent sx={{ width: "100%", m: 2 }}>
            <Box
              sx={{
                display: "flex",
                height: "100%",
                flexDirection: "column",
              }}
            >
              <Box>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Status
                </Typography>
                <CommonSelect
                  selectValue={""}
                  menuitems={[]}
                  onChange={(event) => {
                    // uiEvent.HandleUiStateChange(
                    //   "LocalNumber",
                    //   event.target.value
                    // );
                  }}
                  sx={{
                    height: "48px",
                    width: "100%",
                    fontStyle: fontStyle.regularS,
                    color: "black",
                    mt: "8px",
                  }}
                  disabled
                />
              </Box>
              <VerticalSpacer height={16} />
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ width: "48.5%" }}>
                  <Typography
                    fontStyle={fontStyle.boldXS}
                    color={color.gray600}
                  >
                    Type
                  </Typography>

                  <CommonSelect
                    selectValue={createData.type}
                    menuitems={[
                      "Site Manager",
                      "Customer Manager",
                      "Engineering Manager",
                      "Dealer",
                    ]}
                    onChange={(event) => {
                      handleCreateDataChange("type", event.target.value);
                    }}
                    sx={{
                      height: "48px",
                      width: "100%",
                      fontStyle: fontStyle.regularS,
                      color: "black",
                      mt: "8px",
                    }}
                    error={inValidDataCheck.type}
                  />
                  {inValidDataCheck.type && (
                    <Typography
                      fontStyle={fontStyle.regularXS}
                      color={color.error}
                    >
                      Please select a type.
                    </Typography>
                  )}
                </Box>
                <Box sx={{ width: "48.5%" }}>
                  <Typography
                    fontStyle={fontStyle.boldXS}
                    color={color.gray600}
                  >
                    Role
                  </Typography>

                  <CommonSelect
                    selectValue={createData.role}
                    menuitems={["Normal", "Lead"]}
                    onChange={(event) => {
                      handleCreateDataChange("role", event.target.value);
                    }}
                    sx={{
                      height: "48px",
                      width: "100%",
                      fontStyle: fontStyle.regularS,
                      color: "black",
                      mt: "8px",
                    }}
                    error={inValidDataCheck.role}
                  />
                  {inValidDataCheck.role && (
                    <Typography
                      fontStyle={fontStyle.regularXS}
                      color={color.error}
                    >
                      Please select a role.
                    </Typography>
                  )}
                </Box>
              </Box>
              <VerticalSpacer height={16} />
              <Box sx={{ width: "100%" }}>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Office
                </Typography>

                <CommonSelect
                  selectValue={createData.office}
                  menuitems={[
                    "Head Office(Pohang, South Korea)",
                    "Yongin, South Korea",
                    "Gwangju, South Korea",
                    "Siem Reap, Cambodia",
                    "Phnom Penh, Cambodia",
                  ]}
                  onChange={(event) => {
                    // uiEvent.HandleUiStateChange(
                    //   "LocalNumber",
                    //   event.target.value
                    // );
                  }}
                  sx={{
                    height: "48px",
                    width: "100%",
                    fontStyle: fontStyle.regularS,
                    color: "black",
                    mt: "8px",
                  }}
                  // error={inValidDataCheck.office} //todo office 정해진게 없어 0 으로 넣어야함
                />
              </Box>
              <VerticalSpacer height={16} />
              <Box sx={{ width: "100%" }}>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Group
                </Typography>

                <CommonSelect
                  selectValue={createData.group}
                  menuitems={["IT Department"]}
                  onChange={(event) => {
                    // uiEvent.HandleUiStateChange(
                    //   "LocalNumber",
                    //   event.target.value
                    // );
                  }}
                  sx={{
                    height: "48px",
                    width: "100%",
                    fontStyle: fontStyle.regularS,
                    color: "black",
                    mt: "8px",
                  }}
                  error={inValidDataCheck.group} //todo Group 정해진게 없어 error 처리 할 수 없음
                />
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      {/* Role 카드 */}
      <Grid item lg={12}>
        <Card
          sx={{
            borderRadius: 3,
            minHeight: 140,
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CardContent sx={{ width: "100%", height: "100%" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <CardHeader
                title="Role"
                titleTypographyProps={{ fontWeight: "bold", fontSize: 19 }}
              />
              {inValidDataCheck.checkBox && (
                <Typography fontStyle={fontStyle.regularXS} color={color.error}>
                  At least one checkbox must be selected.
                </Typography>
              )}
            </Box>
            {/* 체크박스 작업 */}
            <Box sx={{ display: "flex" }}>
              <Box sx={{ width: "20%" }}>
                <CommonIndeterminateCheckBox
                  parentLabel="Statistics"
                  childLabels={statisticsChildLabels}
                  checked={statisticsChecked}
                  setChecked={setStatisticsChecked}
                  onParentClick={() => console.log("Parent checkbox clicked")}
                />
              </Box>
              <Box sx={{ width: "20%" }}>
                <CommonIndeterminateCheckBox
                  parentLabel="User Management"
                  childLabels={userManagementChildLabels}
                  checked={userManagementChecked}
                  setChecked={setUserManagementChecked}
                  onParentClick={() => console.log("Parent checkbox clicked")}
                />
              </Box>
              <Box sx={{ width: "20%" }}>
                <CommonIndeterminateCheckBox
                  parentLabel="Content"
                  childLabels={contentChildLabels}
                  checked={contentChecked}
                  setChecked={setContentChecked}
                  onParentClick={() => console.log("Parent checkbox clicked")}
                />
              </Box>
              <Box sx={{ width: "20%" }}>
                <CommonIndeterminateCheckBox
                  parentLabel="Merchandise"
                  childLabels={merchandiseChildLabels}
                  checked={merchandiseChecked}
                  setChecked={setMerchandiseChecked}
                  onParentClick={() => console.log("Parent checkbox clicked")}
                />
              </Box>
              <Box sx={{ width: "20%" }}>
                <CommonIndeterminateCheckBox
                  parentLabel="Production Material"
                  childLabels={productionMaterialChildLabels}
                  checked={productionMaterialChecked}
                  setChecked={setProductionMaterialChecked}
                  onParentClick={() => console.log("Parent checkbox clicked")}
                />
              </Box>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Box sx={{ width: "20%" }}>
                <CommonIndeterminateCheckBox
                  parentLabel="Operations"
                  childLabels={operationsChildLabels}
                  checked={operationsChecked}
                  setChecked={setOperationsChecked}
                  onParentClick={() => console.log("Parent checkbox clicked")}
                />
              </Box>

              <Box sx={{ width: "20%" }}>
                <CommonIndeterminateCheckBox
                  parentLabel="Carbon Reduction"
                  childLabels={carbonReductionChildLabels}
                  checked={carbonReductionChecked}
                  setChecked={setCarbonReductionsChecked}
                  onParentClick={() => console.log("Parent checkbox clicked")}
                />
              </Box>

              <Box sx={{ width: "20%" }}>
                <CommonIndeterminateCheckBox
                  parentLabel="Blockchain"
                  childLabels={blockchainChildLabels}
                  checked={blockchainChecked}
                  setChecked={setBlockchainChecked}
                  onParentClick={() => console.log("Parent checkbox clicked")}
                />
              </Box>

              <Box sx={{ width: "20%" }}>
                <CommonIndeterminateCheckBox
                  parentLabel="System Setting"
                  childLabels={systemSettingChildLabels}
                  checked={systemSettingChecked}
                  setChecked={setSystemSettingChecked}
                  onParentClick={() => console.log("Parent checkbox clicked")}
                />
              </Box>
            </Box>
            {/* 체크박스 작업 */}
          </CardContent>
        </Card>
      </Grid>
      {/* Details 카드 */}
      <Grid item lg={12}>
        <Card
          sx={{
            borderRadius: 3,
            minHeight: 240,
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CardContent sx={{ width: "100%", height: "100%" }}>
            <CardHeader
              title="Details"
              titleTypographyProps={{ fontWeight: "bold", fontSize: 19 }}
            />
            <Box
              sx={{
                m: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* 첫번째 줄 */}
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ width: "48.5%" }}>
                  <Typography>Birth of Date</Typography>
                  <Box
                    sx={{
                      display: "flex",
                      mt: 1,
                      justifyContent: "space-between",
                    }}
                  >
                    <CommonDatePicker
                      value={
                        createData.birthOfDate !== null
                          ? dayjs(createData.birthOfDate)
                          : null
                      }
                      onChange={(newDate: Dayjs | null) => {
                        handleCreateDataChange(
                          "birthOfDate",
                          newDate?.valueOf()
                        );
                      }}
                      views={["year", "month", "day"]}
                      textFieldSx={{ width: "100%" }}
                      InputPropsSx={{ height: "48px" }}
                      // error={inValidDataCheck.birthOfDate}
                    />
                  </Box>
                </Box>
                <Box sx={{ width: "48.5%" }}>
                  <Typography>Gender</Typography>
                  <CommonSelect
                    selectValue={createData.gender}
                    menuitems={["Male", "Female"]}
                    onChange={(event) => {
                      handleCreateDataChange("gender", event.target.value);
                    }}
                    sx={{
                      height: "48px",
                      width: "100%",
                      mt: "8px",
                      borderRadius: "10px",
                      fontStyle: fontStyle.regularS,
                      color: "black",
                    }}
                    // error={inValidDataCheck.gender}
                  />
                </Box>
              </Box>
              <VerticalSpacer height={40} />
              {/* 두번째 줄 */}
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ width: "48.5%" }}>
                  <Typography>Address</Typography>
                  <Box
                    sx={{
                      display: "flex",
                      mt: 1,
                      justifyContent: "space-between",
                    }}
                  >
                    <CommonSelect
                      selectValue={createData.addressCountry}
                      menuitems={[
                        "Cambodia",
                        // , "Korea", "Usa"
                      ]}
                      onChange={(event) => {
                        handleCreateDataChange(
                          "addressCountry",
                          event.target.value
                        );
                      }}
                      sx={{
                        height: "48px",
                        width: "48.5%",
                        mt: "8px",
                        borderRadius: "10px",
                        fontStyle: fontStyle.regularS,
                        color: "black",
                      }}
                      error={inValidDataCheck.addressCountry}
                    />

                    <CommonSelect
                      selectValue={createData.addressProvince}
                      menuitems={[
                        "Siem Reap",
                        "Battambang",
                        "Banteay Meanchey",
                        "Phnom Penh",
                        "Kandal",
                        "Sihanoukville",
                        "Oddar Meanchey",
                        "Pursat",
                        "Kampong Thom",
                        "Kampong Speu",
                        "Svay Rieng",
                        "Takéo",
                        "Svay Rieng",
                        "Kampong Chhnang",
                        "Kampong Cham",
                        "Pailin",
                        "Prey Veng",
                        "Tboung Khmum",
                        "Kampot",
                        "Ratanakiri",
                        "Koh Kong",
                        "Kratié",
                        "Preah Vihear",
                        "Mondulkiri",
                        "Kep",
                        "Sihanoukville",
                        "other",
                      ]}
                      onChange={(event) => {
                        handleCreateDataChange(
                          "addressProvince",
                          event.target.value
                        );
                      }}
                      sx={{
                        height: "48px",
                        width: "48.5%",
                        mt: "8px",
                        borderRadius: "10px",
                        fontStyle: fontStyle.regularS,
                        color: "black",
                      }}
                      error={inValidDataCheck.addressProvince}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "48.5%",
                    display: "flex",
                    alignItems: "flex-end",
                  }}
                >
                  <CommonTextField
                    value={createData.addressDetail}
                    onChange={(event) => {
                      handleCreateDataChange(
                        "addressDetail",
                        event.target.value
                      );
                    }}
                    sx={{
                      width: "100%",
                      mt: "8px",
                      height: "48px",
                    }}
                    InputProps={{
                      style: {
                        height: "48px",
                        borderRadius: "10px",
                        // backgroundColor: color.gray200,
                        // color: color.gray600, // 비활성화 상태일 때 색상을 지정
                      },
                    }}
                    error={inValidDataCheck.addressDetail}
                  />
                </Box>
              </Box>
              {(inValidDataCheck.addressCountry ||
                inValidDataCheck.addressProvince ||
                inValidDataCheck.addressDetail) && (
                <Typography fontStyle={fontStyle.regularXS} color={color.error}>
                  Please select a address.
                </Typography>
              )}
              <VerticalSpacer height={40} />

              {/* 세번째 줄 */}
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
              >
                <Box sx={{ width: "48.5%" }}>
                  <Typography>Registration Date</Typography>
                  <CommonTextField
                    sx={{ width: "100%", mt: 1 }}
                    value={LocalizedDate({
                      date: 0,
                      type: DateFormatType.numericTime,
                    })}
                    disabled
                  />
                </Box>
                <Box sx={{ width: "48.5%" }}>
                  <Typography>Last Modified Date</Typography>
                  <CommonTextField
                    sx={{ width: "100%", mt: 1 }}
                    value={LocalizedDate({
                      date: 0,
                      type: DateFormatType.numericTime,
                    })}
                    disabled
                  />
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default CreateAdminGrid;
