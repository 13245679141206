import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Button, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import CommonList from "../../../component/CommonList";
import CommonSearch from "../../../component/CommonSearch";
import { Project } from "../../../store/projectType";
import ProjectTable from "../section/ProjectTable";
import { SERVER_CONFIG } from "../../../constants/ServerConfig";
import axios from "axios";

const ProjectMngListPage = () => {
  const navigate = useNavigate();
  const [projectType, setProjectType] = useState<string>("ALL");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState<number>(0);
  const [data, setData] = useState<Project[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState<string>("");
  const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [projectFilter, setProjectFilter] = useState<string>("ALL");
  const API_URL = SERVER_CONFIG.CARBON_SERVER + "/project";

  // Debounce the search query
  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    debounceTimeoutRef.current = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 900);

    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, [searchQuery]);

  // Fetch data function
  const fetchData = async () => {
    setLoading(true);
    try {
      const params = {
        page,
        size: rowsPerPage,
        ...(projectType !== "ALL" && { projectType }),
        ...(projectFilter !== "ALL" && { type: projectFilter }),
        searchValue: debouncedSearchQuery.trim()
      };

      const response = await axios.get(API_URL, { params });

      if (response.data && response.data.content) {
        setData(response.data.content);
        setTotalCount(response.data.totalElements || 0);
      } else {
        throw new Error("Unexpected data format");
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("Error fetching data:", {
          message: error.message,
          response: error.response?.data,
          config: error.config
        });
        setError(`Error fetching data: ${error.message}`);
      } else {
        console.error("Unexpected error fetching data", error);
        setError("Unexpected error fetching data");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage, projectType, projectFilter, debouncedSearchQuery]);

  const handleProjectTypeChange = (event: SelectChangeEvent<string>) => {
    setProjectType(event.target.value);
    setPage(0);
  };

  const handleProjectFilterChange = (event: SelectChangeEvent<string>) => {
    setProjectFilter(event.target.value || "ALL");
    setPage(0);
  };

  const handleRowsPerPageChange = (value: number) => {
    setRowsPerPage(value);
    setPage(0);
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);
  };

  const headerButton = () => {
    return (
      <Button
        sx={{
          width: "113px",
          height: "45px",
          borderRadius: "40px",
          padding: "20px, 0px, 20px, 0",
          background: "#5D38E5",
          color: "#FFFFFF",
          textTransform: "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textWeight: "600",
          textSize: "14px",
          lineHeight: "20px",
          "&:hover": {
            background: " #5a3acb"
          }
        }}
        onClick={() => {
          navigate("/projectMng/projectDetails");
        }}
      >
        <AddIcon fontSize="small" />
        Create
      </Button>
    );
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage - 1);
  };

  return (
    <CommonList title="프로젝트 관리" headerButton={headerButton()}>
      <CommonSearch
        setRowsPerPage={handleRowsPerPageChange}
//         onSearch={handleSearch}
//         placeholder="프로젝트명, 프로젝트 ID, 방법론 ID, 기업명 검색"
      >
        <Select
          id="projectFilter"
          value={projectFilter}
          onChange={handleProjectFilterChange}
          size="small"
          IconComponent={(props) => (
            <KeyboardArrowDown {...props} sx={{ fontSize: "16px" }} />
          )}
          sx={{
            width: "13%",
            ml: 2,
            maxWidth: "600px",
            minHeight: "42px",
            opacity: 1,
            backgroundColor: "#ffff",
            color: "#99989D",
            fontWeight: 600,
            fontSize: "12px",
            "&:hover": {
              borderColor: "#F9F6FD"
            }
          }}
          style={{
            borderRadius: "10px",
            color: "#99989D",
            fontWeight: 600,
            fontSize: "12px"
          }}
        >
          <MenuItem
            sx={{ fontSize: "12px", fontWeight: "400", lineHeight: "18px" }}
            value={"ALL"}
          >
            All
          </MenuItem>
          <MenuItem
            sx={{ fontSize: "12px", fontWeight: "400", lineHeight: "18px" }}
            value={"NEW"}
          >
            신규
          </MenuItem>
          <MenuItem
            sx={{ fontSize: "12px", fontWeight: "400", lineHeight: "18px" }}
            value={"MOVE"}
          >
            변경
          </MenuItem>
        </Select>
      </CommonSearch>
      <ProjectTable
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        totalCount={totalCount}
        data={data}
      />
      {error && <div style={{ color: "red" }}>{error}</div>}
    </CommonList>
  );
};

export default ProjectMngListPage;
