import React, { useEffect, useState } from "react";
import { isBrowser, isMobile } from "react-device-detect";
import { createTheme, ThemeProvider } from "@mui/material";
import ClippedDrawer from "./layout/BaseLayout/CommonLayout";
import MobileDrawer from "./layout/BaseLayout/MobileLayout/MobileDrawer";
import CombinedProvider from "./di/CombinedProvider";
import { AuthRepositoryProvider } from "./di/AuthRepositoryProvider";
import { MethodologyProvider } from "./context/MethodologyContext";
import MonitoringProvider from "./di/MonitoringProvider";
import "./App.css";


const App = () => {
  const [theme, setTheme] = useState(
    createTheme({
      typography: {
        fontFamily: "'Pretendard', sans-serif",
      },
    }),
  );

  useEffect(() => {
    var body = document.querySelector("body") as HTMLElement;

    if (isMobile) {
      setTheme(
        createTheme({
          typography: {
            fontFamily: "'Pretendard', sans-serif",
          },
        }),
      );
      body.id = "mobile";
    } else {
      setTheme(
        createTheme({
          typography: {
            fontFamily: "'OpenSans', sans-serif",
          },
        }),
      );
      body.id = "web";
    }
  }, []);

  useEffect(() => {
    function androidBackHandlerEvent(): void {
      const event = new CustomEvent("androidBackHandlerEvent");
      window.dispatchEvent(event);
    }

    window.androidBackHandlerEvent = androidBackHandlerEvent;
  }, []);

  return (
    <ThemeProvider theme={theme}>
      {isBrowser && (
        <AuthRepositoryProvider>
          <MethodologyProvider>
            <MonitoringProvider>
              <ClippedDrawer />
            </MonitoringProvider>
          </MethodologyProvider>
        </AuthRepositoryProvider>
      )}
      {isMobile && (
        <CombinedProvider>
          <MobileDrawer />
        </CombinedProvider>
      )}
    </ThemeProvider>
  );
};

export default App;
