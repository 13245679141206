export class MoreProfileEditPageUiState {
  isShowAlertDialog: boolean = false;

  isDoEditCompleteDialog: boolean = false;
  isEditCompleteDialog: boolean = false;

  name: string = "";
  email: string = "";
  localNumber: string = "+855";
  phoneNumber: string = "010-1234-1234";
  day: string = "29";
  month: string = "10";
  year: string = "1998";
  gender: string = "male";
  country: string = "korea";
  cityCountyDistrict: string = "동천역 유타워";
  cityDetail: string = "1205호";

  localNumberError: string = "";
  phoneNumberError: string = "";
  dayError: string = "";
  monthError: string = "";
  yearError: string = "";
  countryError: string = "";
  cityCountyDistrictError: string = "";
  cityDetailError: string = "";
  tribeCategory: string = "";
  tribeName: string = "";
}
