import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  IMAGE_EMPTY_IMAGE,
  IMAGE_STATION_01,
} from "../../../constants/appImagePath";

interface SwiperPhotosProps {
  imgData: any;
}

const SwiperPhotos = ({ imgData }: SwiperPhotosProps) => {
  type CustomCSSProperties = React.CSSProperties & {
    "--swiper-pagination-color"?: string;
    "--swiper-pagination-top"?: string;
    height?: string;
  };

  // Define the custom styles
  const swiperStyles: CustomCSSProperties = {
    "--swiper-pagination-color": "#5D38E5",
    "--swiper-pagination-top": "83%",
    height: "115px",
  };
  return (
    <>
      <Swiper
        style={swiperStyles}
        slidesPerView={1}
        height={100}
        spaceBetween={10}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
      >
        {imgData ? (
          imgData.map((data: any) => (
            <SwiperSlide>
              <img
                src={data.imgData}
                loading="lazy"
                style={{ width: "101px", height: "91.19px", borderRadius: 15 }}
              />
            </SwiperSlide>
          ))
        ) : (
          <>
            <SwiperSlide>
              <img
                src={IMAGE_EMPTY_IMAGE}
                loading="lazy"
                style={{ width: "101px", height: "91.19px", borderRadius: 15 }}
              />
            </SwiperSlide>
          </>
        )}
      </Swiper>
    </>
  );
};

export default SwiperPhotos;

{
  /* <SwiperSlide>
          <img
            src={IMAGE_STATION_01}
            loading="lazy"
            style={{ width: "101px", height: "91.19px", borderRadius: 15 }}
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={IMAGE_STATION_01}
            loading="lazy"
            style={{ width: "101px", height: "91.19px" }}
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={IMAGE_STATION_01}
            loading="lazy"
            style={{ width: "101px", height: "91.19px" }}
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={IMAGE_STATION_01}
            loading="lazy"
            style={{ width: "101px", height: "91.19px" }}
          />
        </SwiperSlide> */
}
