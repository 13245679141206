import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
  Tabs,
  Tab,
  Box
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { SERVER_CONFIG } from "../../../constants/ServerConfig";
import CommonList from "../../../component/CommonList";
import { ProjectFormData } from "../../../store/projectType";
import CreateProjectGridPage from "./CreateProjectGridPage";
import ProjectInfoPage from "./ProjectInfoPage";
interface WorkspaceImage {
  name: string;
}

const CreateProjectPage = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [isSaved, setIsSaved] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState<"delete" | "navigate" | null>(
    null
  );
  const [error, setError] = useState("");
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  // 초기 상태를 저장하는 변수
  const [initialFormData, setInitialFormData] = useState<ProjectFormData>({
    id: 0,
    contactName: "",
    team: "",
    phoneCode: "82",
    phone: "",
    email: "",
    isExported: false,
    title: "",
    startDate: "",
    endDate: "",
    content: "",
    methodologyId: 0,
    latitude: 0,
    longitude: 0,
    address: "",
    detailAddress: "",
    reductionAmount: 0,
    businessOwnership: "",
    workspaceImages: [],
    businessValidationReportFile: ""
  });

  const [formData, setFormData] = useState<ProjectFormData>(initialFormData);
  const [activeTab, setActiveTab] = useState(0);

  // 초기 데이터를 불러와 설정하는 useEffect
  useEffect(() => {
    if (id) {
      const fetchProject = async () => {
        try {
          const response = await fetch(
            SERVER_CONFIG.CARBON_SERVER + `/project/${id}`
          );
          if (response.ok) {
            const data = await response.json();
            console.log("Fetched project data:", data); // Log fetched data
            setFormData(data);
            setInitialFormData(data);
            setIsSaved(true);
          } else {
            console.error("Failed to fetch data");
          }
        } catch (error) {
          console.error("Error fetching project", error);
        }
      };
      fetchProject();
    }
  }, [id]);

  // formData가 변경될 때마다 초기 상태와 비교하여 변경 사항을 확인
  useEffect(() => {
    if (formData !== initialFormData) {
      setHasUnsavedChanges(true);
    } else {
      setHasUnsavedChanges(false);
    }
  }, [formData, initialFormData]);
  const handleSave = async () => {
    console.log("Saving formData: ", formData);
    if (
      !formData.company?.name ||
      !formData.title ||
      !formData.company.ownerName ||
      !formData.company.businessNumber ||
      !formData.company.url ||
      !formData.contactName ||
      !formData.team ||
      !formData.email ||
      !formData.phone
    ) {
      setError("모든 필드에 입력해 주세요.");
      return;
    }
    setErrorMessage(null);
    setLoading(true);
    console.log("Form Data being sent:", formData);

    try {
      let method, url, dataToSend;

      if (formData.id) {
        method = "PUT";
        url = SERVER_CONFIG.CARBON_SERVER + `/project/${formData.id}`;
        const { id, methodology, ...data } = formData;
        data.methodologyId = methodology?.id;
        dataToSend = {
          ...data,
          reductionAmount: formData.reductionAmount,
          businessAssignment: formData.businessAssignment
        };
      } else {
        method = "POST";
        url = SERVER_CONFIG.CARBON_SERVER + "/project";
        const { id, ...data } = formData;
        data.type = "NEW";
        dataToSend = {
          ...data,
          methodologyId: formData.methodologyId,
          reductionAmount: formData.reductionAmount,
          businessAssignment: formData.businessAssignment
        };
      }

      const response = await fetch(url, {
        method,
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(dataToSend)
      });

      if (response.ok) {
        const savedData = await response.json();
        console.log("Saved Data:", savedData);

        setFormData(savedData);
        setIsSaved(true);
        setInitialFormData(savedData);
        setError("");
        handleNavigate("/projectMng");
      } else {
        const errorData = await response.json();
        setError(
          `Failed to save data: ${errorData.message || response.statusText}`
        );
        console.error("Save failed:", errorData);
      }
    } catch (error) {
      setError("An error occurred while saving data.");
      console.error("An error occurred:", error);
    } finally {
      setLoading(false);
    }
  };
  const HeaderButton = () => (
    <form action="" style={{ display: "flex", alignItems: "center" }}>
      <Button
        sx={{
          width: "113px",
          height: "45px",
          backgroundColor: "white",
          color: "#5D38E5",
          borderRadius: 5,
          textTransform: "none",
          ":hover": {
            backgroundColor: "#5D38E5",
            color: "white"
          }
        }}
        onClick={handleSave}
        disabled={loading}
      >
        {isSaved ? "Update" : "Create"}
      </Button>
      {isSaved && (
        <Button
          sx={{
            width: "113px",
            height: "45px",
            borderRadius: "40px",
            padding: "20px, 0px, 20px, 0",
            textTransform: "none",
            backgroundColor: "white",
            color: "red",
            ":hover": {
              backgroundColor: "red",
              color: "white"
            },
            marginLeft: "10px"
          }}
          onClick={() => handleClickOpenDialog("delete")}
          disabled={loading}
        >
          Delete
        </Button>
      )}
    </form>
  );

  const handleClickOpenDialog = (type: "delete" | "navigate") => {
    setDialogType(type);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setDialogType(null);
  };

  const handleConfirmDelete = async () => {
    if (!id) return;
    setLoading(true);
    try {
      const response = await fetch(
        SERVER_CONFIG.CARBON_SERVER + `/project/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json"
          }
        }
      );
      if (response.ok) {
        setError("");
        handleNavigate("/projectMng");
      } else {
        const errorData = await response.json();
        setError(
          `Failed to delete data: ${errorData.message || response.statusText}`
        );
        console.error("Delete failed:", errorData);
      }
    } catch (error) {
      setError("An error occurred while deleting data.");
      console.error("An error occurred:", error);
    } finally {
      setLoading(false);
      handleCloseDialog();
    }
  };

  const handleConfirmNavigate = () => {
    handleNavigate("/projectMng");
    handleCloseDialog();
  };

  const handleNavigate = (route: string) => {
    navigate(route);
    // 페이지를 새로고침
    window.location.reload();
  };

  const HeaderBackButton = () => {
    const handleBackClick = () => {
      if (hasUnsavedChanges) {
        handleClickOpenDialog("navigate");
      } else {
        handleNavigate("/projectMng");
      }
    };

    return (
      <IconButton
        sx={{
          ":hover": { backgroundColor: "#F2F2F2" },
          mb: 0.5,
          mr: 1
        }}
        onClick={handleBackClick}
      >
        <ArrowBackIosNewIcon fontSize="medium" />
      </IconButton>
    );
  };

  // Handle tab change
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <CommonList
      title={id ? "프로젝트 상세" : "프로젝트 등록"}
      setModal={null}
      headerButton={HeaderButton()}
      headerBackButton={HeaderBackButton()}
    >
      <Box>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          aria-label="project tabs"
          sx={{
            "& .MuiTabs-indicator": {
              bgcolor: "#5D38E5",
              fontSize: "18px"
            }
          }}
        >
          <Tab
            label={
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  fontSize: "18px",
                  color: activeTab === 0 ? "black" : "grey",
                  "&.Mui-selected": {
                    color: "black"
                  }
                }}
              >
                기업 정보
              </Typography>
            }
          />
          <Tab
            label={
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  fontSize: "18px",
                  color: activeTab === 1 ? "black" : "grey"
                }}
              >
                프로젝트 정보
              </Typography>
            }
          />
        </Tabs>
        <Box sx={{ p: 0 }}>
          {activeTab === 0 && (
            <Box>
              <Box>
                <CreateProjectGridPage
                  formData={formData}
                  setFormData={setFormData}
                  error={""}
                />
              </Box>
            </Box>
          )}
          {activeTab === 1 && (
            <Box>
              <ProjectInfoPage
                formData={formData}
                setFormData={setFormData}
                error={""}
              />
            </Box>
          )}
        </Box>
      </Box>

      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        PaperProps={{
          sx: {
            minWidth: { xs: "70%", sm: "500px" },
            minHeight: "auto",
            borderRadius: "25px",
            backgroundColor: "#ffffff"
          }
        }}
      >
        <DialogContent
          sx={{
            padding: { xs: "1rem", sm: "2rem" },
            color: "black",
            textAlign: "center",
            fontSize: { xs: "0.875rem", sm: "1rem" }
          }}
        >
          {dialogType === "delete" ? (
            <>
              <p>삭제한 프로젝트는 복구할 수 없습니다.</p>
              <p>프로젝트를 삭제 하시겠습니까?</p>
            </>
          ) : (
            <>
              <p>
                작성하던 것을 중단하고 이동하시겠습니까? 해당 사항은 저장되지
                않습니다.
              </p>
            </>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            padding: "2rem",
            backgroundColor: "#ffffff",
            justifyContent: "center"
          }}
        >
          <Button
            onClick={handleCloseDialog}
            variant="outlined"
            sx={{
              color: "#5D38E5",
              borderBlockColor: "#5D38E5",
              borderRadius: "25px"
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={
              dialogType === "delete"
                ? handleConfirmDelete
                : handleConfirmNavigate
            }
            variant="contained"
            sx={{
              color: "#ffffff",
              backgroundColor: "#5D38E5",
              borderRadius: "25px"
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </CommonList>
  );
};

export default CreateProjectPage;
