import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Toolbar from "../../../component/Toolbar";
import { ROUTE_PATHS } from "../../../constants/RouteConstants";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";
import { ApiService } from "../../../restAPI/ApiService";
import { color } from "../../../theme/Color";
import { fontStyle } from "../../../theme/Style";
import { partners, typeData } from "../model/PartnersModel";
import { BannerModel } from "../model/BannerModel";
import { getCountryCode } from "../../../customHook/useLocale";
import useAndroidBackHandler from "../../../customHook/useAndroidBackHandler";
import PartnersCategoryButton from "../component/PartnersCategoryButton";
import PartnersItem from "../component/PartnersItem";

const MorePartnersPage = () => {
  const [type, setType] = useState<typeData>({ svcType: "All" });
  const [partnersList, setPartnersList] = useState<partners[]>([]);
  const navigate = useNavigate();
  const [banner, setBanner] = useState<BannerModel | undefined>(undefined);
  const categories = [
    { id: "All", label: LocalizedText("ua_g600_all") },
    { id: "Hotel", label: LocalizedText("ua_g600_hotel") },
    { id: "Restaurant", label: LocalizedText("ua_g600_restaurant") },
    { id: "Hot Spot", label: LocalizedText("ua_g600_hotspot") },
  ];

  useAndroidBackHandler(() => {
    window.history.back();
  });

  useEffect(() => {
    getBanners();
  }, []);

  async function getBanners() {
    const url = "/pw/ua/contents/banner/random";
    const requestBody = {
      country: getCountryCode(),
    };
    console.log(getCountryCode());

    await ApiService.MobilePost(url, requestBody)
      .then((res) => {
        const response = res?.data.body;
        console.log(`BANNER RANDOM response`, res);
        if (response && Object.keys(response).length > 0) {
          setBanner(response as BannerModel);
        } else {
          console.log("보여줄 랜덤 배너가 없습니다.");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  useEffect(() => {
    resetPartnersList();
  }, [type.svcType]);

  const resetPartnersList = async () => {
    const url = "/pw/backend/api/partners/list";
    const requestBody = type.svcType === "All" ? {} : type;
    const partnersDataList: partners[] = [];
    await ApiService.MobilePost(url, requestBody).then((res) => {
      res?.data.body.forEach((partners: any) => {
        const partnersData: partners = {
          serviceId: partners.svcId,
          serviceName: partners.svcNm,
          serviceDescription: partners.svcDescription,
          location: partners.location,
          serviceImage: partners.svcImage,
        };
        partnersDataList.push(partnersData);
      });

      setPartnersList(partnersDataList);
    });
  };

  type CustomCSSProperties = React.CSSProperties & {
    "--swiper-pagination-color"?: string;
    "--swiper-pagination-top"?: string;
    "--swiper-pagination-left"?: string;
    "--swiper-pagination-right"?: string;
    "--swiper-pagination-fraction-color"?: string;
  };

  // Define the custom styles
  const swiperStyles: CustomCSSProperties = {
    "--swiper-pagination-fraction-color": "white",
    // "--swiper-pagination-top": "75%",
    // "--swiper-pagination-left": "75%",
    height: "100%",
    width: "100%",
  };
  const changeType = (id: string) => {
    console.log(id);
    setType({ svcType: id });
  };

  const navigateDetail = (partners: partners) => {
    navigate(ROUTE_PATHS.PARTNERS_DETAIL, {
      state: { svcId: partners.serviceId },
    });
  };

  return (
    <Box sx={{ height: "100vh", display: "flex", flexDirection: "column" }}>
      <Toolbar
        onBack={() => {
          window.history.back();
        }}
        title={LocalizedText("ua_g600_partners")}
      />

      <Box
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          paddingLeft: "20px",
          paddingRight: "20px",
          boxSizing: "border-box",
        }}
      >
        {banner ? (
          <Box sx={{ position: "relative" }}>
            <Swiper
              style={swiperStyles}
              slidesPerView={1}
              // height={100}
              spaceBetween={10}
              pagination={{
                type: "fraction",
                renderFraction: function (currentClass: any, totalClass: any) {
                  return (
                    '<div style="background-color: ' +
                    color.baseDimmer +
                    '; border-radius: 50px; display: flex; align-items: center; justify-content: center; padding: 2px 8px; margin-left: 87%; width: fit-content;">' +
                    '<span class="' +
                    currentClass +
                    '" style="' +
                    "font-size: " +
                    fontStyle.bodySr.fontSize +
                    "; " +
                    "line-height: " +
                    fontStyle.bodySr.lineHeight +
                    "; " +
                    "font-weight: " +
                    fontStyle.bodySr.fontWeight +
                    ';">' +
                    "</span>" +
                    '<span style="color: ' +
                    color.gray300 +
                    "; " +
                    "font-size: " +
                    fontStyle.bodySr.fontSize +
                    "; " +
                    "line-height: " +
                    fontStyle.bodySr.lineHeight +
                    "; " +
                    "font-weight: " +
                    fontStyle.bodySr.fontWeight +
                    ';">/</span>' +
                    '<span class="' +
                    totalClass +
                    '" style="color: ' +
                    color.gray300 +
                    "; " +
                    "font-size: " +
                    fontStyle.bodySr.fontSize +
                    "; " +
                    "line-height: " +
                    fontStyle.bodySr.lineHeight +
                    "; " +
                    "font-weight: " +
                    fontStyle.bodySr.fontWeight +
                    ';">' +
                    "</span>" +
                    "</div>"
                  );
                },
              }}
              modules={[Pagination]}
            >
              <SwiperSlide>
                <img
                  src={banner.image}
                  style={{ width: "100%", height: "88px", borderRadius: 10 }}
                  onClick={() => {
                    // TODO 앱에서 어떻게 해당 URL을 열지 고민이 필요함.
                    console.log(
                      `TODO 앱에서 어떻게 해당 URL을 열지 고민이 필요함. , ${banner.link}`
                    );
                    // window.location.href = //banner.link;
                  }}
                />
                <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
              </SwiperSlide>
            </Swiper>
          </Box>
        ) : null}

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            gap: "10px",
            marginTop: 2,
            marginBottom: 2,
            paddingLeft: 0,
            paddingRight: 4,
            overflowX: "auto",
            whiteSpace: "nowrap",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {categories.map(({ id, label }) => (
            <PartnersCategoryButton
              key={id}
              category={{ label, isSelected: type.svcType === id }}
              onClick={() => changeType(id)}
            />
          ))}
        </Box>

        {partnersList.length === 0 && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50%",
            }}
          >
            <Typography
              sx={{
                fontStyle: fontStyle.bodyMr,
                color: color.gray500,
                textAlign: "center",
                whiteSpace: "pre-line",
              }}
            >
              No partners available at the moment
            </Typography>
          </Box>
        )}

        {partnersList.map((partners) => (
          <PartnersItem
            key={partners.serviceId}
            partners={partners}
            onClick={() => navigateDetail(partners)}
          />
        ))}
      </Box>
    </Box>
  );
};

export default MorePartnersPage;
