import { Box, Typography } from "@mui/material";
import CommonButton from "../../component/CommonButton";
import { MoreProfilePageSideEffect } from "./MoreProfilePageSideEffect";
import Toolbar from "../../component/Toolbar";
import VerticalSpacer from "../../component/Spacer";
import { fontStyle } from "../../theme/Style";
import InputField from "./section/InputField";
import { LocalizedText } from "../../di/LanguageRepositoryProvider";
import LocalizedDate, { DateFormatType } from "../../component/LocalizedDate";
import { ProfilePageUiState } from "./model/ProfilePageUiState";

interface MoreProfileRenderProps {
  uiState: ProfilePageUiState;
  sideEffect: MoreProfilePageSideEffect;
}

const MoreProfileRender = ({ uiState, sideEffect }: MoreProfileRenderProps) => {
  return (
    <>
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          overflowY: "scroll",
          scrollbarWidth: "none", // Firefox에서 스크롤바 숨기기
          "&::-webkit-scrollbar": {
            display: "none", // Webkit 브라우저에서    스크롤바 숨기기
          },
        }}
      >
        {/* 상단 toolbar */}
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Toolbar
            title={LocalizedText("ua_g200_profile")}
            onBack={() => {
              window.history.back();
            }}
          />
        </Box>
        {/* 중간 요소 */}
        <VerticalSpacer height={23.83} />
        <Box
          sx={{
            width: "calc(100% - 40px)",
          }}
        >
          <InputField
            type={LocalizedText("ua_g200_name")}
            content={uiState.name}
          />
          <VerticalSpacer height={30} />
          <InputField
            type={LocalizedText("ua_g200_email")}
            content={uiState.email}
          />
          <VerticalSpacer height={30} />
          <InputField
            type={LocalizedText("ua_g210_phonenumber")}
            subContent={`+${uiState.countryNumber}`}
            content={uiState.phoneNumber}
          />
          <VerticalSpacer height={30} />
          <InputField
            type={LocalizedText("ua_g200_birthofdate")}
            content={LocalizedDate({
              date: uiState.birthday,
              type: DateFormatType.YearMonthDay,
            })}
          />
          <VerticalSpacer height={30} />
          <InputField
            type={LocalizedText("ua_g200_gender")}
            content={uiState.gender}
          />
          <VerticalSpacer height={30} />
          <InputField
            type={LocalizedText("ua_g200_address")}
            content={uiState.address}
          />
          <VerticalSpacer height={30} />
          <InputField
            type={"Tribe Category (Only if applicable) "}
            content={uiState.tribeCategory}
          />
          <VerticalSpacer height={30} />
          <InputField type={"Tribe Name"} content={uiState.tribeName} />
          <VerticalSpacer height={30} />
          {!window.webkit && (
            <Box
              onClick={() => {
                sideEffect.navigate("Withdrawal");
              }}
            >
              <Typography
                sx={{
                  display: "inline-block",
                  borderBottom: 1,
                  color: "#C7C7C7",
                }}
                fontStyle={fontStyle.subTitleS}
              >
                {LocalizedText("ua_g220_deleteaccount")}
              </Typography>
            </Box>
          )}
          <VerticalSpacer height={30} />
          <VerticalSpacer height={66} />
        </Box>
        {/* 하단 버튼 */}
        <Box
          sx={{
            position: "absolute",
            bottom: "0px",
            width: "100%",
            display: "flex",
          }}
        >
          <CommonButton
            title={LocalizedText("ua_g200_editprofile_button")}
            textColor="white"
            onClick={() => {
              sideEffect.navigate("EditProfile");
            }}
            sx={{
              mx: "20px",
              mb: "14px",
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default MoreProfileRender;
