import CommonTable, {
  HeadCell,
  useVisibleRows,
} from "../../../../component/CommonTable";
import { RegionManagementTableRow } from "./RegionManagementTableRow";

interface RegionManagementTableProps {
  headCells?: HeadCell[];
  mngRows?: any;
  rowsPerPage?: number;
  setRowsPerPage?: React.Dispatch<React.SetStateAction<number>>;
  page?: number;
  pageCount?: number;
  setPage?: any;
  docCount?: any;
}
interface RegionManagementData {
  [x: string]: string | number;
}

export function RegionManagementTable({
  headCells,
  mngRows,
  rowsPerPage,
  setRowsPerPage,
  docCount,
  pageCount,
  page,
  setPage,
}: RegionManagementTableProps) {
  console.log(rowsPerPage);
  const { order, orderBy, handleRequestSort, handleChangePage, visibleRows } =
    useVisibleRows(mngRows, 10, setPage, page);

  console.log("visibleRows is : ", visibleRows);
  return (
    <CommonTable
      headCells={headCells}
      order={order}
      orderBy={orderBy}
      page={page}
      docCount={docCount}
      pageCount={pageCount}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      handleRequestSort={handleRequestSort}
      handleChangePage={handleChangePage}
      setPage={setPage}
    >
      {visibleRows.map((data: RegionManagementData) => (
        <RegionManagementTableRow row={data} key={data.no} />
      ))}
    </CommonTable>
  );
}
