import React from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { SxProps, Theme } from "@mui/material";
import { color } from "../theme/Color";
import { fontStyle } from "../theme/Style";

interface CommonTextFieldProps {
  sx?: SxProps<Theme>; // 스타일링을 위한 sx
  placeholder?: string; // 플레이스홀더 텍스트 (선택적)
  value?: string; // 입력 값
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void; // 변경 이벤트 핸들러
  multiline?: boolean;
  rows?: number;
  disabled?: boolean; // 비활성화 상태
  InputProps?: TextFieldProps["InputProps"]; // 추가 InputProps
  inputProps?: TextFieldProps["inputProps"]; // 추가 InputProps
  error?: TextFieldProps["error"]; // 추가 InputProps
}

const CommonTextField = ({
  sx,
  placeholder,
  value,
  onChange,
  multiline = false,
  disabled = false,
  rows,
  InputProps, // InputProps 추가
  inputProps,
  error,
}: CommonTextFieldProps) => {
  return (
    <TextField
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      multiline={multiline}
      disabled={disabled}
      rows={rows}
      sx={{
        "& .MuiOutlinedInput-root": {
          borderRadius: "10px",
          "& fieldset": {
            borderColor: color.gray300, // 기본 border 색상
          },
          "&:hover fieldset": {
            borderColor: color.gray300, // hover 시 border 색상
          },
          "&.Mui-focused fieldset": {
            borderColor: color.primary500, // 포커스 시 border 색상
            borderWidth: "1px",
          },
          "&.Mui-disabled fieldset": {
            backgroundColor: color.gray200,
            borderColor: color.gray300, // 비활성화 시 border 색상
          },
        },
        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
          borderColor: color.error, // 원하는 색상으로 변경
        },
        "& .MuiFormLabel-root.Mui-error": {
          color: color.error, // 라벨 색상 변경
        },

        ...sx, // 커스텀 스타일을 덮어씌울 수 있도록 props에서 전달받은 sx 적용
      }}
      InputProps={{
        style: {
          borderRadius: "10px",
        },

        ...InputProps, // 전달받은 InputProps와 병합
      }}
      inputProps={{
        style: {
          fontSize: fontStyle.regularS.fontSize, // fontSize 추가
          lineHeight: fontStyle.regularS.lineHeight, // lineHeight 추가
          fontWeight: fontStyle.regularS.fontWeight, // fontWeight 추가
          zIndex: 100,
          // backgroundColor: "white",
        },

        autoComplete: "off", // 자동완성 비활성화

        ...inputProps,
      }}
      error={error}
    />
  );
};

export default CommonTextField;
