import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import DnsOutlinedIcon from "@mui/icons-material/DnsOutlined";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import FiberManualRecordOutlinedIcon from "@mui/icons-material/FiberManualRecordOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import MiscellaneousServicesOutlinedIcon from "@mui/icons-material/MiscellaneousServicesOutlined";
import { Children } from "react";
import { Box } from "@mui/material";
import {
  ICON_BLOCKCHAIN,
  ICON_CARBON,
  ICON_CHAT,
  ICON_CONTENTS,
  ICON_PACKAGE,
  ICON_PROFILE,
  ICON_STATISTICS,
  ICON_SYSTEM,
  ICON_CASH,
} from "../constants/imagePath";

export interface MenuItem {
  title: string;
  key: string;
  type: string;
  url: string;
  Icon?: JSX.Element;
  Children?: MenuItem[];
}

const ItemIcon = () => {
  return (
    <FiberManualRecordOutlinedIcon
      sx={{
        width: 12,
        height: 12,
        ml: 1.5,
      }}
    />
  );
};

export const MenuList: { [key: string]: MenuItem } = {
  Home: {
    title: "",
    key: "",
    type: "group",
    url: "",
    Children: [
      {
        title: "Statistics", // 사용자 관리
        key: "statistics",
        type: "collapse",
        url: "",
        Icon: <Box component="img" src={ICON_STATISTICS} />,
        Children: [
          {
            title: "Statistics", // 통계
            key: "statistics",
            type: "item",
            url: "/dashboard",
            Icon: ItemIcon(),
          },
        ],
      },
      {
        title: "User Management", // 사용자 관리
        key: "userManagement",
        type: "collapse",
        url: "",
        Icon: <Box component="img" src={ICON_PROFILE} />,
        Children: [
          {
            title: "Member", // 회원 관리
            key: "member",
            type: "item",
            url: "/usrMng",
            Icon: ItemIcon(),
          },
          {
            title: "Administrator", // 관리자 관리
            key: "administrator",
            type: "item",
            url: "/adminMng",
            Icon: ItemIcon(),
          },
          {
            title: "Group", // 그룹 관리
            key: "group",
            type: "item",
            url: "/grpMng",
            Icon: ItemIcon(),
          },
        ],
      },
      {
        title: "Content", // 컨텐츠 관리
        key: "content",
        type: "collapse",
        url: "",
        Icon: <Box component="img" src={ICON_CONTENTS} />,
        Children: [
          {
            title: "FAQ", // FAQ 관리
            key: "faq",
            type: "item",
            url: "/FAQMng",
            Icon: ItemIcon(),
          },
          {
            title: "1:1 Inquiry", // 1:1 문의 관리
            key: "inquiry",
            type: "item",
            url: "/inquiryMng",
            Icon: ItemIcon(),
          },
          {
            title: "CS", // CS 관리
            key: "cs",
            type: "item",
            url: "/csMng",
            Icon: ItemIcon(),
          },
          {
            title: "Notice", // 공지 사항 관리
            key: "notice",
            type: "item",
            url: "/announcement",
            Icon: ItemIcon(),
          },
          {
            title: "Push Notification", // 푸시 알람 관리
            key: "pushNotification",
            type: "item",
            url: "/pushMng",
            Icon: ItemIcon(),
          },
          {
            title: "Popup/Banner", // 팝업 관리
            key: "popupBanner",
            type: "item",
            url: "/popupMng",
            Icon: ItemIcon(),
          },
        ],
      },
      {
        title: "Merchandise", // 상품 관리
        key: "merchandise",
        type: "collapse",
        url: "",
        Icon: <Box component="img" src={ICON_CASH} />,
        Children: [
          {
            title: "Membership Voucher Settings", // 멤버십 바우처 설정
            key: "membershipVoucher",
            type: "item",
            url: "/membershipVoucherSetting",
            Icon: ItemIcon(),
          },
          {
            title: "NFT Settings", // NFT 설정
            key: "nft",
            type: "item",
            url: "/NFTManagement",
            Icon: ItemIcon(),
          },
          {
            title: "Coupon Settings", // 쿠폰 설정
            key: "coupon",
            type: "item",
            url: "/",
            Icon: ItemIcon(),
          },
          {
            title: "Payment", // 결제 관리
            key: "payment",
            type: "item",
            url: "/paymentManagement",
            Icon: ItemIcon(),
          },
        ],
      },
      {
        title: "Production Material", // Production
        key: "productionMaterial",
        type: "collapse",
        url: "",
        Icon: <Box component="img" src={ICON_PACKAGE} />,
        Children: [
          {
            title: "Production", // 생산 관리
            key: "production",
            type: "item",
            url: "/productionManagement",
            Icon: ItemIcon(),
          },
          {
            title: "Inventory", // 재고 관리
            key: "inventory",
            type: "item",
            url: "/inventoryManagement",
            Icon: ItemIcon(),
          },
          {
            title: "Request", // 요청 관리
            key: "request",
            type: "item",
            url: "/requestManagement",
            Icon: ItemIcon(),
          },
          {
            title: "Product Name", // 모델 관리
            key: "productName",
            type: "item",
            url: "/modelManagement",
            Icon: ItemIcon(),
          },
        ],
      },
      {
        title: "Operations", // 운영 관리
        key: "operations",
        type: "collapse",
        url: "",
        Icon: <Box component="img" src={ICON_CHAT} />,
        Children: [
          {
            title: "Area and Service", // 지역 및 서비스 관리
            key: "regionService",
            type: "item",
            url: "/regionAndServiceManagement",
            Icon: ItemIcon(),
          },
          {
            title: "Scooter", // 스쿠터 관리
            key: "scooter",
            type: "item",
            url: "/vehicleMng",
            Icon: ItemIcon(),
          },
          {
            title: "Station", // 스테이션 관리
            key: "station",
            type: "item",
            url: "/stationMng",
            Icon: ItemIcon(),
          },
          {
            title: "Battery", // 배터리 관리
            key: "battery",
            type: "item",
            url: "/batteryMng",
            Icon: ItemIcon(),
          },
          {
            title: "NFC Card", // NFC 카드 관리
            key: "nfcCard",
            type: "item",
            url: "/nfcCardManagement",
            Icon: ItemIcon(),
          },
        ],
      },
      {
        title: "Carbon Reduction", // 탄소감축량관리
        key: "carbonReduction",
        type: "collapse",
        url: "",
        Icon: <Box component="img" src={ICON_CARBON} />,
        Children: [
          {
            title: "Methodology", // 방법론 관리
            key: "methodology",
            type: "item",
            url: "/methodologyMng",
            Icon: ItemIcon(),
          },
          {
            title: "Project", // 프로젝트 관리
            key: "project",
            type: "item",
            url: "/projectMng",
            Icon: ItemIcon(),
          },
          {
            title: "Data/Parameter", // 데이터/인자 관리
            key: "dataParameter",
            type: "item",
            url: "/paramDataMng",
            Icon: ItemIcon(),
          },
          {
            title: "Monitoring", // 모니터링 관리
            key: "monitoring",
            type: "item",
            url: "/monitoringMng",
            Icon: ItemIcon(),
          },
        ],
      },
      {
        title: "Blockchain", // 블록체인 관리
        key: "blockchain",
        type: "collapse",
        url: "",
        Icon: <Box component="img" src={ICON_BLOCKCHAIN} />,
        Children: [
          {
            title: "Blockchain Monitoring", // 블록체인 모니터링
            key: "monitoring",
            type: "item",
            url: "/blockchainMonitoring",
            Icon: ItemIcon(),
          },
          {
            title: "Eco-Platform Wallet", // 에코플랫폼 월렛
            key: "wallet",
            type: "item",
            url: "/wallet",
            Icon: ItemIcon(),
          },
        ],
      },
      {
        title: "System Setting", // 시스템 관리
        key: "system",
        type: "collapse",
        url: "",
        Icon: <Box component="img" src={ICON_SYSTEM} />,
        Children: [
          {
            title: "Notification", // 알림 설정
            key: "notification",
            type: "item",
            url: "/notiMng",
            Icon: ItemIcon(),
          },
          {
            title: "Multi-language", // 다국어 설정
            key: "multiLanguage",
            type: "item",
            url: "/multilingual",
            Icon: ItemIcon(),
          },
          {
            title: "History Management", // 이력 관리
            key: "batch",
            type: "item",
            url: "/historyMng",
            Icon: ItemIcon(),
          },
          {
            title: "Batch", // 연계/배치 관리
            key: "history",
            type: "item",
            url: "/linkBatchMng",
            Icon: ItemIcon(),
          },
        ],
      },
      //       {
      //         title: "MQTT",
      //         type: "item",
      //         url: "/mqtt",
      //         Icon: <DashboardOutlinedIcon />,
      //       },
    ],
  },
};
