import {
  Box,
  Divider,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { ApiService } from "../../restAPI/ApiService";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { IMAGE_BANNER_01 } from "../../constants/appImagePath";
import VerticalSpacer from "../../component/Spacer";
import { PWUserAppMapUiEvent } from "./PWUserAppMapUiEvent";
import useAndroidBackHandler from "../../customHook/useAndroidBackHandler";
import { LocalizedText } from "../../di/LanguageRepositoryProvider";
import { fontStyle } from "../../theme/Style";
import { color } from "../../theme/Color";
import { BannerModel } from "../MobilePartners/model/BannerModel";
import { getCountryCode } from "../../customHook/useLocale";

interface SwiperPhotosProps {
  image: string;
  url: string;
}

// 배너 자리 보여주는 컴포넌트
const SwiperPhotos = ({ image, url }: SwiperPhotosProps) => {
  type CustomCSSProperties = React.CSSProperties & {
    "--swiper-pagination-color"?: string;
    "--swiper-pagination-top"?: string;
    "--swiper-pagination-left"?: string;
    "--swiper-pagination-right"?: string;
    "--swiper-pagination-fraction-color"?: string;
  };

  // Define the custom styles
  const swiperStyles: CustomCSSProperties = {
    "--swiper-pagination-fraction-color": "white",
    // "--swiper-pagination-top": "75%",
    // "--swiper-pagination-left": "75%",
    height: "100%",
    width: "100%",
  };
  return (
    <>
      <Swiper
        style={swiperStyles}
        slidesPerView={1}
        // height={100}
        spaceBetween={10}
        pagination={{
          type: "fraction",
          renderFraction: function (currentClass: any, totalClass: any) {
            return (
              '<div style="background-color: gray; border-radius: 10px; display: inline-block; margin-left: 82%;width:12%;">' +
              '<span class="' +
              currentClass +
              '" style="">asdasdasdsadsad</span>' +
              "/" +
              '<span class="' +
              totalClass +
              '"></span>' +
              "</div>"
            );
          },
        }}
        modules={[Pagination]}
      >
        <SwiperSlide>
          <img
            src={image}
            style={{ width: "100%", height: "100%", borderRadius: 10 }}
            onClick={() => {
              // TODO 앱에서 어떻게 해당 URL을 열지 고민이 필요함.
              console.log(
                `TODO 앱에서 어떻게 해당 URL을 열지 고민이 필요함. , ${url}`
              );
            }}
          />
          <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
        </SwiperSlide>
      </Swiper>
    </>
  );
};

interface SearchStationPageProps {
  uiEvent: PWUserAppMapUiEvent;
}

const SearchStationPage = ({ uiEvent }: SearchStationPageProps) => {
  const [searchWord, setSearchWord] = useState("");
  const [searchHistory, setSearchHistory] = useState<any>([]);
  const [searchResults, setSearchResults] = useState([]); // 검색 결과 상태 추가

  const translateWord = {
    ua_c200_searchstation: LocalizedText("ua_c200_searchstation"),
    ua_c200_recentresearch: LocalizedText("ua_c200_recentresearch"),
    ua_c200_last3months: LocalizedText("ua_c200_last3months"),
    ua_c200_deleteall: LocalizedText("ua_c200_deleteall"),
    ua_c200_noresults: LocalizedText("ua_c200_noresults"),
    ua_c200_wordcorrectly: LocalizedText("ua_c200_wordcorrectly"),
    ua_c200_nohistory: LocalizedText("ua_c200_nohistory"),
  };

  // useAndroidBackHandler(() => {
  //   uiEvent.SetIsClickSearchStation(false);
  // }, []);
  // 페이지가 로드될 때 로컬 스토리지에서 검색 기록을 가져옴
  useEffect(() => {
    const savedHistory = localStorage.getItem("searchHistory");
    if (savedHistory) {
      setSearchHistory(JSON.parse(savedHistory));
    }
  }, []);

  // 검색어 변경 핸들러
  const handleSearchWordChange = (event: any) => {
    setSearchWord(event.target.value);
    // API 요청 보내기
    const searchData = {
      searchCondition: event.target.value,
    };
    if (event.target.value.length > 0) {
      ApiService.MobilePost("/pw/backend/api/geo/search", searchData).then(
        (res: any) => {
          // API 응답 데이터를 searchResults 상태에 저장
          setSearchResults(res.data.body);
        }
      );
    }
  };

  useEffect(() => {
    if (searchWord.length === 0) {
      setSearchResults([]);
    }
  }, [searchWord]);

  useEffect(() => {
    console.log(searchResults);
  }, [searchResults]);

  // 특정 검색어 삭제 핸들러
  const handleDeleteHistory = (idToDelete: any) => {
    const updatedHistory = searchHistory.filter(
      (item: any) => item.svcAreaId !== idToDelete
    );
    localStorage.setItem("searchHistory", JSON.stringify(updatedHistory));
    setSearchHistory(updatedHistory);
  };

  const handleSearchResultClick = (item: any) => {
    // 기존 검색 기록에서 클릭된 아이템을 찾기
    const existingIndex = searchHistory.findIndex(
      (historyItem: any) => historyItem.svcAreaId === item.svcAreaId
    );

    let updatedHistory;
    if (existingIndex !== -1) {
      // 아이템이 존재하면, 해당 아이템을 제거하고 맨 앞에 추가
      const existingItem = searchHistory[existingIndex];
      const filteredHistory = searchHistory.filter(
        (historyItem: any) => historyItem.svcAreaId !== item.svcAreaId
      );
      updatedHistory = [existingItem, ...filteredHistory];
    } else {
      // 중복이 아니면 검색 기록에 추가하고 가장 위에 위치시키기
      updatedHistory = [
        { svcAreaId: item.svcAreaId, svcAreaNm: item.svcAreaNm },
        ...searchHistory,
      ];
    }

    setSearchHistory(updatedHistory);
    localStorage.setItem("searchHistory", JSON.stringify(updatedHistory));

    uiEvent.SetSvcAreaId(item.svcAreaId);

    ApiService.MobilePost("/pw/backend/api/geo/searchGeoPoint", {
      svcAreaId: item.svcAreaId,
    })
      .then((res: any) => {
        const position = new google.maps.LatLng(
          res.data.body.lat,
          res.data.body.lon
        );
        uiEvent.SetPositionChange(position);
        uiEvent.SetSvcFilterType([]);
        uiEvent.SetSelectedChip("");
        uiEvent.SetFinalPosition({
          lat: res.data.body.lat,
          lng: res.data.body.lon,
        });
      })
      .then(() => {
        uiEvent.SetIsSearchListClick(true);
      })
      .then(() => {
        uiEvent.SetIsClickSearchStation(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [banner, setBanner] = useState<BannerModel | undefined>(undefined);
  useEffect(() => {
    getBanners();
  }, []);

  async function getBanners() {
    const url = "/pw/ua/contents/banner/random";
    const requestBody = {
      country: getCountryCode(),
    };
    console.log(getCountryCode());

    await ApiService.MobilePost(url, requestBody)
      .then((res) => {
        const response = res?.data.body;
        console.log(`response ${response}`);
        if (response && Object.keys(response).length > 0) {
          setBanner(response as BannerModel);
        } else {
          console.log("보여줄 랜덤 배너가 없습니다.");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "white",
          width: "100vw",
          height: "100vh",
        }}
      >
        {searchWord.length > 0 ? (
          <>
            {/* 검색창 */}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "white",
              }}
            >
              <Box
                sx={{
                  width: "calc(100% - 40px)",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <VerticalSpacer height={44} />
                <Paper
                  component="form"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    height: "52px",
                    borderRadius: 2,
                    color: "#BFBFBF",
                    border: 1,
                  }}
                  elevation={0}
                >
                  <div
                    style={{ marginLeft: 10, marginTop: 4, color: "black" }}
                    onClick={() => {
                      uiEvent.SetIsClickSearchStation(false);
                    }}
                  >
                    <ArrowBackIcon />
                  </div>

                  <InputBase
                    sx={{ ml: 1, flex: 1, zIndex: 1500 }}
                    placeholder=""
                    value={searchWord}
                    onChange={handleSearchWordChange}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        event.preventDefault(); // 엔터 키 입력 시에만 기본 동작 차단
                      }
                    }}
                  />
                </Paper>
                <VerticalSpacer height={10} />
                <Box sx={{ flexGrow: 1 }}>
                  <List>
                    {searchResults.map((item: any) => (
                      <div key={item.id}>
                        <VerticalSpacer height={12} />
                        <ListItem
                          disablePadding
                          sx={{ height: 59 }}
                          onClick={() => {
                            handleSearchResultClick(item);
                          }}
                        >
                          <ListItemText>{item.svcAreaNm}</ListItemText>
                        </ListItem>
                        <Divider />
                      </div>
                    ))}
                  </List>
                </Box>
              </Box>
              {searchResults.length == 0 && (
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <Box fontStyle={fontStyle.titleL}>
                    {translateWord.ua_c200_noresults}
                  </Box>
                  <VerticalSpacer height={4} />
                  <Box
                    fontStyle={fontStyle.bodyMr}
                    color={color.gray600}
                    sx={{
                      maxWidth: "70%", // 너비가 70%를 넘지 않도록 설정
                      wordBreak: "break-word", // 단어가 너무 길 경우 줄바꿈
                      overflowWrap: "break-word", // 줄 바꿈 처리를 위한 속성
                    }}
                  >
                    {translateWord.ua_c200_wordcorrectly}
                  </Box>
                </Box>
              )}
            </Box>
          </>
        ) : (
          <>
            {/* 검색창 */}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "white",
              }}
            >
              <Box
                sx={{
                  width: "calc(100% - 40px)",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <VerticalSpacer height={44} />
                <Paper
                  component="form"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    height: "52px",
                    borderRadius: 2,
                    color: "#BFBFBF",
                    border: 1,
                  }}
                  elevation={0}
                >
                  <div
                    style={{ marginLeft: 10, marginTop: 4, color: "black" }}
                    onClick={() => {
                      uiEvent.SetIsClickSearchStation(false);
                    }}
                  >
                    <ArrowBackIcon />
                  </div>

                  <InputBase
                    sx={{ ml: 1, flex: 1, zIndex: 1500 }}
                    placeholder={translateWord.ua_c200_searchstation}
                    value={searchWord}
                    onChange={handleSearchWordChange}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        event.preventDefault(); // 엔터 키 입력 시에만 기본 동작 차단
                      }
                    }}
                  />
                </Paper>
                {banner ? (
                  <Box
                    sx={{
                      mt: 1,
                      width: "100.9%",
                      height: "80px",
                    }}
                  >
                    <SwiperPhotos image={banner.image} url={banner.link} />
                  </Box>
                ) : null}
                <VerticalSpacer height={12} />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    width: "100%",

                    ml: 0.2,
                  }}
                >
                  <Typography
                    fontSize={14}
                    color={"#676665"}
                    fontWeight={"bold"}
                  >
                    {translateWord.ua_c200_recentresearch}
                  </Typography>
                </Box>
                <VerticalSpacer height={12} />
              </Box>
            </Box>

            {/* Divider */}
            <Box
              sx={{
                backgroundColor: "#F2F2F2",
                width: "100%",
                height: "8px",
              }}
            >
              &nbsp;
            </Box>

            {/* 최근 검색어 목록 */}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                backgroundColor: "white",
                flexGrow: 1,
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "91.7%",
                  height: "42px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  color={"#B3B3B3"}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <ErrorOutlineOutlinedIcon sx={{ mr: 0.5 }} />
                  {translateWord.ua_c200_last3months}
                </Typography>
                <Typography
                  fontSize={17}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSearchHistory([]);
                    localStorage.removeItem("searchHistory");
                  }}
                >
                  {translateWord.ua_c200_deleteall}
                </Typography>
              </Box>

              <Box sx={{ width: "91.7%", mt: 1 }}>
                <List>
                  {searchHistory.length == 0 ? (
                    <Box
                      sx={{
                        position: "absolute",
                        top: "50%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                      }}
                    >
                      <Box fontStyle={fontStyle.bodyLr} color={color.gray500}>
                        {translateWord.ua_c200_nohistory}
                      </Box>
                    </Box>
                  ) : (
                    <>
                      {searchHistory.map((item: any) => (
                        <div key={item.id}>
                          <ListItem
                            disablePadding
                            sx={{ height: 50 }}
                            onClick={() => {
                              handleSearchResultClick(item);
                            }}
                          >
                            <ListItemText>{item.svcAreaNm}</ListItemText>
                            <IconButton
                              // edge="end"
                              onClick={(event) => {
                                event.stopPropagation();
                                handleDeleteHistory(item.svcAreaId);
                              }}
                            >
                              <ClearOutlinedIcon />
                            </IconButton>
                          </ListItem>
                          <Divider />
                        </div>
                      ))}
                    </>
                  )}
                </List>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default SearchStationPage;
