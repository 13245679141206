export class StationMngDetailUiState {
  managerId: string;
  managerName: string;
  managerEmail: string;
  managerLocalNumber: string;
  managerPhoneNumber: string;
  serviceOpenHour: string;
  serviceOpenMin: string;
  serviceCloseHour: string;
  serviceCloseMin: string;
  serviceDate: boolean[];
  status: string;
  serviceName: string;
  serviceType: string; // TODO 타입으로 변경
  serviceAreaName: string;
  registrationDate: number;

  constructor(
    managerId: string = "",
    managerName: string = "",
    managerEmail: string = "",
    managerLocalNumber: string = "",
    managerPhoneNumber: string = "",
    serviceOpenHour: string = "",
    serviceOpenMin: string = "",
    serviceCloseHour: string = "",
    serviceCloseMin: string = "",
    // serviceDate = [전체, 일, 월, 화, 수, 목, 금, 토] 체크 상태를 의미
    serviceDate: boolean[] = [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ],
    status: string = "",
    serviceName: string = "",
    serviceType: string = "",
    serviceAreaName: string = "",
    registrationDate: number = 0,
  ) {
    this.managerId = managerId;
    this.managerName = managerName;
    this.managerEmail = managerEmail;
    this.managerLocalNumber = managerLocalNumber;
    this.managerPhoneNumber = managerPhoneNumber;
    this.serviceOpenHour = serviceOpenHour;
    this.serviceOpenMin = serviceOpenMin;
    this.serviceCloseHour = serviceCloseHour;
    this.serviceCloseMin = serviceCloseMin;
    this.serviceDate = serviceDate;
    this.status = status;
    this.serviceName = serviceName;
    this.serviceType = serviceType;
    this.serviceAreaName = serviceAreaName;
    this.registrationDate = registrationDate;
  }
}
