import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiService } from "../../../restAPI/ApiService";
import { Box, TableCell, TableRow, Typography } from "@mui/material";
import { fontStyle } from "../../../theme/Style";
import LocalizedDate, {
  DateFormatType,
} from "../../../component/LocalizedDate";
import CommonTable, { useVisibleRows } from "../../../component/CommonTable";
import { color } from "../../../theme/Color";
import { HorizontalSpacer } from "../../../component/Spacer";
interface StationMngTableProps {
  mngRows?: any;
  rowsPerPage?: any;
  setRowsPerPage?: any;
  page?: any;
  pageCount?: any;
  setPage?: any;
  docCount?: number;
}

interface RowProps {
  row: any;
  index: any;
}
const Row = ({ row, index }: RowProps) => {
  return (
    <>
      <TableRow>
        <TableCell align="center" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.no}</Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.name}</Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>
            {row.serialNumber}
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          {row.state == "Open" && (
            <Box
              sx={{
                fontStyle: fontStyle.regularXS,
                display: "flex",
                alignItems: "center",
                // justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  width: "7.5px",
                  height: "7.5px",
                  bgcolor: color.success,
                  borderRadius: "10px",
                }}
              />
              <HorizontalSpacer width={7.5} />
              {row.state}
            </Box>
          )}
          {row.state == "Close" && (
            <Box
              sx={{
                fontStyle: fontStyle.regularXS,
                display: "flex",
                alignItems: "center",
                // justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  width: "7.5px",
                  height: "7.5px",
                  bgcolor: color.warning2,
                  borderRadius: "10px",
                }}
              />
              <HorizontalSpacer width={7.5} />
              {row.state}
            </Box>
          )}
          {row.state == "New" && (
            <Box
              sx={{
                fontStyle: fontStyle.regularXS,
                display: "flex",
                alignItems: "center",
                // justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  width: "7.5px",
                  height: "7.5px",
                  bgcolor: color.progress,
                  borderRadius: "10px",
                }}
              />
              <HorizontalSpacer width={7.5} />
              {row.state}
            </Box>
          )}
          {row.state == "Preparing" && (
            <Box
              sx={{
                fontStyle: fontStyle.regularXS,
                display: "flex",
                alignItems: "center",
                // justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  width: "7.5px",
                  height: "7.5px",
                  bgcolor: color.gray600,
                  borderRadius: "10px",
                }}
              />
              <HorizontalSpacer width={7.5} />
              {row.state}
            </Box>
          )}
          {row.state == "Closure" && (
            <Box
              sx={{
                fontStyle: fontStyle.regularXS,
                display: "flex",
                alignItems: "center",
                // justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  width: "7.5px",
                  height: "7.5px",
                  bgcolor: color.error,
                  borderRadius: "10px",
                }}
              />
              <HorizontalSpacer width={7.5} />
              {row.state}
            </Box>
          )}
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          {row.link == "Linked" && (
            <Box
              sx={{
                fontStyle: fontStyle.regularXS,
                display: "flex",
                alignItems: "center",
                // justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  width: "7.5px",
                  height: "7.5px",
                  bgcolor: color.success,
                  borderRadius: "10px",
                }}
              />
              <HorizontalSpacer width={7.5} />
              {row.link}
            </Box>
          )}
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.country}</Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>
            {LocalizedDate({
              date: row.lastUpdateDate,
              type: DateFormatType.AdminType,
            })}
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>
            {LocalizedDate({
              date: row.registerDate,
              type: DateFormatType.AdminType,
            })}
          </Typography>
        </TableCell>
      </TableRow>
    </>
  );
};

const StationMngTable = ({
  mngRows,
  rowsPerPage,
  setRowsPerPage,
  docCount,
  page,
  pageCount,
  setPage,
}: StationMngTableProps) => {
  const headCells = [
    {
      id: "no",
      align: "center",
      disablePadding: false,
      label: "No",
    },
    {
      id: "name",
      align: "left",
      disablePadding: false,
      label: "Name",
    },

    {
      id: "serialNumber",
      align: "left",
      disablePadding: false,
      label: "Serial Number",
    },
    {
      id: "state",
      align: "left",
      disablePadding: false,
      label: "State",
    },
    {
      id: "link",
      align: "left",
      disablePadding: false,
      label: "Link",
    },

    {
      id: "country",
      align: "left",
      disablePadding: false,
      label: "Country",
    },

    {
      id: "lastUpdateDate",
      align: "left",
      disablePadding: false,
      label: "Last Update Date",
    },
    {
      id: "registerDate",
      align: "left",
      disablePadding: false,
      label: "Register Date",
    },
  ];

  const { order, orderBy, handleRequestSort, handleChangePage, visibleRows } =
    useVisibleRows(mngRows, rowsPerPage, setPage, page);

  return (
    <CommonTable
      headCells={headCells}
      order={order}
      orderBy={orderBy}
      page={page}
      docCount={docCount}
      pageCount={pageCount}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      handleRequestSort={handleRequestSort}
      handleChangePage={handleChangePage}
      setPage={setPage}
    >
      {visibleRows.map((row: any, index: any) => (
        <Row row={row} index={index} key={index} />
      ))}
    </CommonTable>
  );
};

export default StationMngTable;
