import { Box } from "@mui/material";
import {
  APP_LOGO,
  ICON_SNS_APPLE,
  ICON_SNS_GOOGLE,
  IMAGE_LOGIN_BACKGROUND,
  IMAGE_LOGIN_CHARACTER,
} from "../../constants/appImagePath";
import { useLayoutEffect, useState } from "react";
import SNSLoginButton from "./section/SNSLoginButton";
import { color } from "../../theme/Color";
import VerticalSpacer from "../../component/Spacer";
import { Text } from "../../component/Text";
import { fontStyle } from "../../theme/Style";
import { LoginPageUiEvent } from "./model/LoginPageUiEvent";
import { LoginPageSideEffect } from "./model/LoginPageSideEffect";
import LanguageSelect from "./section/LanguageSelect";
import { getLanguageCode } from "../../customHook/useLocale";
import { LoginPageUiState } from "./model/LoginPageUiState";

interface LoginPageRenderProps {
  uiState: LoginPageUiState;
  uiEvent: LoginPageUiEvent;
  sideEffect: LoginPageSideEffect;
}

export default function LoginPageRender({
  uiState,
  uiEvent,
  sideEffect,
}: LoginPageRenderProps) {
  const backgroundImage = () => {
    let backgroundImage = new Image();
    let characterImage = new Image();
    backgroundImage.src = IMAGE_LOGIN_BACKGROUND;
    characterImage.src = IMAGE_LOGIN_CHARACTER;
  };

  const [currentLanguageCode, setCurrentLanguageCode] =
    useState(getLanguageCode());

  useLayoutEffect(() => {
    backgroundImage();
  }, []);

  return (
    <Box
      sx={{
        width: "100vw", // 화면 가로 전체 채우기
        height: "100vh", // 화면 세로 전체 채우기
        position: "relative", // 자식 요소 배치 위한 relative
        overflow: "hidden", // 넘치는 부분 숨기기
      }}
    >
      {/* 배경 이미지 */}
      <img
        src={IMAGE_LOGIN_BACKGROUND}
        alt="Login Background"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover", // 이미지가 화면에 맞게 꽉 차도록 조정
        }}
      />

      <Box
        sx={{
          position: "absolute",
          right: "16px",
          top: "8px",
          zIndex: 100,
        }}
      >
        <LanguageSelect
          currentSelectedItem={currentLanguageCode}
          itemList={["en", "km"]}
          onChange={function (item: string): void {
            setCurrentLanguageCode(item);
            uiEvent.changeLanguage(item);
          }}
        />
      </Box>

      <Box
        sx={{
          position: "absolute",
          left: "50%", // 가운데 정렬
          top: "59px",
          transform: "translateX(-50%)", // 중앙 정렬을 위한 transform
          textAlign: "center", // 자식 요소의 텍스트와 이미지를 중앙에 배치
        }}
      >
        <img
          src={APP_LOGO}
          alt="Login Character"
          style={{
            width: "103px",
            height: "26px",
          }}
        />
        <VerticalSpacer height={20} />
        <Text
          sx={{
            color: color.white,
            fontStyle: fontStyle.headingS,
          }}
          text={uiState.title}
        />
        <VerticalSpacer height={4} />
        <Text
          sx={{
            color: color.white,
            fontStyle: fontStyle.bodyLr,
          }}
          text={uiState.subTitle}
        />
      </Box>

      {/* 캐릭터 이미지 */}
      <img
        src={IMAGE_LOGIN_CHARACTER}
        alt="Login Character"
        style={{
          position: "absolute",
          bottom: "44px", // 하단에 고정
          left: "50%", // 가운데 정렬
          transform: "translateX(-50%)", // 중앙 정렬을 위한 transform
          maxWidth: "100%", // 캐릭터 이미지가 넘치지 않게
          height: "auto", // 가로에 맞춰 비율 유지
        }}
      />

      <Box
        sx={{
          position: "absolute",
          bottom: "40px", // 화면 하단에 고정
          width: "100%",
          px: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          boxSizing: "border-box", // 패딩을 width에 포함
        }}
      >
        {/*  TODO Facebook 로그인 사용 검수 필요 : https://developers.facebook.com/apps/803053031194222/app-review/submissions/current-request/ */}
        {/*<SNSLoginButton*/}
        {/*  imageSrc={ICON_SNS_FACEBOOK_1}*/}
        {/*  text="uiState.facebookLoginText"*/}
        {/*  onClick={() => {*/}
        {/*    console.log("Facebook login clicked");*/}
        {/*  }}*/}
        {/*  fontColor={color.white}*/}
        {/*  backgroundColor={"#1877F2"}*/}
        {/*/>*/}
        {/*<VerticalSpacer height={21} />*/}
        <SNSLoginButton
          imageSrc={ICON_SNS_GOOGLE}
          text={uiState.googleLoginText}
          onClick={() => {
            uiEvent.onClickGoogleLogin();
          }}
          fontColor={color.gray700}
          backgroundColor={color.white}
        />

        {window.webkit ? (
          <>
            <VerticalSpacer height={21} />
            <SNSLoginButton
              imageSrc={ICON_SNS_APPLE}
              text={uiState.appleLoginText}
              onClick={() => {
                uiEvent.onClickAppleLogin();
              }}
              fontColor={color.default}
              backgroundColor={color.white}
            />
          </>
        ) : null}
      </Box>
    </Box>
  );
}
