import { Box, Button, CircularProgress, Typography } from "@mui/material";
import VerticalSpacer from "../../../component/Spacer";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import { fontStyle } from "../../../theme/Style";
import { useEffect, useState } from "react";
import { color } from "../../../theme/Color";
import SimpleDialog from "../../../component/dialog/SimpleDialog";
import { ApiService } from "../../../restAPI/ApiService";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";
import LocalizedDate, {
  DateFormatType,
} from "../../../component/LocalizedDate";

interface HasBatteryReservationProps {
  reservationList?: any;
  setIsOverTime?: any;
}

const HasBatteryReservation = ({
  reservationList,
  setIsOverTime,
}: HasBatteryReservationProps) => {
  const [timeRemaining, setTimeRemaining] = useState("");
  const [simpleCancleDialogOpen, setSimpleCancleDialogOpen] = useState(false);
  const [deepLinkDialog, setDeepLinkDialog] = useState(false);
  // 로딩 상태 관리
  const [isLoading, setIsLoading] = useState(false);

  const handleDimEvent = (event: boolean) => {
    if (window.Android) {
      window.Android.isShownDialog(event);
    }

    if (window.webkit) {
      window.webkit.messageHandlers.isShownDialog.postMessage(event);
    }
  };
  useEffect(() => {
    const updateTimeRemaining = () => {
      const currentTime = Date.now(); // 현재 시간을 밀리초로 가져옴
      // setThentime(currentTime + 10000)
      // const thentime = currentTime + 10000;
      const timeDifference = reservationList.canReservationDt - currentTime; // canReservationDt와 현재 시간의 차이 계산
      // const timeDifference = thentime - currentTime; // canReservationDt와 현재 시간의 차이 계산

      if (timeDifference > 0) {
        // 시간 차이를 시, 분, 초로 변환
        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
        const minutes = Math.floor(
          (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

        setTimeRemaining(`${minutes}m ${seconds}s`);
      } else {
        setTimeRemaining(`End Time`);
        setIsOverTime(true);
      }
    };

    updateTimeRemaining(); // 초기 계산
    // 1초마다 시간 업데이트
    const intervalId = setInterval(updateTimeRemaining, 1000);
    // 컴포넌트가 언마운트될 때 인터벌 제거
    return () => clearInterval(intervalId);
  }, [reservationList.canReservationDt]);
  // ############################################## 딥링크 ##############################################
  function exeDeepLink() {
    setIsLoading(true); // 로딩 시작

    const ua = navigator.userAgent.toLowerCase();
    const url =
      ua.indexOf("android") > -1
        ? "intent://maps.google.com/#Intent;scheme=http;package=com.google.android.apps.maps;end"
        : "comgooglemaps://";

    window.location.href = url;

    // 앱 실행 실패 시 스토어로 이동 확인 다이얼로그 표시
    setTimeout(checkInstallApp, 1000);
  }

  // 앱이 설치되지 않았을 경우 스토어로 이동할지 확인하는 함수
  function checkInstallApp() {
    function clearTimers() {
      clearInterval(check);
      clearTimeout(timer);
      setIsLoading(false); // 로딩 종료
    }

    function isHideWeb() {
      if (document.hidden) {
        clearTimers(); // 앱이 실행되면 타이머를 제거
      }
    }

    const check = setInterval(isHideWeb, 200);

    // 일정 시간 후 스토어로 이동할지 묻는 다이얼로그 표시
    const timer = setTimeout(function () {
      setDeepLinkDialog(true); // 스토어로 이동할지 묻는 다이얼로그 열기
      clearTimers(); // 타이머 정리
    }); // 0.5초 후 다이얼로그 표시
  }

  // 스토어로 이동하는 함수
  function redirectToStore(isAndroid: boolean) {
    const url = isAndroid
      ? "market://details?id=com.google.android.apps.maps"
      : "https://apps.apple.com/kr/app/google-maps/id585027354";

    window.location.href = url;
  }

  // ############################################## 딥링크 ##############################################
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <VerticalSpacer height={16} />
        <Typography fontWeight={"bold"}>
          {LocalizedText("ua_d100_batteryreserve_title")}
        </Typography>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            backgroundColor: "#F2F2F2",
            mt: 1,
            mb: 1.5,
            borderRadius: 2.5,
          }}
        >
          <Box sx={{ m: 1.5, ml: 2, mr: 2, width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  borderRadius: 10,
                  color: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#4D4C4C",
                  pl: 1,
                  pr: 1,
                }}
              >
                <AccessTimeOutlinedIcon fontSize="small" sx={{ width: 18 }} />
                <Typography sx={{ ml: 0.2, mt: 0.2 }} fontSize={14}>
                  {timeRemaining}
                </Typography>
              </Box>
              <Typography fontStyle={fontStyle.subTitleM} color={color.gray600}>
                {LocalizedDate({
                  date: reservationList.reqReservationDt,
                  type: DateFormatType.YearMonthDayTime,
                })}
              </Typography>
            </Box>
            <VerticalSpacer height={8} />
            <Typography fontStyle={fontStyle.titleL}>
              {reservationList.svcNm}
            </Typography>
            <Typography
              color={"#B3B3B3"}
              fontSize={13}
              sx={{ mt: 0.7, lineHeight: 1.3 }}
            >
              No#214, St. Charles De Gaulle, Phum Mundul3, Sangkat Slorkram,
              Krong Siem Reap, Siem Reap Province,Cambodia
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: 2,
              }}
            >
              <Button
                sx={{
                  width: "48.5%",
                  height: "5.4vh",
                  borderRadius: 2.5,
                  color: "#5D38E5",
                  border: 1,
                  backgroundColor: "white",
                  "&:hover": {
                    backgroundColor: "white", // hover 시 배경색
                  },
                  textTransform: "none",
                }}
                onClick={() => {
                  // setCheckReservation(false);
                  handleDimEvent(true);
                  setSimpleCancleDialogOpen(true);
                }}
                // disableRipple
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  {LocalizedText("ua_d100_cancel")}
                </Typography>
              </Button>
              <Button
                sx={{
                  width: "48.5%",
                  height: "5.4vh",
                  borderRadius: 2.5,
                  textTransform: "none",
                  backgroundColor: "#5D38E5",
                  "&:hover": {
                    backgroundColor: "#5D38E5", // hover 시 배경색
                  },
                }}
                // disableRipple
                onClick={() => {
                  exeDeepLink();
                }}
              >
                <svg
                  width="19"
                  height="18"
                  viewBox="0 0 19 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.05269 10.6684L9.91411 16.0524C10.263 17.0616 11.6779 17.0953 12.0745 16.1039L17.4147 2.75548C17.7862 1.82687 16.8802 0.897998 15.9426 1.24622L2.25163 6.33111C1.25114 6.7027 1.24891 8.11701 2.24822 8.49175L8.05269 10.6684Z"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <Typography sx={{ color: "white", fontWeight: "500", ml: 1 }}>
                  {LocalizedText("ua_d100_directions")}
                </Typography>
              </Button>
            </Box>
          </Box>
        </Box>
        <SimpleDialog
          children={LocalizedText("ua_d100_alert_cancelreservation_title")}
          isOpen={simpleCancleDialogOpen}
          positiveText="OK"
          onPositive={() => {
            console.log(reservationList.id);
            const cancleData = {
              id: reservationList.id,
            };
            ApiService.MobilePost(
              "/pw/backend/api/battery/cancel",
              cancleData
            ).then((res: any) => {
              handleDimEvent(false);
              setIsOverTime(true);
              setSimpleCancleDialogOpen(false);
            });
          }}
          onNagative={() => {
            handleDimEvent(false);
            setSimpleCancleDialogOpen(false);
          }}
          onClose={() => {
            handleDimEvent(false);
            setSimpleCancleDialogOpen(false);
          }}
        />
        <SimpleDialog
          children={
            "구글 맵이 설치 되어있지 않습니다. 스토어로 이동 하시겠습니까?"
          }
          isOpen={deepLinkDialog}
          positiveText="OK"
          onPositive={() => {
            const ua = navigator.userAgent.toLowerCase();
            const isAndroid = ua.indexOf("android") > -1;

            redirectToStore(isAndroid); // OK 버튼을 누르면 스토어로 이동
          }}
          onNagative={() => {
            handleDimEvent(false);
            setDeepLinkDialog(false);
          }}
          onClose={() => {
            handleDimEvent(false);
            setDeepLinkDialog(false);
          }}
        />
      </Box>
      {isLoading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            zIndex: 9999,
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default HasBatteryReservation;
