import { useEffect, useState } from "react";
import CommonList from "../../component/CommonList";
import CommonSearch from "../../component/CommonSearch";
import { Button, Tabs, Tab, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import NFTTable from "./section/NFTTable";
import VerticalSpacer, { HorizontalSpacer } from "../../component/Spacer";
import { mockNFTData, NFT } from "./model/NFT";
import { NFTHeadCells } from "./model/NFTHeadCells";
import { NFTManagementTab } from "./section/NFTManagementTab";

const NFTManagement = () => {
  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [rows, setRows] = useState<NFT[]>();
  const [page, setPage] = useState<number>(0);
  const [docCount, setDocCount] = useState<number>(0);
  const [searchContents, setSearchContents] = useState("");
  const [tabValue, setTabValue] = useState("mng");
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const HeaderButton = () => {
    return (
      <Button
        sx={{
          height: 40,
          backgroundColor: "#5D38E5",
          color: "white",
          borderRadius: 5,
          width: 110,
          ":hover": {
            backgroundColor: "#A38BFC",
          },
        }}
        onClick={() => {
          navigate("/NFTCreate");
        }}
      >
        <AddIcon fontSize="small" />
        Create
      </Button>
    );
  };

  useEffect(() => {
    const filteredData = mockNFTData.filter((item) => {
      return searchContents
        ? item.nftCharactor
            .toLowerCase()
            .includes(searchContents.toLowerCase()) ||
            item.reword.toLowerCase().includes(searchContents.toLowerCase()) ||
            item.writer.toLowerCase().includes(searchContents.toLowerCase())
        : true;
    });
    const startData = page * rowsPerPage;
    const endData = startData + rowsPerPage;
    const pagedata = filteredData.slice(startData, endData);
    setRows(pagedata);
    setPageCount(Math.ceil(filteredData.length / rowsPerPage)); // 전체 페이지 수 계산
    setDocCount(filteredData.length);
  }, [page, rowsPerPage, searchContents]);

  return (
    <CommonList
      title="NFT 설정"
      tabButton={<NFTManagementTab />} //추후 tab에 맞는 table 기능 구현
      headerButton={HeaderButton()}
    >
      <CommonSearch
        placeHolder={"Name, Reward, Writer, Distribute Date"}
        searchContents={searchContents}
        setSearchContents={setSearchContents}
      >
        <Box sx={{ display: "flex" }}>
          <HorizontalSpacer width={12} />
        </Box>
      </CommonSearch>
      <VerticalSpacer height={16} />
      <NFTTable
        headCells={NFTHeadCells}
        docCount={docCount}
        rowsPerPage={rowsPerPage}
        pageCount={pageCount}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
    </CommonList>
  );
};

export default NFTManagement;
