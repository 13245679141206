import { useEffect, useState } from "react";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Button, Card, CardContent, Grid, IconButton, Typography } from "@mui/material";
import { color } from "../../theme/Color";
import { fontStyle } from "../../theme/Style";
import CommonList from "../../component/CommonList";
import VerticalSpacer, { HorizontalSpacer } from "../../component/Spacer";
import { ApiService } from "../../restAPI/ApiService";
import { ADMIN_CONFIG } from "../../constants/AdminConfig";
import { SERVER_CONFIG } from "../../constants/ServerConfig";
import BlockChainTable from "./section/BlockChainTable";
import TransactionsTable from "./section/TransactionsTable";

interface TotalCardProps {
  title: string;
  totalCount: number;
}

const BlockchainMonitoringPage = () => {
  // totalBlock 관련 변수
  const [totalBlock, setTotalBlock] = useState<any>([]);
  const [totalBlockRowsPerPage, setTotalBlockRowsPerPage] = useState(10); // 한 테이블당 보여줄 row 의 갯수
  const [totalBlockPage, setTotalBlockPage] = useState<number>(0); // 현재 페이지의 번호
  const [totalBlockDocCount, setTotalBlockDocCount] = useState<number>(0); // 총 row의 수
  const [totalBlockPageCount, setTotalBlockPageCount] = useState(0); // 테이블 왼쪽 하단에 보여질 페이지의 갯수

  // totalTransactions 관련 변수
  const [totalTransactions, setTotalTransactions] = useState<any>([]);
  const [totalTransactionsRowsPerPage, setTotalTransactionsRowsPerPage] = useState(10); // 한 테이블당 보여줄 row 의 갯수
  const [totalTransactionsPage, setTotalTransactionsPage] = useState<number>(0); // 현재 페이지의 번호
  const [totalTransactionsDocCount, setTotalTransactionsDocCount] = useState<number>(0); // 총 row의 수
  const [totalTransactionsPageCount, setTotalTransactionsPageCount] = useState(0); // 테이블 왼쪽 하단에 보여질 페이지의 갯수

  const [totalAddress, setTotalAddress] = useState<any>([]);
  const [totalNFT, setTotalNFT] = useState<any>([]);

  useEffect(() => {
    ApiService.BlockchainPost("/nft/totalBlock", {
      page: totalBlockPage,
      size: totalBlockRowsPerPage,
    }).then((res) => {
      setTotalBlock(res?.data.body.blockInfos);
      setTotalBlockPageCount(
        Math.ceil(res?.data.body.total / totalBlockRowsPerPage)
      );
      setTotalBlockDocCount(res?.data.body.total);
    });
    ApiService.BlockchainPost("/nft/totalTransactions", {
      page: totalTransactionsPage,
      size: totalTransactionsRowsPerPage,
    }).then((res) => {
      setTotalTransactions(res?.data?.body?.txHashInfos);
      setTotalTransactionsPageCount(
        Math.ceil(res?.data.body.total / totalTransactionsRowsPerPage)
      );
      setTotalTransactionsDocCount(res?.data?.body?.total);

    });
    ApiService.BlockchainPost("/wallet/totalWallet").then((res) => {
      setTotalAddress(res?.data?.body?.total)
    });
    ApiService.BlockchainPost("/nft/echoPlatform/totalNFT").then((res) => {
      setTotalNFT(res?.data?.body?.total)
    });
  }, [
    totalBlockPage,
    totalBlockRowsPerPage,
    totalTransactionsPage,
    totalTransactionsRowsPerPage,
 ]);

  const TotalCard = ({ title, totalCount }: TotalCardProps) => {
    return (
      <Card
        sx={{
          borderRadius: "15px",
          border: 1,
          borderColor: color.gray200,
          minHeight: 60,
          width: "100%",
          display: "flex",
        }}
        elevation={0}
      >
        <CardContent
          sx={{
            width: "100%",
            p: "20px",
            "&:last-child": {
              paddingBottom: "20px",
            },
          }}
        >
          <Typography fontStyle={fontStyle.semiboldM} color={color.gray800}>
            {title}
          </Typography>
          <VerticalSpacer height={4} />
          <Typography fontStyle={fontStyle.displayM}>{totalCount}</Typography>
        </CardContent>
      </Card>
    );
  };

  const createGoToMetadiumEexplorerButton = () => {
    return (
      <Button
        sx={{
          backgroundColor: "#99989D",
          borderRadius: 5,
          color: "white",
          fontSize: "12px",
          padding: "5px 22px",
          height: 40,
          ":hover": {
            backgroundColor: "#A38BFC",
          },
          textTransform: "none",
        }}
        onClick={() => {
          window.open(SERVER_CONFIG.METADIUME_EXPLORER_URL);
        }}
      >
        <OpenInNewIcon fontSize="small" />
        &nbsp;Go to metadiume explorer
      </Button>
    );
  };

  return (
    <CommonList
      title={ADMIN_CONFIG.MENU_BLOCKCHAIN_BLOCKCHAIN_MONITORING.TITLE}
      headerButton={createGoToMetadiumEexplorerButton()}
      setModal={null}
    >
      <Grid container rowSpacing={4.5} columnSpacing={2.75}>
        <Grid item lg={3}>
          <TotalCard title="Total Block" totalCount={totalBlockDocCount} />
        </Grid>
        <Grid item lg={3}>
          <TotalCard title="Total Transactions" totalCount={totalTransactionsDocCount} />
        </Grid>
        <Grid item lg={3}>
          <TotalCard title="Total Address" totalCount={totalAddress} />
        </Grid>
        <Grid item lg={3}>
          <TotalCard title="Total NFT" totalCount={totalNFT} />
        </Grid>
      </Grid>
      <VerticalSpacer height={20} />
      <Grid container rowSpacing={4.5} columnSpacing={2.75}>
        <Grid item lg={6}>
          <Card
            sx={{
              borderRadius: "15px",
              border: 1,
              borderColor: color.gray200,
              minHeight: 60,
              width: "100%",
              display: "flex",
            }}
            elevation={0}
          >
            <CardContent
              sx={{
                width: "100%",
                pt: "24px",
                px: 0,
                "&:last-child": {
                  paddingBottom: "0px",
                },
              }}
            >
              <Box
                fontStyle={fontStyle.semiboldL}
                // color={color.gray800}
                sx={{ display: "flex" }}
              >
                <HorizontalSpacer width={24} />
                Blocks
              </Box>
              <VerticalSpacer height={8} />
              {totalBlock && (
                <BlockChainTable
                  mngRows={totalBlock}
                  rowsPerPage={totalBlockRowsPerPage}
                  setRowsPerPage={setTotalBlockRowsPerPage}
                  docCount={totalBlockDocCount}
                  page={totalBlockPage}
                  setPage={setTotalBlockPage}
                  pageCount={totalBlockPageCount}
                />
              )}
              {!totalBlock && (
                <Box
                  sx={{
                    textAlign: "center",
                    width: "100%",
                    p: 2,
                  }}
                >
                  No data
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={6}>
          <Card
            sx={{
              borderRadius: "15px",
              border: 1,
              borderColor: color.gray200,
              minHeight: 60,
              width: "100%",
              display: "flex",
            }}
            elevation={0}
          >
            <CardContent
              sx={{
                width: "100%",
                pt: "24px",
                px: 0,
                "&:last-child": {
                  paddingBottom: "0px",
                },
              }}
            >
              <Box
                fontStyle={fontStyle.semiboldL}
                // color={color.gray800}
                sx={{ display: "flex" }}
              >
                <HorizontalSpacer width={24} />
                Transactions
              </Box>
              <VerticalSpacer height={8} />
              {totalTransactions && (
                <TransactionsTable
                  mngRows={totalTransactions}
                  rowsPerPage={totalTransactionsRowsPerPage}
                  setRowsPerPage={setTotalTransactionsRowsPerPage}
                  docCount={totalTransactionsDocCount}
                  page={totalTransactionsPage}
                  setPage={setTotalTransactionsPage}
                  pageCount={totalTransactionsPageCount}
                />
              )}
              {!totalTransactions && (
                <Box
                  sx={{
                    textAlign: "center",
                    width: "100%",
                    p: 2,
                  }}
                >
                  No data
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </CommonList>
  );
};

export default BlockchainMonitoringPage;
