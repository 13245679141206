import { useEffect, useState } from "react";
import MembershipVoucherRender from "./MembershipVoucherRender";
import { MembershipVoucherUiState } from "./model/MembershipVoucherUiState";
import { MembershipVoucherUiEvent } from "./model/MembershipVoucherUiEvent";
import {
  Box,
  Button,
  Divider,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import VerticalSpacer, { HorizontalSpacer } from "../../component/Spacer";
import { fontStyle } from "../../theme/Style";
import { color } from "../../theme/Color";
import { ApiService } from "../../restAPI/ApiService";
import CommonList from "../../component/CommonList";
import CommonSearch from "../../component/CommonSearch";

const MembershipVoucherPage = () => {
  const [tabVelue, setTabVelue] = useState("management");
  // 검색어 관련 변수
  const [searchContents, setSearchContents] = useState("");
  // 국가관련 변수
  const [country, setCountry] = useState("Country");
  const [countryCode, setCountryCode] = useState("");
  const handleCountryChange = (event: SelectChangeEvent) => {
    setCountry(event.target.value[0] as string);
    setCountryCode(event.target.value[1] as string);
  };

  // type 관련 변수
  const [type, setType] = useState("");
  const handleTypeChange = (event: SelectChangeEvent) => {
    setType(event.target.value as string);
  };

  const [uiState, setUiState] = useState(new MembershipVoucherUiState());
  const uiEvent: MembershipVoucherUiEvent = {
    HandleUiStateChange: (key: keyof MembershipVoucherUiState, value: any) => {
      setUiState((prevState) => ({
        ...prevState,
        [key]: value, // key에 해당하는 상태를 업데이트
      }));
    },
  };

  const TabButton = () => {
    return (
      <Box sx={{ display: "flex", height: "24px" }}>
        <HorizontalSpacer width={16} />
        <Box
          sx={{
            fontStyle: fontStyle.semiboldM,
            cursor: "pointer",
            color: tabVelue == "management" ? color.primary500 : color.gray600,
          }}
          onClick={() => {
            setTabVelue("management");
            setType("");
          }}
        >
          Management
        </Box>
        <HorizontalSpacer width={16} />
        <Divider orientation="vertical" />
        <HorizontalSpacer width={16} />
        <Box
          sx={{
            fontStyle: fontStyle.semiboldM,
            cursor: "pointer",
            color: tabVelue == "history" ? color.primary500 : color.gray600,
          }}
          onClick={() => {
            setTabVelue("history");
            setType("");
          }}
        >
          History
        </Box>
        <HorizontalSpacer width={16} />
      </Box>
    );
  };

  // 서버에 select 목록들 가져오기
  useEffect(() => {
    ApiService.WebPost("/pw/aw/contents/getCountry").then((res) => {
      uiEvent.HandleUiStateChange("CountryList", res?.data.body);
    });
  }, []);

  // 서버에 filter된 리스트들 가져오기
  useEffect(() => {
    ApiService.WebPost("/pw/aw/product/MV/filter", {
      country: countryCode,
      searchContents: searchContents,
      type: type,
    }).then((res) => {
      console.log(res?.data.body);
      uiEvent.HandleUiStateChange("MVDataRows", res?.data.body);
    });
  }, [tabVelue, searchContents, type, countryCode]);

  return (
    <CommonList
      title="Membership & Voucher"
      // headerButton={tabVelue == "management" && HeaderButton()}
      tabButton={TabButton()}
    >
      {tabVelue == "management" && (
        <CommonSearch
          placeHolder={"Name, Description"}
          setSearchContents={setSearchContents}
        >
          <Box sx={{ display: "flex" }}>
            <Select
              value={country}
              onChange={handleCountryChange}
              renderValue={(v) => {
                return v?.length ? v : `Country`;
              }}
              sx={{
                width: "auto",
                height: "42px",
                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                "&.MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "red",
                  },
                  "&:hover fieldset": {
                    borderColor: "yellow",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "white",
                  },
                },
                bgcolor: "white",
                borderRadius: "10px",
                fontStyle: fontStyle.semiboldXS,
                color: color.gray600,
              }}
            >
              <MenuItem
                value={["Country", ""]}
                sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}
              >
                all
              </MenuItem>
              {uiState.CountryList.map((data: any, index: any) => {
                return (
                  <MenuItem
                    value={[data.label, data.code]}
                    key={index}
                    sx={{
                      fontStyle: fontStyle.semiboldXS,
                      color: color.gray600,
                    }}
                  >
                    {data.label}
                  </MenuItem>
                );
              })}
            </Select>
            <HorizontalSpacer width={12} />
            <Select
              value={type}
              onChange={handleTypeChange}
              displayEmpty
              renderValue={(v) => (v?.length ? v : `Type`)}
              sx={{
                width: "auto",
                height: "42px",
                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                "&.MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "red",
                  },
                  "&:hover fieldset": {
                    borderColor: "yellow",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "white",
                  },
                },
                bgcolor: "white",
                borderRadius: "10px",
                fontStyle: fontStyle.semiboldXS,
                color: color.gray600,
              }}
            >
              <MenuItem
                value={""}
                sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}
              >
                All
              </MenuItem>
              <MenuItem
                value={"NFC Character"}
                sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}
              >
                NFC Character
              </MenuItem>
            </Select>
          </Box>
        </CommonSearch>
      )}
      {tabVelue == "history" && (
        <CommonSearch
          placeHolder={"Search"}
          setSearchContents={setSearchContents}
        >
          <Box sx={{ display: "flex" }}>
            <Select
              value={type}
              onChange={handleTypeChange}
              displayEmpty
              renderValue={(v) => (v?.length ? v : `Type`)}
              sx={{
                width: "auto",
                height: "42px",
                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                "&.MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "red",
                  },
                  "&:hover fieldset": {
                    borderColor: "yellow",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "white",
                  },
                },
                bgcolor: "white",
                borderRadius: "10px",
                fontStyle: fontStyle.semiboldXS,
                color: color.gray600,
              }}
            >
              <MenuItem
                value={""}
                sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}
              >
                All
              </MenuItem>
              <MenuItem
                value={"NFC Character"}
                sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}
              >
                NFC Character
              </MenuItem>
            </Select>
          </Box>
        </CommonSearch>
      )}
      <VerticalSpacer height={16} />
      {tabVelue == "management" && (
        <MembershipVoucherRender uiState={uiState} uiEvent={uiEvent} />
      )}
    </CommonList>
  );
};
export default MembershipVoucherPage;
