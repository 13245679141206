export class ServiceMngDetailUiState {
  ManagerList: Array<Object> = [];
  AreaList: Array<Object> = [];

  ManagerEmail: string = "";
  ID: string = "";
  Name: string = "";
  LocalNumber: string = "";
  PhoneNumber: string = "";
  OpenTimeHour: string = "00";
  OpenTimeMin: string = "00";
  CloseTimeHour: string = "00";
  CloseTimeMin: string = "00";
  OpenDay: string = "XXXXXXX";
  DetailsFile: Array<File> = [];
  SvcImgList: Array<string> = [];

  ServiceName: string = "";
  Service: string = "";
  Status: string = "";

  AreaName: string = "";
  AreaId: number | null = null;

  Linked: string = "";
  RegistrationDate: number = 0;
  LastModifiedDate: number = 0;
  PartnersCategory: string = "";
  PartnersSubtitle: string = "";
  PartnersDescription: string = "";

  IDError: boolean = false;
  FileError: boolean = false;
  ServiceNameError: boolean = false;
  ServiceError: boolean = false;
  StatusError: boolean = false;
  AreaNameError: boolean = false;
  PartnersCategoryError: boolean = false;
  PartnersSubtitleError: boolean = false;
  PartnersDescriptionError: boolean = false;
}
