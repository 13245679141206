import React, {createContext, ReactNode, useContext, useEffect, useState,} from "react";
import {LanguageRepository} from "../repository/LanguageRepository";
import {LanguageRepositoryImpl} from "../repository/impl/LanguageRepositoryImpl";
import ProgressBar from "../component/ProgressBar";
import {getLanguageCode} from "../customHook/useLocale";

const LanguageRepositoryContext = createContext<LanguageRepository | null>(
  null
);

export const LanguageRepositoryProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [languageRepository, setLanguageRepository] =
    useState<LanguageRepository | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const initializeLanguagePack = async () => {
      const languageRepositoryInstance = LanguageRepositoryImpl.getInstance();
      const success = await languageRepositoryInstance.getLanguagePack();

      if (success) {
        setLanguageRepository(languageRepositoryInstance);
      } else {
        setError("Failed to load language pack.");
      }
      setLoading(false);
    };

    initializeLanguagePack().then((r) => {});
  }, []);

  if (loading) {
    return <ProgressBar />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <LanguageRepositoryContext.Provider value={languageRepository}>
      {children}
    </LanguageRepositoryContext.Provider>
  );
};

export const useLanguageRepository = () => {
  const context = useContext(LanguageRepositoryContext);
  if (!context) {
    throw new Error(
      "useLanguageRepository must be used within a LanguageRepositoryProvider"
    );
  }
  return context;
};

export function LocalizedText(key: string, ...args: string[]): string {
  const languageRepository = useLanguageRepository();
  const [languageCode, setLanguageCode] = useState(getLanguageCode());

  useEffect(() => {
    const handleStorageChange = () => {
      const newLanguageCode = getLanguageCode();
      setLanguageCode(newLanguageCode);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  let localizedText = languageRepository.getLocalizedText(key, languageCode);

  // %s 자리마다 순차적으로 args 요소로 대체
  args.forEach((arg) => {
    localizedText = localizedText.replace("%s", arg);
  });

  return localizedText;
}
