import {
  Box,
  Card,
  darken,
  Divider,
  Grid,
  InputBase,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import VerticalSpacer, { HorizontalSpacer } from "../../../component/Spacer";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  ICON_ADD,
  ICON_COPY,
  ICON_EDIT,
  ICON_SHOW_WHITE,
} from "../../../constants/imagePath";
import { ApiService } from "../../../restAPI/ApiService";

interface PopupGridProps {
  data?: any;
  countryLists: any;
}

const PopupGrid = ({ data, countryLists }: PopupGridProps) => {
  console.log(data);
  const [value, setValue] = useState<Dayjs | null>(dayjs(1723528994343));

  // 국가관련 변수
  const [countryList, setCountryList] = useState(countryLists);
  const [country, setCountry] = useState("Country");
  const [countryCode, setCountryCode] = useState("");
  const handleCountryChange = (event: SelectChangeEvent) => {
    console.log(event.target.value);
    setCountry(event.target.value[0] as string);
    setCountryCode(event.target.value[1] as string);
  };

  // 상태 관련 변수
  const [status, setStatus] = useState(data.state);
  const handleStatusChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value as string);
  };

  // 커스텀 PopupSelect 컴포넌트 생성
  const PopupSelect = (props: any) => {
    return (
      <Select
        {...props}
        sx={{
          width: "100%",
          height: "40px",
          backgroundColor: "white",
          fontStyle: fontStyle.semiboldXS,
          color: color.gray600,
          "&.MuiOutlinedInput-root": {
            "& fieldset": {
              border: "0.1px solid",
              borderColor: color.gray300,
            },
            "&:hover fieldset": {
              border: "0.1px solid",
              borderColor: color.gray300,
            },
            "&.Mui-focused fieldset": {
              border: "0.1px solid",
              borderColor: color.gray300,
            },
          },
        }}
      >
        {props.children}
      </Select>
    );
  };

  return (
    <Grid item lg={4}>
      <Card elevation={0} sx={{ borderRadius: "10px", minHeight: "100px" }}>
        <Box
          sx={{
            width: "100%",
            height: "328px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            transition: "background-color 0.5s ease", // 애니메이션 적용
            backgroundImage: `url(${data.image})`, // 이미지 URL을 배경으로 사용
            backgroundSize: "contain", // 이미지가 요소에 맞게 조정되도록
            backgroundPosition: "center", // 이미지 중앙 배치
            backgroundRepeat: "no-repeat", // 이미지 반복 방지
            ":hover": {
              backgroundColor: darken(color.white, 0.3),
              "& .hover-content": {
                opacity: 1, // 호버 시 보이도록
                visibility: "visible", // 호버 시 보이도록
              },
            },
          }}
        >
          <Box
            className="hover-content"
            sx={{
              opacity: 0, // 기본 상태에서 보이지 않도록
              visibility: "hidden", // 기본 상태에서 보이지 않도록
              transition: "opacity 0.5s ease, visibility 0.5s ease", // 부드럽게 보이도록
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 100,
            }}
          >
            <Box
              sx={{
                bgcolor: color.gray800,
                borderRadius: "54px",
                width: "56px",
                height: "56px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                ":hover": {
                  backgroundColor: color.gray700,
                },
              }}
            >
              <Box component="img" src={ICON_SHOW_WHITE} />
            </Box>
            <HorizontalSpacer width={12} />
            <Box
              sx={{
                bgcolor: color.gray800,
                borderRadius: "54px",
                width: "56px",
                height: "56px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                ":hover": {
                  backgroundColor: color.gray700,
                },
              }}
            >
              <Box component="img" src={ICON_EDIT} />
            </Box>
            <HorizontalSpacer width={12} />
            <Box
              sx={{
                bgcolor: color.gray800,
                borderRadius: "54px",
                width: "56px",
                height: "56px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                ":hover": {
                  backgroundColor: color.gray700,
                },
              }}
            >
              <Box component="img" src={ICON_COPY} />
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ m: "24px", mb: "40px" }}>
          <Typography fontStyle={fontStyle.boldXS} color={color.gray700}>
            Country
          </Typography>
          <VerticalSpacer height={8} />
          <PopupSelect
            value={country}
            onChange={handleCountryChange}
            renderValue={(v: any) => {
              return v?.length ? v : `Country`;
            }}
          >
            {countryList.map((country: any, index: any) => (
              <MenuItem key={index} value={[country.label, country.code]}>
                {country.label}
              </MenuItem>
            ))}
          </PopupSelect>
          <VerticalSpacer height={40} />
          <Typography fontStyle={fontStyle.boldXS} color={color.gray700}>
            Status
          </Typography>
          <VerticalSpacer height={8} />
          <PopupSelect
            value={status}
            onChange={handleStatusChange}
            renderValue={(v: any) => {
              return v?.length ? v : null;
            }}
          >
            <MenuItem
              value={"Show"}
              sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}
            >
              Show
            </MenuItem>
            <MenuItem
              value={"Period"}
              sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}
            >
              Period
            </MenuItem>
            <MenuItem
              value={"Hide"}
              sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}
            >
              Hide
            </MenuItem>
          </PopupSelect>
          <VerticalSpacer height={40} />
          <Typography fontStyle={fontStyle.boldXS} color={color.gray700}>
            Start Date
          </Typography>
          <VerticalSpacer height={8} />
          <Box sx={{ display: "flex", width: "100%" }}>
            <Box>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={value}
                  onChange={(newValue) => setValue(newValue)}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      height: "40px",
                      width: "160px",
                      // padding: 0,
                      "& fieldset": {
                        border: 0.1,
                        borderColor: color.gray300,
                      },
                      "&:hover fieldset": {
                        border: 0.1,
                        borderColor: color.gray300,
                      },
                      "&.Mui-focused fieldset": {
                        border: 0.1,
                        borderColor: color.gray300,
                      },
                    },
                  }}
                />
              </LocalizationProvider>
            </Box>
            <HorizontalSpacer width={12} />
            <Paper
              component="form"
              sx={{
                p: "10px 12px",
                display: "flex",
                alignItems: "center",
                // width: "360px",
                height: "40px",
                border: 1,
                borderColor: color.gray300,
              }}
              elevation={0}
            >
              <InputBase sx={{}} />
            </Paper>
          </Box>
          <VerticalSpacer height={40} />
          <Typography fontStyle={fontStyle.boldXS} color={color.gray700}>
            URL
          </Typography>
          <VerticalSpacer height={8} />
          <Paper
            component="form"
            sx={{
              p: "10px 12px",
              display: "flex",
              alignItems: "center",
              width: "100%",
              height: "40px",
              border: 1,
              borderColor: color.gray300,
            }}
            elevation={0}
          >
            <InputBase sx={{}} />
          </Paper>
        </Box>
      </Card>
    </Grid>
  );
};

interface PopupMngGridProps {
  rows: any;
  countryLists: any;
}

const PopupMngGrid = ({ rows, countryLists }: PopupMngGridProps) => {
  return (
    <Grid container rowSpacing={4.5} columnSpacing={2.75}>
      {rows.map((data: any, index: any) => {
        console.log(data);
        return (
          <PopupGrid key={index} data={data} countryLists={countryLists} />
        );
      })}
      <Grid item lg={4}>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            minWidth: "330px",
            height: "100%",
            minHeight: "912px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "80px",
              height: "80px",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: color.primary500,
              borderRadius: "80px",
              cursor: "pointer",
              ":hover": {
                backgroundColor: color.primary300,
                "& .hover-content": {
                  opacity: 1, // 호버 시 보이도록
                  visibility: "visible", // 호버 시 보이도록
                },
              },
            }}
          >
            <Box component="img" src={ICON_ADD} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default PopupMngGrid;
