import { Status, Wrapper } from "@googlemaps/react-wrapper";
import { useEffect, useState } from "react";
import { getLocale } from "../../../../../customHook/useLocale";
import {
  Backdrop,
  Box,
  CircularProgress,
  InputBase,
  Paper,
} from "@mui/material";
import { fontStyle } from "../../../../../theme/Style";
import { RegionMngCreateUiState } from "../model/RegionMngCreateUiState";
import { RegionMngCreateUiEvent } from "../model/RegionMngCreateUiEvent";
import CommonErrorText from "../../../../../component/CommonErrorText";
import { color } from "../../../../../theme/Color";

interface GoogleMapImplProps {
  uiState: RegionMngCreateUiState;
  uiEvent: RegionMngCreateUiEvent;
}

const GoogleMapImpl = ({ uiState, uiEvent }: GoogleMapImplProps) => {
  const [map, setMap] = useState<any>(null);
  const [searchPlace, setSearchPlace] = useState("");
  const [markers, setMarkers] = useState<any[]>([]);
  useEffect(() => {
    async function initMap() {
      const { Map } = (await google.maps.importLibrary(
        "maps"
      )) as google.maps.MapsLibrary;
      const mapCenter = { lat: 37.4161493, lng: -122.0812166 };
      const newMap = new Map(document.getElementById("map") as HTMLElement, {
        mapId: "92cb7201b7d43b21",
        disableDefaultUI: true,
        center: mapCenter,
        maxZoom: 16,
        minZoom: 16,
        zoom: 16,
      });
      setMap(newMap);
    }

    initMap();
  }, []);

  async function findPlaces() {
    if (!searchPlace || !map) return;

    const { Place } = (await google.maps.importLibrary(
      "places"
    )) as google.maps.PlacesLibrary;
    //@ts-ignore
    const { AdvancedMarkerElement } = (await google.maps.importLibrary(
      "marker"
    )) as google.maps.MarkerLibrary;
    const request = {
      textQuery: searchPlace,
      fields: ["displayName", "location", "businessStatus"],
    };

    // 기존 마커 제거
    markers.forEach((marker) => marker.setMap(null));
    setMarkers([]);

    //@ts-ignore
    const { places } = await Place.searchByText(request);

    if (places.length) {
      const { LatLngBounds } = (await google.maps.importLibrary(
        "core"
      )) as google.maps.CoreLibrary;
      const bounds: any = new LatLngBounds();

      // 첫 번째 장소만 선택하여 마커 표시
      const place = places[0];
      const marker = new AdvancedMarkerElement({
        map,
        position: place.location,
        title: place.displayName,
      });

      bounds.extend(place.location);
      setMarkers([marker]); // 하나의 마커만 상태에 저장
      map.setCenter(bounds.getCenter());

      uiEvent.HandleUiStateChange("Latitude", bounds.getCenter().lat());
      uiEvent.HandleUiStateChange("Longitude", bounds.getCenter().lng());
    } else {
      alert("No results");
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      findPlaces();
    }
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Box sx={{ position: "absolute", zIndex: 100, width: "100%" }}>
        <Paper
          component="form"
          sx={{
            display: "flex",
            alignItems: "center",
            width: "calc(100% - 32px)",
            height: "48px",
            boxShadow: "0px 0px 14px 0px rgba(0,0,0,0)",
            borderRadius: 2,
            mt: "16px",
            mx: "16px",
            border: uiState.LatitudeError ? 1 : 0,
            borderColor: uiState.LatitudeError ? color.error : null,
          }}
          elevation={1}
        >
          <InputBase
            sx={{ mx: 3, flex: 1, fontStyle: fontStyle.regularXS }}
            onChange={(event) => setSearchPlace(event.target.value)}
            onKeyDown={handleKeyDown} // 엔터키 이벤트 추가
            error={uiState.LatitudeError}
          />
        </Paper>
        {uiState.LatitudeError && (
          <CommonErrorText
            value="Please enter the address."
            sx={{ ml: "24px", mt: "4px" }}
          />
        )}
      </Box>
      <div
        id="map"
        style={{ width: "100%", height: "500px", borderRadius: "10px" }}
      />
    </Box>
  );
};

interface PlaceGoogleMapProps {
  uiState: RegionMngCreateUiState;
  uiEvent: RegionMngCreateUiEvent;
}
const PlaceGoogleMap = ({ uiState, uiEvent }: PlaceGoogleMapProps) => {
  const [locale, setLocale] = useState(getLocale());

  useEffect(() => {
    localStorage.setItem("auto_login", "true");
  }, []);

  useEffect(() => {
    const handleStorageChange = () => {
      const newLanguageCode = getLocale();
      if (newLanguageCode !== locale) {
        setLocale(newLanguageCode);
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [locale]);

  const render = (status: Status) => {
    switch (status) {
      case Status.LOADING:
        return (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        );
      case Status.FAILURE:
        return <>Error</>;
      case Status.SUCCESS:
        return <GoogleMapImpl uiState={uiState} uiEvent={uiEvent} />;
    }
  };

  return (
    <Wrapper
      apiKey="AIzaSyAeFkKWDx0JWkB808pK61mD-dB9Jc3hcJ4"
      render={render}
      libraries={["places", "marker"]}
      language={locale}
    />
  );
};

export default PlaceGoogleMap;
