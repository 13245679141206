export class CustomerSupportRegistrationBody {
  title: string; // CS 제목
  content: string; // CS 내용
  uuid: string; // CS 작성자의 UUID
  preferredStationId: number; // 선호하는 Station의 ID (정수)
  preferredDate: number; // 선호하는 날짜 (밀리초, long 타입)
  vin: string; // 차량의 차대번호

  constructor(
    title: string,
    content: string,
    uuid: string,
    preferredStationId: number,
    preferredDate: number,
    vin: string,
  ) {
    this.title = title;
    this.content = content;
    this.uuid = uuid;
    this.preferredStationId = preferredStationId;
    this.preferredDate = preferredDate;
    this.vin = vin;
  }
}
