import { useEffect, useRef } from "react";
import { ServiceMngDetailUiEvent } from "./model/ServiceMngDetailUiEvent";
import { ServiceMngDetailUiState } from "./model/ServiceMngDetailUiState";
import { ApiService } from "../../../../restAPI/ApiService";
import { Grid } from "@mui/material";
import ManagerCard from "./section/ManagerCard";
import ServiceCard from "./section/ServiceCard";
import DetailsCard from "./section/DetailsCard";
import PartnersInformationCard from "./section/PartnersInformationCard";

interface ServiceMngDetailGridProps {
  uiState: ServiceMngDetailUiState;
  uiEvent: ServiceMngDetailUiEvent;
}

const ServiceMngDetailGrid = ({ uiState, uiEvent }: ServiceMngDetailGridProps) => {
  const partnersInfoCardRef = useRef<HTMLDivElement | null>(null); // ref 추가
  useEffect(() => {
    // uiState.Service가 "Hot Spot", "Hotel", 또는 "Restaurant"일 때 스크롤 내리기
    if (uiState.Service === "Partner" && partnersInfoCardRef.current) {
      partnersInfoCardRef.current.scrollIntoView({
        behavior: "smooth", // 부드러운 스크롤
        block: "start", // 스크롤을 카드의 시작 부분으로 맞춤
      });
    }
  }, [uiState.Service]); // uiState.Service가 변경될 때마다 실행

  useEffect(() => {
    ApiService.WebPost("/pw/users/admin/stationAdmin").then((res) => {
      uiEvent.HandleUiStateChange("ManagerList", res?.data.body);
    });
  }, []);

  useEffect(() => {
    const selectManager: any = uiState.ManagerList.filter((data: any) => data.uuid == uiState.ID);
    uiEvent.HandleUiStateChange("ManagerEmail", selectManager[0]?.email);
  }, [uiState.ManagerList, uiState.ID]);

  useEffect(() => {
    ApiService.WebPost("/pw/backend/api/geo/svcAreaNameList").then((res) => {
      uiEvent.HandleUiStateChange("AreaList", res?.data.body);
    });
  }, []);

  return (
    <>
      <Grid container columnSpacing={2.75} rowSpacing={2.75} sx={{ mt: -2.75 }}>
        {/* Manager 카드 */}
        <ManagerCard uiState={uiState} uiEvent={uiEvent} />
        {/* Service 카드 */}
        <ServiceCard uiState={uiState} uiEvent={uiEvent} />
        {/* Details 카드 */}
        <DetailsCard uiState={uiState} uiEvent={uiEvent} />
        {/* Partners Information 카드 */}
        {uiState.Service == "Partner" && <PartnersInformationCard ref={partnersInfoCardRef} uiState={uiState} uiEvent={uiEvent} />}
      </Grid>
    </>
  );
};

export default ServiceMngDetailGrid;
