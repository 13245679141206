import { useNavigate } from "react-router-dom";
import { Box, TableCell, TableRow, Typography } from "@mui/material";
import CommonTable, { useVisibleRows } from "../../../component/CommonTable";
import { fontStyle } from "../../../theme/Style";

interface RowProps {
  row: any;
  index: any;
}

const Row = ({ row, index }: RowProps) => {
  return (
    <>
      <TableRow
        // sx={{ cursor: "pointer" }}
        onClick={() => {}}
      >
        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>
            {row.txHash
              ? `${row.txHash.slice(0, 6)}...${row.txHash.slice(-4)}`
              : ""}
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>
            {row.from
              ? `${row.from.slice(0, 6)}...${row.from.slice(-4)}`
              : ""}
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>
            {row.to
              ? `${row.to.slice(0, 6)}...${row.to.slice(-4)}`
              : ""}
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.value}</Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.age}</Typography>
        </TableCell>
      </TableRow>
    </>
  );
};

interface BlockChainTableProps {
  mngRows: any;
  rowsPerPage: any;
  setRowsPerPage: any;
  page?: any;
  pageCount?: any;
  setPage?: any;
  docCount?: number;
}

const TransactionsTable = ({
  mngRows,
  rowsPerPage,
  setRowsPerPage,
  docCount,
  page,
  pageCount,
  setPage,
}: BlockChainTableProps) => {
  const headCells = [
    {
      id: "txHash",
      align: "left",
      disablePadding: false,
      label: "TxHash",
    },

    {
      id: "from",
      align: "left",
      disablePadding: false,
      label: "From",
    },

    {
      id: "to",
      align: "left",
      disablePadding: false,
      label: "To",
    },

    {
      id: "value",
      align: "left",
      disablePadding: false,
      label: "Value",
    },

    {
      id: "age",
      align: "left",
      disablePadding: false,
      label: "Age",
    },
  ];

  const { order, orderBy, handleRequestSort, handleChangePage, visibleRows } =
    useVisibleRows(mngRows, rowsPerPage, setPage, page);

  return (
    <CommonTable
      headCells={headCells}
      order={order}
      orderBy={orderBy}
      page={page}
      docCount={docCount}
      pageCount={pageCount}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      handleRequestSort={handleRequestSort}
      handleChangePage={handleChangePage}
      setPage={setPage}
    >
      {visibleRows.map((row: any, index: any) => (
        <Row row={row} index={index} key={index} />
      ))}
    </CommonTable>
  );
};

export default TransactionsTable;
