import React, { useEffect, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/zoom";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import { Zoom, Pagination } from "swiper/modules";
import { Box, IconButton } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { IMAGE_EMPTY_IMAGE } from "../../../constants/appImagePath";

interface PhotoDetailsProps {
  setDetailsOpen?: any;
  data: any;
}

const PhotoDetails = ({ setDetailsOpen, data }: PhotoDetailsProps) => {
  type CustomCSSProperties = React.CSSProperties & {
    "--swiper-pagination-fraction-color"?: string;
    "--swiper-pagination-bottom"?: string;
    "--swiper-pagination-bullet-horizontal-gap"?: string;
    height?: string;
  };

  // Define the custom styles
  const swiperStyles: CustomCSSProperties = {
    "--swiper-pagination-fraction-color": "#fff",
    "--swiper-pagination-bottom": "96%",
  };

  const imageStyle: React.CSSProperties = {
    width: "100%",
    objectFit: "contain",
    position: "relative",
    height: "96%",
    top:"2%"
  };

  console.log("data is : ", data);
  return (
    <Box
      sx={{
        zIndex: 1400,
        position: "fixed",
        width: "100%",
        height: "100%",
        display: "flex",
        backgroundColor: "black",
      }}
    >
      <IconButton
        sx={{
          color: "white",
          position: "fixed",
          zIndex: 1500,
          bottom: "94.5%",
          left: "3%",
        }}
        onClick={() => {
          setDetailsOpen(false);
        }}
      >
        <KeyboardBackspaceIcon />
      </IconButton>
      <Swiper
        style={swiperStyles}
        zoom={true}
        pagination={{
          type: "fraction",
          clickable: true,
        }}
        modules={[Zoom, Pagination]}
      >
        {data ? (
          data.map((data: any) => (
            <SwiperSlide>
             <div className="swiper-zoom-container" style={{ position: "relative", height: "100%" }}>
                <img src={data} style={imageStyle} />
              </div>
            </SwiperSlide>
          ))
        ) : (
          <SwiperSlide>
            <div className="swiper-zoom-container">
              <img src={IMAGE_EMPTY_IMAGE} />
            </div>
          </SwiperSlide>
        )}
      </Swiper>
    </Box>
  );
};

export default PhotoDetails;
