import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import CommonList from "../../../../component/CommonList";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import AdminDetailGrid from "./AdminDetailGrid";
import { useEffect, useState } from "react";
import { ApiService } from "../../../../restAPI/ApiService";
import CommonDialog from "../../../../component/CommonDialog";
import ProgressBar from "../../../../component/ProgressBar";
import VerticalSpacer from "../../../../component/Spacer";
import { color } from "../../../../theme/Color";

const AdminDetailPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const userId = pathname.split("/").pop(); // URL에서 마지막 부분을 추출
  const [isLoading, setIsLoading] = useState(false);

  const [adminDetailData, setAdminDetailData] = useState({});
  const updateAdminDetailData = (key: string, value: any) => {
    setAdminDetailData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleNavigate = (route: string) => {
    navigate(route);
    // 페이지를 새로고침
    window.location.reload();
  };

  useEffect(() => {
    ApiService.WebPost("/pw/users/admin/read", {
      uuid: userId,
    }).then((res) => {
      setAdminDetailData(res?.data.body);
    });
  }, [userId]);

  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);

  const HeaderButton = () => {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Button
          sx={{
            height: 40,
            backgroundColor: "#5D38E5",
            color: "white",
            borderRadius: 5,
            width: 110,
            ":hover": {
              backgroundColor: "#A38BFC",
            },
          }}
          onClick={() => {
            // console.log(adminDetailData);

            setUpdateDialogOpen(true);
          }}
        >
          업데이트
        </Button>
        {/* &nbsp; &nbsp;
        <Typography fontSize={30} sx={{ mb: 0.5, color: "gray" }}>
          |
        </Typography>
        <IconButton sx={{ color: "red" }}>
          <DeleteOutlineOutlinedIcon fontSize="medium" />
        </IconButton> */}
      </Box>
    );
  };

  const HeaderBackButton = () => {
    return (
      <IconButton
        sx={{
          ":hover": { backgroundColor: "#F2F2F2" },
          mb: 0.5,
          mr: 1,
        }}
        onClick={() => {
          handleNavigate("/adminMng");
        }}
      >
        <ArrowBackIosNewIcon fontSize="medium" />
      </IconButton>
    );
  };

  return (
    <CommonList
      title="관리자 정보 상세"
      setModal={null}
      headerButton={HeaderButton()}
      headerBackButton={HeaderBackButton()}
    >
      {isLoading && (
        <>
          <LinearProgress color="secondary" />
          <VerticalSpacer height={16} />
        </>
      )}
      <Box>
        <AdminDetailGrid
          adminDetailData={adminDetailData}
          updateAdminDetailData={updateAdminDetailData}
        />
      </Box>
      <CommonDialog
        open={updateDialogOpen}
        handleClose={() => {
          setUpdateDialogOpen(false);
        }}
        handleOk={() => {
          setIsLoading(true);
          ApiService.WebPost("/pw/users/admin/update", adminDetailData).then(
            (res) => {
              console.log(res?.data);
              setIsLoading(false);
            }
          );
          setUpdateDialogOpen(false);
        }}
      >
        Would you like to update?
      </CommonDialog>
    </CommonList>
  );
};

export default AdminDetailPage;
