import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { IMAGE_ADD_PHOTO } from "../../../../../constants/imagePath";
import VerticalSpacer from "../../../../../component/Spacer";
import { fontStyle } from "../../../../../theme/Style";
import { color } from "../../../../../theme/Color";
import { ServiceMngCreateUiState } from "../model/ServiceMngCreateUiState";
import { ServiceMngCreateUiEvent } from "../model/ServiceMngCreateUiEvent";

interface FileUploadPreviewProps {
  uiState: ServiceMngCreateUiState;
  uiEvent: ServiceMngCreateUiEvent;
}
const FileUploadPreview = ({ uiState, uiEvent }: FileUploadPreviewProps) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]); // 선택된 파일들
  const [previewUrls, setPreviewUrls] = useState<string[]>([]); // 미리보기 URL들
  const fileInputRef = useRef<HTMLInputElement | null>(null); // ref로 input 참조

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files) {
      const newFiles = Array.from(files).filter(
        (file) => (file.type === "image/jpeg" || file.type === "image/png") && !selectedFiles.some((existingFile) => existingFile.name === file.name) // 중복 방지
      );

      if (newFiles.length + selectedFiles.length > 5) {
        alert("파일은 최대 5개까지 추가할 수 있습니다.");
        return;
      }

      const newUrls = newFiles.map((file) => URL.createObjectURL(file));

      setSelectedFiles((prev) => [...prev, ...newFiles]);
      setPreviewUrls((prev) => [...prev, ...newUrls]);
    }
  };

  const handleFileRemove = (index: number) => {
    setSelectedFiles((prev) => prev.filter((_, i) => i !== index));
    setPreviewUrls((prev) => prev.filter((_, i) => i !== index));
  };

  const handleBoxClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // input 클릭 트리거
    }
  };

  useEffect(() => {
    uiEvent.HandleUiStateChange("DetailsFile", selectedFiles);
  }, [selectedFiles]);

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        columnGap: "8px",
      }}
    >
      {previewUrls.map((url, index) => (
        <Box
          key={index}
          sx={{
            position: "relative",
            width: "89px",
            height: "89px", // 썸네일 아래에 파일 이름을 추가할 공간
          }}
        >
          <Box
            component="img"
            src={url}
            alt={`미리보기-${index}`}
            sx={{
              width: "100%",
              height: "100%",
              borderRadius: "8px",
              border: "1px solid #ccc",
              objectFit: "cover",
            }}
          />
          <Button
            size="small"
            variant="contained"
            color="error"
            onClick={() => handleFileRemove(index)}
            sx={{
              position: "absolute",
              top: "3px",
              right: "3px",
              minWidth: "18px",
              height: "18px",
              fontSize: "12px",
              padding: 0,
              borderRadius: "50%",
            }}
          >
            X
          </Button>
          <Typography
            fontStyle={fontStyle.regularXS}
            color={color.gray700}
            sx={{
              overflow: "hidden", // 넘치는 내용 숨기기
              textOverflow: "ellipsis", // 넘칠 경우 '...'으로 표시
              display: "-webkit-box", // 두 줄까지 표시할 수 있도록 설정
              WebkitLineClamp: 2, // 최대 2줄까지 표시
              WebkitBoxOrient: "vertical", // 세로 방향으로 텍스트 자르기
            }}
          >
            {selectedFiles[index]?.name} {/* 파일 이름 표시 */}
          </Typography>
        </Box>
      ))}

      {/* 파일이 5개 미만일 때만 버튼 표시 */}
      {selectedFiles.length < 5 && (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              backgroundImage: `url(${IMAGE_ADD_PHOTO})`,
              width: "89px",
              height: "89px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundSize: "cover", // 이미지 크기 설정
              backgroundPosition: "center", // 이미지 위치 설정
              backgroundRepeat: "no-repeat", // 이미지 반복 방지
              overflow: "hidden", // 버튼 안 내용이 배경 이미지 밖으로 나가는 것을 방지
              cursor: "pointer", // 클릭 가능한 영역 표시
              border: uiState.FileError ? 1 : 0,
              borderColor: uiState.FileError ? color.error : "",
              borderRadius: "10px",
            }}
            onClick={handleBoxClick} // Box 클릭 이벤트
          >
            <input
              ref={fileInputRef} // ref 연결
              type="file"
              accept=".jpg,.jpeg,.png"
              hidden
              multiple
              onChange={handleFileChange}
            />
          </Box>
          <VerticalSpacer height={4} />
          <Typography fontStyle={fontStyle.regularXS} color={color.gray700}>
            Select File
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default FileUploadPreview;
