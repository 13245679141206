import { Box, CardContent, Typography } from "@mui/material";
import { fontStyle } from "../../../../../theme/Style";
import VerticalSpacer, { HorizontalSpacer } from "../../../../../component/Spacer";
import { color } from "../../../../../theme/Color";
import { CommonSelect } from "../../../../../component/CommonSelect";
import { useEffect, useState } from "react";
import { ICON_NOIMAGE } from "../../../../../constants/imagePath";
import CommonSingleLineTextField from "../../../../../component/CommonSingleLineTextField";
import { ProductData } from "../model/ProductData";
import CommonTextField from "../../../../../component/CommonTextField";
import { ProductUiState } from "../model/ProductUiState";
import { ProductUiEvent } from "../model/ProductUiEvent";
import CommonErrorText from "../../../../../component/CommonErrorText";
import { ApiService } from "../../../../../restAPI/ApiService";

interface SelectedProductProps {
  uiState: ProductUiState;
  uiEvent: ProductUiEvent;
}

export function SelectedProduct({ uiState, uiEvent }: SelectedProductProps) {
  useEffect(() => {
    ApiService.WebPost("/pw/aw/material/product/search").then((res) => {
      if (res?.data.body) {
        uiEvent.HandleUiStateChange("ProductNameList", res?.data.body);
      }
    });
    ApiService.WebPost("/pw/aw/material/searchBranch").then((res) => {
      if (res?.data.body) {
        uiEvent.HandleUiStateChange("MadeList", res?.data.body);
      }
    });
  }, []);
  return (
    <CardContent
      sx={{
        width: "100%",
        padding: "0px",
        "&:last-child": {
          paddingBottom: 0,
        },
      }}
    >
      <Typography fontStyle={fontStyle.semiboldL}>Product</Typography>
      <VerticalSpacer height={16} />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {/* 첫번째 줄 */}
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
          <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            Product Name
          </Typography>
          <VerticalSpacer height={8} />
          <CommonSelect
            menuitems={uiState.ProductNameList.map((item) => item.productName)}
            selectValue={uiState.ProductName}
            onChange={(event) => {
              uiEvent.HandleUiStateChange("ProductName", event.target.value);
              const selectData: any = uiState.ProductNameList.filter((data) => data.productName === event.target.value);
              if (selectData) {
                uiEvent.HandleUiStateChange("PartsCode", selectData[0].partsCode);
                uiEvent.HandleUiStateChange("ImgUrl", selectData[0].image);
                uiEvent.HandleUiStateChange("MaterialId", selectData[0].materialId);
              }
            }}
            sx={{ height: "48px" }}
            placeholder="Select"
            error={uiState.ProductNameError}
          />
          {uiState.ProductNameError && <CommonErrorText value="Please select Product Name." sx={{ ml: "4px", mt: "4px" }} />}
        </Box>
        <VerticalSpacer height={28} />
        {/* 두번째 줄 */}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "48.5%" }}>
            <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
              Parts Code
            </Typography>
            <VerticalSpacer height={8} />
            <CommonTextField
              value={uiState.PartsCode}
              sx={{
                width: "100%",
              }}
              InputProps={{
                sx: {
                  height: "48px",
                },
              }}
              disabled
            />
          </Box>
          <Box sx={{ width: "48.5%" }}>
            <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
              Made
            </Typography>
            <VerticalSpacer height={8} />
            <CommonSelect
              menuitems={uiState.MadeList.map((item) => item.name)}
              // menuitems={["Gwang-Ju"]}
              selectValue={uiState.Made}
              onChange={(event) => {
                uiEvent.HandleUiStateChange("Made", event.target.value);
                const selectData: any = uiState.MadeList.filter((data) => data.name === event.target.value);
                console.log(selectData);
                uiEvent.HandleUiStateChange("MadeId", selectData[0].id);
              }}
              placeholder="Select"
              sx={{ height: "48px", width: "100%" }}
              error={uiState.MadeError}
            />
            {uiState.MadeError && <CommonErrorText value="Please select Made." sx={{ ml: "4px", mt: "4px" }} />}
          </Box>
        </Box>
        <VerticalSpacer height={28} />
        {/* 세번째 줄 */}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "48.5%" }}>
            <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
              Serial Number
            </Typography>
            <VerticalSpacer height={8} />
            <CommonTextField
              value={uiState.SerialNumber}
              onChange={(event) => {
                uiEvent.HandleUiStateChange("SerialNumber", event.target.value);
              }}
              sx={{
                width: "100%",
              }}
              InputProps={{
                sx: {
                  height: "48px",
                },
              }}
              error={uiState.SerialNumberError}
            />
            {uiState.SerialNumberError && <CommonErrorText value="Enter your serial number." sx={{ ml: "4px", mt: "4px" }} />}
          </Box>
          <Box sx={{ width: "48.5%" }}>
            <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
              Serial Number CELL PACK
            </Typography>
            <VerticalSpacer height={8} />
            <CommonTextField
              value={uiState.SerialNumberCELLPACK}
              sx={{
                width: "100%",
              }}
              onChange={(event) => {
                uiEvent.HandleUiStateChange("SerialNumberCELLPACK", event.target.value);
              }}
              InputProps={{
                sx: {
                  height: "48px",
                },
              }}
              error={uiState.SerialNumberCELLPACKError}
            />
            {uiState.SerialNumberCELLPACKError && <CommonErrorText value="Enter your serial number." sx={{ ml: "4px", mt: "4px" }} />}
          </Box>
        </Box>
        <VerticalSpacer height={28} />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "48.5%" }}>
            <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
              Image
            </Typography>
            <VerticalSpacer height={8} />
            {uiState.ImgUrl ? (
              <Box
                component="img"
                src={uiState.ImgUrl}
                sx={{
                  width: "100%",
                  maxHeight: "100%", // 부모 요소 높이를 넘지 않도록 설정
                  objectFit: "cover", // 이미지를 비율에 맞게 잘라서 채우기
                  height: "255px",
                  borderRadius: "10px",
                }}
              />
            ) : (
              <Box
                sx={{
                  height: "255px",
                  borderRadius: "10px",
                  background: color.gray200,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    width: "113px",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box component="img" src={ICON_NOIMAGE} sx={{ width: "40px", height: "40px", padding: "4px" }} />
                  <Typography fontStyle={fontStyle.semiboldS} color={color.gray600}>
                    No Image
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
          <Box sx={{ width: "48.5%" }}>
            <Box sx={{ width: "100%" }}>
              <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                Serial Number M-BMS-V240
              </Typography>
              <VerticalSpacer height={8} />
              <CommonTextField
                value={uiState.SerialNumberMBMSV240}
                onChange={(event) => {
                  uiEvent.HandleUiStateChange("SerialNumberMBMSV240", event.target.value);
                }}
                sx={{
                  width: "100%",
                }}
                InputProps={{
                  sx: {
                    height: "48px",
                  },
                }}
                error={uiState.SerialNumberMBMSV240Error}
              />
              {uiState.SerialNumberMBMSV240Error && <CommonErrorText value="Enter your serial number." sx={{ ml: "4px", mt: "4px" }} />}
            </Box>
            <VerticalSpacer height={28} />
            <Box sx={{ width: "100%" }}>
              <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                Serial Number FET-V240
              </Typography>
              <VerticalSpacer height={8} />
              <CommonTextField
                value={uiState.SerialNumberFETV240}
                onChange={(event) => {
                  uiEvent.HandleUiStateChange("SerialNumberFETV240", event.target.value);
                }}
                sx={{
                  width: "100%",
                }}
                InputProps={{
                  sx: {
                    height: "48px",
                  },
                }}
                error={uiState.SerialNumberFETV240Error}
              />
              {uiState.SerialNumberFETV240Error && <CommonErrorText value="Enter your serial number." sx={{ ml: "4px", mt: "4px" }} />}
            </Box>
            <VerticalSpacer height={28} />
            <Box sx={{ width: "100%" }}>
              <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                Memo CT_SENSOR HARNESS
              </Typography>
              <VerticalSpacer height={8} />
              <CommonTextField
                value={uiState.MemoCtSensorHarness}
                onChange={(event) => {
                  uiEvent.HandleUiStateChange("MemoCtSensorHarness", event.target.value);
                }}
                sx={{
                  width: "100%",
                }}
                InputProps={{
                  sx: {
                    height: "48px",
                  },
                }}
                error={uiState.MemoCtSensorHarnessError}
              />
              {uiState.MemoCtSensorHarnessError && <CommonErrorText value="Please enter a memo." sx={{ ml: "4px", mt: "4px" }} />}
            </Box>
          </Box>
        </Box>
      </Box>
    </CardContent>
  );
}
