import React, { forwardRef } from "react";
import { Box, Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import { fontStyle } from "../../../../../theme/Style";
import { ServiceMngDetailUiState } from "../model/ServiceMngDetailUiState";
import { ServiceMngDetailUiEvent } from "../model/ServiceMngDetailUiEvent";
import { color } from "../../../../../theme/Color";
import { CommonSelect } from "../../../../../component/CommonSelect";
import CommonTextField from "../../../../../component/CommonTextField";
import CommonMultiLineTextField from "../../../../../component/CommonMultiLineTextField";
import VerticalSpacer from "../../../../../component/Spacer";
import { ADMIN_CONFIG } from "../../../../../constants/AdminConfig";

interface PartnersInformationCardProps {
  uiState: ServiceMngDetailUiState;
  uiEvent: ServiceMngDetailUiEvent;
}

const PartnersInformationCard = forwardRef<HTMLDivElement, PartnersInformationCardProps>(
  ({ uiState, uiEvent }: PartnersInformationCardProps, ref) => {
    return (
      <Grid item lg={12} ref={ref}>
        <Card
          sx={{
            borderRadius: "15px",
            minHeight: 240,
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CardContent sx={{ width: "100%", height: "100%" }}>
            <CardHeader title={"Partners Information"} titleTypographyProps={{ fontStyle: fontStyle.semiboldL }} />
            <Box
              sx={{
                m: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* 첫번째 줄 */}
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ width: "48.5%" }}>
                  <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                    Partners Category
                  </Typography>
                  <CommonSelect
                    selectValue={uiState.PartnersCategory}
                    menuitems={[ADMIN_CONFIG.HOT_SPOT, ADMIN_CONFIG.RESTAURANT, ADMIN_CONFIG.HOTEL]}
                    onChange={(event) => {
                      uiEvent.HandleUiStateChange("PartnersCategory", event.target.value);
                    }}
                    sx={{
                      height: "48px",
                      width: "100%",
                      mt: "8px",
                      borderRadius: "10px",
                      fontStyle: fontStyle.regularS,
                      color: "black",
                    }}
                    error={uiState.PartnersCategoryError}
                  />
                </Box>
                <Box sx={{ width: "48.5%" }}>
                  <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                    Partners Subtitle
                  </Typography>
                  <CommonTextField
                    value={uiState.PartnersSubtitle}
                    onChange={(event) => {
                      uiEvent.HandleUiStateChange("PartnersSubtitle", event.target.value);
                    }}
                    sx={{
                      mt: "8px",
                      width: "100%",
                      height: "48px",
                    }}
                    InputProps={{
                      style: {
                        height: "48px",
                        borderRadius: "10px",
                      },
                    }}
                    error={uiState.PartnersSubtitleError}
                  />
                </Box>
              </Box>
              <VerticalSpacer height={24} />
              {/* 두번째 줄 */}
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                    Description
                  </Typography>
                  <VerticalSpacer height={8} />
                  <CommonMultiLineTextField
                    placeholder="Please enter a description."
                    inputValue={uiState.PartnersDescription}
                    inputValueError={uiState.PartnersDescriptionError}
                    heightPx={164}
                    maxLength={1000}
                    onChange={(value) => {
                      uiEvent.HandleUiStateChange("PartnersDescription", value);
                    }}
                    onClear={() => {
                      uiEvent.HandleUiStateChange("PartnersDescription", "");
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    );
  }
);

export default PartnersInformationCard;
