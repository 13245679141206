import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
  Tabs,
  Tab,
  Box,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { SERVER_CONFIG } from "../../../constants/ServerConfig";
import { ADMIN_CONFIG } from "../../../constants/AdminConfig";
import CommonList from "../../../component/CommonList";
import { ProjectFormData } from "../../../store/projectType";
import CreateProjectGridPage from "./CreateProjectGridPage";
import ProjectInfoPage from "./ProjectInfoPage";

interface WorkspaceImage {
  name: string;
}

const CreateProjectPage = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [isSaved, setIsSaved] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState<"delete" | "navigate" | null>(
    null
  );
  const [error, setError] = useState("");
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  // 초기 상태를 저장하는 변수
  const [initialFormData, setInitialFormData] = useState<ProjectFormData>({
    id: 0,
    contactName: "",
    team: "",
    phoneCode: "82",
    phone: "",
    email: "",
    isExported: false,
    title: "",
    startDate: "",
    endDate: "",
    content: "",
    methodologyId: 0,
    latitude: 0,
    longitude: 0,
    address: "",
    detailAddress: "",
    reductionAmount: 0,
    businessOwnership: "",
    workspaceImages: [],
    businessValidationReportFile: null,
  });

  const [formData, setFormData] = useState<ProjectFormData>(initialFormData);
  const [activeTab, setActiveTab] = useState(0);
  const [tempMethodology, setTempMethodology] = useState<any>({});

  const fetchImagesAsFiles = async (imageUrls: any) => {
    const fileArray = [];

    if (imageUrls?.length > 0) {
      for (const url of imageUrls) {
        try {
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const blob = await response.blob();
          const fileName = url.split("/").pop();
          const file = new File([blob], fileName || "", { type: blob.type });
          fileArray.push(file);
        } catch (error) {
          console.error(`Error fetching image from ${url}:`, error);
        }
      }
    }

    return fileArray;
  };

  useEffect(() => {
    if (id) {
      const fetchProject = async () => {
        try {
          const response = await fetch(
            SERVER_CONFIG.CARBON_SERVER + `/project/${id}`
          );
          if (response.ok) {
            const data = await response.json();
            console.log("Fetched project data:", data);
            setFormData({
              ...data,
              methodologyId: data?.methodology?.id,
              workspaceImages: await fetchImagesAsFiles(data.workspaceImages),
            });
            setInitialFormData(data);
            setIsSaved(true);
          } else {
            console.error("Failed to fetch data");
          }
        } catch (error) {
          console.error("Error fetching project", error);
        }
      };
      fetchProject();
    }
  }, [id]);

  useEffect(() => {
    if (formData !== initialFormData) {
      setHasUnsavedChanges(true);
    } else {
      setHasUnsavedChanges(false);
    }
  }, [formData, initialFormData]);

  const uploadFile = async (file: File): Promise<string> => {
    try {
      console.log("file", file);

      const _file = new File([file], file.name.replace(/ /g, "_"), {
        type: file.type,
      });
      const formDataToUpload = new FormData();
      formDataToUpload.append("file", _file);

      const uploadResponse = await fetch(
        SERVER_CONFIG.CARBON_SERVER + "/greenery/api/bucket/upload",
        {
          method: "POST",
          body: formDataToUpload,
        }
      );

      if (!uploadResponse.ok) {
        throw new Error(`File upload failed: ${uploadResponse.statusText}`);
      }

      const uploadResult = await uploadResponse.json();
      console.log("File uploaded successfully:", uploadResult);

      if (!uploadResult.result) {
        throw new Error("File upload failed: No result.");
      }

      return uploadResult.objectUrl;
    } catch (error) {
      console.error("Error during file upload:", error);
      throw new Error("File upload failed.");
    }
  };

  const validation = () => {
    const newErrors: { [key: string]: string } = {};

    const validations = [
      {
        condition: !formData.company?.name,
        message: "기업명을 입력 해주세요.",
        key: "companyName",
      },
      {
        condition: !formData.title,
        message: "프로젝트명을 입력 해주세요.",
        key: "title",
      },
      {
        condition: !formData.company?.ownerName,
        message: "대표자명을 입력 해주세요.",
        key: "companyOwnerName",
      },
      {
        condition: !formData.company?.businessNumber,
        message: "사업자등록번호를 입력 해주세요.",
        key: "companyBusinessNumber",
      },
      {
        condition: !formData.company?.url,
        message: "홈페이지 링크를 입력 해주세요.",
        key: "companyUrl",
      },
      {
        condition: !formData.contactName,
        message: "담당자명을 입력 해주세요.",
        key: "contactName",
      },
      {
        condition: !formData.team,
        message: "부서명을 입력 해주세요.",
        key: "team",
      },
      {
        condition: !formData.email,
        message: "이메일을 입력 해주세요.",
        key: "email",
      },
      {
        condition: !formData.phone,
        message: "연락처를 입력 해주세요.",
        key: "phone",
      },
    ];

    for (const validation of validations) {
      if (validation.condition) {
        newErrors[validation.key] = validation.message;
      }
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return false;
    }
    return true;
  };

  const handleSave = useCallback(async () => {
    console.log("Starting save process with formData:", formData);
    if (!validation()) {
      return;
    }
    // if (
    //   !formData.company?.name ||
    //   !formData.title ||
    //   !formData.company.ownerName ||
    //   !formData.company.businessNumber ||
    //   !formData.company.url ||
    //   !formData.contactName ||
    //   !formData.team ||
    //   !formData.email ||
    //   !formData.phone
    // ) {
    //   setError("모든 필드에 입력해 주세요.");
    //   return;
    // }

    setErrorMessage(null);
    setLoading(true);

    try {
      let uploadedCertificateUrl: string | null = null;
      if (formData.company?.certificate instanceof File) {
        uploadedCertificateUrl = await uploadFile(formData.company.certificate);
      } else {
        uploadedCertificateUrl = formData?.company?.certificate as string;
      }

      let uploadedValidationReportUrl: string | null = null;
      if (formData.businessValidationReportFile instanceof File) {
        uploadedValidationReportUrl = await uploadFile(
          formData.businessValidationReportFile
        );
      } else {
        uploadedValidationReportUrl =
          formData.businessValidationReportFile as string;
      }

      let workspaceImages: any = [];
      if (
        formData.workspaceImages &&
        formData.workspaceImages?.length > 0 &&
        formData.workspaceImages[0] instanceof File
      ) {
        for (const element of formData.workspaceImages) {
          const url = await uploadFile(element as File);
          workspaceImages.push(url);
        }
      } else {
        workspaceImages = formData?.workspaceImages;
      }

      const { id, methodologyId, company, ...data } = formData;
      const dataToSend = {
        ...data,
        company: {
          ...company,
          certificate: uploadedCertificateUrl,
        },
        businessValidationReportFile: uploadedValidationReportUrl,
        workspaceImages: workspaceImages,
        type: id ? "MOVE" : "NEW",
        methodologyId: methodologyId,
      };

      delete dataToSend?.methodology;

      console.log("Payload to be sent:", dataToSend);

      const url = id
        ? `${SERVER_CONFIG.CARBON_SERVER}/project/${id}`
        : `${SERVER_CONFIG.CARBON_SERVER}/project`;
      const method = id ? "PUT" : "POST";

      const response = await fetch(url, {
        method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataToSend, null, 0),
      });

      if (response.ok) {
        const savedData = await response.json();
        console.log("Data saved successfully:", savedData);

        setFormData(savedData);
        setIsSaved(true);
        setInitialFormData(savedData);
        setError("");
        setTempMethodology({});
        handleNavigate("/projectMng");
      } else {
        const errorData = await response.json();
        setError(
          `Failed to save data: ${errorData.message || response.statusText}`
        );
        console.error("Save failed:", errorData);
      }
    } catch (error) {
      setError("An error occurred while saving data.");
      console.error("An error occurred:", error);
    } finally {
      setLoading(false);
    }
  }, [formData, uploadFile]);

  useEffect(() => {
    if (error) alert(error);
  }, [error]);

  const HeaderButton = () => (
    <form action="" style={{ display: "flex", alignItems: "center" }}>
      <Button
        sx={{
          width: "113px",
          height: "45px",
          backgroundColor: "white",
          color: "#5D38E5",
          borderRadius: 5,
          textTransform: "none",
          ":hover": {
            backgroundColor: "#5D38E5",
            color: "white",
          },
        }}
        onClick={handleSave}
        disabled={loading}
      >
        {isSaved ? "Update" : "Create"}
      </Button>
      {isSaved && (
        <Button
          sx={{
            width: "113px",
            height: "45px",
            borderRadius: "40px",
            padding: "20px, 0px, 20px, 0",
            textTransform: "none",
            backgroundColor: "white",
            color: "red",
            ":hover": {
              backgroundColor: "red",
              color: "white",
            },
            marginLeft: "10px",
          }}
          onClick={() => handleClickOpenDialog("delete")}
          disabled={loading}
        >
          Delete
        </Button>
      )}
    </form>
  );

  const handleClickOpenDialog = (type: "delete" | "navigate") => {
    setDialogType(type);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setDialogType(null);
  };

  const handleConfirmDelete = async () => {
    if (!id) return;
    setLoading(true);
    try {
      const response = await fetch(
        SERVER_CONFIG.CARBON_SERVER + `/project/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        setError("");
        handleNavigate("/projectMng");
      } else {
        const errorData = await response.json();
        setError(
          `Failed to delete data: ${errorData.message || response.statusText}`
        );
        console.error("Delete failed:", errorData);
      }
    } catch (error) {
      setError("An error occurred while deleting data.");
      console.error("An error occurred:", error);
    } finally {
      setLoading(false);
      handleCloseDialog();
    }
  };

  const handleConfirmNavigate = () => {
    handleNavigate("/projectMng");
    handleCloseDialog();
  };

  const handleNavigate = (route: string) => {
    navigate(route);
  };

  const HeaderBackButton = () => {
    const handleBackClick = () => {
      if (hasUnsavedChanges) {
        handleClickOpenDialog("navigate");
      } else {
        handleNavigate("/projectMng");
      }
    };
    console.log("Form Data being sent:", formData);
    return (
      <IconButton
        sx={{
          ":hover": { backgroundColor: "#F2F2F2" },
          mb: 0.5,
          mr: 1,
        }}
        onClick={handleBackClick}
      >
        <ArrowBackIosNewIcon fontSize="medium" />
      </IconButton>
    );
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <CommonList
      title={id ? ADMIN_CONFIG.PAGE_PROJECT.DETAIL : ADMIN_CONFIG.PAGE_PROJECT.REGISTER}
      setModal={null}
      headerButton={HeaderButton()}
      headerBackButton={HeaderBackButton()}
    >
      <Box>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          aria-label="project tabs"
          sx={{
            "& .MuiTabs-indicator": {
              bgcolor: "#5D38E5",
              fontSize: "18px",
            },
          }}
        >
          <Tab
            label={
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  fontSize: "18px",
                  color: activeTab === 0 ? "black" : "grey",
                  "&.Mui-selected": {
                    color: "black",
                  },
                }}
              >
                {ADMIN_CONFIG.PAGE_PROJECT.COMPANY_INFORMATION}
              </Typography>
            }
          />
          <Tab
            label={
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  fontSize: "18px",
                  color: activeTab === 1 ? "black" : "grey",
                }}
              >
                {ADMIN_CONFIG.PAGE_PROJECT.PROJECT_INFORMATION}
              </Typography>
            }
          />
        </Tabs>
        <Box sx={{ p: 0 }}>
          {activeTab === 0 && (
            <Box>
              <Box>
                <CreateProjectGridPage
                  formData={formData}
                  setFormData={setFormData}
                  errors={errors}
                />
              </Box>
            </Box>
          )}
          {activeTab === 1 && (
            <Box>
              <ProjectInfoPage
                formData={formData}
                setFormData={setFormData}
                tempMethodology={tempMethodology}
                setTempMethodology={setTempMethodology}
                errors={errors}
              />
            </Box>
          )}
        </Box>
      </Box>

      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        PaperProps={{
          sx: {
            minWidth: { xs: "70%", sm: "500px" },
            minHeight: "auto",
            borderRadius: "25px",
            backgroundColor: "#ffffff",
          },
        }}
      >
        <DialogContent
          sx={{
            padding: { xs: "1rem", sm: "2rem" },
            color: "black",
            textAlign: "center",
            fontSize: { xs: "0.875rem", sm: "1rem" },
          }}
        >
          {dialogType === "delete" ? (
            <>
              <p>삭제한 프로젝트는 복구할 수 없습니다.</p>
              <p>프로젝트를 삭제 하시겠습니까?</p>
            </>
          ) : (
            <>
              <p>작성하던 것을 중단하고 이동하시겠습니까? 해당 사항은 저장되지 않습니다.</p>
            </>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            padding: "2rem",
            backgroundColor: "#ffffff",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={handleCloseDialog}
            variant="outlined"
            sx={{
              color: "#5D38E5",
              borderBlockColor: "#5D38E5",
              borderRadius: "25px",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={
              dialogType === "delete"
                ? handleConfirmDelete
                : handleConfirmNavigate
            }
            variant="contained"
            sx={{
              color: "#ffffff",
              backgroundColor: "#5D38E5",
              borderRadius: "25px",
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </CommonList>
  );
};

export default CreateProjectPage;
