// ServerConfig.js

export const SERVER_CONFIG = {
  FRONT_URL: window.location.origin,
  KEYCLOAK_URL: "http://localhost:8080",
  KC_NAME: "lukehan",
  KC_PW: "1234",
  PW_USER_APP_REALM: "pw_user_app",
  PW_USER_APP_CLIENT_ID: "pw_user_app",
  PW_USER_APP_ONLOAD: "login_required",
  PW_ADMIN_WEB_REALM: "pw_admin_web",
  PW_ADMIN_WEB_CLIENT_ID: "pw_admin_web",
  PW_ADMIN_WEB_ONLOAD: "login_required",
//  CARBON_SERVER: "https://api-kisa.greenery.im",
  CARBON_SERVER: "http://192.168.0.7:8888",
  ON_FOR_GREENERY: true,
  ON_MAINTENANCE_APP: false,
}