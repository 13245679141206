import {
  MenuItem,
  Select,
  SelectChangeEvent,
  SxProps,
  Theme,
} from "@mui/material";
import { fontStyle } from "../theme/Style";
import { color } from "../theme/Color";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";

interface CommonSelectProps {
  selectValue: string;
  onChange: (event: SelectChangeEvent) => void;
  menuitems: any[];
  sx?: SxProps<Theme>;
  placeholder?: string;
  disabled?: boolean;
  paddingRight?: number;
  paddingLeft?: number;
  paddingTop?: number;
  paddingBottom?: number;
  height?: number;
  error?: any;
  itemSx?: SxProps<Theme>;
  renderValue?: (value: any) => React.ReactNode; // renderValue 추가
}

// Type guard to check if item is an object with a label property
function hasLabel(item: any): item is { label: string; code: string } {
  return item && typeof item === "object" && "label" in item;
}

export function CommonSelect({
  selectValue,
  onChange,
  menuitems,
  sx,
  placeholder,
  disabled,
  paddingRight,
  paddingLeft = 16,
  paddingTop = 12,
  paddingBottom = 12,
  height = 18,
  error,
  renderValue, // renderValue 추가
}: CommonSelectProps) {
  return (
    <Select
      disabled={disabled}
      value={selectValue}
      onChange={onChange}
      error={error}
      displayEmpty
      IconComponent={() => null}
      renderValue={
        renderValue ||
        ((v) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              flexGrow: 1,
            }}
          >
            <span style={{ lineHeight: "18px", flexGrow: 1 }}>
              {v === "" ? placeholder : hasLabel(v) ? v.label : v}
            </span>
            <ExpandMoreRoundedIcon
              style={{ fontSize: "16px", color: color.gray600 }}
            />
          </div>
        ))
      }
      sx={{
        "&.MuiOutlinedInput-root": {
          "& .MuiSelect-select": {
            display: "flex",
            alignItems: "center",
            paddingRight: paddingRight || "16px !important",
            paddingLeft: { paddingLeft },
            paddingTop: { paddingTop },
            paddingBottom: { paddingBottom },
            height: { height },
          },
          "& fieldset": {
            borderColor: color.gray300,
            zIndex: 400,
          },
          "&:hover fieldset": {
            borderColor: color.primary500,
          },
          "&.Mui-focused fieldset": {
            borderColor: color.primary500,
            borderWidth: "1px",
          },
          "&.Mui-disabled fieldset": {
            borderColor: color.gray300,
          },
        },
        bgcolor: disabled ? color.gray200 : "white",
        borderRadius: "10px",
        fontStyle: fontStyle.semiboldXS,
        color: color.gray600,
        ...sx,
      }}
      inputProps={{
        style: { zIndex: 200 },
      }}
      placeholder={placeholder}
    >
      {menuitems.map((item, index) => (
        <MenuItem
          key={index}
          value={hasLabel(item) ? item.code : item}
          data-name={hasLabel(item) ? item.label : item} // data-name 속성 사용
          sx={{
            backgroundColor:
              selectValue === (hasLabel(item) ? item.label : item)
                ? color.primary50
                : "transparent",
            "&:hover": {
              backgroundColor: color.primary50,
            },
            "&.Mui-selected": {
              backgroundColor:
                selectValue === (hasLabel(item) ? item.label : item)
                  ? color.primary50
                  : "transparent",
              "&:active": {
                backgroundColor: color.primary100,
              },
              "&:hover": {
                backgroundColor: color.primaryWeb100,
              },
            },
            "&:active": {
              backgroundColor: color.primary100,
            },
            "& .MuiTouchRipple-root": {
              color: color.primary200,
            },
            fontStyle: fontStyle.regularXS,
            color,
          }}
        >
          {hasLabel(item) ? item.label : item}
        </MenuItem>
      ))}
    </Select>
  );
}
