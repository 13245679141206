import { Tab, Tabs } from "@mui/material";
import { Text } from "../../../../component/Text";
import { fontStyle } from "../../../../theme/Style";
import { color } from "../../../../theme/Color";
import { TabType } from "../model/TabType";

interface ScooterInfoDetailTabProps {
  currentTab: TabType;
  onClick: (tab: TabType) => void;
}

export function StationInfoDetailTab({
  currentTab,
  onClick,
}: ScooterInfoDetailTabProps) {
  return (
    <Tabs
      value={currentTab}
      onChange={(event, value) => {
        onClick(value);
      }}
      TabIndicatorProps={{
        style: {
          backgroundColor: color.primary500,
        },
      }}
      sx={{
        height: "29px",
      }}
    >
      <Tab
        sx={{
          minWidth: 0,
          padding: 0,
        }}
        disableRipple
        value={TabType.BASICS}
        label={
          <Text
            sx={{
              fontStyle: fontStyle.semiboldM,
              textTransform: "none",
              color:
                currentTab === TabType.BASICS
                  ? color.primary500
                  : color.gray500,
            }}
            text={TabType.BASICS}
          />
        }
      />
    </Tabs>
  );
}
