import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Typography,
} from "@mui/material";
import { fontStyle } from "../../../theme/Style";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useEffect, useState } from "react";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { useNavigate } from "react-router-dom";
import {
  ICON_BATTERY,
  ICON_CARD,
  ICON_CHECK,
  ICON_COPY,
} from "../../../constants/appImagePath";
import VerticalSpacer, { HorizontalSpacer } from "../../../component/Spacer";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";
import useAndroidBackHandler from "../../../customHook/useAndroidBackHandler";
import { ApiService } from "../../../restAPI/ApiService";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ToastAlert from "../../../component/ToastAlert";
import LocalizedDate, {
  DateFormatType,
} from "../../../component/LocalizedDate";
import { Text } from "../../../component/Text";
import { color } from "../../../theme/Color";
import { MyRideUiEvent } from "../model/MyRideUiEvent";
import { MyRideUiState } from "../model/MyRideUiState";
import { ROUTE_PATHS } from "../../../constants/RouteConstants";

interface ScooterInfoScreenProps {
  uiState: MyRideUiState;
  uiEvent: MyRideUiEvent;
}

const ScooterInfoScreen = ({ uiState, uiEvent }: ScooterInfoScreenProps) => {
  const ua_e300_rentalperiod = LocalizedText("ua_e300_rentalperiod");
  const [scooterOpen, setScooterOpen] = useState(false);
  const [selectScooterId, setSelectScooterId] = useState(
    uiState.scooterList[0].id
  );
  const [selectScooterData, setSelectScooterData] = useState<any>();
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [warrantyUrl, setWarrantyUrl] = useState<String>("/Warranty");
  const navigate = useNavigate();

  const toggleDrawer = (newOpen: any) => () => {
    setScooterOpen(newOpen);
  };

  useAndroidBackHandler(() => {
    if (scooterOpen) {
      setScooterOpen(false);
    } else {
      window.Android.onBackStackCleared();
    }
  }, [scooterOpen]);

  useEffect(() => {
    const data = {
      id: selectScooterId,
    };
    ApiService.MobilePost("/pw/backend/api/scooter/userScoo/detail", data).then(
      (res) => {
        console.log(res?.data);
        setWarrantyUrl("/Warranty?sctrSN=" + res?.data?.body?.serialNumber);
        setSelectScooterData(res?.data.body);
      }
    );
  }, [selectScooterId]);

  useEffect(() => {
    const requestBody = {
      uuid: localStorage.getItem("uuid") || "",
    };
    ApiService.MobilePost(
      "/pw/backend/api/scooterAgreement/list",
      requestBody
    ).then((res) => {
      const data = res?.data.body.handOverList;
      console.log("asdasdasdasdasd : ", data);
      if (data) {
        uiEvent.SetContractList(data);
      }
    });
  }, []);

  const [remainingMileage, setRemainingMileage] = useState<any>();
  useEffect(() => {
    const requestBody = {
      uuid: localStorage.getItem("uuid") || "",
    };
    ApiService.MobilePost(`/pw/ua/product/MV/myMembership`, requestBody).then(
      (res) => {
        setRemainingMileage(res?.data.body?.totalAllowance); // 멤버쉽이 없는 경우 분기 처리 필요
      }
    );
  }, []);

  const [selectScooterPicUrl, setSelectScooterPicUrl] = useState("");
  useEffect(() => {
    const selectedScooter = uiState.scooterList.find(
      (scooter) => scooter.id === selectScooterId
    );
    setSelectScooterPicUrl(selectedScooter?.modelPicUrl);
  }, [uiState.scooterList, selectScooterId]);

  const DrawerList = (
    <div>
      <style>
        {`
        div::-webkit-scrollbar {
          display: none;
        }
      `}
      </style>
      <Box
        sx={{
          width: "100%",
          maxHeight: "51vh",
        }}
      >
        <ListSubheader
          sx={{
            height: 50,
            display: "flex",
            alignItems: "center",
            pt: 1,
            justifyContent: "space-between",
          }}
        >
          <Text
            sx={{
              fontStyle: fontStyle.titleL,
              color: color.default,
            }}
            text={LocalizedText("ua_e300_selectscooter")}
          />

          <Box style={{ marginTop: 12 }} onClick={toggleDrawer(false)}>
            <ClearOutlinedIcon />
          </Box>
        </ListSubheader>
        <List>
          {uiState.scooterList.map((data: any, index: any) => (
            <ListItem key={index} disablePadding>
              <ListItemButton
                onClick={() => {
                  setSelectScooterId(data.id);
                  toggleDrawer(false)(); // 이 부분 수정
                }}
              >
                <ListItemText>
                  {data.productName} | {data.plateNumber}
                </ListItemText>
                {selectScooterId == data.id && (
                  <Box component="img" src={ICON_CHECK} />
                )}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </div>
  );

  const calcBatteryStatus = (batteryStatus: any) => {
    const badText = LocalizedText("ua_e300_bad");
    const goodText = LocalizedText("ua_e300_good");
    const normalText = LocalizedText("ua_e300_normal");

    const soc = parseInt(batteryStatus?.soc, 10);
    const temperature = batteryStatus?.temperature;

    const socStatus = soc < 30 ? badText : soc <= 70 ? normalText : goodText;
    const temperatureStatus =
      temperature > 45 ? badText : temperature > 40 ? normalText : goodText;

    const finalStatus =
      socStatus === goodText && temperatureStatus === goodText
        ? goodText
        : socStatus === normalText && temperatureStatus !== badText
        ? normalText
        : badText;

    return (
      <Text
        sx={{
          fontStyle: fontStyle.bodyLr,
          color: color.black,
          ml: 1,
        }}
        text={finalStatus}
      />
    );
  };

  const ua_e300_contractscooter = LocalizedText(
    "ua_e300_contractscooter",
    String(uiState.scooterContractList.length)
  );
  const ua_e300_needscooter = LocalizedText("ua_e300_needscooter");

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "#F2F2F2",
          width: "100vw",
          height: "calc(100vh)",
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {/* 윗 요소 */}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
            borderBottomRightRadius: "20px",
            borderBottomLeftRadius: "20px",
            boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)",
          }}
        >
          <Box
            sx={{
              width: "calc(100% - 40px)",
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <VerticalSpacer height={12} />
            <Box onClick={toggleDrawer(true)}>
              <Box fontStyle={fontStyle.titleL} sx={{ display: "flex" }}>
                {selectScooterData?.productName}
                <Box>
                  <KeyboardArrowDownIcon />
                </Box>
              </Box>
              <Typography
                fontStyle={fontStyle.bodyLr}
                color={"#676665"}
                sx={{ mt: -1.5 }}
              >
                {selectScooterData?.plateNumber}
              </Typography>
            </Box>
            <Box
              component="img"
              src={selectScooterPicUrl}
              sx={{
                height: "242px",
                mb: 2,
                objectFit: "contain",
              }}
            />
            <Box
              fontStyle={fontStyle.subTitleL}
              sx={{ display: "flex", alignItems: "center" }}
              color={"#999999"}
            >
              {LocalizedText("ua_e300_vin")}
              <CopyToClipboard text={selectScooterData?.serialNumber}>
                <Box
                  fontStyle={fontStyle.bodyLr}
                  sx={{ ml: 1, display: "flex", alignItems: "center" }}
                  color={"black"}
                >
                  {selectScooterData?.serialNumber}
                  <HorizontalSpacer width={4} />
                  <Box
                    sx={{ mt: "1px" }}
                    onClick={() => {
                      if (!window.Android) {
                        setIsToastOpen(true);
                      }
                    }}
                  >
                    <img src={ICON_COPY} />
                  </Box>
                </Box>
              </CopyToClipboard>
            </Box>
            {selectScooterData?.rentOrOwn == "Rent" && (
              <Box
                fontStyle={fontStyle.subTitleL}
                sx={{ display: "flex", alignItems: "center" }}
                color={"#999999"}
              >
                {ua_e300_rentalperiod}
                <Box
                  fontStyle={fontStyle.bodyLr}
                  sx={{ ml: 1 }}
                  color={"black"}
                >
                  {selectScooterData?.issueTime != 0 &&
                    LocalizedDate({
                      date: selectScooterData?.issueTime,
                      type: DateFormatType.YearMonthDay,
                    })}{" "}
                  -{" "}
                  {selectScooterData?.expireTime != 0 &&
                    LocalizedDate({
                      date: selectScooterData?.expireTime,
                      type: DateFormatType.YearMonthDay,
                    })}
                </Box>
              </Box>
            )}

            <Box
              fontStyle={fontStyle.subTitleL}
              sx={{ display: "flex", alignItems: "center", mb: 2, mt: 0 }}
              color={"#999999"}
            >
              <img src={ICON_CARD} style={{ marginRight: 5, marginTop: 1 }} />
              {LocalizedText("ua_e300_nfc")}
              <Typography
                fontStyle={fontStyle.bodyLr}
                sx={{ ml: 1 }}
                color={"black"}
              >
                8915-5762-4467-2177
              </Typography>
            </Box>
          </Box>
        </Box>
        {/* 중간 요소 */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mb: 2,
            mt: 2,
            width: "91%",
          }}
        >
          {/* <Typography
            fontStyle={fontStyle.bodyMr}
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              color: "#999999",
              mb: 1.5,
            }}
          >
            {LocalizedText("ua_e300_latestupdate") + " 01/07/2024 16:00"}
          </Typography> */}
          {/* 배터리 정보 */}
          <Box
            sx={{
              width: "100%",
              backgroundColor: "white",
              minHeight: 40,
              mb: 2,
              boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)",
              borderRadius: "10px",
              display: "flex",
            }}
          >
            <Box sx={{ m: 2.5, mb: 1.5, width: "100%" }}>
              <Typography fontStyle={fontStyle.titleL}>
                {LocalizedText("ua_e300_batterystatus")}
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", mt: 0.8 }}>
                <img
                  src={ICON_BATTERY}
                  style={{ height: "25px", marginLeft: 5, marginRight: 8 }}
                />
                <Typography
                  fontStyle={fontStyle.headingL}
                  sx={{ color: "#2BCA86" }}
                >
                  {(parseInt(selectScooterData?.leftBatt?.soc) +
                    parseInt(selectScooterData?.rightBatt?.soc)) /
                  2
                    ? (parseInt(selectScooterData?.leftBatt?.soc) +
                        parseInt(selectScooterData?.rightBatt?.soc)) /
                      2
                    : "0"}
                  %
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  mt: 1.5,
                  width: "100%",
                }}
              >
                {/* left */}
                <Box
                  sx={{ display: "flex", alignItems: "center", width: "50%" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      backgroundColor: "#F2F2F2",
                      borderRadius: "10px",
                      alignItems: "center",
                      justifyContent: "center",
                      px: 1,
                    }}
                  >
                    <Typography fontStyle={fontStyle.subTitleM}>
                      {LocalizedText("ua_e300_left")}
                    </Typography>
                  </Box>
                  {calcBatteryStatus(selectScooterData?.leftBatt)}
                </Box>
                {/* right */}
                <Box
                  sx={{ display: "flex", alignItems: "center", width: "50%" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      backgroundColor: "#F2F2F2",
                      borderRadius: "10px",
                      alignItems: "center",
                      justifyContent: "center",
                      px: 1,
                    }}
                  >
                    <Typography fontStyle={fontStyle.subTitleM}>
                      {LocalizedText("ua_e300_right")}
                    </Typography>
                  </Box>
                  {calcBatteryStatus(selectScooterData?.rightBatt)}
                </Box>
              </Box>
            </Box>
          </Box>
          {/* 라이딩 리포트 */}
          <Box
            sx={{
              width: "100%",
              backgroundColor: "white",
              minHeight: 40,
              mb: 2,
              boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)",
              borderRadius: "10px",
              display: "flex",
            }}
          >
            <Box
              sx={{ m: 2.5, mb: 1.5, width: "100%" }}
              onClick={() => {
                navigate("/RidingReport");
              }}
            >
              <Box
                fontStyle={fontStyle.titleL}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {LocalizedText("ua_e300_ridingreport")}
                <Box
                  sx={{ position: "relative", left: 10 }}
                  // onClick={() => {
                  //   navigate("/RidingReport");
                  // }}
                >
                  <ChevronRightIcon />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                fontStyle={fontStyle.titleL}
              >
                {LocalizedText("ua_e300_totalmileage")}
                <Typography
                  fontStyle={fontStyle.titleXL}
                  sx={{ color: "#5D38E5" }}
                >
                  {selectScooterData?.totalMileage?.toFixed(2)}
                  km
                </Typography>
              </Box>
            </Box>
          </Box>
          {/* 워런티 */}
          <Box
            sx={{
              width: "100%",
              backgroundColor: "white",
              minHeight: 40,
              boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)",
              borderRadius: "10px",
              mb: 2,
              display: "flex",
            }}
          >
            <Box
              sx={{ m: 2.5, mb: 1.5, mt: 1.7, width: "100%" }}
              onClick={() => {
                let url = [warrantyUrl][0];
                if ("string" === typeof url) {
                  navigate(url);
                }
              }}
            >
              <Box
                fontStyle={fontStyle.titleL}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {LocalizedText("ua_e300_warranty")}
                <Box sx={{ position: "relative", left: 10, top: 2 }}>
                  <ChevronRightIcon />
                </Box>
              </Box>
            </Box>
          </Box>
          {/* 스쿠터 계약 */}
          <Box
            sx={{
              width: "100%",
              backgroundColor: "white",
              minHeight: 40,
              boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)",
              borderRadius: "10px",
              display: "flex",
            }}
          >
            <Box
              sx={{ m: 2.5, mb: 1.5, mt: 1.7, width: "100%" }}
              onClick={() => {
                navigate("/MyScooterContractListScreen");
              }}
            >
              <Box
                fontStyle={fontStyle.titleL}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {uiState.scooterContractList.length == 0 ? ( // scooterContractList의 길이로 보여줄 텍스트 분기
                  <Box>{ua_e300_needscooter}</Box>
                ) : (
                  <Box>{ua_e300_contractscooter}</Box>
                )}

                <Box sx={{ position: "relative", left: 10, top: 2 }}>
                  <ChevronRightIcon />
                </Box>
              </Box>
            </Box>
          </Box>

          <ToastAlert
            open={isToastOpen}
            onClose={() => {
              setIsToastOpen(false);
            }}
            message={LocalizedText("common_toast_copied")}
          />

          <VerticalSpacer height={45} />
        </Box>
      </Box>

      {/* 아랫 요소 */}
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          backgroundColor: "#F2F2F2",
          height: "45px",
          display: "flex",
          width: "100%",
        }}
      >
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            width: "100%",
            height: "45px",
            background: "linear-gradient(to bottom right, #5D38E5, #9E84FF)",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            color: "white",
          }}
          onClick={() => {
            navigate(ROUTE_PATHS.CHARGING_MEMBERSHIP);
          }}
        >
          <Typography sx={{ ml: 2 }} fontStyle={fontStyle.titleL}>
            {LocalizedText("ua_e300_remaining")}
          </Typography>
          <Box
            sx={{ mr: 0, display: "flex", alignItems: "center" }}
            fontStyle={fontStyle.titleL}
          >
            {remainingMileage}
            <Typography sx={{ ml: 0.5 }} fontStyle={fontStyle.bodyLr}>
              km
            </Typography>
            <IconButton>
              <ChevronRightIcon sx={{ color: "white", mt: 0.2 }} />
            </IconButton>
          </Box>
        </Box>
      </Box>

      {/* Drawer */}
      <Drawer
        open={scooterOpen}
        onClose={toggleDrawer(false)}
        anchor={"bottom"}
        PaperProps={{
          sx: {
            borderTopLeftRadius: 12,
            borderTopRightRadius: 12,
          },
        }}
      >
        {DrawerList}
      </Drawer>
    </>
  );
};

export default ScooterInfoScreen;
