import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  styled,
  Typography,
} from "@mui/material";
import { fontStyle } from "../../../theme/Style";
import { ICON_TOGLE_RADIO } from "../../../constants/appImagePath";
import { useEffect, useState } from "react";
import { color } from "../../../theme/Color";
import { LoginDetailUiEvent } from "../LoginDetailUiEvent";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";

interface EditGenderFieldProps {
  type?: string;
  title?: string;
  gender?: string;
  genderError: string;
  uiEvent: LoginDetailUiEvent;
}

// 커스텀 라디오 버튼을 스타일링합니다.
const CustomRadio = styled(Radio)(({ theme }) => ({
  "&.Mui-checked": {
    color: "transparent", // 라디오 버튼이 체크되었을 때의 색상을 투명으로 설정합니다.
  },
  "& .MuiSvgIcon-root": {
    width: 24,
    height: 24,
    // 기본 스타일은 투명으로 설정하고 이미지로 대체합니다.
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
}));

// 커스텀 FormControlLabel을 스타일링합니다.
const CustomFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  marginTop: theme.spacing(0.5),
  "& .MuiTypography-root": {
    marginLeft: theme.spacing(0),
  },
}));

const EditGenderField = ({
  type,
  title,
  gender,
  genderError,
  uiEvent,
}: EditGenderFieldProps) => {
  console.log(gender);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    uiEvent.HandleGenderChange(event.target.value);
  };

  return (
    <>
      <Typography fontStyle={fontStyle.titleS}>{title}</Typography>
      <RadioGroup onChange={handleChange} value={gender}>
        <Box sx={{ display: "flex" }}>
          <CustomFormControlLabel
            value="male"
            control={
              <CustomRadio
                sx={{
                  color: genderError == "" ? color.gray300 : color.error,
                  "&.Mui-checked": {
                    margin: "9px",
                    width: "24px",
                    height: "24px",
                    backgroundImage: `url(${ICON_TOGLE_RADIO})`,
                  },
                }}
              />
            }
            label={
              <Typography
                fontStyle={fontStyle.bodyLr}
                sx={
                  gender == "male"
                    ? { color: "#5D38E5" }
                    : genderError == ""
                    ? { color: color.gray600 }
                    : { color: color.error }
                }
              >
                {LocalizedText("ua_b123_male")}
              </Typography>
            }
          />
          <CustomFormControlLabel
            value="female"
            control={
              <CustomRadio
                sx={{
                  color: genderError == "" ? color.gray300 : color.error,
                  "&.Mui-checked": {
                    margin: "9px",
                    width: "24px",
                    height: "24px",
                    backgroundImage: `url(${ICON_TOGLE_RADIO})`, // 선택되지 않은 상태의 이미지
                  },
                }}
              />
            }
            label={
              <Typography
                fontStyle={fontStyle.bodyLr}
                sx={
                  gender == "female"
                    ? { color: "#5D38E5" }
                    : genderError == ""
                    ? { color: color.gray600 }
                    : { color: color.error }
                }
              >
                {LocalizedText("ua_b123_female")}
              </Typography>
            }
          />
        </Box>
      </RadioGroup>
    </>
  );
};

export default EditGenderField;
