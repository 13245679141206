import { Box, Button, Typography } from "@mui/material";
import { fontStyle } from "../../../theme/Style";

const MorePageBottomVersion = () => {
  return (
    <>
      <Box
        sx={{
          height: "42px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mx: "16px",
          my: "10px",
        }}
      >
        <Typography fontStyle={fontStyle.bodyMr} color={"#999999"}>
          App version v2.1
        </Typography>
        <Button
          sx={{
            bgcolor: "#5D38E5",
            color: "white",
            display: "none",
            height: "26px",
            width: "81px",
            borderRadius: "30px",
            "&:hover": {
              bgcolor: "#5D38E5", // hover 시 배경색
            },
          }}
        >
          <Typography
            fontStyle={fontStyle.titleS}
            sx={{ textTransform: "none" }}
          >
            Update
          </Typography>
        </Button>
      </Box>
    </>
  );
};

export default MorePageBottomVersion;
