import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiService } from "../../../restAPI/ApiService";
import { TableCell, TableRow, Typography } from "@mui/material";
import { fontStyle } from "../../../theme/Style";
import LocalizedDate, {
  DateFormatType,
} from "../../../component/LocalizedDate";
import CommonTable, { useVisibleRows } from "../../../component/CommonTable";
import { BatteryDataType, BatteryUiState } from "../model/BatteryUiState";
import { BatteryUiEvent } from "../model/BatteryUiEvent";
interface BatteryMngTableProps {
  mngRows?: any;
  rowsPerPage?: any;
  setRowsPerPage?: any;
  page?: any;
  pageCount?: any;
  setPage?: any;
  docCount?: number;
  uiState: BatteryUiState;
  uiEvent: BatteryUiEvent;
}

interface RowProps {
  row: BatteryDataType;
  index: number;
  page: number;
  rowsPerPage: number;
  uiEvent: BatteryUiEvent;
}
const Row = ({ row, index, rowsPerPage, page, uiEvent }: RowProps) => {
  return (
    <>
      <TableRow>
        <TableCell align="center" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>
            {" "}
            {page * rowsPerPage + index + 1}{" "}
          </Typography>
        </TableCell>

        <TableCell
          onClick={() => {
            uiEvent.onClick(row.battSerialNumber);
          }}
          align="center"
          sx={{ height: "52px", py: 0, cursor: "pointer" }}
        >
          <Typography fontStyle={fontStyle.regularXS}>
            {row.battSerialNumber}
          </Typography>
        </TableCell>

        <TableCell align="center" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.type}</Typography>
        </TableCell>

        <TableCell align="center" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.userD}</Typography>
        </TableCell>

        <TableCell align="center" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>
            {row.packCount}
          </Typography>
        </TableCell>

        <TableCell align="center" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.soc}</Typography>
        </TableCell>

        <TableCell align="center" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.station}</Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>{row.status}</Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>
            {LocalizedDate({
              date: row.lastUptDt * 1000,
              type: DateFormatType.numericTime,
            })}
          </Typography>
        </TableCell>

        <TableCell align="left" sx={{ height: "52px", py: 0 }}>
          <Typography fontStyle={fontStyle.regularXS}>
            {LocalizedDate({
              date: row.registerDt * 1000,
              type: DateFormatType.numericTime,
            })}
          </Typography>
        </TableCell>
      </TableRow>
    </>
  );
};

const BatteryMngTable = ({
  mngRows,
  rowsPerPage,
  setRowsPerPage,
  docCount,
  page,
  pageCount,
  setPage,
  uiState,
  uiEvent,
}: BatteryMngTableProps) => {
  const headCells = [
    {
      id: "no",
      align: "center",
      disablePadding: false,
      label: "No",
    },
    {
      id: "BatterySN",
      align: "left",
      disablePadding: false,
      label: "Battery S/N",
    },

    {
      id: "Model",
      align: "left",
      disablePadding: false,
      label: "Model",
    },
    {
      id: "UserD",
      align: "left",
      disablePadding: false,
      label: "User.D",
    },

    {
      id: "PackC",
      align: "left",
      disablePadding: false,
      label: "Pack.C",
    },

    {
      id: "SOC",
      align: "left",
      disablePadding: false,
      label: "SOC",
    },
    {
      id: "Station",
      align: "left",
      disablePadding: false,
      label: "Station",
    },
    {
      id: "Status",
      align: "left",
      disablePadding: false,
      label: "Status",
    },
    {
      id: "lastUpdateDate",
      align: "left",
      disablePadding: false,
      label: "Last Update Date",
    },
    {
      id: "registerDate",
      align: "left",
      disablePadding: false,
      label: "Register Date",
    },
  ];

  const { order, orderBy, handleRequestSort, handleChangePage, visibleRows } =
    useVisibleRows(mngRows, rowsPerPage, setPage, page);

  return (
    <CommonTable
      headCells={headCells}
      order={order}
      orderBy={orderBy}
      page={page}
      docCount={docCount}
      pageCount={pageCount}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      handleRequestSort={handleRequestSort}
      handleChangePage={handleChangePage}
      setPage={setPage}
    >
      {uiState.data?.map((row: BatteryDataType, index: number) => (
        <Row
          row={row}
          index={index}
          key={index}
          rowsPerPage={rowsPerPage}
          page={page}
          uiEvent={uiEvent}
        />
      ))}
    </CommonTable>
  );
};

export default BatteryMngTable;
