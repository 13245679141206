import { Box, Button, SwipeableDrawer, Typography } from "@mui/material";

import GraphCard from "./section/GraphCard";
import { useEffect, useState } from "react";
import { HasNFTUiState } from "./HasNFTUiState";
import { HasNFTUiEvent } from "./HasNFTUiEvent";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { color } from "../../../../../theme/Color";
import NFTCardAndInfo from "./section/NFTCardAndInfo";
import VerticalSpacer, {
  HorizontalSpacer,
} from "../../../../../component/Spacer";
import { fontStyle } from "../../../../../theme/Style";
import {
  ICON_RIGHT_ARROW,
  IMAGE_QRCODE,
} from "../../../../../constants/appImagePath";
import CouponCard from "./section/CouponCard";
import { LocalizedText } from "../../../../../di/LanguageRepositoryProvider";
import { MoreCarbonReductionNFTUiEvent } from "../../../MoreCarbonReductionNFTUiEvent";
import { MoreCarbonReductionNFTUiState } from "../../../MoreCarbonReductionNFTUiState";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "../../../../../constants/RouteConstants";
import { ApiService } from "../../../../../restAPI/ApiService";
import LocalizedDate, {
  DateFormatType,
} from "../../../../../component/LocalizedDate";
interface HasNFTRenderProps {
  uiState: HasNFTUiState;
  uiEvent: HasNFTUiEvent;
  parentUiState: MoreCarbonReductionNFTUiState;
  parentUiEvent: MoreCarbonReductionNFTUiEvent;
}

const HasNFTRender = ({
  uiState,
  uiEvent,
  parentUiState,
  parentUiEvent,
}: HasNFTRenderProps) => {
  const navigate = useNavigate();

  console.log(parentUiState.NFTCharData);
  const [couponData, setCouponData] = useState([]);

  useEffect(() => {
    ApiService.WebPost("/pw/ua/product/coupon/usable", {
      uuid: localStorage.getItem("uuid"),
    }).then((res) => {
      console.log(res?.data.body);
      setCouponData(res?.data.body);
    });
  }, []);

  const [couponId, setCouponId] = useState("");
  const [qrCodeImg, setQrCodeImg] = useState("");
  const [content, setContent] = useState("");
  const [expiredDate, setExpireddate] = useState(0);
  useEffect(() => {
    if (couponId) {
      ApiService.MobilePost(
        "/pw/ua/product/coupon/qr",
        {
          id: couponId,
        },
        {},
        "blob"
      )
        .then((res) => {
          if (res?.data) {
            console.log(res);
            const qrcodeUrl = URL.createObjectURL(res.data);
            setContent(res.headers.description);
            setExpireddate(parseInt(res.headers.expireddate, 10));
            setQrCodeImg(qrcodeUrl);
          }
        })
        .catch((error) => {
          console.error("Error fetching QR code:", error);
        });
    }
  }, [couponId]);

  const ua_g320_coupon = LocalizedText("ua_g320_coupon");
  const ua_g320 = LocalizedText("ua_g320");
  const ua_g320_usagehistory = LocalizedText("ua_g320_usagehistory");

  return (
    <>
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          overflowY: "scroll",
          backgroundColor: `${color.gray50}`,
          scrollbarWidth: "none", // Firefox에서 스크롤바 숨기기
          "&::-webkit-scrollbar": {
            display: "none", // Webkit 브라우저에서    스크롤바 숨기기
          },
        }}
      >
        <NFTCardAndInfo NFTCharData={parentUiState.NFTCharData} />
        <Box sx={{ width: "calc(100% - 40px)" }}>
          {/* 쿠폰 */}
          {couponData && (
            <Box>
              <VerticalSpacer height={32} />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography fontStyle={fontStyle.titleL}>
                  {ua_g320_coupon}
                </Typography>

                {/*  TODO 쿠폰 사용내역 페이지가 개발되지 않아 주석 처리합니다. */}
                <Typography
                  fontStyle={fontStyle.subTitleM}
                  color={color.gray700}
                  sx={{ display: "flex", alignItems: "center" }}
                  onClick={() => {
                    navigate(ROUTE_PATHS.COUPON_HIS_PAGE);
                  }}
                >
                  {ua_g320_usagehistory}
                  <Box
                    component="img"
                    src={ICON_RIGHT_ARROW}
                    sx={{ width: 25 }}
                  />
                </Typography>
              </Box>
              <VerticalSpacer height={12} />
              {couponData.length > 0 ? (
                couponData?.map((data: any) => (
                  <>
                    {console.log(data)}
                    <CouponCard
                      useWhere={data.name}
                      content={data.description}
                      couponId={data.id}
                      setCouponId={setCouponId}
                      // date="7 Jun 2024 ~ 24 Jun 2024"
                      date={
                        " ~ " +
                        LocalizedDate({
                          date: data.expiredDate,
                          type: DateFormatType.YearMonthDay,
                        })
                      }
                      handleDrawerOpenChange={
                        parentUiEvent.HandleDrawerOpenChange
                      }
                    />
                    <VerticalSpacer height={12} />
                  </>
                ))
              ) : (
                <Box
                  sx={{
                    bgcolor: "white",
                    minHeight: "113px",
                    width: "100%",
                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.03)",
                  }}
                >
                  <Typography
                    fontStyle={fontStyle.bodyMr}
                    color={color.gray600}
                  >
                    {ua_g320}
                  </Typography>
                </Box>
              )}
            </Box>
          )}

          <VerticalSpacer height={36} />
          {/* 그래프 */}
          {/* <Box>
            <GraphCard NFTCharData={parentUiState.NFTCharData} />
            <VerticalSpacer height={12} />
          </Box> */}
          {/* 쿠폰 Drawer */}
          <SwipeableDrawer
            anchor={"bottom"}
            open={parentUiState.drawerOpen}
            onClose={() => {
              parentUiEvent.HandleDrawerOpenChange(false);
            }}
            onOpen={() => {
              parentUiEvent.HandleDrawerOpenChange(true);
            }}
            PaperProps={{
              sx: {
                borderTopLeftRadius: 12,
                borderTopRightRadius: 12,
              },
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Box
                sx={{
                  height: "calc(100vh - 96px)",
                  width: "calc(100% - 40px)",
                  position: "relative",
                  display: "flex",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    position: "absolute",
                    top: "22px",

                    // justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ flex: 1 }} />
                  <Box
                    sx={{
                      flex: 1,
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Typography fontStyle={fontStyle.titleL}>
                      {LocalizedText("ua_g323_couponqr")}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      flex: 1,
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                    onClick={() => {
                      parentUiEvent.HandleDrawerOpenChange(false);
                    }}
                  >
                    <ClearOutlinedIcon />
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: "264px",
                      height: "auto",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      border: 6,
                      borderColor: color.primary200,
                      borderRadius: "10px",
                    }}
                  >
                    <VerticalSpacer height={32} />
                    <Box component="img" src={qrCodeImg} width={"200px"} />
                    <VerticalSpacer height={12} />
                    <Typography fontStyle={fontStyle.headingS}>
                      {couponId}
                    </Typography>
                    <VerticalSpacer height={20} />
                  </Box>
                  <VerticalSpacer height={80} />
                  <Box
                    sx={{
                      display: "flex",
                      textAlign: "center",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Typography fontStyle={fontStyle.titleL}>
                      {content}
                    </Typography>
                    <VerticalSpacer height={4} />
                    <Typography fontStyle={fontStyle.bodySr}>
                      Expiration Date{" "}
                      {LocalizedDate({
                        date: expiredDate,
                        type: DateFormatType.YearMonthDay,
                      })}
                    </Typography>
                    <VerticalSpacer height={16} />
                    <Box
                      sx={{
                        bgcolor: color.primary500,
                        borderRadius: "50px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <VerticalSpacer height={6} />
                      <Typography
                        fontStyle={fontStyle.titleS}
                        color={"white"}
                        sx={{ display: "flex" }}
                      >
                        <HorizontalSpacer width={16} />
                        {LocalizedText("ua_g323_available")}
                        <HorizontalSpacer width={16} />
                      </Typography>
                      <VerticalSpacer height={6} />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </SwipeableDrawer>
        </Box>
      </Box>
    </>
  );
};

export default HasNFTRender;
