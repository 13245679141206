import { Box, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CommonList from "../../component/CommonList";
import CommonSearch from "../../component/CommonSearch";
import { fontStyle } from "../../theme/Style";
import { color } from "../../theme/Color";
import VerticalSpacer, { HorizontalSpacer } from "../../component/Spacer";
import { ICON_MENU1, ICON_MENU2, ICON_MENU3 } from "../../constants/imagePath";
import StationMngTable from "./section/StationMngTable";

const StationMngPage = () => {
  const navigate = useNavigate();
  // 페이지 관련
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [rows, setRows] = useState([
    {
      no: "1",
      name: "test",
      serialNumber: "12345",
      state: "Open",
      link: "Linked",
      country: "South Korea",
      lastUpdateDate: 1727683793000,
      registerDate: 1727683793000,
    },
    {
      no: "2",
      name: "test2",
      serialNumber: "23456",
      state: "Close",
      link: "Linked",
      country: "South Korea",
      lastUpdateDate: 1727683793000,
      registerDate: 1727683793000,
    },
    {
      no: "3",
      name: "test3",
      serialNumber: "23456",
      state: "New",
      link: "Linked",
      country: "South Korea",
      lastUpdateDate: 1727683793000,
      registerDate: 1727683793000,
    },
    {
      no: "4",
      name: "test3",
      serialNumber: "23456",
      state: "Preparing",
      link: "Linked",
      country: "South Korea",
      lastUpdateDate: 1727683793000,
      registerDate: 1727683793000,
    },
    {
      no: "5",
      name: "test3",
      serialNumber: "23456",
      state: "Closure",
      link: "Linked",
      country: "South Korea",
      lastUpdateDate: 1727683793000,
      registerDate: 1727683793000,
    },
  ]);
  const [page, setPage] = useState<number>(0);
  const [docCount, setDocCount] = useState<number>(0);

  // 검색어 관련
  const [searchContents, setSearchContents] = useState("");

  // country Name
  const [countryList, setCountryList] = useState([]);
  const [country, setCountry] = useState("Country");
  const [countryCode, setCountryCode] = useState("");
  const handleCountryChange = (event: SelectChangeEvent) => {
    setCountry(event.target.value[0] as string);
    setCountryCode(event.target.value[1] as string);
  };

  // status
  const [status, setStatus] = useState("Status");
  const handleStatusChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value as string);
  };

  // menu
  const [menu, setMenu] = useState("dashboard");
  return (
    <CommonList title="스테이션 관리">
      <CommonSearch
        placeHolder={"Station ID, Station Code"}
        setSearchContents={setSearchContents}
      >
        <Box sx={{ display: "flex" }}>
          <Select
            value={country}
            onChange={handleCountryChange}
            renderValue={(v) => {
              return v?.length ? v : `Country`;
            }}
            sx={{
              width: "auto",
              height: "42px",
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
              "&.MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "red",
                },
                "&:hover fieldset": {
                  borderColor: "yellow",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "white",
                },
              },
              bgcolor: "white",
              borderRadius: "10px",
              fontStyle: fontStyle.semiboldXS,
              color: color.gray600,
            }}
          >
            <MenuItem
              value={["Country", ""]}
              sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}
            >
              all
            </MenuItem>
          </Select>
          <HorizontalSpacer width={12} />
          <Select
            value={status}
            onChange={handleStatusChange}
            renderValue={(v) => {
              return v?.length ? v : `Country`;
            }}
            sx={{
              width: "auto",
              height: "42px",
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
              "&.MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "red",
                },
                "&:hover fieldset": {
                  borderColor: "yellow",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "white",
                },
              },
              bgcolor: "white",
              borderRadius: "10px",
              fontStyle: fontStyle.semiboldXS,
              color: color.gray600,
            }}
          >
            <MenuItem
              value={"Status"}
              sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}
            >
              Status
            </MenuItem>
          </Select>
          <HorizontalSpacer width={12} />
          <Box
            sx={{
              height: "42px",
              bgcolor: "white",
              borderRadius: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontStyle: fontStyle.semiboldXS,
              color: color.gray600,
            }}
          >
            <HorizontalSpacer width={16} />
            View
            <HorizontalSpacer width={12} />
            <Box sx={{ display: "flex" }}>
              <Box
                component="img"
                src={ICON_MENU1}
                sx={{
                  bgcolor: menu == "dashboard" ? color.primaryWeb100 : "white",
                  cursor: "pointer",
                  p: "5.6px",
                  borderRadius: "4px",
                  transition: "background-color 0.3s, transform 0.3s",
                  "&:hover": {
                    transform: "scale(1.2)",
                  },
                }}
                onClick={() => {
                  setMenu("dashboard");
                }}
              />
              <HorizontalSpacer width={12} />
              <Box
                component="img"
                src={ICON_MENU2}
                sx={{
                  bgcolor: menu == "card" ? color.primaryWeb100 : "white",
                  cursor: "pointer",
                  p: "5.6px",
                  borderRadius: "4px",
                  transition: "background-color 0.3s, transform 0.3s",
                  "&:hover": {
                    transform: "scale(1.2)",
                  },
                }}
                onClick={() => {
                  setMenu("card");
                }}
              />
              <HorizontalSpacer width={12} />
              <Box
                component="img"
                src={ICON_MENU3}
                sx={{
                  bgcolor: menu == "table" ? color.primaryWeb100 : "white",
                  cursor: "pointer",
                  p: "5.6px",
                  borderRadius: "4px",
                  transition: "background-color 0.3s, transform 0.3s",
                  "&:hover": {
                    transform: "scale(1.2)",
                  },
                }}
                onClick={() => {
                  setMenu("table");
                }}
              />
            </Box>
            <HorizontalSpacer width={16} />
          </Box>
        </Box>
      </CommonSearch>

      <VerticalSpacer height={16} />
      <StationMngTable
        mngRows={rows}
        docCount={docCount}
        rowsPerPage={rowsPerPage}
        pageCount={pageCount}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
    </CommonList>
  );
};

export default StationMngPage;
