import { Box, Typography } from "@mui/material";
import { color } from "../../../theme/Color";
import { ReceiveDeliverDataType } from "./model/RecieveDeliverData";
import { fontStyle } from "../../../theme/Style";

export function ReceiveDeliverStatusBadge({ statusType }: { statusType: ReceiveDeliverDataType }) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "5px",
        backgroundColor: getBackgroundColor(statusType ? ReceiveDeliverDataType.RECIEVE : ReceiveDeliverDataType.DELIVER),
      }}
    >
      <Typography
        sx={{
          py: "2px",
          px: "8px",
          fontStyle: fontStyle.semiboldXXS,
          color: getTextColor(statusType ? ReceiveDeliverDataType.RECIEVE : ReceiveDeliverDataType.DELIVER),
        }}
      >
        {statusType ? ReceiveDeliverDataType.RECIEVE : ReceiveDeliverDataType.DELIVER}
      </Typography>
    </Box>
  );
}

function getTextColor(statusType: ReceiveDeliverDataType) {
  switch (statusType) {
    case ReceiveDeliverDataType.RECIEVE:
      return color.proceed;
    case ReceiveDeliverDataType.DELIVER:
      return color.warningWaiting;
  }
}

function getBackgroundColor(statusType: ReceiveDeliverDataType) {
  switch (statusType) {
    case ReceiveDeliverDataType.RECIEVE:
      return color.proceedBg;
    case ReceiveDeliverDataType.DELIVER:
      return color.warningWaitingBg;
  }
}
