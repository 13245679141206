import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  Divider,
  Radio,
  useTheme,
  Theme,
  Button,
} from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import VerticalSpacer, { HorizontalSpacer } from "../../../component/Spacer";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import CommonTextField from "../../../component/CommonTextField";
import { AnnounceCreateUiEvent } from "../model/AnnounceCreateUiEvent";
import { AnnounceCreateUiState } from "../model/AnnounceCreateUiState";
import React from "react";
import { CommonSelect } from "../../../component/CommonSelect";
import CommonDatePicker from "../../../component/CommonDatePicker";
import dayjs, { Dayjs } from "dayjs";
import CommonTimePicker from "../../../component/CommonTimePicker";
import { ICON_INDETERMINATE } from "../../../constants/imagePath";

interface AnnounceCreateGridProps {
  uiEvent: AnnounceCreateUiEvent;
  uiState: AnnounceCreateUiState;
  inValidDataCheck: {
    title: boolean;
    context: boolean;
    openDate: boolean;
    showHide: boolean;
    country: boolean;
  };
  fileUrls: string[];
  setFileUrls: Dispatch<SetStateAction<string[]>>;
  createData: {
    title: string;
    context: string;
    openDate: number;
    showHide: boolean;
    country: string;
  };
  handleCreateDataChange: (
    key: "title" | "context" | "openDate" | "showHide" | "country",
    value: string | number | boolean
  ) => void;
  countryCodeMap: { [key: string]: string };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AnnounceCreateGrid = ({
  uiEvent,
  uiState,
  inValidDataCheck,
  createData,
  fileUrls,
  setFileUrls,
  handleCreateDataChange,
  countryCodeMap,
}: AnnounceCreateGridProps) => {
  const [date, setDate] = useState<Dayjs | null>(null);
  const [time, setTime] = useState<Dayjs | null>(
    dayjs().set("hour", 0).set("minute", 0)
  );

  const getCountryName = (code: string) => {
    return (
      Object.keys(countryCodeMap).find((key) => countryCodeMap[key] === code) ||
      code
    );
  };

  const handleDateChange = (newDate: Dayjs | null) => {
    setDate(newDate);
  };

  const handleTimeChange = (newDate: Dayjs | null) => {
    setTime(newDate);
  };

  const getUnixTimestamp = (): number | null => {
    if (date && time) {
      // `date`에 `time`의 시간을 설정하여 결합
      return date
        .set("hour", time.hour()) // time에서 시간 가져오기
        .set("minute", time.minute()) // time에서 분 가져오기
        .set("second", time.second()) // time에서 초 가져오기
        .valueOf(); // Unix 타임스탬프(밀리초) 반환
    }
    return null; // date와 time이 모두 없으면 null 반환
  };

  useEffect(() => {
    const timestamp = getUnixTimestamp();
    if (timestamp !== null) {
      handleCreateDataChange("openDate", timestamp);
    }
  }, [date, time]);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = event.target.files?.[0];
    if (uploadedFile) {
      const allowedExtensions = ["png", "jpg", "jpeg"];
      const fileExtension = uploadedFile.name.split(".").pop()?.toLowerCase();

      if (fileExtension && allowedExtensions.includes(fileExtension)) {
        uiEvent.HandleUiStateChange("image", uploadedFile); // 파일 정보 저장
        const previewUrl = URL.createObjectURL(uploadedFile);
        setFileUrls((prev) => [...prev, previewUrl]); // 미리보기 URL 설정
      } else {
        alert("Only PNG, JPG, and JPEG files are allowed.");
      }
    }
  };

  const fileInputRef = useRef<HTMLInputElement>(null);

  // 버튼클릭하면 handleFileUpload 불러오기위해 만든 함수
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <Grid container rowSpacing={4.5} columnSpacing={2.75} sx={{ mt: -5 }}>
      {/* 첫번째 카드 */}
      <Grid item lg={8}>
        <Card
          sx={{
            borderRadius: "15px",
            width: "100%",
            padding: "24px",
            boxShadow: 0,
            border: "1px solid #E9E9EA",
          }}
        >
          <CardContent sx={{ width: "100%", padding: "0px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Title
                </Typography>
                <VerticalSpacer height={8} />
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <CommonTextField
                    placeholder="Enter Title"
                    onChange={(event) => {
                      handleCreateDataChange("title", event.target.value);
                    }}
                    error={inValidDataCheck.title}
                  />
                  {inValidDataCheck.title && (
                    <Typography
                      fontStyle={fontStyle.regularXS}
                      color={color.error}
                    >
                      Please enter a title
                    </Typography>
                  )}
                </Box>
              </Box>
              <VerticalSpacer height={40} />
              <Box>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Context
                </Typography>
                <VerticalSpacer height={8} />
                <Box
                  sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
                >
                  <CommonTextField
                    placeholder="Please enter a description"
                    multiline={true}
                    inputProps={{ style: { height: "220px" } }}
                    onChange={(event) => {
                      handleCreateDataChange("context", event.target.value);
                    }}
                    error={inValidDataCheck.context}
                  />
                  {inValidDataCheck.context && (
                    <Typography
                      fontStyle={fontStyle.regularXS}
                      color={color.error}
                    >
                      Please enter a content
                    </Typography>
                  )}
                </Box>
                <Box></Box>
              </Box>
              <VerticalSpacer height={40} />
              <Box>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Attach Image
                </Typography>
                <VerticalSpacer height={8} />
                <Box sx={{ display: "flex", gap:"20px" }}>
                  {fileUrls.map((fileUrl, index) => (
                    <Box key={index} sx={{ display: "flex", flexDirection: "column" }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex",
                          height: "108px",
                          width: "108px",
                          backgroundColor: color.gray200,
                          borderRadius: "10px",
                          border: "1px solid",
                          borderColor: color.gray300,
                          position: "relative",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyItems: "center",
                            flexDirection: "row-reverse",
                            alignItems: "flex-end",
                          }}
                        >
                          <Box
                            component="img"
                            src={fileUrl}
                            alt={`미리보기 이미지 ${index + 1}`}
                            sx={{
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                            }}
                          />
                          <Box
                            sx={{
                              position: "absolute",
                              bottom: "-5px", // 아래로 살짝 이동
                              right: "-5px", // 오른쪽으로 살짝 이동
                              cursor: "pointer",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                              height: "22px",
                              width: "22px",
                              borderRadius: "50%",
                              backgroundColor: color.gray800,
                              marginLeft: "4px",
                            }}
                            onClick={() => {
                              setFileUrls(
                                fileUrls.filter((_, i) => i !== index)
                              ); // 해당 파일 제거
                            }}
                          >
                            <img
                              src={ICON_INDETERMINATE}
                              style={{ width: "16px", height: "16px" }}
                            ></img>
                          </Box>
                        </Box>
                      </Box>
                      <HorizontalSpacer width={20} />
                    </Box>
                  ))}
                  {fileUrls.length < 5 && (
                    <>
                      <Box
                        sx={{
                          height: "108px",
                          width: "108px",
                          backgroundColor: color.gray200,
                          cursor: "pointer",
                          borderRadius: "10px",
                          border: "1px solid",
                          borderColor: color.gray300,
                          display: "flex",
                          position: "relative", // 상대 위치 지정
                        }}
                        onClick={handleButtonClick}
                      >
                        <Box
                          sx={{
                            position: "absolute",
                            width: "22px", // 원의 크기 설정
                            height: "22px", // 원의 크기 설정
                            borderRadius: "50%", // 원 모양 만들기
                            backgroundColor: color.primary500,
                            bottom: "-5px",
                            right: "-5px",
                            display: "flex",
                            alignItems: "center", // 수직 중앙 정렬
                            justifyContent: "center", // 수평 중앙 정렬
                            fontSize: "24px",
                            color: color.white,
                          }}
                        >
                          +
                        </Box>
                      </Box>
                    </>
                  )}
                  <input
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    onChange={handleFileUpload}
                    ref={fileInputRef}
                    hidden
                  />
                </Box>
                <VerticalSpacer height={24} />
                <Typography
                  fontStyle={fontStyle.regularS}
                  color={color.gray600}
                >
                  You can upload up to 5 attachments, with each image file up to
                  10MB.
                </Typography>
                <Typography
                  fontStyle={fontStyle.regularS}
                  color={color.gray600}
                >
                  Supported image formats: jpg, jpeg, gif, png.
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      {/* 두번째 카드 */}
      <Grid item lg={4}>
        <Card
          sx={{
            borderRadius: "15px",
            width: "100%",
            padding: "24px",
            boxShadow: 0,
            border: "1px solid #E9E9EA",
          }}
        >
          <CardContent sx={{ width: "100%", padding: "0px" }}>
            <CardHeader
              title="Information"
              titleTypographyProps={{ fontWeight: "bold", fontSize: 19 }}
              sx={{ padding: "0px" }}
            />
            <VerticalSpacer height={16} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box sx={{}}>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Country
                </Typography>
                <VerticalSpacer height={8} />
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <CommonSelect
                    selectValue={getCountryName(createData.country)}
                    sx={{
                      minHeight: "48px",
                      flexGrow: 1,
                      fontStyle: fontStyle.regularS,
                      color: color.default,
                    }}
                    onChange={(event) => {
                      handleCreateDataChange("country", event.target.value);
                    }}
                    menuitems={Object.keys(countryCodeMap)}
                    placeholder="Select"
                    error={inValidDataCheck.country}
                  />
                </Box>
                {inValidDataCheck.country && (
                  <Typography
                    fontStyle={fontStyle.regularXS}
                    color={color.error}
                  >
                    Please select country.
                  </Typography>
                )}
                <VerticalSpacer height={40} />
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Show/Hide
                </Typography>
                <Box sx={{ display: "flex" }}>
                  <Box>
                    <Radio
                      checked={createData.showHide === true}
                      onChange={() => {
                        handleCreateDataChange("showHide", true);
                      }}
                      value={true}
                      name="radio-buttons"
                      inputProps={{ "aria-label": "A" }}
                    />
                    Show
                  </Box>
                  <Box sx={{ ml: 5 }}>
                    <Radio
                      checked={createData.showHide === false}
                      onChange={() => {
                        handleCreateDataChange("showHide", false);
                      }}
                      value={false}
                      name="radio-buttons"
                      inputProps={{ "aria-label": "B" }}
                    />
                    Hide
                  </Box>
                </Box>
              </Box>
              <Box sx={{ mt: 3 }}>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Open date
                </Typography>
                <VerticalSpacer height={8} />
                <CommonDatePicker
                  value={createData.openDate == 1 ? dayjs(date) : null}
                  onChange={handleDateChange}
                  views={["year", "month", "day"]}
                  textFieldSx={{ width: "100%" }}
                  sx={{ height: "48px" }}
                  error={inValidDataCheck.openDate}
                  placeholder="Select Date"
                />
                {inValidDataCheck.openDate && (
                  <Typography
                    fontStyle={fontStyle.regularXS}
                    color={color.error}
                  >
                    Please select date.
                  </Typography>
                )}
                <VerticalSpacer height={40} />
                <Box>
                  <Typography
                    fontStyle={fontStyle.boldXS}
                    color={color.gray600}
                  >
                    Open Time
                  </Typography>
                </Box>
                <VerticalSpacer height={8} />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    borderColor: inValidDataCheck.openDate
                      ? color.error
                      : color.white,
                    borderRadius: "10px",
                  }}
                >
                  <CommonTimePicker value={time} onChange={handleTimeChange} />
                </Box>
              </Box>
              <VerticalSpacer height={40} />
              <Box>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Writer
                </Typography>
                <TextField
                  name="description"
                  sx={{ width: "100%", mt: 1 }}
                  value={localStorage.getItem("user_name")}
                  // onChange={handleChange}
                  InputProps={{
                    style: {
                      borderRadius: "12px",
                      backgroundColor: "#E9E9EA",
                    },
                  }}
                  disabled
                />
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default AnnounceCreateGrid;
