import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from "@mui/material";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { format } from "date-fns";
import { Monitoring } from "../../../store/monitoringType";
import { SERVER_CONFIG } from "../../../constants/ServerConfig";

const CustomCell = ({
  title,
  align,
  width,
  onSort
}: {
  title: string;
  align: "left" | "center" | "right";
  width?: string | number;
  onSort?: () => void;
}) => (
  <TableCell
    sx={{
      backgroundColor: "white",
      color: "#99989D",
      fontWeight: 600,
      fontFamily: "var(--bs-font-sans-serif)",
      width: width,
      overflow: "hidden",
      whiteSpace: "nowrap",
      position: "relative"
    }}
    align={align}
  >
    {title}
    {onSort && (
      <div
        onClick={onSort}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "absolute",
          right: 1,
          top: 10,
          fontSize: "1.0rem"
        }}
      >
        <UnfoldMoreIcon
          style={{
            marginTop: "10px",
            marginRight: "18px",
            fontSize: "medium",
            color: "#1D1B26"
          }}
        />
      </div>
    )}
  </TableCell>
);

interface MonitoringTableProps {
  rowsPerPage: number;
  rows: Monitoring[];
  page: number;
  totalRows: number;
  onChangePage: (event: React.ChangeEvent<unknown>, newPage: number) => void;
  onChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const MonitoringTable: React.FC<MonitoringTableProps> = ({
  rowsPerPage,
  rows,
  page,
  totalRows,
  onChangePage,
  onChangeRowsPerPage
}) => {
  const navigate = useNavigate();
  const [sortedRows, setSortedRows] = useState<Monitoring[]>(rows);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");

  useEffect(() => {
    // Sorting logic
    const sorted = [...rows].sort((a, b) => {
      if (sortDirection === "asc") {
        return (
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );
      } else {
        return (
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      }
    });
    setSortedRows(sorted);
  }, [rows, sortDirection]);

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    onChangePage(event, newPage);
  };
  const handleSort = () => {
    setSortDirection((prevDirection) =>
      prevDirection === "asc" ? "desc" : "asc"
    );
  };

  const handleRowClick = (id: number) => {
    navigate(`/monitoringMng/monitoringDetails/${id}`);
  };


  return (
    <Paper
      sx={{
        width: "100%",
        border: 0,
        borderColor: "#F3F3F4",
        borderRadius: "15px",
        mt: 3,
      }}
      elevation={0}
    >
      <TableContainer sx={{ borderRadius: "15px" }}>
        <Table
          sx={{
            width: "100%",
            border: "0px solid #E9E9EA",
            "&th": {
              border: "px solid #E9E9EA",
            },
            borderBottom: "1px solid #E9E9EA",
          }}
          stickyHeader
          aria-label="sticky table"
        >
          <TableHead>
            <TableRow>
              <CustomCell title="No" align="center" />
              <CustomCell
                title="모니터링 시작일"
                align="center"
                width="125px"
              />
              <CustomCell
                title="모니터링 종료일"
                align="center"
                width="125px"
              />
              <CustomCell title="버전" align="center" width="125px" />
              <CustomCell title="차수" align="center" width="125px" />
              <CustomCell
                title="감축량(검증 전)"
                align="center"
                width="125px"
              />
              <CustomCell
                title="감축량(검증 후)"
                align="center"
                width="125px"
              />
              <CustomCell
                title="모니터링 보고서"
                align="center"
                width="125px"
              />
              <CustomCell title="3자 검증 기관" align="center" />
              <CustomCell title="검증의견서" align="center" width="120px" />
              <CustomCell
                title="생성일"
                align="center"
                width="150px"
                onSort={handleSort}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRows.map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  ":hover": {
                    backgroundColor: "#F9F6FD",
                    cursor: "pointer",
                  },
                  "& td": {
                    color: "#53515A",
                    "& .MuiSvgIcon-root": {
                      color: "black",
                    },
                  },
                }}
                onClick={() => handleRowClick(row.id)}
              >
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  sx={{ color: "#53515A", fontWeight: 400 }}
                >
                  {page * rowsPerPage + index + 1}
                </TableCell>
                <TableCell align="center">
                  {format(new Date(row.startDate), "yyyy/MM/dd")}
                </TableCell>
                <TableCell align="center">
                  {format(new Date(row.endDate), "yyyy/MM/dd")}
                </TableCell>
                <TableCell align="center">{row.version}</TableCell>
                <TableCell align="center">{row.degree}</TableCell>
                <TableCell align="center">
                  {row.beforeReductionAmount}
                </TableCell>
                <TableCell align="center">{row.afterReductionAmount}</TableCell>
                <TableCell
                  align="center"
                  style={{ whiteSpace: "nowrap", justifyContent: "center" }}
                  onClick={async (e: any) => {
                    e.stopPropagation();
                    e.preventDefault();
                    try {
                      const response = await fetch(
                        SERVER_CONFIG.CARBON_SERVER +
                          `/file/monitoring/${row.id}`,
                        {
                          method: "GET",
                          headers: {},
                        }
                      );

                      if (!response.ok) {
                        throw new Error("Failed to download the file");
                      }

                      const blob = await response.blob();
                      const url = window.URL.createObjectURL(blob);

                      const link = document.createElement("a");
                      link.href = url;
                      link.setAttribute(
                        "download",
                        `monitoring_report_${row.id}.pdf`
                      );
                      document.body.appendChild(link);
                      link.click();
                    } catch (error) {
                      console.error(
                        "Error downloading the monitoring report:",
                        error
                      );
                      alert("Failed to download the report. Please try again.");
                    }
                  }}
                >
                  <a
                    href={row.monitoringReportUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      justifyContent: "center",
                      alignContent: "center",
                      display: "flex",
                      color: "#53515A",
                    }}
                  >
                    <InsertLinkIcon
                      style={{
                        cursor: "pointer",
                        marginRight: 8,
                        paddingBottom: 3,
                      }}
                    />
                    다운로드
                  </a>
                </TableCell>
                <TableCell>{row.verificationAgencyName}</TableCell>
                <TableCell
                  align="center"
                  style={{ whiteSpace: "nowrap", justifyContent: "center" }}
                  onClick={async (e: any) => {
                    e.stopPropagation();
                    e.preventDefault();
                    try {
                      const url = row.verificationStatementFile;
                      const response = await fetch(url);
                      if (!response.ok) {
                        throw new Error("네트워크 응답이 좋지 않습니다.");
                      }
                      const blob = await response.blob();
                      const blobUrl = URL.createObjectURL(blob);
                      const link = document.createElement("a");
                      link.href = blobUrl;
                      link.download =
                        url.split("/").pop() ||
                        `verificationStatement_file_${row.id}.pdf`;

                      document.body.appendChild(link);

                      link.click();
                      document.body.removeChild(link);
                      URL.revokeObjectURL(blobUrl);
                    } catch (error) {
                      console.error("이미지 다운로드 중 오류 발생:", error);
                    }
                  }}
                >
                  <a
                    href={row.verificationStatementFile}
                    rel="noopener noreferrer"
                    style={{
                      justifyContent: "center",
                      alignContent: "center",
                      display: "flex",
                      color: "#53515A",
                    }}
                  >
                    <InsertLinkIcon
                      style={{
                        cursor: "pointer",
                        marginRight: 8,
                        paddingBottom: 3,
                        color: "#53515A",
                      }}
                    />
                    다운로드
                  </a>
                </TableCell>
                <TableCell align="center">
                  {row.createdAt
                    ? format(
                        new Date(Number(row.createdAt) * 1000),
                        "yyyy/MM/dd HH:mm"
                      )
                    : "Invalid date"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        sx={{
          mt: 2,
          ml: 1,
          mb: 2,
          display: "flex",
          justifyContent: "flex-start",
          size: "medium",
          gap: "10px",
          opacity: 1,
          "& .MuiPaginationItem-root": {
            color: "blue",
            backgroundColor: "white",
            borderRadius: "50%",
            minWidth: "28px",
            height: "28px",
            padding: "4px",
            "&:hover": {
              backgroundColor: "#E0E0E0",
            },
            "&.Mui-selected": {
              backgroundColor: "#EFECFD",
              color: "#5D38E5",
              shape: "circular",
            },
          },
        }}
        count={Math.ceil(totalRows / rowsPerPage)}
        shape="rounded"
        page={page + 1}
        color="primary"
        onChange={handleChangePage}
      />
    </Paper>
  );
};

export default MonitoringTable;
