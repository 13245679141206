import { Box, Typography } from "@mui/material";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import { HorizontalSpacer } from "../../../component/Spacer";

interface CountryButtonProps {
  title: string;
  image: string;
  isSelected: boolean;
  onClick: () => void;
}

export default function CountryButton({
  title,
  image,
  isSelected,
  onClick,
}: CountryButtonProps) {
  return (
    <Box
      sx={{
        width: "100%",
        height: "65px",
        border: "2px solid",
        borderColor: isSelected ? color.primary500 : color.gray200,
        borderRadius: "10px",
        display: "flex",
        alignItems: "center",
        mb: "12px",
        px: "20px",
        boxSizing: "border-box",
      }}
      onClick={onClick}
    >
      <img
        src={image}
        alt=""
        style={{
          width: "20px",
          height: "20px",
          borderRadius: "50%",
          border: `1px solid ${color.gray300}`,
          objectFit: "cover",
        }}
      />
      <HorizontalSpacer width={8} />
      <Typography fontStyle={fontStyle.subTitleL} color={color.default}>
        {title}
      </Typography>
    </Box>
  );
}
