import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { LabeledPaper } from "./LabeledPaper";
import VerticalSpacer from "../../../component/Spacer";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import { CommonSelect } from "../../../component/CommonSelect";
import { BatteryDetailUiState } from "../model/BatteryDetailUiState";
import { BatteryDetailUiEvent } from "../model/BatteryDetailUiEvent";
import LocalizedDate, {
  DateFormatType,
} from "../../../component/LocalizedDate";

interface BatteryDetailCardProps {
  uiState: BatteryDetailUiState;
  uiEvent: BatteryDetailUiEvent;
}

const BatteryDetailCard = ({ uiState, uiEvent }: BatteryDetailCardProps) => {
  const lastUptDt = LocalizedDate({
    date: uiState.data?.lastUptDt.toString(),
    type: DateFormatType.numericTime,
  });
  const registerDt = LocalizedDate({
    date: uiState.data?.registerDt.toString(),
    type: DateFormatType.numericTime,
  });
  const menuitems = [
    { label: "Normal", icon: <Box sx={{ width: 24, height: 24, backgroundColor: "green", borderRadius: "50%" }} /> },
    { label: "Defective", icon: <Box sx={{ width: 24, height: 24, backgroundColor: "red", borderRadius: "50%" }} /> },
  ];
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          width: "100%",
          paddingBottom: "16px",
        }}
      >
        <Card
          sx={{
            flexGrow: 1,
            borderRadius: "15px",
            minWidth: "509px",
            padding: 0,
            boxShadow: 0,
            border: "1px solid #E9E9EA",
          }}
        >
          <CardContent
            sx={{
              padding: "24px",
              paddingBottom: "40px !important",
            }}
          >
            <LabeledPaper label="ID" values={[uiState.data.battSerialNumber]} />
            <VerticalSpacer height={40} />
            <LabeledPaper label="Type" values={[uiState.data.type]} />
            <VerticalSpacer height={40} />
            <LabeledPaper label="Station" values={[uiState.data.station]} />
            <VerticalSpacer height={40} />
            <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
              Status
            </Typography>
            <VerticalSpacer height={8} />
            <CommonSelect
              selectValue={uiState.status}
              onChange={(event) => {
                const selectedValue = event?.target.value;
                uiEvent.HandleUiStateChange(
                  "status",
                  selectedValue === "All" ? "" : selectedValue
                );
              }}
              sx={{ width: "100%" }}
              menuitems={["Normal", "Defective"]}
              placeholder="Status"
              disabled={uiState.data.status === "Defective" ? true : false}
            />
          </CardContent>
        </Card>
        <Card
          sx={{
            flexGrow: 1,
            borderRadius: "15px",
            minWidth: "509px",
            padding: 0,
            boxShadow: 0,
            border: "1px solid #E9E9EA",
          }}
        >
          <CardContent sx={{ padding: "24px" }}>
            <LabeledPaper
              label="Balancing"
              values={[uiState.data.balancing, "-"]}
            />
            <VerticalSpacer height={40} />
            <LabeledPaper
              label="Alarm"
              values={[uiState.data.alarm, "-"]}
            />
            <LabeledPaper
              label=""
              values={[""]}
              paperSx={{ backgroundColor: color.white, height: "214px" }}
            />
          </CardContent>
        </Card>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          width: "100%",
          paddingBottom: "16px",
        }}
      >
        <Card
          sx={{
            flexGrow: 1,
            borderRadius: "15px",
            minWidth: "509px",
            padding: 0,
            boxShadow: 0,
            border: "1px solid #E9E9EA",
          }}
        >
          <CardContent sx={{ padding: "24px" }}>
            <Typography fontStyle={fontStyle.semiboldL}>Battery</Typography>
            <VerticalSpacer height={16} />
            <Box
              sx={{
                display: "flex",
                gap: "16px",
                flexGrow: 1,
                width: "100%",
              }}
            >
              <LabeledPaper
                label="Serial Pararell"
                values={[uiState.data.serialPararell]}
              />
              <LabeledPaper
                label="Pack Capacity"
                values={[uiState.data.packCapacity]}
              />
              <LabeledPaper label="User.D" values={[uiState.data.userD]} />
              <LabeledPaper
                label="Pack Count"
                values={[uiState.data.packCount]}
              />
            </Box>
            <VerticalSpacer height={40} />
            <Box
              sx={{
                display: "flex",
                gap: "16px",
                flexGrow: 1,
              }}
            >
              <LabeledPaper label="Voltage" values={[uiState.data.voltage]} />
              <LabeledPaper label="Current" values={["-"]} />
              <LabeledPaper label="SOC" values={[uiState.data.soc]} />
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          width: "100%",
          paddingBottom: "16px",
        }}
      >
        <Card
          sx={{
            flexGrow: 1,
            borderRadius: "15px",
            minWidth: "509px",
            padding: 0,
            boxShadow: 0,
            border: "1px solid #E9E9EA",
          }}
        >
          <CardContent sx={{ padding: "24px" }}>
            <Typography fontStyle={fontStyle.semiboldL}>
              Cell Voltage
            </Typography>
            <VerticalSpacer height={16} />
            <Box
              sx={{
                display: "flex",
                gap: "16px",
                flexGrow: 1,
              }}
            >
              <LabeledPaper label="10" values={[uiState.data.cv10]} />
              <LabeledPaper label="9" values={[uiState.data.cv9]} />
              <LabeledPaper label="8" values={[uiState.data.cv8]} />
              <LabeledPaper label="7" values={[uiState.data.cv7]} />
              <LabeledPaper label="6" values={[uiState.data.cv6]} />
            </Box>
            <VerticalSpacer height={40} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "16px",
                flexGrow: "1px",
              }}
            >
              <LabeledPaper label="5" values={[uiState.data.cv5]} />
              <LabeledPaper label="4" values={[uiState.data.cv4]} />
              <LabeledPaper label="3" values={[uiState.data.cv3]} />
              <LabeledPaper label="2" values={[uiState.data.cv2]} />
              <LabeledPaper label="1" values={[uiState.data.cv1]} />
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          width: "100%",
          paddingBottom: "16px",
        }}
      >
        <Card
          sx={{
            flexGrow: 1,
            borderRadius: "15px",
            minWidth: "509px",
            padding: 0,
            boxShadow: 0,
            border: "1px solid #E9E9EA",
          }}
        >
          <CardContent sx={{ padding: "24px" }}>
            <Typography fontStyle={fontStyle.semiboldL}>Temperature</Typography>
            <VerticalSpacer height={16} />
            <Box
              sx={{
                display: "flex",
                gap: "16px",
                flexGrow: "1px",
              }}
            >
              <LabeledPaper label="6" values={[uiState.data.t6]} />
              <LabeledPaper label="5" values={[uiState.data.t5]} />
              <LabeledPaper label="4" values={[uiState.data.t4]} />
              <LabeledPaper label="3" values={[uiState.data.t3]} />
              <LabeledPaper label="2" values={[uiState.data.t2]} />
              <LabeledPaper label="1" values={[uiState.data.t1]} />
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Box>
        <Card
          sx={{
            flexGrow: 1,
            borderRadius: "15px",
            minWidth: "509px",
            padding: 0,
            boxShadow: 0,
            border: "1px solid #E9E9EA",
          }}
        >
          <CardContent sx={{ display: "flex", padding: "24px", gap: "16px" }}>
            <LabeledPaper label="Updated Date" values={[lastUptDt === "" ? "-" : lastUptDt || "-"]} />
            <LabeledPaper label="Registration Date" values={[registerDt === "" ? "-" : registerDt || "-"]} />
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};
export default BatteryDetailCard;
