// ServerConfig.js

export const SERVER_CONFIG = {
  FRONT_URL: window.location.origin,

  // for keycloak
  KEYCLOAK_URL: "http://localhost:8080",
  KC_NAME: "lukehan",
  KC_PW: "1234",
  PW_USER_APP_REALM: "pw_user_app",
  PW_USER_APP_CLIENT_ID: "pw_user_app",
  PW_USER_APP_ONLOAD: "login_required",
  PW_ADMIN_WEB_REALM: "pw_admin_web",
  PW_ADMIN_WEB_CLIENT_ID: "pw_admin_web",
  PW_ADMIN_WEB_ONLOAD: "login_required",

  // for test - phone number code
  ON_FOR_PASS_KOREAN_NUMBER: false,
  ON_FOR_TEST: true,
  MASTER_NUMBER: "031548271412041204",
  MASTER_CODE: "12041204",

  // for carbon
//  CARBON_SERVER: "https://api-kisa.greenery.im",
  CARBON_SERVER: "http://10.0.1.103:8888",
  ON_FOR_GREENERY: false,

  // for blockchain
  METADIUME_EXPLORER_URL: "https://explorer.metadium.com",

  // for maintenance
  ON_MAINTENANCE_APP: false,
};
