import { Box, CardContent, Typography } from "@mui/material";
import { fontStyle } from "../../../../theme/Style";
import VerticalSpacer, { HorizontalSpacer } from "../../../../component/Spacer";
import { color } from "../../../../theme/Color";
import { ReceiveDeliverDataType } from "../model/RecieveDeliverData";
import { useEffect, useState } from "react";
import { CommonRadio } from "../../../../component/CommonRadio";
import { CommonSelect } from "../../../../component/CommonSelect";
import CommonTextField from "../../../../component/CommonTextField";
import CommonDatePicker from "../../../../component/CommonDatePicker";
import dayjs, { Dayjs } from "dayjs";
import CommonTimePicker from "../../../../component/CommonTimePicker";
import { ReceiveDeliverUiState } from "../model/ReceiveDeliverUiState";
import { ReceiveDeliverUiEvent } from "../model/ReceiveDeliverUiEvent";
import CommonErrorText from "../../../../component/CommonErrorText";

interface Field {
  value: ReceiveDeliverDataType;
  label: string;
}

interface InventoryDetailInspectorProps {
  uiState: ReceiveDeliverUiState;
  uiEvent: ReceiveDeliverUiEvent;
}
export function InventoryDetailInspector({ uiState, uiEvent }: InventoryDetailInspectorProps) {
  const [type, setType] = useState<ReceiveDeliverDataType>();
  const [registerStartDate, setRegisterStartDate] = useState<Dayjs | null>(null);
  const [RegisterStartTime, setRegisterStartTime] = useState<Dayjs | null>(null);

  // FinalRegisterDate 값이 Unix 타임스탬프라면 이를 나누어 등록
  useEffect(() => {
    if (uiState.FinalRegisterDate) {
      const finalDate = dayjs(uiState.FinalRegisterDate);
      setRegisterStartDate(finalDate.startOf("day")); // 날짜만 추출
      setRegisterStartTime(finalDate); // 시간 포함한 전체 시간
    }
  }, [uiState.FinalRegisterDate]);

  const handleRegisterDateChange = (newDate: Dayjs | null) => {
    setRegisterStartDate(newDate);
  };

  const handleRegisterTimeChange = (newTime: Dayjs | null) => {
    setRegisterStartTime(newTime);
  };

  // registerDate와 registerTime을 병합하여 finalRentalStartDate 설정
  useEffect(() => {
    if (registerStartDate && RegisterStartTime) {
      const mergedDateTime = registerStartDate.hour(RegisterStartTime.hour()).minute(RegisterStartTime.minute()).second(0);
      uiEvent.HandleUiStateChange("FinalRegisterDate", mergedDateTime.valueOf());
    }
  }, [registerStartDate, RegisterStartTime]);

  const radioTypes: Field[] = [
    { label: "Receive", value: ReceiveDeliverDataType.RECIEVE },
    { label: "Deliver", value: ReceiveDeliverDataType.DELIVER },
  ];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (Object.values(ReceiveDeliverDataType).includes(value as ReceiveDeliverDataType)) {
      setType(value as ReceiveDeliverDataType);
      console.log("Selected Material Type:", value);
      uiEvent.HandleUiStateChange("Type", value);
    }
  };

  useEffect(() => {
    if (uiState.Type == ReceiveDeliverDataType.RECIEVE) {
      setType(ReceiveDeliverDataType.RECIEVE);
    } else {
      setType(ReceiveDeliverDataType.DELIVER);
    }
  }, [uiState.Type]);

  return (
    <CardContent
      sx={{
        width: "100%",
        padding: "0px",
        "&:last-child": {
          paddingBottom: 0,
        },
      }}
    >
      <Typography fontStyle={fontStyle.semiboldL}>Inspector</Typography>
      <VerticalSpacer height={16} />
      <Box sx={{ display: "flex" }}>
        <Box sx={{ width: "50%" }}>
          <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            Type
          </Typography>
          <VerticalSpacer height={8} />
          <Box sx={{ display: "flex", height: "48px", alignItems: "center" }}>
            {radioTypes.map((radioType, index) => (
              <Box key={index} sx={{ display: "flex", alignItems: "center" }}>
                <CommonRadio<ReceiveDeliverDataType>
                  selectValue={type}
                  label={radioType.label}
                  value={radioType.value}
                  onchange={handleChange}
                  sx={{ width: "14.4px", height: "14.4px" }}
                  disabled={uiState.IsWriter ? false : true}
                />
                <HorizontalSpacer width={8} />
                <Typography fontStyle={fontStyle.regularS}>{radioType.label}</Typography>
                <HorizontalSpacer width={28} />
              </Box>
            ))}
          </Box>
        </Box>
        <HorizontalSpacer width={28} />
        <Box sx={{ display: "flex", width: "50%" }}>
          <Box sx={{ width: "50%" }}>
            <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
              Register Date
            </Typography>
            <VerticalSpacer height={8} />
            <CommonDatePicker
              value={registerStartDate || dayjs(new Date())}
              sx={{ height: "48px" }}
              InputPropsSx={{ height: "48px" }}
              views={["year", "month", "day"]}
              onChange={handleRegisterDateChange}
              disabled={uiState.IsWriter ? false : true}
            />
          </Box>
          <HorizontalSpacer width={16} />
          <Box sx={{ width: "50%" }}>
            <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
              Register Time
            </Typography>
            <VerticalSpacer height={8} />
            <CommonTimePicker
              value={RegisterStartTime || dayjs(new Date())}
              sx={{ height: "48px" }}
              onChange={handleRegisterTimeChange}
              disabled={uiState.IsWriter ? false : true}
            />
          </Box>
        </Box>
      </Box>
      <VerticalSpacer height={40} />
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
          }}
        >
          <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            Branch
          </Typography>
          <VerticalSpacer height={8} />
          <CommonSelect
            sx={{ minHeight: "48px", flexGrow: 1 }}
            menuitems={uiState.BranchList.map((item: any) => item.name)}
            selectValue={uiState.BranchName}
            onChange={(event) => {
              // setBranch(event.target.value);
              uiEvent.HandleUiStateChange("BranchName", event.target.value);
              const selectData: any = uiState.BranchList.filter((data) => data.name === event.target.value);
              console.log(selectData);
              uiEvent.HandleUiStateChange("BranchId", selectData[0].id);
            }}
            placeholder="Select"
            disabled={uiState.IsWriter ? false : true}
            error={uiState.BranchError}
          />
          {uiState.BranchError && <CommonErrorText value="Please enter the code." sx={{ ml: "92px", mt: "4px" }} />}
        </Box>
        <HorizontalSpacer width={28} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
          }}
        >
          <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            Inspector
          </Typography>
          <VerticalSpacer height={8} />
          <CommonTextField
            sx={{ minHeight: "48px", flexGrow: 1 }}
            value={uiState.InspectorName}
            onChange={(event) => {
              // setBranch(event.target.value);
            }}
            disabled
          />
        </Box>
      </Box>
      <VerticalSpacer height={40} />
      <Box>
        <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
          Commnet
        </Typography>
        <VerticalSpacer height={8} />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CommonTextField
            multiline
            value={uiState.Commnet}
            onChange={(event) => {
              uiEvent.HandleUiStateChange("Commnet", event.target.value);
            }}
            InputProps={{ style: { padding: "0px" } }}
            inputProps={{ style: { height: "80px", padding: "12px 16px" } }}
            error={uiState.CommnetError}
            disabled={uiState.IsWriter ? false : true}
          />
        </Box>
      </Box>
    </CardContent>
  );
}
