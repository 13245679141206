import { useEffect, useState } from "react";
import SupportRender from "./SupportRender";
import { SupportPageSideEffect } from "./SupportPageSideEffect";
import { useNavigate } from "react-router-dom";
import { SupportPageUiState } from "./SupportPageUiState";
import { SupportPageUiEvent } from "./SupportPageUiEvent";
import { ApiService } from "../../restAPI/ApiService";
import { SupportButtonModel } from "./model/SupportButtonModel";
import useAndroidBackHandler from "../../customHook/useAndroidBackHandler";
import { getCountryCode } from "../../customHook/useLocale";
import { ROUTE_PATHS } from "../../constants/RouteConstants";

const SupportPage = () => {
  const navigate = useNavigate();
  const [uiState, setUiState] = useState(new SupportPageUiState());

  useAndroidBackHandler(() => {
    if (uiState.isShowEmergencyDialog) {
      uiEventHandler.HideEmergencyDialog();
    } else {
      window.Android.onBackStackCleared();
    }
  }, [uiState.isShowEmergencyDialog]);

  useEffect(() => {
    (async () => {
      await updateFAQList();
    })();
  }, []);

  useEffect(() => {
    updateNoticeList().then((r) => {});
  }, []);

  const uiEventHandler: SupportPageUiEvent = {
    ShowEmergencyDialog: () => {
      if (window.Android) {
        window.Android.isShownDialog(true);
      }

      if (window.webkit) {
        window.webkit.messageHandlers.isShownDialog.postMessage(true);
      }

      setUiState((prev) => {
        return {
          ...prev,
          isShowEmergencyDialog: true,
        };
      });
    },

    HideEmergencyDialog: function (): void {
      if (window.Android) {
        window.Android.isShownDialog(false);
      }

      if (window.webkit) {
        window.webkit.messageHandlers.isShownDialog.postMessage(false);
      }

      setUiState((prev) => {
        return {
          ...prev,
          isShowEmergencyDialog: false,
        };
      });
    },
  };

  const sideEffectHandlers: SupportPageSideEffect = {
    callEmergency: (number: string) => {
      window.location.href = `tel:${number}`;
    },
    navigate: function (target: string, state?: any): void {
      if (state !== undefined) {
        navigate(target, { state: { id: state.state.id } });
      } else {
        navigate(target);
      }
    },
    moveToFAQDetail: function (id: string, uuid: string): void {
      navigate(ROUTE_PATHS.FAQ_DETAIL, { state: { id, uuid } });
    },
  };

  // FAQButton 리스트를 반환 하는 함수
  async function getFAQTop4List(): Promise<SupportButtonModel[]> {
    let faqList: SupportButtonModel[] = [];
    const country = getCountryCode();
    const url = `/pw/ua/contents/faq/topView/${country}`;
    try {
      await ApiService.MobilePost(url).then((res) => {
        faqList = res?.data.body;
      });
    } catch (e) {}
    return faqList;
  }

  async function updateFAQList() {
    const faqList = await getFAQTop4List();
    setUiState((prev) => ({
      ...prev,
      faqTop4List: faqList,
    }));
  }

  async function updateNoticeList() {
    const url = "/pw/ua/contents/announce/recent";
    const reqBody = {
      country: getCountryCode(),
    };
    try {
      const res = await ApiService.MobilePost(url, reqBody);
      if (res != null || res != undefined) {
        setUiState((prev) => ({
          ...prev,
          noticeList: res?.data.body,
        }));
      }
    } catch (e) {
      console.log("failed to get notice list", e);
    }
  }

  return (
    <SupportRender
      uiState={uiState}
      uiEvent={uiEventHandler}
      sideEffect={sideEffectHandlers}
    />
  );
};

export default SupportPage;
