import { Box, CardContent, Typography } from "@mui/material";
import { fontStyle } from "../../../../theme/Style";
import { color } from "../../../../theme/Color";
import VerticalSpacer, { HorizontalSpacer } from "../../../../component/Spacer";
import CommonTextField from "../../../../component/CommonTextField";
import { ReceiveDeliverUiState } from "../model/ReceiveDeliverUiState";
import { ReceiveDeliverUiEvent } from "../model/ReceiveDeliverUiEvent";

interface InventoryDetailFindPartsProps {
  uiState: ReceiveDeliverUiState;
  uiEvent: ReceiveDeliverUiEvent;
}
export function InventoryDetailFindParts({ uiState, uiEvent }: InventoryDetailFindPartsProps) {
  return (
    <CardContent
      sx={{
        width: "100%",
        padding: "0px",
        "&:last-child": {
          paddingBottom: 0,
        },
      }}
    >
      <Typography fontStyle={fontStyle.semiboldL}>Request Parts</Typography>
      <VerticalSpacer height={16} />
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "90%",
          }}
        >
          <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            Material Name
          </Typography>
          <VerticalSpacer height={8} />
          <CommonTextField
            sx={{ minHeight: "48px", flexGrow: 1 }}
            value={uiState.MaterialName}
            onChange={(event) => {
              // setBranch(event.target.value);
              uiEvent.HandleUiStateChange("MaterialName", event.target.value);
            }}
            disabled={uiState.IsWriter ? false : true}
            error={uiState.MaterialNameError}
          />
        </Box>
        <HorizontalSpacer width={28} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "10%",
          }}
        >
          <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
            Count
          </Typography>
          <VerticalSpacer height={8} />
          <CommonTextField
            sx={{ minHeight: "48px", flexGrow: 1 }}
            value={uiState.Count.toString()}
            onChange={(event) => {
              // setBranch(event.target.value);
              uiEvent.HandleUiStateChange("Count", parseInt(event.target.value));
            }}
            inputProps={{
              type: "number", // 숫자만 입력 가능하도록 설정
            }}
            disabled={uiState.IsWriter ? false : true}
            error={uiState.CountError}
          />
        </Box>
      </Box>
    </CardContent>
  );
}
