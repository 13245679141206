import { Box, Divider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Toolbar from "../../../component/Toolbar";
import {
  IMAGE_EMPTY_IMAGE,
  IMAGE_RESTAURANT,
  IMAGE_RESTAURANT2,
  IMAGE_RESTAURANT3,
} from "../../../constants/appImagePath";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";
import { ApiService } from "../../../restAPI/ApiService";
import { color } from "../../../theme/Color";
import { fontStyle } from "../../../theme/Style";
import { Partners } from "./../model/PartnersModel";
import ProgressBar from "../../../component/ProgressBar";

const week = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const MorePartnersDetail = () => {
  const location = useLocation();
  const [error, setError] = useState<string>("");
  const [partners, setPartners] = useState<Partners | undefined>(undefined);

  const ua_g610_open = LocalizedText("ua_g610_open");
  const ua_g610_holiday = LocalizedText("ua_g610_holiday");
  const ua_g610_location = LocalizedText("ua_g610_location");
  const ua_g600_hotel = LocalizedText("ua_g600_hotel");
  const ua_g600_restaurant = LocalizedText("ua_g600_restaurant");
  const ua_g600_hotspot = LocalizedText("ua_g600_hotspot");

  type CustomCSSProperties = React.CSSProperties & {
    "--swiper-pagination-color"?: string;
    "--swiper-pagination-top"?: string;
    "--swiper-pagination-left"?: string;
    "--swiper-pagination-right"?: string;
    "--swiper-pagination-fraction-color"?: string;
  };

  const swiperStyles: CustomCSSProperties = {
    "--swiper-pagination-fraction-color": "white",
    // "--swiper-pagination-top": "75%",
    // "--swiper-pagination-left": "75%",

    width: "100%",
    aspectRatio: "65 / 37",
    height: "auto",
  };
  const arrayDifference = (arr: string[]) => {
    let res: string = " ";
    for (let i = 0; i < arr.length; ++i) {
      if ("X" === arr[i]) {
        if (0 < i) res += " ";
        res += week[i];
      }
    }
    return res;
  };

  function serviceType(prop: string): string | undefined {
    switch (prop) {
      case "Hot Spot":
        return ua_g600_hotspot;
      case "Hotel":
        return ua_g600_hotel;
      case "Restaurant":
        return ua_g600_restaurant;
      default:
        return "Error";
    }
  }

  const partnersDetail = async () => {
    const url = "/pw/backend/api/partners/detail";
    const requestBody = {
      svcId: location.state.svcId,
    };
    await ApiService.MobilePost(url, requestBody)
      .then((res) => {
        console.log("partnersDetail response", res);
        if (res?.data?.body) {
          const partnersDetail: Partners = {
            serviceId: res?.data.body.svcId,
            serviceName: res?.data.body.svcNm,
            serviceOpenTime: res?.data.body.svcOpenTime,
            serviceDescription: res?.data.body.svcDescription,
            serviceCloseTime: res?.data.body.svcCloseTime,
            serviceOpenDay: res?.data.body.svcOpenDay,
            serviceType: res?.data.body.svcType,
            serviceSubTitle: res?.data.body.svcSubTitle,
            location: res?.data.body.location,
            serviceImage: res?.data.body.svcImage,
          };
          console.log(`PARTNERS DETAIL ${partnersDetail.serviceName}`, partnersDetail);
          console.log(partnersDetail.serviceType);
          setPartners(partnersDetail);
        } else {
        }
      })
      .catch((error) => {
        setError(`failed partnersDetail api request ${error}`);
      });
  };

  useEffect(() => {
    partnersDetail();
  }, []);

  if (error) {
    return <div>{error}</div>;
  }
  if (!partners) {
    return <ProgressBar />;
  }
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Toolbar
        onBack={() => {
          window.history.back();
        }}
        title=""
      />

      <Box
        sx={{
          flexGrow: 1,
          overflowY: "auto",
        }}
      >
        <Swiper
          style={swiperStyles}
          slidesPerView={1}
          // height={100}
          spaceBetween={0}
          pagination={{
            type: "fraction",
            renderFraction: function (currentClass: any, totalClass: any) {
              return (
                '<div style="background-color: ' +
                color.baseDimmer +
                '; border-radius: 50px; display: flex; align-items: center; justify-content: center; padding: 2px 8px; margin-left: 87%; width: fit-content;">' +
                '<span class="' +
                currentClass +
                '" style="font-size: ' +
                fontStyle.bodySr.fontSize +
                "; " +
                "line-height: " +
                fontStyle.bodySr.lineHeight +
                "; " +
                "font-weight: " +
                fontStyle.bodySr.fontWeight +
                ';">' +
                "</span>" +
                '<span style="color: ' +
                color.gray300 +
                "; " +
                "font-size: " +
                fontStyle.bodySr.fontSize +
                "; " +
                "line-height: " +
                fontStyle.bodySr.lineHeight +
                "; " +
                "font-weight: " +
                fontStyle.bodySr.fontWeight +
                ';">/</span>' +
                '<span class="' +
                totalClass +
                '" style="color: ' +
                color.gray300 +
                "; " +
                "font-size: " +
                fontStyle.bodySr.fontSize +
                "; " +
                "line-height: " +
                fontStyle.bodySr.lineHeight +
                "; " +
                "font-weight: " +
                fontStyle.bodySr.fontWeight +
                ';">' +
                "</span>" +
                "</div>"
              );
            },
          }}
          modules={[Pagination]}
        >
          { partners.serviceImage.length > 0 ? (
            partners.serviceImage.map((data: string) => (
              <SwiperSlide>
                <img
                  src={data}
                  style={{ width: "100%", height: "100%" }}
                />
                <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
              </SwiperSlide>
            ))
          ) : (
            <SwiperSlide>
              <img
                src={IMAGE_EMPTY_IMAGE}
                style={{ width: "100%", height: "100%" }}
              />
              <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
            </SwiperSlide>
          )}
        </Swiper>

        <Box sx={{ padding: "0 20px 0 20px", marginTop: "13px" }}>
          <Box sx={{ paddingBottom: "16px" }}>
            <Typography
              // sx={{
              //   whiteSpace: "pre-line"
              // }}
              color="#B3B3B3"
              fontStyle={fontStyle.subTitleS}
            >
              {serviceType(partners.serviceType)}
            </Typography>
            <Typography
              color="#1B1918"
              fontStyle={fontStyle.headingS}
              marginBottom={"4px"}
            >
              {partners.serviceName}
            </Typography>
            <Typography fontStyle={fontStyle.bodyLr} color={color.gray700}>
              {partners.serviceSubTitle}
            </Typography>
          </Box>
          {/* 구분선 */}
          <Divider sx={{ color: color.gray200 }} />

          {/* 운영시간 및 휴무일 섹션 */}
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              marginTop: "20px",
              marginBottom: "12px",
            }}
          >
            <Box
              sx={{
                width: "64px",
                textAlign: "left",
                marginRight: 1,
              }}
            >
              <Typography fontStyle={fontStyle.titleM} color="#4D4C4C">
                {ua_g610_open}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "12px",
              }}
            >
              <Typography fontStyle={fontStyle.bodyMr} color={color.gray800}>
                {partners.serviceOpenTime} ~ {partners.serviceCloseTime}
              </Typography>

              <Box sx={{ display: "flex", marginTop: 1 }}>
                <Typography
                  fontStyle={fontStyle.subTitleM}
                  color={color.gray600}
                  sx={{ marginRight: 1 }}
                >
                  {ua_g610_holiday}
                </Typography>
                <Typography fontStyle={fontStyle.bodyMr} color={color.gray800}>
                  {arrayDifference(partners.serviceOpenDay)}
                </Typography>
              </Box>
            </Box>
          </Box>

          {/* 위치 섹션 */}
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              marginBottom: "16px",
            }}
          >
            <Box
              sx={{
                width: "64px",
                textAlign: "left",
                marginRight: 1,
              }}
            >
              <Typography fontStyle={fontStyle.titleM} color="#4D4C4C">
                {ua_g610_location}
              </Typography>
            </Box>
            <Box sx={{ flexBasis: "80%" }}>
              <Typography fontStyle={fontStyle.bodyMr} color={color.gray800}>
                {partners.location}
              </Typography>
            </Box>
          </Box>

          {/* 구분선 */}
          <Divider
            sx={{
              color: color.gray200,
            }}
          />
          <Box sx={{ marginBottom: "60px" }}>
            <Typography
              color={color.default}
              fontStyle={fontStyle.bodyLr}
              sx={{ margin: "16px 0 20px 0" }}
            >
              {partners.serviceDescription}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MorePartnersDetail;
