import { Box, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CommonList from "../../component/CommonList";
import CommonSearch from "../../component/CommonSearch";
import { fontStyle } from "../../theme/Style";
import { color } from "../../theme/Color";
import VerticalSpacer, { HorizontalSpacer } from "../../component/Spacer";
import { ICON_MENU1, ICON_MENU2, ICON_MENU3 } from "../../constants/imagePath";
import StationMngTable from "./section/StationMngTable";
import { StationDataType, StationUiState } from "./model/StationUiState";
import { StationUiEvent } from "./model/StationUiEvent";
import { ApiService } from "../../restAPI/ApiService";
import { CommonSelect } from "../../component/CommonSelect";

const StationMngPage = () => {
  const navigate = useNavigate();
  // 페이지 관련
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState<number>(0);
  const [docCount, setDocCount] = useState<number>(0);

  // 검색어 관련
  const [searchContents, setSearchContents] = useState("");

  // country Name
  const [countryList, setCountryList] = useState([]);
  const [country, setCountry] = useState("Country");
  const [countryCode, setCountryCode] = useState("");
  const handleCountryChange = (event: SelectChangeEvent) => {
    setCountry(event.target.value[0] as string);
    setCountryCode(event.target.value[1] as string);
  };

  // status
  const [status, setStatus] = useState("Status");
  const handleStatusChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value as string);
  };

  // menu
  const [menu, setMenu] = useState("table");

  const [uiState, setUiState] = useState(new StationUiState());
  const uiEvent: StationUiEvent = {
    HandleUiStateChange(key: keyof StationUiState, value: any) {
      setUiState((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    },

    HandleCountryChange: (value) => {
      setUiState((prevState) => ({
        ...prevState,
        country: value,
      }));
    },

    onClick: (id: string) => {    
      navigate("/station_mng_detail", { state: { id } });
    },
  };
  useEffect(() => {
    ApiService.WebPost("/pw/aw/contents/getCountry").then((res) => {
      setCountryList(res?.data.body);
      uiEvent.HandleUiStateChange("countryList", res?.data.body);
    });
  }, []);

  //TODO API 연동 필요
  useEffect(() => {
    ApiService.WebPost("/pw/backend/api/station/searchCondition", {
      svcStt: uiState.status,
      ctry: uiState.country,
      searchCondition: searchContents,
      page: page,
      size: rowsPerPage,
    })
      .then((res) => {
        const data = res?.data.body.serviceList || [];
        uiEvent.HandleUiStateChange("data", data);
        setPageCount(Math.ceil(res?.data.body.docCount / rowsPerPage) || 1);
        setDocCount(res?.data.body.docCount || 0);
        console.log("res data : ", res?.data.body.serviceList);
      })
      .catch((e) => {
        console.error("error : ", e);
      });
  }, [searchContents, uiState.status, uiState.country, page]);
  return (
    <CommonList title="Station">
      <CommonSearch
        placeHolder={"Station ID, Station Code"}
        setSearchContents={setSearchContents}
      >
        <Box sx={{ display: "flex" }}>
          <CommonSelect
            selectValue={
              uiState.countryList.find((item) => item.code === uiState.country)
                ?.label || ""
            }
            onChange={(event) => {
              const value = event.target.value;
              uiEvent.HandleCountryChange(value);
            }}
            menuitems={[
              { label: "All", code: "" },
              ...(uiState.countryList || []),
            ]}
            placeholder="Country"
          />

          <HorizontalSpacer width={12} />
          <CommonSelect
            selectValue={uiState.status}
            onChange={(event) => {
              const selectedStatus = event?.target.value;
              uiEvent.HandleUiStateChange(
                "status",
                selectedStatus === "All" ? "" : selectedStatus
              );
            }}
            menuitems={["All", "New", "PreParing", "Open", "Closed", "Closure"]}
            placeholder="Status"
          />
          {/* <HorizontalSpacer width={12} /> //Todo View버튼 기능 추후 구현
          <Box
            sx={{
              height: "42px",
              bgcolor: "white",
              borderRadius: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontStyle: fontStyle.semiboldXS,
              color: color.gray600,
            }}
          >
            <HorizontalSpacer width={16} />
            View
            <HorizontalSpacer width={12} />
            <Box sx={{ display: "flex" }}>
              <Box
                component="img"
                src={ICON_MENU1}
                sx={{
                  bgcolor: menu == "dashboard" ? color.primaryWeb100 : "white",
                  cursor: "pointer",
                  p: "5.6px",
                  borderRadius: "4px",
                  transition: "background-color 0.3s, transform 0.3s",
                  "&:hover": {
                    transform: "scale(1.2)",
                  },
                }}
                onClick={() => {
                  setMenu("dashboard");
                }}
              />
              <HorizontalSpacer width={12} />
              <Box
                component="img"
                src={ICON_MENU2}
                sx={{
                  bgcolor: menu == "card" ? color.primaryWeb100 : "white",
                  cursor: "pointer",
                  p: "5.6px",
                  borderRadius: "4px",
                  transition: "background-color 0.3s, transform 0.3s",
                  "&:hover": {
                    transform: "scale(1.2)",
                  },
                }}
                onClick={() => {
                  setMenu("card");
                }}
              />
              <HorizontalSpacer width={12} />
              <Box
                component="img"
                src={ICON_MENU3}
                sx={{
                  bgcolor: menu == "table" ? color.primaryWeb100 : "white",
                  cursor: "pointer",
                  p: "5.6px",
                  borderRadius: "4px",
                  transition: "background-color 0.3s, transform 0.3s",
                  "&:hover": {
                    transform: "scale(1.2)",
                  },
                }}
                onClick={() => {
                  setMenu("table");
                }}
              />
            </Box>
            <HorizontalSpacer width={16} />
          </Box> */}
        </Box>
      </CommonSearch>

      <VerticalSpacer height={16} />
      <StationMngTable
        docCount={docCount}
        rowsPerPage={rowsPerPage}
        pageCount={pageCount}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        uiState={uiState}
        uiEvent={uiEvent}
      />
    </CommonList>
  );
};

export default StationMngPage;
