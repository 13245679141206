import { LoginDetailUiState } from "./LoginDetailUiState";
import { LoginDetailUiEvent } from "./LoginDetailUiEvent";
import { Box, Typography } from "@mui/material";
import { color } from "../../theme/Color";
import { fontStyle } from "../../theme/Style";
import Toolbar from "../../component/Toolbar";
import VerticalSpacer from "../../component/Spacer";
import { LocalizedText } from "../../di/LanguageRepositoryProvider";
import EditBirthDateField from "./component/EditBirthDateField";
import EditAddressField from "./component/EditAddressField";
import EditGenderField from "./component/EditGenderField";
import CommonButton from "../../component/CommonButton";
import SimpleDialog from "../../component/dialog/SimpleDialog";
import IconTextDialog, {
  IconTextDialogType,
} from "../../component/dialog/IconTextDialog";
import ToastAlert from "../../component/ToastAlert";
import { useNavigate } from "react-router-dom";

interface LoginDetailRenderProps {
  uiState: LoginDetailUiState;
  uiEvent: LoginDetailUiEvent;
}

const LoginDetailRender = ({ uiState, uiEvent }: LoginDetailRenderProps) => {
  const navigate = useNavigate();

  return (
    <>
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          overflowY: "scroll",
          scrollbarWidth: "none", // Firefox에서 스크롤바 숨기기
          "&::-webkit-scrollbar": {
            display: "none", // Webkit 브라우저에서    스크롤바 숨기기
          },
        }}
      >
        {/* 상단 toolbar */}
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Toolbar
            title=""
            onBack={() => {
              uiEvent.OpenDialog();
            }}
          />
        </Box>
        <Box
          sx={{
            width: "calc(100% - 40px)",
            height: "calc(100vh - 40px)",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <VerticalSpacer height={20} />
          <Typography fontStyle={fontStyle.headingS}>
            {LocalizedText("ua_b123_useservice_title")}
          </Typography>
          <VerticalSpacer height={24} />
          <EditBirthDateField
            title={LocalizedText("ua_g210_birthofdate")}
            Day={uiState.day}
            Month={uiState.month}
            Year={uiState.year}
            dayError={uiState.dayError}
            monthError={uiState.monthError}
            yearError={uiState.yearError}
            uiEvent={uiEvent}
          />
          <VerticalSpacer height={36} />
          <EditAddressField
            type="Address"
            title={LocalizedText("ua_g210_address")}
            country={uiState.country}
            cityCountyDistrict={uiState.cityCountyDistrict}
            cityDetail={uiState.cityDetail}
            countryError={uiState.countryError}
            cityCountyDistrictError={uiState.cityCountyDistrictError}
            cityDetailError={uiState.cityDetailError}
            uiEvent={uiEvent}
          />
          <VerticalSpacer height={36} />
          <EditGenderField
            type="Gender"
            title={LocalizedText("ua_g210_gender")}
            gender={uiState.gender}
            genderError={uiState.genderError}
            uiEvent={uiEvent}
          />
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: "0px",
            width: "100%",
            display: "flex",
          }}
        >
          <CommonButton
            title={LocalizedText("ua_b120_next_button")}
            textColor="white"
            // buttonColor="white"
            onClick={uiEvent.VerifyingData}
            sx={{
              mx: "20px",
              mb: "14px",
              // border: 1,
              // borderColor: `${color.gray700}`,
              bgcolor: color.primary500,
            }}
          />
        </Box>
      </Box>
      <SimpleDialog
        children={LocalizedText("common_alert_goprevious")}
        isOpen={uiState.dialogOpen}
        positiveText="OK"
        onPositive={() => {
          window.history.back();
        }}
        onNagative={() => {
          uiEvent.CloseDialog();
        }}
        onClose={() => {
          uiEvent.CloseDialog();
        }}
      />
      <IconTextDialog
        title={LocalizedText("ua_b123_alert_complete_title")}
        content={LocalizedText("ua_b123_alert_complete_message")}
        type={IconTextDialogType.SUCCESS}
        positiveText="OK"
        isOpen={uiState.finishOpen}
        onPositive={() => {
          navigate("/LoginFinishPage");
        }}
        onClose={() => {}}
      />

      <ToastAlert
        open={uiState.networkError}
        message={LocalizedText("common_failsignup")}
        onClose={() => {
          uiEvent.CloseNetworkErrorToast();
        }}
        mainColor={color.error}
        bottomOffset={75}
      />
    </>
  );
};

export default LoginDetailRender;