import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  MenuItem,
  Select,
  Typography,
  Divider,
  Radio,
  Button,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import { ApiService } from "../../../restAPI/ApiService";
import { LocalizedText } from "../../../di/LanguageRepositoryProvider";
import VerticalSpacer, { HorizontalSpacer } from "../../../component/Spacer";
import LocalizedDate, { DateFormatType, } from "../../../component/LocalizedDate";
import CommonMultiLineTextField from "../../../component/CommonMultiLineTextField";
import { InquiryDetailsSideEffect } from "../model/InquiryDetailsSideEffect";
import { InquiryDetailsUiState } from "../model/InquiryDetailsUiState";
import { InquiryDetailsUiEvent } from "../model/InquiryDetailsUiEvent";
import ImageUpload from "../../../component/ImageUpload";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

interface InquiryDetailsRenderProps {
  uiState: InquiryDetailsUiState;
  uiEvent: InquiryDetailsUiEvent;
  sideEffect: InquiryDetailsSideEffect;
}

export default function InquiryDetailsGrid({
  uiState,
  uiEvent,
  sideEffect,
}: InquiryDetailsRenderProps) {
  const [countryList, setCountryList] = useState([]);
  const location = useLocation();
  const pathname = location.pathname;
  const InquiryId = pathname.split("/").pop(); // URL에서 마지막 부분을 추출
  const [data, setData] = useState<any>();

  useEffect(() => {
    ApiService.WebPost("/pw/aw/contents/getCountry").then((res) => {
      setCountryList(res?.data.body);
    });
    ApiService.WebPost(`/pw/aw/contents/personal/detail`, {
      id: InquiryId,
    }).then((res) => {
      setData(res?.data.body);
    });
  }, [InquiryId]);

  useEffect(() => {
    console.log("data is : ", data);
    console.log(uiState);
    uiEvent.onChangeId(data?.id);
  }, [data]);

  const handleGetCountry = (country: string) => {
    for (let i = 0; i < countryList.length; ++i) {
      if (country == countryList[i]["code"]) {
        country = countryList[i]["label"];
      }
    }
    return country;
  };

  const handleAddComment = () => {
    console.log("[handleAddComment] uiState : ", uiState);
    sideEffect.onClickSubmitButton();
  };

  return (
    <Grid container columnSpacing={"20px"}>
      {/* 첫번째 카드 */}
      <Grid item lg={3.5}>
        <Card
          sx={{
            borderRadius: 3,
            width: "100%",
            display: "flex",
          }}
        >
          <CardContent
            sx={{
              width: "100%",
              p: "24px",
              "&:last-child": {
                paddingBottom: "40px",
              },
            }}
          >
            <CardHeader
              title="Information"
              titleTypographyProps={{ fontStyle: fontStyle.semiboldL }}
              sx={{ p: 0 }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <VerticalSpacer height={16} />
              <Box>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Country
                </Typography>
                <VerticalSpacer height={8} />
                <Typography fontStyle={fontStyle.regularM}>
                  {handleGetCountry(data?.country)}
                </Typography>
              </Box>
              <VerticalSpacer height={40} />
              <Box>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Status
                </Typography>
                <VerticalSpacer height={8} />
                <Typography fontStyle={fontStyle.regularM}>
                  {data?.status}
                </Typography>
              </Box>
              <VerticalSpacer height={40} />
              <Box>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Category
                </Typography>
                <VerticalSpacer height={8} />
                <Typography fontStyle={fontStyle.regularM}>
                  {data?.category !== null && <></>}
                </Typography>
              </Box>
              <VerticalSpacer height={40} />
              <Box>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Writer
                </Typography>
                <VerticalSpacer height={8} />
                <Typography fontStyle={fontStyle.regularM}>
                  {data?.writer}
                </Typography>
              </Box>
              <VerticalSpacer height={40} />
              <Box>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  E-mail
                </Typography>
                <VerticalSpacer height={8} />
                <Typography fontStyle={fontStyle.regularM}>
                  {data?.email}
                </Typography>
              </Box>
              <VerticalSpacer height={40} />
              <Box>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Phone Number
                </Typography>
                <VerticalSpacer height={8} />
                <Typography fontStyle={fontStyle.regularM}>
                  {data?.phoneNumber}
                </Typography>
              </Box>
              <VerticalSpacer height={40} />
              <Divider />
              <VerticalSpacer height={40} />
              <Box>
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <Typography
                    fontStyle={fontStyle.regularS}
                    color={color.gray600}
                  >
                    Registered
                  </Typography>
                  <HorizontalSpacer width={12} />
                  <Typography fontStyle={fontStyle.regularS}>
                    {LocalizedDate({
                      date: data?.registrationDate,
                      type: DateFormatType.YearMonthDayTime,
                    })}
                  </Typography>
                </Box>

                {data?.answer !== null && (
                  <>
                    <VerticalSpacer height={8} />
                    <Box
                      sx={{
                        display: "flex",
                      }}
                    >
                      <Typography
                        fontStyle={fontStyle.regularS}
                        color={color.gray600}
                      >
                        Answered
                      </Typography>
                      <HorizontalSpacer width={12} />
                      <Typography fontStyle={fontStyle.regularS}>
                        {LocalizedDate({
                          date: data?.answer[0].answerDate,
                          type: DateFormatType.YearMonthDayTime,
                        })}
                      </Typography>
                    </Box>
                  </>
                )}

                {data?.deleteDate !== null && (
                  <>
                    <VerticalSpacer height={8} />
                    <Box
                      sx={{
                        display: "flex",
                      }}
                    >
                      <Typography
                        fontStyle={fontStyle.regularS}
                        color={color.gray600}
                      >
                        Deleted
                      </Typography>
                      <HorizontalSpacer width={12} />
                      <Typography fontStyle={fontStyle.regularS}>
                        {LocalizedDate({
                          date: data?.deleteDate,
                          type: DateFormatType.YearMonthDayTime,
                        })}
                      </Typography>
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      {/* 두번째 카드 */}
      <Grid item lg={8.5}>
        <Card
          sx={{
            borderRadius: 3,
            width: "100%",
            display: "flex",
          }}
        >
          <CardContent
            sx={{
              p: "24px",
              "&:last-child": {
                paddingBottom: "40px",
              },
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box>
                <Typography fontStyle={fontStyle.semiboldL}>
                  {data?.title}
                </Typography>
                <VerticalSpacer height={4} />
                <Typography
                  fontStyle={fontStyle.regularS}
                  color={color.gray600}
                >
                  {LocalizedDate({
                    date: data?.registrationDate,
                    type: DateFormatType.YearMonthDayTime,
                  })}
                </Typography>
                <VerticalSpacer height={20} />
                <Divider />
                <VerticalSpacer height={12} />
                <Typography
                  fontStyle={fontStyle.regularS}
                  sx={{ whiteSpace: "pre-line" }}
                >
                  {data?.content}
                </Typography>
                <Swiper
                  pagination={{
                    type: "bullets",
                    clickable: true,
                  }}
                  modules={[Pagination]}
                >
                  {data?.imageUrls !== null && (
                    <>
                      {data?.imageUrls.map(
                        (imageUrl: string, index: number) => {
                          return (
                            <SwiperSlide key={index}>
                              <img
                                src={imageUrl}
                                alt="Example Icon"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                  objectPosition: "center",
                                  marginTop: "20px",
                                }}
                              />
                            </SwiperSlide>
                          );
                        }
                      )}
                    </>
                  )}
                </Swiper>
              </Box>
              {data?.answer !== null && (
                <>
                  {data?.answer.map((answerObj: any, index: number) => {
                    return (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "left",
                            mt: 10,
                            mb: -2,
                          }}
                        >
                          <Typography
                            fontStyle={fontStyle.semiboldM}
                            color={color.black}
                          >
                            {answerObj.answerer}
                          </Typography>
                          <Typography
                            fontStyle={fontStyle.semiboldXS}
                            color={color.gray400}
                            sx={{ ml: 1 }}
                          >
                            {LocalizedDate({
                              date: answerObj.answerDate,
                              type: DateFormatType.YearMonthDayTime,
                            })}
                          </Typography>
                        </Box>
                        <>
                          <Box
                            sx={{
                              backgroundColor: "#EFECFD",
                              borderRadius: "15px 15px 0 0",
                              display: "flex",
                              justifyContent: "left",
                              mt: 3,
                              p: 2,
                            }}
                          >
                            <Typography
                              fontStyle={fontStyle.semiboldXS}
                              color={color.black}
                              sx={{ whiteSpace: "pre-line" }}
                            >
                              {answerObj.answerContent}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              backgroundColor: "#EFECFD",
                              borderRadius: "0 0 15px 15px",
                              display: "flex",
                              justifyContent: "left",
                              mb: -2,
                              p: 2,
                              pt: 0,
                            }}
                          >
                            <Swiper
                              pagination={{
                                type: "bullets",
                                clickable: true,
                              }}
                              modules={[Pagination]}
                            >
                              {answerObj.answerImageUrls?.map(
                                (imageUrl: string, index: number) => {
                                  return (
                                    <SwiperSlide key={index}>
                                      <img
                                        src={imageUrl}
                                        alt="Example Icon"
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          objectFit: "cover",
                                          objectPosition: "center",
                                        }}
                                      />
                                    </SwiperSlide>
                                  );
                                }
                              )}
                            </Swiper>
                          </Box>
                        </>
                      </>
                    );
                  })}
                </>
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "left",
                  mt: 6,
                  mb: -2,
                }}
              >
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Description
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: 3,
                  mb: -3,
                }}
              >
                <CommonMultiLineTextField
                  placeholder="Please enter a description."
                  inputValue={uiState.description}
                  heightPx={180}
                  maxLength={1000}
                  onChange={(value) => {
                    uiEvent.onChangeDescription(value);
                  }}
                  onClear={() => {
                    uiEvent.onChangeDescription("");
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "left",
                  mt: 8,
                  mb: 0,
                }}
              >
                <ImageUpload
                  imageUrls={uiState.uploadedImageUrls}
                  maxImages={5}
                  maxFileSizeMB={10}
                  onClickAddImageFile={(e) => {
                    sideEffect.handelAddImageFile(e);
                  }}
                  removeImage={uiEvent.removeImageUrl}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: 4,
                  mb: -2,
                }}
              >
                <Button
                  sx={{
                    textTransform: "none",
                    height: 40,
                    backgroundColor: "#5D38E5",
                    color: "white",
                    borderRadius: 5,
                    width: 125,
                    ml: 2,
                    ":hover": {
                      backgroundColor: "#A38BFC",
                    },
                  }}
                  onClick={handleAddComment}
                >
                  Comment
                </Button>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}