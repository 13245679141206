import {
  Box,
  Button,
  Card,
  CardHeader,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  InputAdornment,
  LinearProgress,
  Typography,
} from "@mui/material";

import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { fontStyle } from "../../../../../theme/Style";
import { color } from "../../../../../theme/Color";
import VerticalSpacer, {
  HorizontalSpacer,
} from "../../../../../component/Spacer";
import { CommonSelect } from "../../../../../component/CommonSelect";
import CommonTextField from "../../../../../component/CommonTextField";
import {
  ICON_CHECK2,
  ICON_UNCHECK,
} from "../../../../../constants/appImagePath";
import CommonWebModal from "../../../../../component/CommonWebModal";
import { ICON_CLOSE, ICON_DOWNLOAD } from "../../../../../constants/imagePath";
import CommonDialog from "../../../../../component/CommonDialog";
import { UserDetailsUiState } from "../../../UserDetailsUiState";
import { UserDetailsUiEvent } from "../../../UserDetailsUiEvent";
import { ApiService } from "../../../../../restAPI/ApiService";
import LocalizedDate, {
  DateFormatType,
} from "../../../../../component/LocalizedDate";

interface UserScooterInfoCardProps {
  uiState: UserDetailsUiState;
  uiEvent: UserDetailsUiEvent;
}

const UserScooterInfoCard = ({
  uiState,
  uiEvent,
}: UserScooterInfoCardProps) => {
  const [loading, setLoading] = useState(false); // 로딩 상태 추가
  const [scooterInfoSheetData, setScooterInfoSheetData] = useState({
    ContractType: "Select",
    ProductName: "Select",
    Contract: null as File | null, // Contract를 File 타입으로 정의, 초기값은 null
    ResidentIDCardCheck: false,
    ResidentIDCardVerifiedDate: 0,
    ContractVerifiedCheck: false,
    ContractVerifiedDate: 0,
    ContractBy: "",
    VerifiedBy: "",
  });

  const [openModal, setOpenModal] = useState(false);
  const [fileUrl, setFileUrl] = useState<string | undefined>(undefined); // 초기 상태를 undefined로 설정
  const handleViewClick = (file: any) => {
    console.log("view file : ", file);
    if (typeof file != "string" && file) {
      const Url = URL.createObjectURL(file); // 파일 URL 생성
      console.log(Url);
      setFileUrl(Url);
    } else if (file) {
      setFileUrl(file);
    } else {
      setFileUrl("");
    }
    setOpenModal(true);
  };

  const [scale, setScale] = useState(1); // 확대 비율 상태
  const [position, setPosition] = useState({ x: 0, y: 0 }); // 이미지 위치 상태
  const [isDragging, setIsDragging] = useState(false); // 드래그 중인지 여부
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 }); // 드래그 시작 지점

  // 마우스 휠로 확대/축소
  const handleWheel = (event: React.WheelEvent) => {
    event.preventDefault();
    const zoomIntensity = 0.3;
    const newScale =
      event.deltaY < 0 ? scale + zoomIntensity : scale - zoomIntensity;
    setScale(Math.max(1, newScale)); // 최소 1배 축소까지 가능
  };

  // 마우스 클릭 시 드래그 시작
  const handleMouseDown = (event: React.MouseEvent) => {
    event.preventDefault();
    setIsDragging(true);
    setDragStart({
      x: event.clientX - position.x,
      y: event.clientY - position.y,
    });
  };

  // 드래그 중
  const handleMouseMove = (event: React.MouseEvent) => {
    if (isDragging) {
      const dx = (event.clientX - dragStart.x) / scale; // scale에 맞춰 이동량 조정
      const dy = (event.clientY - dragStart.y) / scale; // scale에 맞춰 이동량 조정

      setPosition({
        x: position.x + dx / 1.5, // 이동 속도 줄이기
        y: position.y + dy / 1.5, // 이동 속도 줄이기
      });

      setDragStart({
        x: event.clientX,
        y: event.clientY,
      });
    }
  };

  // 마우스를 떼면 드래그 종료
  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const [residentCheckOkModalOpen, setResidentCheckOkModalOpen] =
    useState(false);
  const handleResidentCheck = () => {
    setResidentCheckOkModalOpen(true);
  };

  const [contractCheckOkModalOpen, setContractCheckOkModalOpen] =
    useState(false);
  const handleContractCheck = () => {
    setContractCheckOkModalOpen(true);
  };

  const createScooterContractSheet = () => {
    // Contract 값이 존재하는지 확인
    let alertMessage = "";

    // 필요한 조건을 확인
    if (!scooterInfoSheetData.Contract) {
      alertMessage = "Contract 파일이 필요합니다."; // Contract 파일이 없을 때 메시지 저장
    } else if (scooterInfoSheetData.ContractType === "Select") {
      alertMessage = "Contract Type을 선택해 주세요."; // Contract Type이 선택되지 않았을 때 메시지 저장
    } else if (scooterInfoSheetData.ProductName === "Select") {
      alertMessage = "Product Name을 선택해 주세요."; // Product Name이 선택되지 않았을 때 메시지 저장
    }

    // 알림 메시지가 존재할 경우 알림을 띄우고 함수를 종료
    if (alertMessage) {
      alert(alertMessage);
      return; // 함수를 종료
    }
    // FormData 객체 생성
    const formData = new FormData();

    if (scooterInfoSheetData.Contract) {
      // 파일을 FormData에 추가 (파일 이름은 기본적으로 사용)
      formData.append("file", scooterInfoSheetData.Contract);
    }

    // userData 객체 생성
    const userData = {
      type: scooterInfoSheetData.ContractType,
      uuid: uiState.uuid,
      // residentIdCardVerifiedDt: scooterInfoSheetData.ResidentIDCardVerifiedDate,
      // contractVerifiedDt: scooterInfoSheetData.ContractVerifiedDate,
      productName: scooterInfoSheetData.ProductName,
      verificationAdministrator: localStorage.getItem("user_uuid") || "",
    };

    console.log(userData);

    // Blob으로 JSON을 감싸서 추가, Content-Type 명시
    const blob = new Blob([JSON.stringify(userData)], {
      type: "application/json",
    });

    formData.append("userData", blob);
    setLoading(true);
    ApiService.WebPost(
      "/pw/backend/api/scooterAgreement/addScooterAgreementInfo",
      formData,
      { "Content-Type": "multipart/form-data" } // 헤더에 Content-Type 명시
    )
      .then((response) => {
        console.log("서버 응답:", response);
        // 성공 처리 로직 추가
      })
      .then(() => {
        ApiService.WebPost(
          "/pw/backend/api/scooterAgreement/agreementNumberList",
          {
            uuid: uiState.uuid,
          }
        ).then((res) => {
          console.log(res?.data.body);
          uiEvent.HandleUiStateChange("ScooterSheet", res?.data.body);
          uiEvent.HandleUiStateChange(
            "SelectedScooterContractSheet",
            res?.data.body[res?.data.body.length - 1]
          );
        });
      })
      .catch((error) => {
        console.error("서버 요청 중 오류 발생:", error);
        // 에러 처리 로직 추가
      })
      .finally(() => {
        setLoading(false);
        // window.location.reload();
        uiEvent.HandleUiStateChange("addLoadingState", true);
      });
  };

  const updateScooterContractSheet = () => {
    // FormData 객체 생성

    // console.log(scooterInfoSheetData);
    const formData = new FormData();

    if (uiState.ContractUrl) {
      //

      if (scooterInfoSheetData.Contract) {
        formData.append("file", scooterInfoSheetData.Contract);
      } else {
        formData.append("file", "");
      }
    }

    // userData 객체 생성
    const userData = {
      agreementNumber: uiState.SelectedScooterContractSheet,
      residentIdCardVerifiedDt: scooterInfoSheetData.ResidentIDCardVerifiedDate
        ? scooterInfoSheetData.ResidentIDCardVerifiedDate
        : uiState.ResidentCheckTime,
      contractVerifiedDt: scooterInfoSheetData.ContractVerifiedDate
        ? scooterInfoSheetData.ContractVerifiedDate
        : uiState.ContractCheckTime,
      productName:
        scooterInfoSheetData.ProductName != "Select"
          ? scooterInfoSheetData.ProductName
          : uiState.ScooterProductName,
      verificationAdministrator: scooterInfoSheetData.Contract
        ? localStorage.getItem("user_uuid") || ""
        : localStorage.getItem("user_uuid") || "",
      verifiedBy: localStorage.getItem("user_uuid") || "",
    };

    console.log(userData);

    // Blob으로 JSON을 감싸서 추가, Content-Type 명시
    const blob = new Blob([JSON.stringify(userData)], {
      type: "application/json",
    });

    formData.append("userData", blob);
    setLoading(true);
    ApiService.WebPost(
      "/pw/backend/api/scooterAgreement/updateScooterAgreementInfo",
      formData,
      { "Content-Type": "multipart/form-data" } // 헤더에 Content-Type 명시
    )
      .then((response) => {
        console.log("서버 응답:", response);
        // 성공 처리 로직 추가
      })
      .then(() => {
        ApiService.WebPost(
          "/pw/backend/api/scooterAgreement/agreementNumberList",
          {
            uuid: uiState.uuid,
          }
        ).then((res) => {
          console.log(res?.data.body);
          uiEvent.HandleUiStateChange("ScooterSheet", res?.data.body);
          uiEvent.HandleUiStateChange(
            "SelectedScooterContractSheet",
            res?.data.body[res?.data.body.length - 1]
          );
        });
      })
      .catch((error) => {
        console.error("서버 요청 중 오류 발생:", error);
        // 에러 처리 로직 추가
      })
      .finally(() => {
        setLoading(false);
        // window.location.reload();
        uiEvent.HandleUiStateChange("addLoadingState", true);
      });
  };

  useEffect(() => {
    uiEvent.HandleUiStateChange("OwnershipType", "");
    uiEvent.HandleUiStateChange("ScooterProductName", "");
    uiEvent.HandleUiStateChange("ContractName", "");
    uiEvent.HandleUiStateChange("ContractUrl", "");
    uiEvent.HandleUiStateChange("ResidentCheck", false);
    uiEvent.HandleUiStateChange("ResidentCheckTime", 0);
    uiEvent.HandleUiStateChange("ContractCheck", false);
    uiEvent.HandleUiStateChange("ContractCheckTime", 0);
    uiEvent.HandleUiStateChange("Contractby", "");
    uiEvent.HandleUiStateChange("Verifiedby", "");
    uiEvent.HandleUiStateChange("hasIdCard", false);
    uiEvent.HandleUiStateChange("isNewSheet", true);
    uiEvent.HandleUiStateChange("isHandOver", false);
    uiEvent.HandleUiStateChange("handOverTime", 0);
    uiEvent.HandleUiStateChange("OwnerUUid", "");

    handleUpdateScooterInfo("ResidentIDCardCheck", false);
    handleUpdateScooterInfo("ResidentIDCardVerifiedDate", 0);
    handleUpdateScooterInfo("ContractType", "Select");
    handleUpdateScooterInfo("ProductName", "Select");
    handleUpdateScooterInfo("Contract", null);
    handleUpdateScooterInfo("ResidentIDCardCheck", false);
    handleUpdateScooterInfo("ResidentIDCardVerifiedDate", 0);
    handleUpdateScooterInfo("ContractVerifiedCheck", false);
    handleUpdateScooterInfo("ContractVerifiedDate", 0);
    handleUpdateScooterInfo("ContractBy", "");
    handleUpdateScooterInfo("VerifiedBy", "");
    if (
      uiState.SelectedScooterContractSheet != "newSheet" &&
      uiState.SelectedScooterContractSheet
    ) {
      setLoading(true); // 로딩 시작
      ApiService.WebPost(
        "/pw/backend/api/scooterAgreement/detailScooterAgreementInfo",
        {
          agreementNumber: uiState.SelectedScooterContractSheet,
        }
      )
        .then((res) => {
          // console.log(res?.data.body);
          uiEvent.HandleUiStateChange("OwnershipType", res?.data.body.type);
          uiEvent.HandleUiStateChange(
            "ScooterProductName",
            res?.data.body.productName
          );
          uiEvent.HandleUiStateChange(
            "ContractName",
            res?.data.body.contractFileName
          );
          uiEvent.HandleUiStateChange("ContractUrl", res?.data.body.contract);
          if (res?.data.body.residentIdCardVerifiedDt) {
            uiEvent.HandleUiStateChange("ResidentCheck", true);
            uiEvent.HandleUiStateChange(
              "ResidentCheckTime",
              res?.data.body.residentIdCardVerifiedDt
            );
          }
          if (res?.data.body.contractVerifiedDt) {
            uiEvent.HandleUiStateChange("ContractCheck", true);
            uiEvent.HandleUiStateChange(
              "ContractCheckTime",
              res?.data.body.contractVerifiedDt
            );
          }
          uiEvent.HandleUiStateChange(
            "Contractby",
            res?.data.body.contractVerificationAdministrator
          );
          uiEvent.HandleUiStateChange("Verifiedby", res?.data.body.verifiedBy);
          uiEvent.HandleUiStateChange("hasIdCard", res?.data.body.idCard);
          uiEvent.HandleUiStateChange(
            "selectScooterSN",
            res?.data.body.serialNumber
          );
          if (
            res?.data.body.receiptConfirmation == "Hand Over" ||
            res?.data.body.receiptConfirmation == "Received"
          ) {
            if (res?.data.body.receiptConfirmation == "Hand Over") {
              uiEvent.HandleUiStateChange(
                "handOverTime",
                res?.data.body.lastUpdateDt
              );
            }
            if (res?.data.body.receiptConfirmation == "Received") {
              uiEvent.HandleUiStateChange("handOverTime", 0);
            }
            uiEvent.HandleUiStateChange("isHandOver", true);
          } else {
            uiEvent.HandleUiStateChange("isHandOver", false);
          }
          if (res?.data.body.type == "Rental") {
            uiEvent.HandleUiStateChange(
              "RentalEndDate",
              res?.data.body.receiptEndDt
            );
            uiEvent.HandleUiStateChange(
              "RentalStartDate",
              res?.data.body.receiptStartDt
            );
            uiEvent.HandleUiStateChange("OwnerUUid", res?.data.body.onwerUuid);
          }
        })
        .catch()
        .finally(() => {
          uiEvent.HandleUiStateChange("isNewSheet", false);
          setLoading(false); // 로딩 종료
        });
    } else {
      uiEvent.HandleUiStateChange("OwnershipType", "");
      uiEvent.HandleUiStateChange("ScooterProductName", "");
      uiEvent.HandleUiStateChange("ContractName", "");
      uiEvent.HandleUiStateChange("ContractUrl", "");
      uiEvent.HandleUiStateChange("ResidentCheck", false);
      uiEvent.HandleUiStateChange("ResidentCheckTime", 0);
      uiEvent.HandleUiStateChange("ContractCheck", false);
      uiEvent.HandleUiStateChange("ContractCheckTime", 0);
      uiEvent.HandleUiStateChange("Contractby", "");
      uiEvent.HandleUiStateChange("Verifiedby", "");
      uiEvent.HandleUiStateChange("hasIdCard", false);
      uiEvent.HandleUiStateChange("isNewSheet", true);
      uiEvent.HandleUiStateChange("isHandOver", false);
      uiEvent.HandleUiStateChange("handOverTime", 0);
      uiEvent.HandleUiStateChange("OwnerUUid", "");
      handleUpdateScooterInfo("ResidentIDCardCheck", false);
      handleUpdateScooterInfo("ResidentIDCardVerifiedDate", 0);
    }

    uiEvent.HandleUiStateChange("addLoadingState", false);
  }, [uiState.SelectedScooterContractSheet, uiState.addLoadingState]);

  useEffect(() => {
    console.log(uiState.SelectedScooterContractSheet);
  }, [uiState.SelectedScooterContractSheet]);
  const handleUpdateScooterInfo = (
    key: keyof typeof scooterInfoSheetData,
    value: any
  ) => {
    setScooterInfoSheetData((prevData) => ({
      ...prevData,
      [key]: value, // 동적으로 속성 업데이트
    }));
  };
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = event.target.files?.[0];
    console.log(uploadedFile);
    if (uploadedFile) {
      const allowedExtensions = ["png", "jpg", "jpeg", "pdf"];
      const fileExtension = uploadedFile.name.split(".").pop()?.toLowerCase();

      if (fileExtension && allowedExtensions.includes(fileExtension)) {
        handleUpdateScooterInfo("Contract", uploadedFile);
      } else {
        alert("Only PNG, JPG, and JPEG files are allowed.");
      }
    }
  };

  const [modelNames, setModelNames] = useState<string[]>([]);
  useEffect(() => {
    ApiService.WebPost("/pw/backend/api/scooterModel/findAll").then((res) => {
      const models = res?.data.body.map(
        (item: { modelName: string }) => item.modelName
      );
      setModelNames(models); // modelName 배열로 상태 업데이트
    });
  }, []);

  return (
    <>
      {loading && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: "140px",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0)", // 배경 흐리게
            zIndex: 1000, // 다른 요소들 위에 배치
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {/* 중간 인포메이션 */}
      <Grid item lg={12} sx={{ mt: "16px" }}>
        <Card
          sx={{
            borderRadius: "15px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            p: "24px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <CardHeader
              title="Information"
              titleTypographyProps={{ fontStyle: fontStyle.semiboldL }}
              sx={{ p: "0px" }}
            />
            <Button
              sx={{
                textTransform: "none",
                width: "90px",
                height: "36px",
                color: color.primary500,
                border: 1,
                borderRadius: "40px",
                borderColor: color.primary500,
                fontStyle: fontStyle.semiboldS,
                "&.Mui-disabled": {
                  borderColor: color.gray300, // disabled 상태에서의 테두리 색상
                },
              }}
              onClick={
                uiState.isNewSheet
                  ? createScooterContractSheet
                  : updateScooterContractSheet
              }
              disabled={uiState.ResidentCheck && uiState.ContractCheck}
            >
              Update
            </Button>
          </Box>
          <VerticalSpacer height={16} />
          {/* 첫번째 줄 */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "48.5%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ width: "48.5%" }}>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Contract Type
                </Typography>

                <CommonSelect
                  selectValue={
                    uiState.OwnershipType != ""
                      ? uiState.OwnershipType
                      : scooterInfoSheetData.ContractType
                  }
                  menuitems={["Possession", "Rental"]}
                  onChange={(event) => {
                    handleUpdateScooterInfo("ContractType", event.target.value);
                  }}
                  sx={{
                    height: "48px",
                    width: "100%",
                    mt: "8px",
                    fontStyle: fontStyle.regularS,
                    color: "black",
                  }}
                  disabled={uiState.OwnershipType != ""}
                />
              </Box>
              <Box sx={{ width: "48.5%" }}>
                <Box>
                  <Typography
                    fontStyle={fontStyle.boldXS}
                    color={color.gray600}
                  >
                    Resident ID Card
                  </Typography>

                  <CommonTextField
                    value={
                      uiState.IDCard?.name
                        ? uiState.IDCard?.name
                        : uiState.IdCardImageFileName
                    } // 파일 이름 표시
                    sx={{
                      width: "100%",
                      height: "48px",
                      mt: "8px",
                    }}
                    InputProps={{
                      style: {
                        height: "48px",
                        margin: 0,
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <Box
                            sx={{
                              display: uiState?.IdCardImageFileName
                                ? "flex"
                                : "none",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "60px",
                              height: "22px",
                              my: 0,
                              zIndex: 100,
                              borderRadius: "22px",
                              fontStyle: fontStyle.semiboldXXS,
                              color: "white",
                              bgcolor: color.gray600,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              handleViewClick(uiState.idCardImageURL);
                            }} // 클릭 시 이미지 미리보기
                          >
                            View
                          </Box>
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{
                      style: { zIndex: 100 },
                    }}
                    disabled
                  />
                </Box>
              </Box>
            </Box>
            <Box sx={{ width: "48.5%" }}>
              <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                User
              </Typography>
              <CommonTextField
                value={uiState.Name}
                sx={{ width: "100%", height: "48px", mt: "8px" }}
                InputProps={{
                  style: {
                    height: "48px",
                  },
                }}
                disabled
              />
            </Box>
          </Box>
          <VerticalSpacer height={16} />
          {/* 두번째 줄 */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "48.5%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ width: "48.5%" }}>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Product Name(Scooter)
                </Typography>
                <CommonSelect
                  selectValue={
                    uiState.ContractCheckTime
                      ? uiState.ScooterProductName != ""
                        ? uiState.ScooterProductName
                        : uiState.ScooterProductName
                      : uiState.isNewSheet == true
                      ? scooterInfoSheetData.ProductName == "Select"
                        ? scooterInfoSheetData.ProductName
                        : scooterInfoSheetData.ProductName
                      : scooterInfoSheetData.ProductName == "Select"
                      ? uiState.ScooterProductName
                      : scooterInfoSheetData.ProductName
                  }
                  menuitems={modelNames ? modelNames : []}
                  onChange={(event) => {
                    // uiEvent.HandleUiStateChange(
                    //   "ScooterProductName",
                    //   event.target.value
                    // );
                    handleUpdateScooterInfo("ProductName", event.target.value);
                  }}
                  sx={{
                    height: "48px",
                    width: "100%",
                    mt: "8px",
                    fontStyle: fontStyle.regularS,
                    color: "black",
                  }}
                  disabled={
                    uiState.OwnershipType == "Rental"
                      ? uiState.RentalStartDate
                        ? true
                        : false
                      : uiState.ContractCheckTime
                      ? true
                      : uiState.ScooterProductName != ""
                      ? false
                      : false
                  }
                />
              </Box>
              <Box sx={{ width: "48.5%" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    fontStyle={fontStyle.boldXS}
                    color={color.gray600}
                  >
                    Contract
                  </Typography>
                  {uiState.OwnershipType == "Rental" ? (
                    uiState.RentalStartDate ? (
                      true
                    ) : (
                      <Button
                        sx={{
                          height: "10px",
                          color: color.gray800,
                          textDecoration: "underline",
                          "&:hover": {
                            backgroundColor: "transparent",
                            color: color.gray600,
                            textDecoration: "underline",
                          },
                        }}
                        variant="text"
                        disableRipple
                        size="small"
                        component="label" // 파일 업로드 버튼
                      >
                        Update File
                        <input
                          type="file"
                          accept=".png, .jpg, .jpeg" // 허용 파일 타입
                          onChange={handleFileChange}
                          hidden
                        />
                      </Button>
                    )
                  ) : uiState.ContractCheckTime ? null : (
                    <Button
                      sx={{
                        height: "10px",
                        color: color.gray800,
                        textDecoration: "underline",
                        "&:hover": {
                          backgroundColor: "transparent",
                          color: color.gray600,
                          textDecoration: "underline",
                        },
                      }}
                      variant="text"
                      disableRipple
                      size="small"
                      component="label" // 파일 업로드 버튼
                    >
                      Update File
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg" // 허용 파일 타입
                        onChange={handleFileChange}
                        hidden
                      />
                    </Button>
                  )}
                </Box>
                <CommonTextField
                  value={
                    uiState.ContractCheckTime
                      ? uiState.ContractName
                      : scooterInfoSheetData.Contract?.name ||
                        uiState.ContractName
                  } // 파일 이름 표시
                  sx={{
                    width: "100%",
                    height: "48px",
                    mt: "8px",
                    py: 0,
                  }}
                  InputProps={{
                    style: {
                      height: "48px",
                      margin: 0,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <Box
                          sx={{
                            display: uiState.ContractName
                              ? "flex"
                              : scooterInfoSheetData?.Contract?.name
                              ? "flex"
                              : "none",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "60px",
                            height: "22px",
                            //   py: 0,
                            zIndex: 100,
                            borderRadius: "22px",
                            fontStyle: fontStyle.semiboldXXS,
                            color: "white",
                            bgcolor: color.gray600,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            handleViewClick(
                              uiState.ContractCheckTime
                                ? uiState.ContractUrl
                                : scooterInfoSheetData.Contract ||
                                    uiState.ContractUrl
                            );
                          }} // 클릭 시 이미지 미리보기
                        >
                          View
                        </Box>
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    style: {
                      zIndex: 100,
                      height: "48px",
                    },
                  }}
                  disabled={
                    uiState.OwnershipType == "Rental"
                      ? uiState.RentalStartDate
                        ? true
                        : false
                      : uiState.ContractCheckTime != 0
                  }
                />
              </Box>
            </Box>
            <Box
              sx={{
                width: "48.5%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Contract by
                </Typography>
                <CommonTextField
                  value={uiState.Contractby}
                  sx={{ width: "100%", height: "48px", mt: "8px" }}
                  InputProps={{
                    style: {
                      height: "48px",
                    },
                  }}
                  disabled
                />
              </Box>
            </Box>
          </Box>
          <VerticalSpacer height={16} />
          {/* 세번째 줄 */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "48.5%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  width: "48.5%",
                }}
              >
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Resident ID Card Verified Date
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "48px",
                      mt: "8px",
                      display: "flex",
                      alignItems: "center",
                      borderRadius: "10px",
                      border: 1,
                      borderColor: color.gray300,
                      bgcolor:
                        uiState.OwnershipType == "Rental"
                          ? uiState.RentalStartDate
                            ? uiState.ResidentCheck
                              ? color.gray200
                              : "white"
                            : color.gray200
                          : uiState.hasIdCard
                          ? uiState.ResidentCheck
                            ? color.gray200
                            : "white"
                          : color.gray200,
                    }}
                  >
                    <FormControlLabel
                      label={
                        <Typography
                          sx={{ fontStyle: fontStyle.bodyMr }}
                          color={color.gray800}
                        >
                          {uiState.hasIdCard ? ( // <>
                            //   {LocalizedDate({
                            //     date: uiState.ResidentCheckTime,
                            //     type: DateFormatType.numericTime,
                            //   })}
                            // </>
                            uiState.ResidentCheck ? (
                              <>
                                {LocalizedDate({
                                  date: uiState.ResidentCheckTime,
                                  type: DateFormatType.numericTime,
                                })}
                              </>
                            ) : scooterInfoSheetData.ResidentIDCardCheck ? (
                              <>
                                {LocalizedDate({
                                  date: scooterInfoSheetData.ResidentIDCardVerifiedDate,
                                  type: DateFormatType.numericTime,
                                })}
                              </>
                            ) : (
                              <>Verified</>
                            )
                          ) : scooterInfoSheetData.ResidentIDCardCheck ? (
                            <>
                              {LocalizedDate({
                                date: scooterInfoSheetData.ResidentIDCardVerifiedDate,
                                type: DateFormatType.numericTime,
                              })}
                            </>
                          ) : (
                            <>Verified</>
                          )}
                        </Typography>
                      }
                      control={
                        <Checkbox
                          sx={{ px: "8px", pl: "24px" }}
                          checked={
                            uiState.hasIdCard
                              ? uiState.ResidentCheck
                                ? uiState.ResidentCheck
                                : scooterInfoSheetData.ResidentIDCardCheck
                              : scooterInfoSheetData.ResidentIDCardCheck
                          }
                          onChange={handleResidentCheck}
                          icon={<img src={ICON_UNCHECK} alt="unchecked" />}
                          checkedIcon={<img src={ICON_CHECK2} alt="checked" />}
                        />
                      }
                      disabled={
                        uiState.OwnershipType == "Rental"
                          ? uiState.RentalStartDate
                            ? uiState.ResidentCheck
                            : true
                          : uiState.hasIdCard
                          ? uiState.ResidentCheck
                          : true
                      }
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "48.5%",
                }}
              >
                <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                  Contract Verified Date
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    height: "48px",
                    mt: "8px",
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "10px",
                    border: 1,
                    borderColor: color.gray300,
                    bgcolor:
                      uiState.OwnershipType == "Rental"
                        ? uiState.RentalStartDate
                          ? uiState.ContractCheck
                            ? color.gray200
                            : "white"
                          : color.gray200
                        : uiState.hasIdCard
                        ? uiState.ContractCheck
                          ? color.gray200
                          : "white"
                        : color.gray200,
                  }}
                >
                  <FormControlLabel
                    label={
                      <Typography
                        sx={{ fontStyle: fontStyle.bodyMr }}
                        color={color.gray800}
                      >
                        {uiState.hasIdCard ? (
                          uiState.ContractCheck ? (
                            <>
                              {LocalizedDate({
                                date: uiState.ContractCheckTime,
                                type: DateFormatType.numericTime,
                              })}
                            </>
                          ) : scooterInfoSheetData.ContractVerifiedCheck ? (
                            <>
                              {LocalizedDate({
                                date: scooterInfoSheetData.ContractVerifiedDate,
                                type: DateFormatType.numericTime,
                              })}
                            </>
                          ) : (
                            <>Verified</>
                          )
                        ) : scooterInfoSheetData.ContractVerifiedCheck ? (
                          <>
                            {LocalizedDate({
                              date: scooterInfoSheetData.ContractVerifiedDate,
                              type: DateFormatType.numericTime,
                            })}
                          </>
                        ) : (
                          <>Verified</>
                        )}
                      </Typography>
                    }
                    control={
                      <Checkbox
                        sx={{ px: "8px", pl: "24px" }}
                        checked={
                          uiState.ContractUrl
                            ? uiState.ContractCheck
                              ? uiState.ContractCheck
                              : scooterInfoSheetData.ContractVerifiedCheck
                            : scooterInfoSheetData.ContractVerifiedCheck
                        }
                        onChange={handleContractCheck}
                        icon={<img src={ICON_UNCHECK} alt="unchecked" />}
                        checkedIcon={<img src={ICON_CHECK2} alt="checked" />}
                      />
                    }
                    disabled={
                      uiState.OwnershipType == "Rental"
                        ? uiState.RentalStartDate
                          ? uiState.ContractCheck
                          : true
                        : uiState.hasIdCard
                        ? uiState.ContractCheck
                        : true
                    }
                  />
                </Box>
              </Box>
            </Box>
            <Box sx={{ width: "48.5%" }}>
              <Typography fontStyle={fontStyle.boldXS} color={color.gray600}>
                Verified by
              </Typography>

              <CommonTextField
                value={uiState.Verifiedby} // 파일 이름 표시
                sx={{
                  width: "100%",
                  height: "48px",
                  mt: "8px",
                  py: 0,
                }}
                InputProps={{
                  style: {
                    height: "48px",
                  },
                }}
                inputProps={{
                  style: {
                    height: "48px",
                    zIndex: 200,
                  },
                }}
                disabled
              />
            </Box>
          </Box>
        </Card>
      </Grid>
      {/* 사진 뷰어 */}
      <CommonWebModal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          setPosition({ x: 0, y: 0 }); // 위치 초기화
          setScale(1); // 확대 비율 초기화
        }}
        title=""
        dialogContentSx={{ m: 0, p: 0 }}
        PaperProps={{ sx: { borderRadius: "15px" } }}
      >
        <Box sx={{ minHeight: "100px" }}>
          <Box
            sx={{
              display: "flex",
              height: "64px",
              px: "18px",
              py: "20px",
              justifyContent: "space-between",
            }}
          >
            <Typography fontStyle={fontStyle.semiboldM}>File Viewer</Typography>
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Box
                component="img"
                src={ICON_DOWNLOAD}
                sx={{ cursor: "pointer" }}
              />
              <HorizontalSpacer width={20} />
              <Box
                component="img"
                src={ICON_CLOSE}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setOpenModal(false);
                  setPosition({ x: 0, y: 0 }); // 위치 초기화
                  setScale(1); // 확대 비율 초기화
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              bgcolor: "black",
              overflow: "hidden", // 부모 Box를 넘어가지 않게 설정
              width: "100%", // 부모 Box의 너비 설정 (이미지 최대 크기와 동일)
              // height: "536px", // 부모 Box의 높이 설정 (이미지 최대 크기와 동일)
              cursor: isDragging ? "grabbing" : "grab", // 드래그 시 커서 모양 변경
            }}
            onMouseDown={handleMouseDown} // 마우스를 눌렀을 때 드래그 시작
            onMouseMove={handleMouseMove} // 마우스를 움직일 때 위치 변경
            onMouseUp={handleMouseUp} // 마우스를 떼면 드래그 종료
            onMouseLeave={handleMouseUp} // 마우스가 영역을 벗어나면 드래그 종료
          >
            {fileUrl && (
              <Box
                component={"img"}
                src={fileUrl}
                alt="Selected"
                sx={{
                  minWidth: "300px",
                  minHeight: "300px",
                  maxWidth: "716px", // 최대 크기 제한 없음
                  maxHeight: "536px", // 최대 크기 제한 없음
                  objectFit: "contain",
                  transform: `scale(${scale}) translate(${position.x}px, ${position.y}px)`, // 확대 및 위치 이동
                  transition: isDragging ? "none" : "transform 0.2s", // 드래그 중에는 애니메이션 제거
                }}
                onWheel={handleWheel} // 마우스 휠로 확대/축소
              />
            )}
          </Box>
        </Box>
      </CommonWebModal>
      {/* firstResidentCheck 모달 */}
      <CommonDialog
        open={residentCheckOkModalOpen}
        handleClose={() => {
          setResidentCheckOkModalOpen(false);
        }}
        handleOk={() => {
          handleUpdateScooterInfo("ResidentIDCardCheck", true);

          let date = new Date().getTime();
          handleUpdateScooterInfo("ResidentIDCardVerifiedDate", date);
          setResidentCheckOkModalOpen(false);
        }}
      >
        <Typography
          fontStyle={fontStyle.regularS}
          color={color.gray800}
          sx={{ display: "flex", alignItems: "center" }}
        >
          회원&nbsp;
          <Typography
            component="span" // inline 요소로 설정
            fontStyle={fontStyle.semiboldS}
            color={color.gray800}
          >
            신분증 정보
          </Typography>
          를 확인 하였음을&nbsp;
          <Typography
            component="span" // inline 요소로 설정
            fontStyle={fontStyle.semiboldS}
            color={color.success}
          >
            인증
          </Typography>
          합니다.
        </Typography>
      </CommonDialog>
      {/* contractCheck 모달 */}
      <CommonDialog
        open={contractCheckOkModalOpen}
        handleClose={() => {
          setContractCheckOkModalOpen(false);
        }}
        handleOk={() => {
          // setContractCheck(true);
          handleUpdateScooterInfo("ContractVerifiedCheck", true);
          let date = new Date().getTime();
          // uiEvent.HandleUiStateChange("ContractCheckTime", date); // Unix 밀리초 값을 저장
          handleUpdateScooterInfo("ContractVerifiedDate", date);
          setContractCheckOkModalOpen(false);
        }}
      >
        <Typography
          fontStyle={fontStyle.regularS}
          color={color.gray800}
          sx={{ display: "flex", alignItems: "center" }}
        >
          회원&nbsp;
          <Typography
            component="span" // inline 요소로 설정
            fontStyle={fontStyle.semiboldS}
            color={color.gray800}
          >
            계약서 정보
          </Typography>
          를 확인 하였음을&nbsp;
          <Typography
            component="span" // inline 요소로 설정
            fontStyle={fontStyle.semiboldS}
            color={color.success}
          >
            인증
          </Typography>
          합니다.
        </Typography>
      </CommonDialog>
    </>
  );
};

export default UserScooterInfoCard;
