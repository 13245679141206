export class ProductUiState {
  ProductNameList: Array<any> = [];
  MadeList: Array<any> = [];

  MaterialId: string = "";
  ProductName: string = "";
  PartsCode: string = "";
  Made: string = "";
  MadeId: number = 0;
  ImgUrl: string = "";
  SerialNumber: string = "";
  SerialNumberCELLPACK: string = "";
  SerialNumberMBMSV240: string = "";
  SerialNumberFETV240: string = "";
  MemoCtSensorHarness: string = "";
  RegistrationDate: number = 0;
  Confirm: string = "";
  ConfirmDate: number = 0;
  lastUpdateDate: number = 0;
  Writer: string = "";

  ProductNameError: boolean = false;
  PartsCodeError: boolean = false;
  MadeError: boolean = false;
  SerialNumberError: boolean = false;
  SerialNumberCELLPACKError: boolean = false;
  SerialNumberMBMSV240Error: boolean = false;
  SerialNumberFETV240Error: boolean = false;
  MemoCtSensorHarnessError: boolean = false;

  AdminRole: string = "";
}
