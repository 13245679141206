import { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import LoginPage from "../pages/auth/LoginPage";
import LogoutPage from "../pages/auth/LogoutPage";
import { ROUTE_PATHS } from "../constants/RouteConstants";
import LoginTermsOfUsePage from "../pagesMobile/MobileLogin/LoginTermsOfUsePage";
import LoginTermsOfUsePrivacyDetails from "../pagesMobile/MobileLogin/LoginTermsOfUsePrivacyDetails";
import LoginTermsOfUseDetails from "../pagesMobile/MobileLogin/LoginTermsOfUseDetails";
import LoginPhoneAuth from "../pagesMobile/MobileLogin/LoginPhoneAuth";
import LoginDetailInfo from "../pagesMobile/MobileLogin/LoginDetailInfo";
import LoginFinishPage from "../pagesMobile/MobileLogin/LoginFinishPage";

const LoginRoutes = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    var lowCaseUrl = window.location.pathname.toLowerCase();
    if (
      0 === lowCaseUrl.indexOf("/login") ||
      0 === lowCaseUrl.indexOf("/logoutmobile")
    ) {
      console.log("go to " + lowCaseUrl);
    } else if (0 !== lowCaseUrl.indexOf("/loginmobile")) {
      /* not use for keycloak
    if('/certaccount' !== location.pathname
        && '/findaccount' !== location.pathname
        && '/findpassword' !== location.pathname){
    */
      navigate("/loginMobile");
    }
  }, [navigate, location]);

  return (
    <Routes>
      {/* not use for keycloak
      <Route path="/certaccount" element={<CertAccountPage />} />
      <Route path="/findaccount" element={<FindAccountPage />} />
      */}
      <Route
        path={ROUTE_PATHS.LOGIN_TERMS_OF_USE}
        element={<LoginTermsOfUsePage />}
      />
      <Route
        path={ROUTE_PATHS.LOGIN_TERMS_OF_USE_PRIVACY_DETAILS}
        element={<LoginTermsOfUsePrivacyDetails />}
      />
      <Route
        path={ROUTE_PATHS.LOGIN_TERMS_OF_USE_DETAILS}
        element={<LoginTermsOfUseDetails />}
      />
      <Route path={ROUTE_PATHS.LOGIN_PHONE_AUTH} element={<LoginPhoneAuth />} />
      <Route
        path={ROUTE_PATHS.LOGIN_DETAIL_INFO}
        element={<LoginDetailInfo />}
      />
      <Route
        path={ROUTE_PATHS.LOGIN_FINISH_PAGE}
        element={<LoginFinishPage />}
      />

      {/* 웹 로그인 페이지 */}
      <Route path={ROUTE_PATHS.ADMIN_LOGIN} element={<LoginPage />} />
      <Route path={ROUTE_PATHS.ADMIN_LOGOUT} element={<LogoutPage />} />
      <Route
        path={ROUTE_PATHS.ADMIN_LOGOUT_CALLBACK}
        element={<LogoutPage />}
      />
    </Routes>
  );
};

export default LoginRoutes;
