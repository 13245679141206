import { useEffect, useState } from "react";
import { Box, TablePaginationProps } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import MuiPagination from "@mui/material/Pagination";
import SaveIcon from "@mui/icons-material/Save";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridPagination,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowsProp,
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import { randomId } from "@mui/x-data-grid-generator";
import axios from "axios";

interface MultilingualGridProps {
  rowModesModel: any;
  setRows: any;
  setRowModesModel: any;
  rows: GridRowsProp;
  rowsPerPage: any;
}

const MultilingualGrid = ({
  setRows,
  setRowModesModel,
  rowModesModel,
  rows,
  rowsPerPage,
}: MultilingualGridProps) => {
  const [page, setPage] = useState(0); // 페이지 번호를 관리할 상태

  const [disabledRows, setDisabledRows] = useState<Set<GridRowId>>(new Set());

  const Pagination = ({
    page,
    onPageChange,
    className,
  }: Pick<TablePaginationProps, "page" | "onPageChange" | "className">) => {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <MuiPagination
        color="primary"
        className={className}
        count={pageCount}
        page={page + 1}
        shape="rounded"
        onChange={(event, newPage) => {
          onPageChange(event as any, newPage - 1);
        }}
      />
    );
  };

  function CustomPagination(props: any) {
    return <GridPagination ActionsComponent={Pagination} {...props} />;
  }

  useEffect(() => {
    axios
      .post("/pw/pw/language/api/findAll")
      .then((res) => {
        console.log("res is : ", res.data.content);
        const transformedData = res.data.content.map((row: any) => ({
          ...row,
          registrationDate: row.registrationDate
            ? new Date(row.registrationDate)
            : null,
        }));
        setRows(res.data.content);
      })
      .catch((err) => {
        console.log("err is : ", err);
      });
  }, []);

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    console.log(rowModesModel);
    // rows.map((row) => {
    //   if (row.id == id) {
    //     console.log(row);
    //   }
    // });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setDisabledRows(new Set(disabledRows).add(id));
    axios
      .delete("/pw/pw/language/api/deleteOne", { data: id })
      .then((res) => {
        console.log(res.data);
        if (res.data == "삭제완료") {
          setRows(rows.filter((row) => row.id !== id));
        }
      })
      .catch((error) => {
        console.error("There was an error deleting the row!", error);
      })
      .finally(() => {
        setDisabledRows((prev) => {
          const newSet = new Set(prev);
          newSet.delete(id);
          return newSet;
        });
      });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    console.log(updatedRow);
    axios
      .post("/pw/pw/language/api/updateOne", updatedRow)
      .then((res) => {
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
      })
      .catch((err) => {
        console.log("err is : ", err);
      });

    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handlePageChange = (newPaginationModel: any) => {
    console.log(newPaginationModel);
    setPage(newPaginationModel.page);
    // setRowsPerPage(newPaginationModel.pageSize);
  };

  const columns: GridColDef[] = [
    {
      field: "no",
      headerName: "순번",
      headerAlign: "center",
      flex: 0.3,
      editable: true,
    },
    {
      field: "code",
      headerName: "코드",
      headerAlign: "left",
      flex: 1.3,
      editable: true,
    },

    {
      field: "kr",
      headerName: "한국어",
      type: "string",
      flex: 1,
      align: "left",
      headerAlign: "left",
      editable: true,
    },
    {
      field: "eng",
      headerName: "영어",
      type: "string",
      flex: 1,
      align: "left",
      headerAlign: "left",
      editable: true,
    },
    {
      field: "kh",
      headerName: "크메르어",
      type: "string",

      flex: 1,
      align: "left",
      headerAlign: "left",
      editable: true,
    },
    {
      field: "jp",
      headerName: "일본어",
      type: "string",

      flex: 1,
      align: "left",
      headerAlign: "left",
      editable: true,
    },

    {
      field: "ch",
      headerName: "중국어",
      type: "string",
      flex: 1,
      align: "left",
      headerAlign: "left",
      editable: true,
    },
    {
      field: "registrationDate",
      headerName: "등록일시",
      align: "left",
      headerAlign: "left",
      type: "dateTime",

      flex: 1.6,
      editable: true,

      valueGetter: (params: any) => {
        if (!params) {
          return null;
        }
        return new Date(params);
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 0.7,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
              disabled={disabledRows.has(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
              disabled={disabledRows.has(id)}
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
            disabled={disabledRows.has(id)}
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
            disabled={disabledRows.has(id)}
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
        mt: 3,
        borderRadius: 3,
        overflowY: "hidden",
      }}
    >
      <DataGrid
        // checkboxSelection
        autoHeight
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        paginationModel={{ pageSize: rowsPerPage, page: page }}
        onPaginationModelChange={handlePageChange} // 페이지 변경 핸들러 추가
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        initialState={{
          sorting: {
            sortModel: [{ field: "registrationDate", sort: "desc" }],
          },
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
        // hideFooter
        // components={{
        //   Toolbar,
        // }}
        pagination
        slots={{
          pagination: CustomPagination,
        }}
        sx={{
          backgroundColor: "white",
          border: 0,
          borderRadius: 4,
          "& .MuiDataGrid-main": {
            borderTopLeftRadius: 18,
            borderTopRightRadius: 18,
          },
        }}
      />
    </Box>
  );
};

export default MultilingualGrid;
