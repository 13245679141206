import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import CommonList from "../../component/CommonList";
import CommonSearch from "../../component/CommonSearch";
import { fontStyle } from "../../theme/Style";
import { color } from "../../theme/Color";
import VerticalSpacer, { HorizontalSpacer } from "../../component/Spacer";
import { ApiService } from "../../restAPI/ApiService";
import AdminTable from "./section/AdminTable/AdminTable";

const AdminMngListPage = () => {
  const navigate = useNavigate();
  // 페이지네이션 기능을 위한 변수
  const [rowsPerPage, setRowsPerPage] = useState(10); // 한 테이블당 보여줄 row 의 갯수
  const [rows, setRows] = useState([]); // 받아온 데이터
  const [page, setPage] = useState<number>(0); // 현재 페이지의 번호
  const [docCount, setDocCount] = useState<number>(0); // 총 row의 수
  const [pageCount, setPageCount] = useState(0); // 테이블 왼쪽 하단에 보여질 페이지의 갯수

  // 검색어 관련 변수
  const [searchContents, setSearchContents] = useState("");

  const [userType, setUserType] = useState("");
  const handleUserTypeChange = (event: SelectChangeEvent) => {
    setUserType(event.target.value as string);
  };

  const [userStatus, setUserStatus] = useState("");
  const handleUserStatusChange = (event: SelectChangeEvent) => {
    setUserStatus(event.target.value as string);
  };

  useEffect(() => {
    ApiService.WebPost("/pw/users/admin/searchCondition", {
      size: rowsPerPage,
      page: page + 1,
      searchCondition: searchContents,
      type: userType,
      status: userStatus,
    }).then((res) => {
      setRows(res?.data.body.list);
      setPageCount(Math.ceil(res?.data.body.docCount / rowsPerPage));
      setDocCount(res?.data.body.docCount);
    });
  }, [page, rowsPerPage, userType, userStatus, searchContents]);

  const headerButton = () => {
    return (
      <Button
        sx={{
          height: 40,
          backgroundColor: "#5D38E5",
          color: "white",
          borderRadius: 5,
          width: 85,
          ":hover": {
            backgroundColor: "#A38BFC",
          },
        }}
        onClick={() => {
          navigate("/adminMng/createAdmin");
        }}
      >
        <AddIcon fontSize="small" />
        등록
      </Button>
    );
  };
  return (
    <CommonList
      title="관리자 관리"
      setModal={null}
      headerButton={headerButton()}
    >
      <CommonSearch
        setRowsPerPage={setRowsPerPage}
        placeHolder={"이름, 메일, 연락처, 접속 일시, 생성 일시 검색"}
        setSearchContents={setSearchContents}
      >
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Select
            value={userType}
            onChange={handleUserTypeChange}
            displayEmpty
            renderValue={(v) => (v?.length ? v : `Role`)}
            sx={{
              width: "auto",
              height: "42px",
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
              "&.MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "red",
                },
                "&:hover fieldset": {
                  borderColor: "yellow",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "white",
                },
              },
              bgcolor: "white",
              borderRadius: "10px",
              fontStyle: fontStyle.semiboldXS,
              color: color.gray600,
            }}
          >
            <MenuItem
              value={""}
              sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}
            >
              All
            </MenuItem>
            <MenuItem
              value={"Site Manager"}
              sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}
            >
              Site Manager
            </MenuItem>
            <MenuItem
              value={"Customer Manager"}
              sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}
            >
              Customer Manager
            </MenuItem>
            <MenuItem
              value={"Engineering Manager"}
              sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}
            >
              Engineering Manager
            </MenuItem>
            <MenuItem
              value={"Dealer"}
              sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}
            >
              Dealer
            </MenuItem>
          </Select>
          <HorizontalSpacer width={12} />
          <Select
            value={userStatus}
            onChange={handleUserStatusChange}
            displayEmpty
            renderValue={(v) => (v?.length ? v : `Status`)}
            sx={{
              width: "auto",
              height: "42px",
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
              "&.MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "red",
                },
                "&:hover fieldset": {
                  borderColor: "yellow",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "white",
                },
              },
              bgcolor: "white",
              borderRadius: "10px",
              fontStyle: fontStyle.semiboldXS,
              color: color.gray600,
            }}
          >
            <MenuItem
              value={""}
              sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}
            >
              All
            </MenuItem>
            <MenuItem
              value={"Active"}
              sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}
            >
              Active
            </MenuItem>
            <MenuItem
              value={"Withdrawal"}
              sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}
            >
              Withdrawal
            </MenuItem>
            <MenuItem
              value={"Blocked"}
              sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}
            >
              Blocked
            </MenuItem>
            <MenuItem
              value={"Deleted"}
              sx={{ fontStyle: fontStyle.semiboldXS, color: color.gray600 }}
            >
              Deleted
            </MenuItem>
          </Select>
        </Box>
      </CommonSearch>
      <VerticalSpacer height={16} />
      <AdminTable
        mngRows={rows}
        docCount={docCount}
        rowsPerPage={rowsPerPage}
        pageCount={pageCount}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
    </CommonList>
  );
};

export default AdminMngListPage;
