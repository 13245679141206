import { ScooterInfoDetailPage } from "./ScooterInfoDetailPage";
import { useEffect, useState } from "react";
import { ScooterInfoDetailUiState } from "./model/ScooterInfoDetailUiState";
import { ScooterInfoDetailUiEvent } from "./model/ScooterInfoDetailUiEvent";
import { TabType } from "./model/TabType";
import { useLocation } from "react-router-dom";
import { ApiService } from "../../../restAPI/ApiService";
import { ScooterBasicInfoResponse } from "./model/ScooterBasicInfoResponse";
import { ScooterBasicInfoUiState } from "./basic/model/ScooterBasicInfoUiState";
import { scooterBasicInfoMapper } from "./mapper/scooterBasicInfoMapper";
import { ScooterBasicInfoUiEvent } from "./basic/model/ScooterBasicInfoUiEvent";

export function ScooterInfoDetail() {
  const [uiState, setUiState] = useState(new ScooterInfoDetailUiState());
  const location = useLocation();
  const scooterState = location.state as { scooterSerialNumber?: string };
  const savedScooterSerialNumber =
    sessionStorage.getItem("scooterSerialNumber") || undefined;
  const scooterSerialNumber =
    scooterState?.scooterSerialNumber || savedScooterSerialNumber;

  const [scooterBasicInfoUiState, setScooterBasicInfoUiState] = useState(
    new ScooterBasicInfoUiState(),
  );

  useEffect(() => {
    if (scooterSerialNumber)
      sessionStorage.setItem("scooterSerialNumber", scooterSerialNumber);
  }, [scooterSerialNumber]);

  async function getScooterBasicInfo(
    scooterSerialNumber: string,
  ): Promise<ScooterBasicInfoResponse | undefined> {
    const url = "/pw/backend/api/scooter/detail";
    const requestBody = {
      serialNumber: scooterSerialNumber,
    };

    try {
      const res = await ApiService.webPost(url, requestBody);
      const data: ScooterBasicInfoResponse = res.data;
      if (typeof data === "object" && Object.keys(data).length === 0)
        return undefined;
      return data;
    } catch (e) {
      throw e;
    }
  }

  async function updateScooterBasicInfo(
    scooterSerialNumber: string,
    plateNumber: string,
  ): Promise<Boolean> {
    const url = "/pw/backend/api/scooter/update";
    const requestBody = {
      serialNumber: scooterSerialNumber,
      plateNumber: plateNumber,
    };
    try {
      const res = await ApiService.webPost(url, requestBody);
      return !(
        typeof res.data === "object" && Object.keys(res.data).length === 0
      );
    } catch (e) {
      throw e;
    }
  }

  useEffect(() => {
    const fetchScooterInfo = async () => {
      try {
        if (!scooterSerialNumber) {
          alert("Scooter Serial Number is not provided");
          return;
        }
        const scooterInfo = await getScooterBasicInfo(scooterSerialNumber);
        if (scooterInfo === undefined) {
          alert("No scooter information available.");
          return;
        }
        setScooterBasicInfoUiState(scooterBasicInfoMapper(scooterInfo));
      } catch (e) {
        alert("Error while fetching scooter info");
      }
    };

    fetchScooterInfo(); // 비동기 함수 호출
  }, [scooterSerialNumber]);

  const uiEvent: ScooterInfoDetailUiEvent = {
    onClickTab: function (tab: TabType): void {
      setUiState((prev) => {
        if (prev.currentTab === tab) return prev;
        return {
          ...prev,
          currentTab: tab,
        };
      });
    },
    onClickUpdate: function (): void {
      if (scooterSerialNumber === undefined || scooterSerialNumber === "") {
        alert("Scooter Serial Number is not provided");
        return;
      }
      updateScooterBasicInfo(
        scooterSerialNumber,
        scooterBasicInfoUiState.plateNumber,
      )
        .then((r) => {
          if (r) {
            alert("Scooter Basic Info Updated");
            window.location.reload();
          } else {
            alert("Failed to update Scooter Basic Info");
          }
        })
        .catch((e) => {
          alert("Failed to update Scooter Basic Info");
        });
    },
    back: function (): void {
      window.history.back();
    },
  };

  const scooterBasicInfoUiEvent: ScooterBasicInfoUiEvent = {
    onChangePlateNumber: function (value: string): void {
      setScooterBasicInfoUiState((prev) => {
        return {
          ...prev,
          plateNumber: value,
        };
      });
    },
  };

  return (
    <ScooterInfoDetailPage
      uiState={uiState}
      scooterBasicInfoUiState={scooterBasicInfoUiState}
      scooterBasicInfoUiEvent={scooterBasicInfoUiEvent}
      uiEvent={uiEvent}
    />
  );
}
