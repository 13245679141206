import { useEffect, useRef, useState } from "react";
import { Root, createRoot } from "react-dom/client";
import {
  Cluster,
  DefaultRenderer,
  MarkerClusterer,
} from "@googlemaps/markerclusterer";
import {
  ICON_CAFE_CLICK,
  ICON_CAFE_COMMON,
  ICON_CAFE_DISABLED,
  ICON_CAFE_DISABLED_CLICK,
  ICON_CHARGING_CLICK,
  ICON_CHARGING_COMMON,
  ICON_CHARGING_DISABLED,
  ICON_CHARGING_DISABLED_CLICK,
  ICON_CS_CENTER_CLICK,
  ICON_CS_CENTER_COMMON,
  ICON_CS_CENTER_DISABLED,
  ICON_CS_CENTER_DISABLED_CLICK,
  ICON_MANY_CLICK,
  ICON_MANY_COMMON,
  ICON_MANY_DISABLED,
  ICON_MANY_DISABLED_CLICK,
  ICON_MANY_NOCHARGING,
  ICON_PARTNERS_CLICK,
  ICON_PARTNERS_COMMON,
  ICON_PARTNERS_DISABLED,
  ICON_PARTNERS_DISABLED_CLICK,
  ICON_POSITION,
} from "../../../constants/appImagePath";
import { ApiService } from "../../../restAPI/ApiService";
import PinMarker from "../component/PinMarker";
import { error } from "console";
import { PWUserAppMapUiEvent } from "../PWUserAppMapUiEvent";
import { PWUserAppMapUiState } from "../PWUserAppMapUiState";
import "./GoogleMap.css";
import { Box, Typography } from "@mui/material";
import ProgressBar from "../../../component/ProgressBar";
import { color } from "../../../theme/Color";

// 커스텀 렌더러 정의
// 마커 클러스터 이미지 결정
class CustomRenderer extends DefaultRenderer {
  render({ count, position }: { count: number; position: google.maps.LatLng }) {
    const color1 = color.primary500;

    // 클러스터 마커에 대한 스타일 정의
    const svg = window.btoa(`
      <svg fill="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" width="36" height="36">
        <circle cx="18" cy="18" r="18" />
        <text x="18" y="23" font-size="12" text-anchor="middle" font-weight="600" fill="${color1}">${count}</text>
      </svg>
    `);

    // SVG 이미지를 배경으로 하는 div 생성
    const div = document.createElement("div");
    div.style.backgroundImage = `url('data:image/svg+xml;base64,${svg}')`;
    div.style.width = "36px";
    div.style.height = "36px";
    div.style.boxShadow = "0px 0px 14px 0px rgba(0,0,0,0.3)";
    div.style.borderRadius = "18px";

    // AdvancedMarkerElement로 마커 생성
    const marker = new google.maps.marker.AdvancedMarkerElement({
      position,
      map: null, // 일단 map을 null로 설정, 나중에 MarkerClusterer에서 설정할 것
      content: div, // 생성한 div 요소 사용
    });

    return marker;
  }
}

interface GoogleMapProps {
  uiEvent: PWUserAppMapUiEvent;
  uiState: PWUserAppMapUiState;
  svcAreaID?: any;
}

export function handleMarkerClick(
  googleMap: any,
  position: any,
  svcAreaId: any,
  setClickedMarkerId: any,
  setDrawerOpen: any,
  ApiService: any,
  uiEvent: any
) {
  uiEvent.SetIsMarkerClick(true);
  if (googleMap) {
    const yOffset = -100; // 원하는 위치 조정값 (음수: 위로, 양수: 아래로)
    const targetPosition = new google.maps.LatLng(
      position.lat() + yOffset / 100000,
      position.lng() + -3 / 100000
    );
    const position1 = {
      lat: position.lat() + yOffset / 100000,
      lng: position.lng() + -3 / 100000,
    };
    uiEvent.SetFinalPosition(position1);

    googleMap.panTo(targetPosition); // Move the map to the marker's position
    googleMap.setZoom(17); // Optionally, adjust the zoom level
  }
  const svcAreaIdData = {
    svcAreaId: svcAreaId,
  };
  setClickedMarkerId(svcAreaId);
  ApiService.MobilePost("/pw/backend/api/geo/detailSvcArea", svcAreaIdData)
    .then((res: any) => {
      uiEvent.HandleDrawerDataChange(res?.data);
    })
    .catch((err: any) => {
      console.log(err);
    });
  setDrawerOpen(true);
}

function GoogleMap({ uiEvent, svcAreaID, uiState }: GoogleMapProps) {
  const [mapBounds, setMapBounds] = useState<google.maps.LatLngBounds>();
  const [markers, setMarkers] = useState<
    google.maps.marker.AdvancedMarkerElement[]
  >([]);
  // 유저 위치 마커
  const [userMarker, setUserMarker] =
    useState<google.maps.marker.AdvancedMarkerElement | null>(null);
  const [markerClusterer, setMarkerClusterer] = useState<MarkerClusterer>();
  // const [clickedMarkerId, setClickedMarkerId] = useState<string | null>(null);

  // 처음에만 실행되는지 여부를 체크하는 플래그
  // 로딩 상태를 관리하는 변수
  const [isLoading, setIsLoading] = useState(true);
  const isMapLoaded = useRef(false); // 처음에 false로 설정

  useEffect(() => {
    uiEvent.SetClickedMarkerId(svcAreaID);
  }, [svcAreaID]);

  const createMarker = (position: any, svcList?: any, svcAreaId?: string) => {
    const markerContainer = document.createElement("div") as HTMLDivElement & {
      _root?: Root;
    };
    const markerInstance = new google.maps.marker.AdvancedMarkerElement({
      position,
      map: uiState.googleMap,
      content: markerContainer,
    });

    let root: Root;
    if (!markerContainer._root) {
      root = createRoot(markerContainer);
      markerContainer._root = root;
    } else {
      root = markerContainer._root;
    }

    if (svcList.length >= 2) {
      // svcList가 두개 이상인 복합 핀일 경우
      // 모든 svcStt가 "CLOSE"인지 확인
      const allClosed = svcList.every((data: any) => data.svcStt === "CLOSE");
      const hasChargingStation = svcList.some(
        (data: any) => data.svcType === "Station"
      );

      const fullBatt = svcList.find(
        (data: any) => data.svcType === "Station"
      )?.fullBatt;

      if (hasChargingStation) {
        // svcList중 차징 스테이션을 포함했다면
        root.render(
          <>
            {uiState.clickedMarkerId === svcAreaId ? (
              <PinMarker
                key={svcAreaId}
                image={allClosed ? ICON_MANY_DISABLED_CLICK : ICON_MANY_CLICK}
                batteryCount={fullBatt}
                type="click"
              />
            ) : (
              <>
                {allClosed ? (
                  <PinMarker
                    key={svcAreaId}
                    image={ICON_MANY_DISABLED}
                    batteryCount={fullBatt}
                    type="noCount"
                  />
                ) : (
                  <PinMarker
                    key={svcAreaId}
                    image={ICON_MANY_COMMON}
                    batteryCount={fullBatt}
                    type="count"
                  />
                )}
              </>
            )}
          </>
        );
      } else {
        // svcList중 차징 스테이션이 없다면
        console.log(svcList);
        root.render(
          <>
            {uiState.clickedMarkerId === svcAreaId ? (
              <PinMarker
                key={svcAreaId}
                image={allClosed ? ICON_MANY_DISABLED_CLICK : ICON_MANY_CLICK}
                type="click"
              />
            ) : (
              <PinMarker
                key={svcAreaId}
                image={allClosed ? ICON_MANY_DISABLED : ICON_MANY_NOCHARGING}
                type="noCount"
              />
            )}
          </>
        );
      }
    } else if (svcList.length == 1) {
      // svcList가 1개인 일반 핀일 경우
      const { svcType: type, svcStt, fullBatt } = svcList[0];
      let image;
      let markerType: "noCount" | "click" | "count" | undefined = "noCount";

      if (type === "CS Center") {
        if (uiState.clickedMarkerId === svcAreaId) {
          image =
            svcStt === "OPEN"
              ? ICON_CS_CENTER_CLICK
              : ICON_CS_CENTER_DISABLED_CLICK;
          markerType = "click";
        } else {
          image =
            svcStt === "OPEN" ? ICON_CS_CENTER_COMMON : ICON_CS_CENTER_DISABLED;
        }
      } else if (type === "Cafe") {
        if (uiState.clickedMarkerId === svcAreaId) {
          image =
            svcStt === "OPEN" ? ICON_CAFE_CLICK : ICON_CAFE_DISABLED_CLICK;
          markerType = "click";
        } else {
          image = svcStt === "OPEN" ? ICON_CAFE_COMMON : ICON_CAFE_DISABLED;
        }
      } else if (type === "Station") {
        if (uiState.clickedMarkerId === svcAreaId) {
          image =
            svcStt === "OPEN"
              ? ICON_CHARGING_CLICK
              : ICON_CHARGING_DISABLED_CLICK;
          markerType = "click";
        } else {
          image =
            svcStt === "OPEN" ? ICON_CHARGING_COMMON : ICON_CHARGING_DISABLED;
          markerType = svcStt === "OPEN" ? "count" : "noCount";
        }
      } else if (
        type === "Restaurant" ||
        type === "Hotel" ||
        type === "Hot Spot" ||
        type === "Partners"
      ) {
        if (uiState.clickedMarkerId === svcAreaId) {
          image =
            svcStt === "OPEN"
              ? ICON_PARTNERS_CLICK
              : ICON_PARTNERS_DISABLED_CLICK;
          markerType = "click";
        } else {
          image =
            svcStt === "OPEN" ? ICON_PARTNERS_COMMON : ICON_PARTNERS_DISABLED;
        }
      }

      root.render(
        <PinMarker
          key={svcAreaId}
          image={image}
          type={markerType}
          batteryCount={
            type === "Station" && svcStt === "OPEN" ? fullBatt : undefined
          }
        />
      );
    }

    if (uiState.isSearchListClick == true) {
      handleMarkerClick(
        uiState.googleMap,
        uiState.position,
        uiState.svcAreaId,
        uiEvent.SetClickedMarkerId,
        uiEvent.HandleDrawerOpenChange,
        ApiService,
        uiEvent
      );
      uiEvent.SetIsSearchListClick(false);
    }

    markerInstance.addListener("click", () => {
      handleMarkerClick(
        uiState.googleMap,
        position,
        svcAreaId,
        uiEvent.SetClickedMarkerId,
        uiEvent.HandleDrawerOpenChange,
        ApiService,
        uiEvent
      );
    });

    return markerInstance;
  };

  const createUserPin = (instance: any) => {
    const markerContainer = document.createElement("div") as HTMLDivElement & {
      _root?: Root;
    };
    let root: Root;
    if (!markerContainer._root) {
      root = createRoot(markerContainer);
      markerContainer._root = root;
    } else {
      root = markerContainer._root;
    }
    root.render(
      <Box sx={{ width: 50, height: 50 }}>
        <Box component="img" src={ICON_POSITION} sx={{ pt: 4.1, pl: 1.2 }} />
      </Box>
    );
    // AdvancedMarkerElement 생성
    const userMarker = new google.maps.marker.AdvancedMarkerElement({
      position: {
        lat: uiState.userLat,
        lng: uiState.userLng,
      },
      map: instance,
      content: markerContainer,
    });
    return userMarker;
  };

  useEffect(() => {
    const mapContainer = document.createElement("div");
    const instance = new window.google.maps.Map(mapContainer, {
      center: {
        lat: uiState.finalPosition.lat,
        lng: uiState.finalPosition.lng,
      },
      zoom: 17,
      mapId: "92cb7201b7d43b21",
      disableDefaultUI: true,
      clickableIcons: false,
      minZoom: 3,
      maxZoom: 20,
      gestureHandling: "greedy",
    });
    mapContainer.id = "map";
    mapContainer.style.minHeight = "calc(100vh)";
    document.body.appendChild(mapContainer);
    console.log(
      "uiState.isLocationPermissionGranted is : ",
      uiState.isLocationPermissionGranted
    );
    if (uiState.isLocationPermissionGranted == true) {
      const userMarkerInstance = createUserPin(instance);
      setUserMarker(userMarkerInstance);
    }
    uiEvent.SetGoogleMap(instance);

    const markerCluster = new MarkerClusterer({
      map: instance,
      renderer: new CustomRenderer(),
      onClusterClick: (event: google.maps.MapMouseEvent, cluster: Cluster) => {
        // 클러스터 중심 좌표 가져오기
        const clusterCenter = cluster.position;
        if (clusterCenter) {
          // 클러스터 중심 좌표로 맵 이동 및 줌인
          instance.panTo(clusterCenter);
          const currentZoom = instance.getZoom() ?? 10; // getZoom()이 undefined일 경우 기본값 10 사용
          instance.setZoom(currentZoom + 3); // 원하는 만큼 줌인 (여기서는 2단계 줌인)
        }
      },
    });
    setMarkerClusterer(markerCluster);

    instance.addListener("click", () => {
      uiEvent.SetIsMarkerClick(false);
      uiEvent.HandleDrawerOpenChange(false);
      uiEvent.SetClickedMarkerId(null); // 마커 클릭 상태 초기화
      uiEvent.SetSvcAreaId(null);
    });

    // 'tilesloaded' 이벤트 리스너 추가
    const handleTilesLoaded = () => {
      if (!isMapLoaded.current) {
        // 처음에만 실행되도록 체크
        isLoading && setIsLoading(false); // 로딩 상태 종료
        if (!uiState.isMapOnloaded) {
          console.log("Map rendering completed!");
          uiEvent.CoordinatesClickTrue();
          uiEvent.SetIsMapOnloaded();
          isMapLoaded.current = true; // 플래그를 true로 설정
        }
      }
    };

    instance.addListener("tilesloaded", handleTilesLoaded);

    const handleIdle = () => {
      // const center = instance.getCenter();
      const bounds = instance.getBounds();
      if (bounds) {
        setMapBounds(bounds);
      }
    };

    const idleListener = instance.addListener("idle", handleIdle);

    return () => {
      google.maps.event.removeListener(idleListener);
      document.body.removeChild(mapContainer);
    };
  }, [uiState.isLocationPermissionGranted]);

  const [newMarkerList, setNewMarkerList] = useState<any[]>([]);

  useEffect(() => {
    const newMarkers = newMarkerList.map((data: any, index: any) => {
      const position = new google.maps.LatLng(
        data.geoPoint.lat,
        data.geoPoint.lon
      );

      return createMarker(position, data.svcList, data.svcAreaId);
    });

    setMarkers((prevMarkers) => {
      if (markerClusterer) {
        markerClusterer.clearMarkers();
        markerClusterer.addMarkers(newMarkers);
      }
      return newMarkers;
    });
  }, [uiState.isMarkerClick, uiState.clickedMarkerId]);

  // api 받아온 핀마커 처리
  useEffect(() => {
    uiEvent.CoordinatesClickFalse();

    if (mapBounds) {
      const northEast = mapBounds.getNorthEast();
      const southWest = mapBounds.getSouthWest();

      const northWest = new google.maps.LatLng(
        northEast.lat(),
        southWest.lng()
      );

      const southEast = new google.maps.LatLng(
        southWest.lat(),
        northEast.lng()
      );

      const latlngData = {
        top_left_vertex: [northWest.lng(), northWest.lat()],
        top_right_vertex: [northEast.lng(), northEast.lat()],
        bottom_left_vertex: [southWest.lng(), southWest.lat()],
        bottom_right_vertex: [southEast.lng(), southEast.lat()],
        svcType: uiState.svcFilterType,
      };

      ApiService.MobilePost("/pw/backend/api/geo/box", latlngData)
        .then((res: any) => {
          if (JSON.stringify(newMarkerList) === JSON.stringify(res.data.body)) {
          } else {
            const newMarkers = res.data.body.map((data: any, index: any) => {
              // console.log("data.geoPoint is : ", typeof data.geoPoint.lat);
              const position = new google.maps.LatLng(
                data.geoPoint.lat,
                data.geoPoint.lon
              );

              return createMarker(position, data.svcList, data.svcAreaId);
            });

            setMarkers((prevMarkers) => {
              if (markerClusterer) {
                markerClusterer.clearMarkers();
                markerClusterer.addMarkers(newMarkers);
              }
              return newMarkers;
            });
          }
          setNewMarkerList(res.data.body);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [mapBounds, uiState.clickedMarkerId, uiState.svcFilterType]);

  useEffect(() => {
    if (uiState.myCoordinatesClick == true) {
      if (userMarker) {
        userMarker.position = {
          lat: uiState.userLat,
          lng: uiState.userLng,
        };

        userMarker.map?.panTo(userMarker.position);
      }
      const position = {
        lat: uiState.userLat,
        lng: uiState.userLng,
      };
      uiEvent.SetFinalPosition(position);
    }
  }, [uiState.myCoordinatesClick]);

  useEffect(() => {
    if (userMarker) {
      userMarker.position = {
        lat: uiState.userLat,
        lng: uiState.userLng,
      };
    }
  }, [uiState.userLat]);

  return (
    <Box sx={{ position: "absolute", height: "100vh", width: "100vw" }}>
      {isLoading && (
        <Box
          sx={{
            position: "relative",

            top: "50%",
            right: "50%",
            backgroundColor: "rgba(255, 255, 255, 0)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000,
          }}
        >
          <Box>
            <ProgressBar />
          </Box>
        </Box>
      )}
      {/* Map will be rendered here */}
    </Box>
  );
}

export default GoogleMap;
