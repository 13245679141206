import { TableRow } from "@mui/material";
import { ProductionTableCell } from "./ProductionTableCell";
import { fontStyle } from "../../../theme/Style";
import { color } from "../../../theme/Color";
import { Text } from "../../../component/Text";
import LocalizedDate, { DateFormatType } from "../../../component/LocalizedDate";
import { ProductionManagementCellData } from "../model/ProductionManagementCellData";
import { ProductionData } from "./model/ProductionData";
import { useNavigate } from "react-router-dom";

interface ProductionTableRowProps {
  row: ProductionData;
}

export function ProductionTableRow({ row }: ProductionTableRowProps) {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`/productionDetails/${row.id}`);
  };
  const productCellData: ProductionManagementCellData[] = [
    new ProductionManagementCellData(`${row.no}`, "left", "auto", "18px"),
    new ProductionManagementCellData(`${row.category}`, "left"),
    new ProductionManagementCellData(`${row.partsCode}`, "left"),
    new ProductionManagementCellData(`${row.productName}`, "left"),
    new ProductionManagementCellData(`${row.spec}`, "left"),
    new ProductionManagementCellData(`${row.made}`, "left"),
    new ProductionManagementCellData(`${row.quantities}`, "left"),
    new ProductionManagementCellData(`${row.amount}`, "left"),
    new ProductionManagementCellData(`${row.state}`, "left"),
    new ProductionManagementCellData(
      LocalizedDate({
        date: row.registrationDate,
        type: DateFormatType.AdminType,
      }),
      "left"
    ),
    new ProductionManagementCellData(
      LocalizedDate({
        date: row.lastUpdateDate,
        type: DateFormatType.AdminType,
      }),
      "left"
    ),
  ];
  return (
    <TableRow onClick={onClick} sx={{ cursor: "pointer" }}>
      {productCellData.map((data: ProductionManagementCellData, index: number) => (
        <ProductionTableCell
          key={index}
          sx={{
            paddingLeft: data.paddingLeft,
            paddingRight: data.paddingRight,
            width: data.width || "auto",
            ...(index === productCellData.length - 6 ? { flex: 1, maxWidth: "66px" } : {}),
          }}
          childrenContainerSx={{ width: data.width }}
          align={data.align}
          onClick={data.onClick}
          children={
            <Text
              sx={{
                fontStyle: fontStyle.regularXS,
                color: color.gray800,
                ...(data.onClick
                  ? {
                      cursor: "pointer",
                    }
                  : {}),
              }}
              text={data.value}
            />
          }
        />
      ))}
    </TableRow>
  );
}
