import NotificationPageRender from "./NotificationPageRender";
import { useEffect, useRef, useState } from "react";
import { NotificationUiState } from "./model/NotificationUiState";
import useAndroidBackHandler from "../../../customHook/useAndroidBackHandler";
import { ApiService } from "../../../restAPI/ApiService";
import { PushPermissionModel } from "./model/PushPermissionModel";
import { Box } from "@mui/material";
import ProgressBar from "../../../component/ProgressBar";

export default function NotificationPage() {
  // 로컬스토리지에서 알림 정보 가져오기, 없으면 기본값 false 설정
  const getNotificationInfo = (key: string): boolean => {
    const result = localStorage.getItem(key);
    if (result === null) {
      localStorage.setItem(key, "false");
      return false;
    }
    return result === "true"; // 문자열 "true"를 boolean true로 변환
  };

  // UI 상태 초기화
  const [uiState, setUiState] = useState(
    new NotificationUiState(
      getNotificationInfo("service_notification"),
      getNotificationInfo("event_notification"),
    ),
  );
  const [progress, setProgress] = useState(false);

  // 디바운스 상태 관리
  const debounceRef = useRef({ service: false, event: false });

  // 안드로이드 백 버튼 처리
  useAndroidBackHandler(() => {
    window.history.back();
  });

  useEffect(() => {
    setProgress(true);
    getPushPermission()
      .then((res) => {
        setUiState((prev) => {
          return {
            ...prev,
            isActivatedServiceNotification: res?.isServiceConsent || false,
            isActivatedEventingNotification: res?.isMarketingConsent || false,
          };
        });
      })
      .catch((e) => {
        console.error("Failed to get push permission", e);
        setUiState((prev) => {
          return {
            ...prev,
            isActivatedServiceNotification: false,
            isActivatedEventingNotification: false,
          };
        });
      })
      .finally(() => {
        setProgress(false);
      });
  }, []);

  // 클릭 이벤트 발생 시 로컬 스토리지 업데이트 및 UI 상태 변경
  const handleServiceSwitch = () => {
    if (debounceRef.current.service) return; // 디바운스 상태일 경우 무시
    debounceRef.current.service = true;

    const currentState = uiState.isActivatedServiceNotification;
    setUiState((prev) => {
      return {
        ...prev,
        isActivatedServiceNotification: !currentState,
      };
    });

    setPushPermission("service", !currentState)
      .then((res) => {
        const newState = res ? !currentState : currentState;
        localStorage.setItem("service_notification", String(newState));

        setUiState((prev) => {
          return {
            ...prev,
            isActivatedServiceNotification: newState,
          };
        });
      })
      .finally(() => {
        debounceRef.current.service = false; // 디바운스 해제
      });
  };

  const handleEventSwitch = () => {
    if (debounceRef.current.event) return; // 디바운스 상태일 경우 무시
    debounceRef.current.event = true;

    const currentState = uiState.isActivatedEventingNotification;
    setUiState((prev) => {
      return {
        ...prev,
        isActivatedEventingNotification: !currentState,
      };
    });

    setPushPermission("marketing", !currentState)
      .then((res) => {
        const newState = res ? !currentState : currentState;
        localStorage.setItem("event_notification", String(newState));

        setUiState((prev) => {
          return {
            ...prev,
            isActivatedEventingNotification: newState,
          };
        });
      })
      .finally(() => {
        debounceRef.current.event = false; // 디바운스 해제
      });
  };

  async function setPushPermission(
    type: string,
    isOn: boolean,
  ): Promise<Boolean> {
    const url = "/pw/users/setPush";
    const requestBody = {
      type: type,
      isOn: isOn,
      uuid: localStorage.getItem("uuid"),
    };
    try {
      const res = await ApiService.post(url, requestBody);
      return res.status == 200;
    } catch (e) {
      console.error("Failed to set push permission", e);
      return false;
    }
  }

  async function getPushPermission(): Promise<PushPermissionModel | undefined> {
    const url = "/pw/users/userData";
    const requestBody = {
      uuid: localStorage.getItem("uuid"),
    };

    try {
      const res = await ApiService.post(url, requestBody);
      const data: PushPermissionModel = res.data.body;
      if (typeof data === "object" && Object.keys(data).length === 0)
        return undefined;
      localStorage.setItem(
        "service_notification",
        String(data.isServiceConsent),
      );
      localStorage.setItem(
        "event_notification",
        String(data.isMarketingConsent),
      );
      return data;
    } catch (e) {
      throw e;
    }
  }

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      {progress ? <ProgressBar /> : null}
      <NotificationPageRender
        uiState={uiState}
        onClickServiceSwitch={handleServiceSwitch}
        onClickEventSwitch={handleEventSwitch}
      />
    </Box>
  );
}
